import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./Home.css";
import Login from "../../components/Login";
import ForgotCredentials from "../../components/Login/ForgotCredentials";
import { homePage, getCountries } from "../../Store/Actions/userActions";
import Popup from "../../components/Popup";

const Home = () => {
  const [userForm, setuserForm] = useState(<Login />);

  const dispatch = useDispatch();

  const isLogin = useSelector((state) => state.isLogin);
  const [isLoginForm, setIsLoginForm] = useState(1);
  const [loginPageContent, setLoginPageConent] = useState(false);
  const homePageContent = useSelector(
    (state) => state.homepageContent.dynamicContent
  );

  useEffect(() => {
    homePage({ var_name: "landing" }, dispatch).then((res) => {
      dispatch({
        type: "HOMEPAGE_CONTENT",
        payload: res.data,
      });
    });

    homePage({ var_name: "login_popup" }, dispatch).then((res) => {
      dispatch({
        type: "SPECIAL_CONTENT",
        payload: res.data,
      });
    });
  }, []);

  useEffect(() => {
    if (homePageContent.status) {
      setLoginPageConent(homePageContent.status);
    }
  }, [homePageContent]);

  const homePageView = () => {
    return { __html: homePageContent.detail };
  };

  const changeForm = (type) => {
    switch (type) {
      case "forget Password":
        setuserForm(<ForgotCredentials />);
        setIsLoginForm(0);
        break;
      default:
        setuserForm(<Login />);
        setIsLoginForm(1);
        break;
    }
  };

  useEffect(() => {
    getCountries(dispatch);
  }, []);

  useEffect(() => {
    const home = document.getElementById("sd-home");
    home.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <div className="sdHome" id="sd-home">
        <div className="bnrBack">
          <div className="sdhBanner">
            <div className="sdhLogo">
              <img
                src="./assets/images/logo_home.png"
                alt="Seadwelling logo"
              ></img>
            </div>
            <h1 className="sdhBannerText">
              Welcome to
              <br />
              Sea Dwelling Creatures - Ordering System
            </h1>
          </div>
        </div>
        <div className="homeMainCnt">
          <div className="leftLoginScreen">
            <section className="specialsSection ">
              <div dangerouslySetInnerHTML={homePageView()} />
            </section>
          </div>
          <div className="rightLoginScreen">
            <section className="loginSection">
              <div className="container">
                <div className="sdhLogin">
                  {userForm}
                  {isLogin ? (
                    <a href="#" onClick={(e) => changeForm("back to home")}>
                      Back To Home
                    </a>
                  ) : isLoginForm ? (
                    <a href="#" onClick={(e) => changeForm("forget Password")}>
                      Forgot Username or Password ?
                    </a>
                  ) : (
                    <a href="#" onClick={(e) => changeForm()}>
                      Login
                    </a>
                  )}
                </div>

                <div className="loginContent">
                  <h3>NEED HELP LOGGING IN?</h3>
                  <p>
                    If you already have an account with SDC Inc, but you are
                    having trouble logging in, you can retrieve your password by
                    clicking here:{" "}
                    <a href="#" onClick={(e) => changeForm("forget Password")}>
                      Forgot Username or Password?
                    </a>{" "}
                    and your password will be sent to the email on file with SDC
                    Inc.
                  </p>
                  <p className="mb-0">
                    For help logging in, Please send an email to:
                  </p>
                  <h4 className="sprtEml">SUPPORT@SEADWELLING.COM</h4>
                  <p>
                    Please include your store name and state, so we can find
                    your account.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="bottomLoginScreen">
          {/* <section>
            <div className="container lead-specials text-center">
              <div className="ro">
                <div className="col-sm-10 offset-sm-1">
                  <h1>OSS LEADER SPECIALS: +40% OFF!!!!</h1>
                  <ul>
                    <li>BLUE "HIPPO" TANG*</li>
                    <li>GREEN MANDARIN GOBY*</li>
                    <li>KUPANG DAMSEL*</li>
                    <li>SCISSORTAIL GOBY*</li>
                    <li>ORBIC CARDINAL*</li>
                    <li>MAXI-MINI CARPET ANEMONE COLORED*</li>
                    <li>VELVET SEA SLUG*</li>
                    <li>RICORDEA MUSHROOM GREEN*</li>
                    <li>TIGER BRITTLE STAR*</li>
                    <li>TIGER SAND CONCH*</li>
                  </ul>
                  <p>
                    **Loss Leader - Quantity and Size Limits are in place for
                    these item...Please see our stock list for details. Offer
                    good on orders of $450 or more.
                  </p>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </>
  );
};

export default Home;
