import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const GreenCheckbox = withStyles({
  root: {
    color: "#56A5C8",
    "&$checked": {
      color: "#56A5C8",
    },
    MuiFormControlLabelRoot: {
      marginBottom: 0,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CustomCheckBox = (props) => {
  /*   const [state, setState] = React.useState({
    checkedA: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }; */

  return (
    <FormControlLabel
      control={
        <GreenCheckbox
          name={props.name}
          checked={props.checked}
          value={props.value}
          onChange={props.onchange ? (e) => props.onchange(e) : null}
          onClick={props.onclick ? (e) => props.onclick(e) : null}
        />
      }
      label={props.label}
    />
  );
};

export default CustomCheckBox;
