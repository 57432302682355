import React, { useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Button,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import "./popAlert.css";
import PrimaryButton from "../PrimaryButton";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const Popup = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch({
        type: "POPUP_MESSAGE",
        payload: {},
      });
    };
  }, []);
  return (
    <div className="popup-alt">
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={props.maxWidth ? props.maxWidth : "sm"}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={`${props.classNameDialog} common-popup cstmPopDetls`}
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className={`popup-header ${props.headingClassName}`}
        >
          <span className="popup-title mr-5">{props.heading}</span>
          <Close
            className={`float-right popup-close ${props.closeButtonClass}`}
            onClick={props.onCloseCross ? props.onCloseCross : props.onClose}
          />
        </DialogTitle>

        <DialogContent>
          <DialogContentText
            id={`alert-dialog-slide-description ${props.dynamicId}`}
            style={{ color: "black" }}
            className={props.contentClassName}
          >
            {props.content}
          </DialogContentText>
        </DialogContent>

        <DialogActions className={props.classNameDialogActions}>
          {props.action ? (
            <PrimaryButton
              className={`${props.okButtonClass} wip`}
              onsubmit={props.onClose}
            >
              {props.action}
            </PrimaryButton>
          ) : null}
          {props.secondaryAction ? (
            <PrimaryButton
              className={`${props.submitButtonClass} wip`}
              onsubmit={props.secClickAction}
            >
              {props.secondaryAction}
            </PrimaryButton>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Popup;
