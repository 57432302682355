const intialState = {
  loadStatus: false,
};

export default function (state = intialState, action) {
  switch (action.type) {
    case "ACCOUNT_OVERVIEW_INFO_UPDATED":
      return {
        ...state,
        statusDtls: action.payload,
        loadStatus: true,
      };
    default:
      return state;
  }
}
