import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: "3em",
      width: "100%",
    },
    "& .MuiInput-underline": {
      paddingBottom: "1em",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#48BF84",
    },
    input: {
      fontFamily: "Ubuntu",
    },
    "label.MuiFormLabel-root.Mui-focused": {
      color: "#48BF84",
    },
  },
}));

const SelectField = (props) => {
  const classes = useStyles();

  return (
    <TextField
      id={props.id}
      select
      label={props.label}
      defaultValue={props.defaultValue}
      SelectProps={{
        native: true,
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      name={props.name}
      onChange={props.pc_onchange}
      {...props}
    >
      {props.children}
    </TextField>
  );
};

export default SelectField;
