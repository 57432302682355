import React from "react";
import "./MyAccount.css";
import Layout from "./Layout";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SecondaryButton from "../../components/SecondaryButton";
import MaterialTable from "material-table";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const ManageAddress = () => {
  const [state, setState] = React.useState({
    columns: [
      { title: "Nickname", field: "nickname" },
      { title: "Address", field: "address" },
      { title: "City", field: "city" },
      { title: "State", field: "state" },
      { title: "Zip", field: "zip" },
      { title: "Country", field: "country" },
    ],
    data: [
      {
        nickname: "Test Name",
        address: "Test Addresss",
        city: "Test city",
        state: "Test State",
        zip: "Test zip",
        country: "Country",
      },
      {
        nickname: "Test Name",
        address: "Test Addresss",
        city: "Test city",
        state: "Test State",
        zip: "Test zip",
        country: "Country",
      },
      {
        nickname: "Test Name",
        address: "Test Addresss",
        city: "Test city",
        state: "Test State",
        zip: "Test zip",
        country: "Country",
      },
      {
        nickname: "Test Name",
        address: "Test Addresss",
        city: "Test city",
        state: "Test State",
        zip: "Test zip",
        country: "Country",
      },
      {
        nickname: "Test Name",
        address: "Test Addresss",
        city: "Test city",
        state: "Test State",
        zip: "Test zip",
        country: "Country",
      },
    ],
  });
  const classes = useStyles();
  return (
    <div className="accountOverview">
      <Layout
        title={
          <div className="d-flex align-items-center justify-content-between">
            Manage Address{" "}
            <Button variant="outlined" color="primary">
              Add a new Address
            </Button>
          </div>
        }
      >
        <MaterialTable
          title=""
          columns={state.columns}
          data={state.data}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  if (oldData) {
                    setState((prevState) => {
                      const data = [...prevState.data];
                      data[data.indexOf(oldData)] = newData;
                      return { ...prevState, data };
                    });
                  }
                }, 600);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                setTimeout(() => {
                  resolve();
                  setState((prevState) => {
                    const data = [...prevState.data];
                    data.splice(data.indexOf(oldData), 1);
                    return { ...prevState, data };
                  });
                }, 600);
              }),
          }}
        />
      </Layout>
    </div>
  );
};
export default ManageAddress;
