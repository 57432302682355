export default {
  email: { value: "", isRequired: true, errMsg: "This is required" },
  firstName: { value: "", isRequired: true, errMsg: "This is required" },
  lastName: { value: "", isRequired: true, errMsg: "This is required" },
  phone: { value: "", isRequired: true, errMsg: "This is required" },
  mobilePhone: { value: "", isRequired: true, errMsg: "This is required" },
  fax: { value: "", isRequired: false, errMsg: "This is required" },
  companyName: { value: "", isRequired: true, errMsg: "This is required" },
  address: { value: "", isRequired: true, errMsg: "This is required" },
  address2: { value: "", isRequired: false, errMsg: "This is required" },
  country: { value: "", isRequired: true, errMsg: "This is required" },
  city: { value: "", isRequired: true, errMsg: "This is required" },
  state: { value: "", isRequired: false, errMsg: "This is required" },
  zipcode: { value: "", isRequired: true, errMsg: "This is required" },
  shippingInformation: {
    value: "",
    isRequired: false,
    errMsg: "This is required",
    isChecked: false,
  },
  addressBook: {
    value: "",
    isRequired: false,
    errMsg: "This is required",
    isChecked: false,
  },
  snickname: { value: "", isRequired: false, errMsg: "This is required" },
  sphone: { value: "", isRequired: false, errMsg: "This is required" },
  semail: { value: "", isRequired: false, errMsg: "This is required" },
  orderPlacedBy: { value: "", isRequired: true, errMsg: "This is required" },
  scompanyName: { value: "", isRequired: false, errMsg: "This is required" },
  saddress1: { value: "", isRequired: true, errMsg: "This is required" },
  saddress2: { value: "", isRequired: false, errMsg: "This is required" },
  scountry: { value: "", isRequired: true, errMsg: "This is required" },
  scity: { value: "", isRequired: true, errMsg: "This is required" },
  sstate: { value: "", isRequired: false, errMsg: "This is required" },
  szipcode: { value: "", isRequired: true, errMsg: "This is required" },
};
