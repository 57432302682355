import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

import "./combineOrders.css";
import {
  getAllUsersAdmin,
  getAdminSelectedUserDtls,
  combineOrderAdmin,
  getUserViewDetailProd,
} from "../../Store/Actions/cart.actions";
import PrimaryButton from "../PrimaryButton";
import Popup from "../../components/PopupAlert";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    // backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const CombineOrder = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [viewOrdDtls, setViewOrdDtls] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [orderUserDtls, setOrderUserDtls] = useState({});
  const [expanded, setExpanded] = React.useState("");

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.users.users);
  const combinOrderUser = useSelector((state) => state.combineOrder);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    getAllUsersAdmin({ sessionKey: userInfo.session_id }, dispatch);
  }, []);

  useEffect(() => {
    console.log("===== combinOrderUser", combinOrderUser);
    var x = document.getElementsByTagName("input");
    for (let i of x) {
      if (i && i.type == "checkbox") {
        i.checked = false;
      }
    }
    setSelectedOrder([]);
  }, [combinOrderUser]);

  useEffect(() => {
    console.log("===== selectedOrder", selectedOrder);
  }, [selectedOrder]);

  const changeUserDtls = (e) => {
    // setSelectedUser(e.target.value);
    let val = e.target.value;
    if (val == "") {
      setSelectedUser("");
    } else {
      if (val.includes("(") && val.includes(")")) {
        if (val.split("(")[val.split("(").length - 1].split(")")[0]) {
          setSelectedUser(
            val.split("(")[val.split("(").length - 1].split(")")[0]
          );
        }
      } else {
        setSelectedUser("undefined");
      }
    }
  };

  const selectedUserDtls = () => {
    if (selectedUser !== "undefined") {
      let userDtls = {
        sessionKey: userInfo.session_id,
        user_id: selectedUser,
      };
      getAdminSelectedUserDtls(userDtls, dispatch);
    }
  };

  const handleCombineOrder = () => {
    let usersCombine = {
      sessionKey: userInfo.session_id,
      orderIds: selectedOrder,
    };
    combineOrderAdmin(usersCombine, dispatch).then((res) => {
      if (res.data.status) {
        selectedUserDtls();
      }
    });
  };

  const handleCombineOrderNumber = (e, eleId) => {
    let chckVal = [...selectedOrder];
    let checked = e.target.checked;
    if (checked) {
      setSelectedOrder([...selectedOrder, eleId]);
    } else {
      let indexVal = chckVal.indexOf(eleId);
      if (indexVal > -1) {
        chckVal.splice(indexVal, 1);
        setSelectedOrder(chckVal);
      }
    }
  };

  const onViewSelectedProductDtls = (eleId, elet) => {
    setViewOrdDtls(true);
    setOrderUserDtls(elet);
    let ordDtls = {
      sessionKey: userInfo.session_id,
      order_id: eleId,
    };
    getUserViewDetailProd(ordDtls, dispatch);
  };
  const onViewProdtDtlsClose = () => {
    setViewOrdDtls(false);
    setExpanded("");
  };

  const contentAreaPopup = () => {
    return (
      <div>
        <Accordion
          square
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
          className="order-info"
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography className="acc-heading">
              <span> General Information</span>{" "}
              <span class="material-icons">expand_more</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {/* <p className="heading-topic">General Information</p> */}
              <div className="general-info">
                <div className="general-info-data">
                  <p>OrderId : {`W${orderUserDtls.order_id}`}</p>
                  <p>Date/Time:{orderUserDtls.ordered_date}</p>
                  <p>Order Total:${orderUserDtls.order_total}</p>
                  <p style={{ lineBreak: "anywhere" }}>
                    Comments:
                    {orderUserDtls.order_comment.split("#").map((ele) => (
                      <span>{ele}</span>
                    ))}
                  </p>
                  <p>
                    Sku Comments:
                    {combinOrderUser.orderViewPrd.map((ele) => (
                      <span>
                        {ele.sku} : {ele.comment}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
          className="billing-acc"
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography className="acc-heading">
              <span> Billing & Shipping Information</span>{" "}
              <span class="material-icons">expand_more</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className="address-info">
                <b>Billing Information :</b>
                <p>
                  {orderUserDtls.billing_firstname}
                  {orderUserDtls.billing_lastname}
                </p>
                <p>{orderUserDtls.billing_company}</p>
                <p>{orderUserDtls.billing_address1}</p>
                <p>{orderUserDtls.billing_address2}</p>
                <p>
                  {orderUserDtls.billing_city},{orderUserDtls.billing_zipcode}.
                </p>
                <p>{orderUserDtls.billing_country}</p>
                <p>{orderUserDtls.billing_phone}</p>
                <p>{orderUserDtls.billing_email}</p>
              </div>
              <div className="address-info">
                <b>Shipping Information :</b>
                <p>{orderUserDtls.shipping_company}</p>
                <p>{orderUserDtls.ship_address}</p>
                <p>
                  {orderUserDtls.ship_city},{orderUserDtls.ship_zipcode}.
                </p>
                <p>{orderUserDtls.ship_country}</p>
                <p>{orderUserDtls.shipping_phone}</p>
                <p>{orderUserDtls.shipping_email}</p>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
          className="table-Dtls"
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography className="acc-heading">
              <span> Order Details</span>{" "}
              <span class="material-icons">expand_more</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div>
                {/* <p className="heading-topic"></p> */}
                {console.log(combinOrderUser, "combinOrderUser")}
                <div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th style={{ whiteSpace: "nowrap" }}>Qty</th>
                        <th>Sku</th>
                        <th>Description</th>
                        <th>Unit Price</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {combinOrderUser.orderViewPrd.map((ele) => (
                        <tr key={ele.sku}>
                          <td>{ele.qty}</td>
                          <td>{ele.sku}</td>
                          <td>{ele.product_nm}</td>
                          <td>{ele.unitprice}</td>
                          <td>{ele.dunitprice}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };
  return (
    <>
      <div className="myAccountLayout">
        <div className="combine-order-heading">
          <div>
            <h3 className="accountTitle">Combine Order</h3>
          </div>
          <div className="user-search">
            <div>
              <lable>User</lable>
              &nbsp;
              <input
                list="users"
                name="users"
                onChange={changeUserDtls}
              ></input>
              {/* <select onChange={changeUserDtls} value={selectedUser}> */}
              <datalist id="users">
                <option value={""}>Select User</option>
                {combinOrderUser.adminUserInfo.map((ele) => (
                  <option
                    key={ele.user_id}
                    value={`${ele.username} - ${ele.companyname} (${ele.user_id})`}
                  ></option>
                ))}
              </datalist>
              {/* </select> */}
            </div>
            <div className="user-search-btn">
              <PrimaryButton onsubmit={selectedUserDtls}>Search</PrimaryButton>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12 d-flex">
            <table className="table">
              <tbody>
                <tr className="">
                  <td>WEB No SwitchMode</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <tbody>
                <tr className="reorder">
                  <td>REORDER</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <tbody>
                <tr className="app">
                  <td>APP ORDER</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <tbody>
                <tr className="combined">
                  <td>COMBINED</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <tbody>
                <tr className="switchmode">
                  <td>SWITCH MODE</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <tbody>
                <tr className="finalorder">
                  <td>FINAL ORDER</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <div className="user-combine-ord">
          <PrimaryButton onsubmit={handleCombineOrder}>
            Combine Order
          </PrimaryButton>
        </div>
        <div className="table-respon">
          <table className="table table-bordered edit-saved-cart-table">
            <thead>
              <tr>
                <th className="no-wrap-tablehead"></th>
                <th className="no-wrap-tablehead">Action</th>
                <th className="no-wrap-tablehead">Order Id</th>
                <th className="no-wrap-tablehead">Username</th>
                <th className="no-wrap-tablehead">Order Total</th>
                <th className="no-wrap-tablehead">Order Date</th>
              </tr>
            </thead>
            <tbody>
              {combinOrderUser.selectAdminDtls &&
              typeof combinOrderUser.selectAdminDtls !== "undefined" &&
              combinOrderUser.selectAdminDtls.length !== 0
                ? combinOrderUser.selectAdminDtls.map((ele) => (
                    <tr key={ele.order_id} className={ele.style}>
                      <td>
                        {
                          <input
                            type="checkbox"
                            id={ele.username}
                            name={ele.username}
                            value={ele.username}
                            onClick={(e) =>
                              handleCombineOrderNumber(e, ele.order_id)
                            }
                          />
                        }
                      </td>
                      <td>
                        <div className="view-ord-cmb">
                          <PrimaryButton
                            onsubmit={() =>
                              onViewSelectedProductDtls(ele.order_id, ele)
                            }
                          >
                            View
                          </PrimaryButton>
                        </div>
                      </td>
                      {/* <td>W{ele.order_id}</td> */}
                      <td>{ele.sageorder_id}</td>
                      <td>{ele.username}</td>
                      <td>{ele.order_total}</td>
                      <td>{ele.ordered_date}</td>
                    </tr>
                  ))
                : null}

              {combinOrderUser.selectAdminDtls &&
              typeof combinOrderUser.selectAdminDtls !== "undefined" &&
              combinOrderUser.selectAdminDtls.length === 0 ? (
                <p> No Data Found</p>
              ) : null}
            </tbody>
          </table>
        </div>
        <div className="user-combine-ord">
          <PrimaryButton onsubmit={handleCombineOrder}>
            Combine Order
          </PrimaryButton>
        </div>
        {/* <Pagination
        page={currentPage}
        count={Math.ceil(100 / 50)}
        // onChange={currentPageNumber}
      /> */}
      </div>
      {viewOrdDtls &&
      Object.keys(orderUserDtls).length !== 0 &&
      combinOrderUser.orderViewPrd &&
      typeof combinOrderUser.orderViewPrd !== "undefined" &&
      combinOrderUser.orderViewPrd.length !== 0 ? (
        <Popup
          open={viewOrdDtls}
          heading={`Order #W${orderUserDtls.order_id}`}
          onClose={onViewProdtDtlsClose}
          action={"Close"}
          content={contentAreaPopup()}
          classNameDialogActions="popup-buttons-checkout"
          classNameDialogTitle="popup-header product-checkout-close-icon"
          classNameDialogContentText="popup-checkout-content"
          classNameDialog="popup-checkout-alert"
        />
      ) : null}
    </>
  );
};
export default CombineOrder;
