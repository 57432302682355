import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Cart.css";
import InputField from "../../components/Input/InputField";
import { Button, Breadcrumbs, Typography } from "@material-ui/core";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import { Link, withRouter } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import Layout from "../../components/MasterLayout";
import BlinkingText from "../../components/BlinkingText";
import moment, { duration } from "moment-timezone";
import {
  deleteAllIncart,
  fetchCartItem,
  addItemToCart,
} from "../../Store/Actions/cart.actions";
import { wishlistList } from "../../Store/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import {
  updateCartItem,
  deleteSingleCartItem,
  deleteOutOfStock,
} from "../../Store/Actions/cart.actions";
import TextareaPopup from "../../components/TextAreaPopup/index";
import SaveToCart from "./SaveToCart";
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import {
  addComment,
  Searchfilters,
  deleteWatchList,
} from "../../Store/Actions/userActions";
import popupClass from "../../utils/emitPopup";
import { CartTimer } from "../../components/Header/TopHeader";
import toastifyClass from "../../utils/emitToastify";
import DetailsPopup from "../../components/DetailsPopupCart";
import Popup from "../../components/Popup";

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    border: "1px solid rgba(224, 224, 224, 1)",
    "&": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  body: {
    fontSize: 14,
    paddingTop: "10px",
    paddingBottom: "10px",
    border: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child": {
      // backgroundColor: theme.palette.action.hover,
      fontWeight: "500",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderCollapse: "collapse",
  },
  container: {
    // maxHeight: 600,
  },
});

const Cart = (props) => {
  const classes = useStyles();
  const userInfo = useSelector((state) => state.users.users);
  const cartDetails = useSelector((state) => state.cart);
  const userInfoWishlist = useSelector((state) => state.users);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cartInfo, setCartInfo] = useState([]);
  const [showSaveCart, setShowSaveCart] = useState(false);
  const [textAreapopTog, settextAreapopTog] = useState(false);
  const [textareapopData, settextareapopData] = useState({});
  const [okbuttonData, setOkButtonData] = useState({});
  const [paramval, setParamVal] = useState({});
  const [eachItem, setEachItem] = useState({});
  const [productCheckout, setProductCheckout] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const loading = useSelector((state) => state.loader);
  const cart = useSelector((state) => state.cart);
  const outOfStock = useSelector((state) => state.cart.outOfStock);
  const [wishList, setWishList] = useState([]);
  const [productView, setProductView] = useState({
    details: [],
    popupView: false,
  });
  const location = useHistory();

  const [isWish, setIsWish] = useState(false);

  useEffect(() => {
    fetchCartItem({
      sessionKey: userInfo.session_id,
    })(dispatch);
  }, []);

  useEffect(() => {
    if (!cart.cartPopup) setShowSaveCart(false);
  }, [cart.cartPopup]);

  useEffect(() => {
    let chngData = [];
    if (
      cartDetails.savedCart.wishlist &&
      cartDetails.savedCart.wishlist.length > 0
    )
      setIsWish(true);

    cartDetails.savedCart.items
      .slice()
      .filter((ele) => {
        if (typeof props.match !== "undefined") {
          if (typeof props.match.params !== "undefined") {
            if (typeof props.match.params.timer !== "undefined") {
              if (props.match.params.timer === "timer") {
                return (
                  ele.sku_dtls.live === 1 ||
                  ele.sku_dtls.live === 2 ||
                  ele.sku_dtls.live === 3
                );
              }
            }
          }
        }
        return true;
      })
      .map((item) => {
        let obj = {
          name: item.name,
          websku: item.websku,
          sku_dtls: item.sku_dtls,
        };
        item["similar_items"] = [obj];
        if (chngData.length === 0) {
          chngData.push(item);
        } else {
          let indexist = chngData.findIndex(
            (ele) => ele.websku === item.websku
          );
          if (indexist === -1) {
            chngData.push(item);
          } else {
            let alreadyexistsObj = chngData.find(
              (ele) => ele.websku === item.websku
            );
            alreadyexistsObj["similar_items"].push(obj);
          }
        }

        return item;
      });
    let priceArr = chngData.map(({ similar_items }) => {
      return similar_items.reduce((acc, val) => {
        let totalVal =
          acc +
          parseFloat(val.sku_dtls.owned_quantity * val.sku_dtls.retail_price);
        return totalVal;
      }, 0);
    });

    let totalValArr = priceArr.reduce((acc, val) => acc + parseFloat(val), 0);

    setTotalPrice(totalValArr.toFixed(2));
    setCartInfo(chngData);
  }, [cartDetails, paramval]);

  useEffect(() => {
    setParamVal(props.match.params);
  }, [props.match.params]);

  const changeCartQty = (ind, index) => (e) => {
    let newChangeValue = [...cartInfo];
    if (!isNaN(e.target.value)) {
      newChangeValue[index].similar_items[ind].sku_dtls.owned_quantity =
        e.target.value;
      setCartInfo([...newChangeValue]);
    }
  };

  useEffect(() => {
    if (cartInfo.length > 0) {
      let priceArr = cartInfo.map(({ similar_items }) => {
        return similar_items.reduce((acc, val) => {
          let totalVal =
            acc +
            parseFloat(val.sku_dtls.owned_quantity * val.sku_dtls.retail_price);
          return totalVal;
        }, 0);
      });

      let totalValArr = priceArr.reduce((acc, val) => acc + parseFloat(val), 0);

      setTotalPrice(totalValArr.toFixed(2));
      dispatch({
        type: "CART_TOTAL",
        payload: totalValArr.toFixed(2),
      });
    }
  }, [cartInfo]);

  const onCloseProductCheckoutPopup = () => {
    setProductCheckout(false);
  };
  const onClickUpdate = (item) => (e) => {
    e.preventDefault();
    if (item.sku_dtls.owned_quantity > 0) {
      let updateInfo = {
        sessionKey: userInfo.session_id,
        websku: item.websku,
        items: [
          {
            sku: item.sku_dtls.sku,
            qty: item.sku_dtls.owned_quantity,
          },
        ],
      };
      // cartInfo[index].sku_dtls.owned_quantity --> this has the quantity
      //cartInfo[index].owned_quantity --> give undefined so changed it

      updateCartItem(updateInfo, dispatch).then((res) => {
        if (res.data.status) {
          fetchCartItem({
            sessionKey: userInfo.session_id,
          })(dispatch);
        }
      });
    } else {
      new popupClass(
        true,
        "Zero Qty choosen on Sku: " + item.sku_dtls.sku,
        "Zero Quantity",
        "",
        "",
        "",
        "",
        dispatch
      ).emitPopup();
    }
  };

  const onDelete = (similarItemIndex, cartInfoIndex) => (e) => {
    e.preventDefault();
    let deletInfo = {
      sessionKey: userInfo.session_id,
      sku: cartInfo[cartInfoIndex]["similar_items"][similarItemIndex][
        "sku_dtls"
      ]["sku"],
    };
    deleteSingleCartItem(deletInfo, dispatch).then(() => {
      // alert("Deleted Successfully");
      fetchCartItem({
        sessionKey: userInfo.session_id,
      })(dispatch);
    });
  };

  const onShopping = () => {
    history.push("/specialProduct?page=1");
  };
  const onDeleteAllItems = () => {
    const deleteData = {
      sessionKey: userInfo.session_id,
    };
    deleteAllIncart(deleteData, dispatch).then((res) => {
      fetchCartItem({
        sessionKey: userInfo.session_id,
      })(dispatch).then((res1) => {
        dispatch({
          type: "CART_LOADING",
          payload: false,
        });
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
      });
    });
  };
  const handleTextAreaClose = () => {
    settextAreapopTog(false);
  };
  const handleCommentValue = (value) => {
    let reqObj = {
      sessionKey: userInfo.session_id,
      websku: textareapopData.websku,
      comment: value,
      sku: textareapopData.sku_dtls.sku,
    };
    addComment(reqObj, dispatch).then(function () {
      settextAreapopTog(false);
      fetchCartItem({
        sessionKey: userInfo.session_id,
      })(dispatch);
    });
  };
  const addCommentopen = (data) => (e) => {
    let obj = {
      sessionKey: userInfo.session_id,
      search_value: data.name,
      search_key: 3,
      size: "25",
      page: "1",
      cid: "",
    };
    Searchfilters(obj, dispatch);
    settextareapopData(data);
    settextAreapopTog(true);
  };
  const saveCartClose = () => {
    setShowSaveCart(false);
  };
  const saveCartOpen = () => {
    setShowSaveCart(true);
    dispatch({
      type: "CART_POPUP",
      payload: true,
    });
  };
  const outOfStockItems = () => {
    return (
      <>
        <p>There are items in your order which are no longer available.</p>
        <p>Please remove or replace them before checkout.</p>
        <ul>
          {outOfStock.detail.map((item) => (
            <li>{item.name}</li>
          ))}
        </ul>
      </>
    );
  };

  const onClickRemoveOutofStock = () => {
    deleteOutOfStock({ sessionKey: userInfo.session_id }, dispatch).then(
      (res) => {
        if (res.data.status) {
          let freqObj = {
            sessionKey: userInfo["session_id"],
          };
          fetchCartItem(freqObj)(dispatch);
          dispatch({
            type: "POPUP_MESSAGE_CLOSE",
            payload: false,
          });
        }
      }
    );
  };
  const onClickClosePopupOutofStock = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    history.push("/cart");
  };

  const tableLoop = (detail) => {
    const sku_sum = detail.reduce((prev, curr) => prev + curr.qty, 0);
    return (
      <table className="table">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Qty</th>
          </tr>
        </thead>
        <tbody>
          {detail.map((d) => (
            <tr>
              <td>{d.sku}</td>
              <td>{d.qty}</td>
            </tr>
          ))}
          <tr>
            <td>
              <b>Total</b>
            </td>
            <td>
              <b>{sku_sum}</b>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const proceedCheckout = () => {
    if (outOfStock && !outOfStock?.status) {
      new popupClass(
        true,
        outOfStockItems(),
        "No Longer Available",
        "Remove For Me And Checkout",
        "Return To Cart",
        "cart-remove-for-me",
        "cart-return-to-cart",
        dispatch,
        onClickRemoveOutofStock,
        onClickClosePopupOutofStock,
        "out-of-stock-close",
        "",
        "out-of-stock-buttons"
      ).emitPopup();
    } else {
      if (cartDetails.wishlist.length > 0) {
        // new popupClass(
        //   true,
        //   "Some items were removed and placed in wishlist. Do you want to add back to the cart page?",
        //   "Products in Wishlist",
        //   "Yes",
        //   "No",
        //   "",
        //   "",
        //   dispatch,
        //   onClickWishListRedirect,
        //   onClickCloseReturnCheckout,
        //   "",
        //   "",
        //   "cart-wishlist-checkout",
        //   "",
        //   onClickCloseInCart
        // ).emitPopup();
        setProductCheckout(true);
      } else {
        if (
          userInfo.detail &&
          (userInfo.detail.account_type == "rep" ||
            userInfo.detail.account_type == "all")
        ) {
          alert(
            "Rep account is not be allowed to submit order. Please switch user."
          );
          return false;
        }
        let reqObj = {
          sessionKey: userInfo.session_id,
          viewTime: 1,
          check_websku_limit: 1,
          furnace_qty_check: 1,
        };
        fetchCartItem(reqObj)(dispatch).then((res) => {
          let zeroQty = [];
          if (res.data?.status == true) {
            res.data.detail.map((ele) => {
              if (ele.sku_dtls.owned_quantity <= 0) {
                zeroQty.push(ele.sku_dtls.sku);
              }
            });
            if (zeroQty.length > 0) {
              new popupClass(
                true,
                "Zero Qty choosen on Sku: " + zeroQty.join(","),
                "Zero Quantity",
                "",
                "",
                "",
                "",
                dispatch
              ).emitPopup();
              setProductCheckout(false);
              zeroQty = [];
            } else {
              setProductCheckout(false);
              zeroQty = [];
              history.push({ pathname: "/checkout" });
            }
          } else {
            if (res?.data.status == false && res.data.flag == "websku_limit") {
              let aa = tableLoop(res.data.wsku_detail);
              setProductCheckout(false);
              new popupClass(
                true,
                aa,
                `Websku limit exceeded: limit is ${res.data.websku_limit}`,
                "",
                "",
                "",
                "",
                dispatch
              ).emitPopup();
            } else if (
              res?.data?.status == false &&
              res?.data?.flag == "furnace_qty"
            ) {
              setProductCheckout(false);
              fetchCartItem({
                sessionKey: userInfo.session_id,
              })(dispatch);
              new popupClass(
                true,
                res.data.message,
                "Furnace quantity",
                "",
                "",
                "",
                "",
                dispatch
              ).emitPopup();
            }
          }
        });
      }
    }
  };

  const onClickCloseInCart = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const onClickWishListRedirect = () => {
    history.push({ pathname: "/account", state: { tab: 1 } });
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const pushToWishlist = () => {
    history.push({
      pathname: "/account",
      state: {
        tab: 1,
      },
    });
  };

  const onClickCloseReturnCheckout = () => {
    if (
      userInfo.detail &&
      (userInfo.detail.account_type == "rep" ||
        userInfo.detail.account_type == "all")
    ) {
      alert(
        "Rep account is not be allowed to submit order. Please switch user."
      );
      return false;
    }
    let reqObj = {
      sessionKey: userInfo.session_id,
      viewTime: 1,
      check_websku_limit: 1,
      furnace_qty_check: 1,
    };
    fetchCartItem(reqObj)(dispatch).then((res) => {
      let zeroQty = [];
      if (res.data?.status == true) {
        res.data.detail.map((ele) => {
          if (ele.sku_dtls.owned_quantity <= 0) {
            zeroQty.push(ele.sku_dtls.sku);
          }
        });
        if (zeroQty.length > 0) {
          new popupClass(
            true,
            "Zero Qty choosen on Sku: " + zeroQty.join(","),
            "Zero Quantity",
            "",
            "",
            "",
            "",
            dispatch
          ).emitPopup();
          setProductCheckout(false);
          zeroQty = [];
        } else {
          setProductCheckout(false);
          zeroQty = [];
          history.push({ pathname: "/checkout" });
        }
      } else {
        if (res?.data?.status == false && res?.data?.flag == "websku_limit") {
          let aa = tableLoop(res.data.wsku_detail);
          setProductCheckout(false);
          new popupClass(
            true,
            aa,
            `Websku limit exceeded: limit is ${res.data.websku_limit}`,
            "",
            "",
            "",
            "",
            dispatch
          ).emitPopup();
        } else if (
          res?.data?.status == false &&
          res?.data?.flag == "furnace_qty"
        ) {
          setProductCheckout(false);
          fetchCartItem({
            sessionKey: userInfo.session_id,
          })(dispatch);
          new popupClass(
            true,
            res.data.message,
            "Furnace quantity",
            "",
            "",
            "",
            "",
            dispatch
          ).emitPopup();
        }
      }
    });
    // setProductCheckout(false);
    // console.log("hfjgfhf")
    // history.push({ pathname: "/checkout" });
  };

  useEffect(() => {
    let reqObj = {
      sessionKey: userInfo["session_id"],
      search_value: "",
      search_key: "",
      size: "",
      page: 1,
      sort: "",
      dir: "",
    };
    wishlistList(reqObj, dispatch);
  }, []);

  const delteAllOk = () => {
    return (
      <p className="cart-confirm-deleted">
        <span class="material-icons">delete_outline</span>
        <span className="confirm-delte-text">Yes</span>
      </p>
    );
  };

  const deleteAllConfimation = () => {
    new popupClass(
      true,
      "Are you sure you want to clear cart?",
      "Confirm Delete!",
      "No",
      delteAllOk(),
      "cart-confirm-delete",
      "cart-confirm-close",
      dispatch,
      "",
      onDeleteAllItems,
      "",
      "",
      "cart-delete-confirm"
    ).emitPopup();
  };

  const listOfWishlist = () => {
    let wishlistArr = cartDetails.wishlist
      .slice(0, 5)
      .map((ele) => ele.sku)
      .join("|");
    return wishlistArr;
  };

  const onClickViewDetails = (item) => {
    setProductView({ ...productView, details: item, popupView: true });
  };

  const okClose = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const okButtonCallback = () => {
    okbuttonData["override"] = "1";
    let wish_req = {
      sessionKey: userInfo.session_id,
      search_value: "",
      search_key: "",
      size: 10,
      page: 1,
      sort: "",
      dir: "",
    };
    addItemToCart(okbuttonData, dispatch)
      .then(() => wishlistList(wish_req, dispatch))
      .then(() => {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
      });
  };

  const onpop = () => {
    setProductView({ ...productView, popupView: false });
  };

  const redirectToWishlist = () => {
    setProductCheckout(false);
    if (
      userInfoWishlist &&
      typeof userInfoWishlist !== "undefined" &&
      userInfoWishlist.fullWishlist &&
      typeof userInfoWishlist.fullWishlist !== "undefined" &&
      userInfoWishlist.fullWishlist.detail &&
      typeof userInfoWishlist.fullWishlist.detail !== "undefined"
    ) {
      userInfoWishlist.fullWishlist.detail.forEach(addingToCart);
    }
  };

  const addingToCart = (item) => {
    let itemVal = {
      sessionKey: userInfo.session_id,
      webSKU: item.websku,
      SKU: item.sku,
      qty: parseInt(item.wishlist_qty),
    };
    let wish_req = {
      sessionKey: userInfo.session_id,
      search_value: "",
      search_key: "",
      size: 10,
      page: 1,
      sort: "",
      dir: "",
    };
    setOkButtonData(itemVal);
    addItemToCart(
      itemVal,
      dispatch,
      okButtonCallback,
      undefined,
      undefined,
      undefined,
      okClose
    )
      .then((res) => {
        let reqObj = {
          sessionKey: userInfo["session_id"],
          id: item.id,
        };
        if (res.data.status) {
          deleteWatchList(reqObj, dispatch);
        }
      })
      .then(() => {
        wishlistList(wish_req, dispatch);

        fetchCartItem({
          sessionKey: userInfo.session_id,
        })(dispatch);
      });
  };
  // SetInitialWishlist(false);
  useEffect(() => {
    if (userInfo && typeof userInfo !== "undefined" && userInfo.status) {
      let wish_req = {
        sessionKey: userInfo.session_id,
        search_value: "",
        search_key: "",
        size: "",
        page: "",
        sort: "",
        dir: "",
      };
      wishlistList(wish_req, dispatch, true).then((res) => {
        if (res.data.status) {
          dispatch({
            type: "FULL_WISHLIST",
            payload: res.data,
          });
        }
      });
    }
  }, [userInfo]);
  const productPopupButtons = () => {
    return (
      <>
        <PrimaryButton onsubmit={redirectToWishlist}>
          Add Item to cart
        </PrimaryButton>
        <PrimaryButton onsubmit={pushToWishlist}>Go to wishlist</PrimaryButton>
        <PrimaryButton onsubmit={onClickCloseReturnCheckout}>
          Proceed to checkout
        </PrimaryButton>
      </>
    );
  };
  const imageError = (e) => {
    return (e.target.src =
      "https://auctionsoftwaremarketplace.com/seadwelling/images/default/NoImageAvailable.jpg");
  };
  return (
    <Layout>
      {loading && loading.cartLoading ? (
        <Loader />
      ) : cartInfo.length !== 0 && Array.isArray(cartInfo) ? (
        <>
          {showSaveCart && <SaveToCart close={saveCartClose} />}
          <div className="sdCart container-fluid" style={{ paddingTop: 45 }}>
            <div>
              <div className="cartAct d-flex justify-content-between align-items-center">
                <PrimaryButton
                  variant="outlined"
                  className="delete-button"
                  onsubmit={deleteAllConfimation}
                >
                  <span
                    className="material-icons"
                    style={{ paddingRight: "10px" }}
                  >
                    delete_sweep
                  </span>
                  Delete all items
                </PrimaryButton>
                {/* {Object.keys(paramval).length === 0 ? (
                  <SecondaryButton
                    variant="outlined"
                    className="second-button"
                    onClick={saveCartOpen}
                  >
                    <span
                      className="material-icons"
                      style={{ paddingRight: "10px" }}
                    >
                      bookmark_border
                    </span>
                    Save this cart
                  </SecondaryButton>
                ) : null} */}
              </div>
            </div>
            {cartInfo && typeof cartInfo != "undefined"
              ? cartInfo.map((item, index) => (
                  <div className="my-3 cart-table">
                    <div className="d-flex justify-content-between align-items-center">
                      <h3
                        className="itemName"
                        onClick={() => onClickViewDetails(item)}
                      >
                        {item.sku_dtls.description}
                      </h3>
                      {/* <div id="ccaatoc">
                        <SecondaryButton
                          onsubmit={addCommentopen(item)}
                          size="small"
                          label={`${
                            item.comments !== "" ? "View" : "Add"
                          } Comment`}
                        />
                      </div> */}
                    </div>
                    <div className="tblWrapper">
                      <Table
                        className={`cart-table-data ${classes.table}`}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              <span className="cart-table-heading">Size</span>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <span className="cart-table-heading">
                                Qty Avail
                              </span>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <span className="cart-table-heading">Price</span>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <span className="cart-table-heading">Order</span>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <span className="cart-table-heading">
                                Comment
                              </span>
                            </StyledTableCell>
                            {Object.keys(paramval).length != 0 ? (
                              <StyledTableCell align="center">
                                <span className="cart-table-heading">Time</span>
                              </StyledTableCell>
                            ) : null}
                            <StyledTableCell align="center">
                              <span className="cart-table-heading">Delete</span>
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {typeof item.similar_items !== "undefined" &&
                          Array.isArray(item.similar_items) &&
                          item.similar_items.length
                            ? item.similar_items.map((ele, ind) => {
                                return (
                                  <React.Fragment key={ind}>
                                    <StyledTableRow key={"cartiNfo" + ind}>
                                      <StyledTableCell
                                        component="th"
                                        scope="row"
                                        align="center"
                                        className="cart-table-data"
                                      >
                                        <div className="imageThumbnail">
                                          <img
                                            src={item.image}
                                            onError={imageError}
                                            alt={item.websku}
                                          />
                                        </div>
                                        {ele &&
                                        typeof ele.sku_dtls !== "undefined" &&
                                        ele.sku_dtls.size === ""
                                          ? "-"
                                          : ele.sku_dtls.size}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="cart-table-data"
                                      >
                                        {isNaN(ele.sku_dtls.quantity_available)
                                          ? "-"
                                          : parseInt(
                                              ele.sku_dtls.quantity_available
                                            )}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="cart-table-data"
                                      >
                                        ${ele.sku_dtls.retail_price}
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="cart-table-data"
                                      >
                                        <div className="updateCart d-flex justify-content-center align-items-center">
                                          <InputField
                                            label="Quantity"
                                            size="small"
                                            value={ele.sku_dtls.owned_quantity}
                                            type="number"
                                            name="owned_quantity"
                                            onChange={changeCartQty(ind, index)}
                                          />
                                          <SecondaryButton
                                            variant="outlined"
                                            color="primary"
                                            onClick={onClickUpdate(ele)}
                                          >
                                            Update Cart
                                          </SecondaryButton>
                                        </div>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        align="center"
                                        className="cart-table-data"
                                      >
                                        <div id="addCommentBtn">
                                          <SecondaryButton
                                            size="small"
                                            onsubmit={addCommentopen(ele)}
                                            label={`${
                                              ele.sku_dtls.comment !== ""
                                                ? "View"
                                                : "Add"
                                            }`}
                                          />
                                        </div>
                                      </StyledTableCell>
                                      {Object.keys(paramval).length != 0 ? (
                                        <StyledTableCell align="center">
                                          <CartTimer
                                            isTooltip={false}
                                            websku={ele.websku}
                                            sku={ele.sku_dtls.sku}
                                            isStyle={false}
                                            isHeaderLoad={false}
                                            cartIndex={index}
                                            {...props}
                                          />
                                        </StyledTableCell>
                                      ) : null}
                                      <StyledTableCell align="center">
                                        <Button onClick={onDelete(ind, index)}>
                                          <span
                                            className="material-icons"
                                            style={{ color: "#c00" }}
                                          >
                                            delete
                                          </span>
                                        </Button>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                    <div className="d-flex flex-column">
                                      {ind === item.similar_items.length - 1 ? (
                                        <p
                                          className="my-2"
                                          style={{
                                            fontSize: "16px",
                                            textAlign: "left",
                                            fontWeight: "400",
                                          }}
                                        >
                                          <b> Sub Total: </b> $
                                          {item.similar_items
                                            .reduce((acc, val) => {
                                              let totalVal =
                                                acc +
                                                parseFloat(
                                                  val.sku_dtls.retail_price *
                                                    val.sku_dtls.owned_quantity
                                                );
                                              return totalVal;
                                            }, 0)
                                            .toFixed(2)}
                                        </p>
                                      ) : null}

                                      {item.sku_dtls.live == 1 && (
                                        <div>
                                          <BlinkingText value="Live Item" />
                                        </div>
                                      )}
                                    </div>
                                  </React.Fragment>
                                );
                              })
                            : null}
                        </TableBody>
                      </Table>
                    </div>
                  </div>
                ))
              : null}
            {Object.keys(paramval).length === 0 ? (
              <>
                {cartDetails.wishlist && cartDetails.wishlist.length > 0 && (
                  <Alert
                    icon={<i className="fa fa-heart cart-ribbon-heart"></i>}
                    severity="info"
                    className="cart-ribbon"
                  >
                    <div className="d-flex justify-content-between align-items-center w-100 cart-ribbon-text">
                      Some items in your wishlist are ready to checkout
                      <Link
                        to={{
                          pathname: "/account",
                          state: {
                            tab: 1,
                          },
                        }}
                      >
                        <Button className="cart-ribbon-button">
                          Click Here
                        </Button>
                      </Link>
                    </div>
                    <span className="cart-ribbon-tooltip-inner">
                      <h5 className="wishlist-heading-cart">Wishlist Items</h5>
                      <span>
                        {listOfWishlist()}
                        {cartDetails.wishlist.length > 5 ? (
                          <span>...</span>
                        ) : null}
                      </span>
                      {/* <ul className="wishlist-items">
                        {cartDetails.wishlist.slice(0, 5).map((ele) => (
                          <li className="wishlist-sku">
                            <span>{ele.sku}</span>|
                          </li>
                        ))}
                      
                      </ul> */}
                    </span>
                  </Alert>
                )}
                <div className="totalAmt mt-5">
                  <b style={{ fontSize: "20px" }}>
                    Total: ${totalPrice ? totalPrice : 0}
                  </b>
                  <div className="checkoutActBtn d-flex justify-content-center flex-row-reverse my-2">
                    <PrimaryButton
                      label="Proceed to checkout"
                      onClick={proceedCheckout}
                      className="proceed-checkout"
                    />

                    <Link
                      to="/specialProduct?page=1"
                      className="continue-shopping-link"
                    >
                      <Button className="continue-shopping-proceed">
                        Continue Shopping
                      </Button>
                    </Link>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </>
      ) : (cartInfo.length === 0 &&
          Object.keys(cartDetails.savedCart).length !== 0 &&
          cartDetails.savedCart.count === 0) ||
        (cartDetails.savedCart.items &&
          cartDetails.savedCart.items.length === 0) ? (
        <div className="" style={{ paddingTop: "15%" }}>
          <p className="no-items">There are no items added to your cart !</p>
          <SecondaryButton
            variant="outlined"
            color="primary"
            onsubmit={onShopping}
            className="second-button continue-shopping"
          >
            Continue Shopping
          </SecondaryButton>
        </div>
      ) : null}
      {textAreapopTog ? (
        <TextareaPopup
          textOpen={true}
          close={handleTextAreaClose}
          commentChange={handleCommentValue}
          comments={textareapopData.sku_dtls.comment}
          {...textareapopData}
        ></TextareaPopup>
      ) : null}
      {productView.popupView && typeof productView.details !== "undefined" ? (
        <DetailsPopup
          value={productView.popupView}
          onpop1={onpop}
          details={productView.details}
          popupCart={false}
        />
      ) : null}
      {productCheckout ? (
        <Popup
          fullWidth={true}
          maxWidth={"sm"}
          open={productCheckout}
          heading={"Checkout"}
          onClose={onCloseProductCheckoutPopup}
          actions={productPopupButtons()}
          classNameDialogActions="popup-buttons-checkout"
          classNameDialogTitle="popup-header product-checkout-close-icon"
          classNameDialogContentText="popup-checkout-content"
          classNameDialog="popup-checkout-alert"
        >
          Some items were removed and placed in wishlist. Do you want to add
          back to the cart page?
        </Popup>
      ) : null}
    </Layout>
  );
};
export default withRouter(Cart);
