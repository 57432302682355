import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import MasterLayout from "../MasterLayout";
import AllFurnance from "../AllFurnance";
import "../AllFurnance/AllFurnace.css";
import NoImage from "../../assets/images/NoImageAvailable.jpg";
import { FullscreenExitOutlined } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { addComment, Searchfilters } from "../../Store/Actions/userActions";
import TextAreaPopup from "../TextAreaPopup";
import DetailsPopup from "../DetailsPopup/index";
import popupClass from "../../utils/emitPopup";
import Loader from "../../components/Loader";
import "./furnance.css";
import Pagination from "@material-ui/lab/Pagination";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { socketChangeInFurnace } from "../../Store/Actions/socketActions";

const Furnance = (props) => {
  const [textAreaOpen, setTextAreaOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  const refReload = useRef(false);

  const infiniteScroll = useRef(null);

  const [commentDts, setCommentDtls] = useState({});

  const [pageWish, setPageWish] = useState(1);
  const refPageWish = useRef(1);
  const listDataRef = useRef(null);

  const [detailsPopUpData, setdetailsPopUpData] = useState({});
  const [stockCartItems, setStockCartItems] = useState([]);

  const [detailsPopupToggle, setDetailsPopupToggle] = useState(false);

  const nav_name = useSelector((state) => state.Search.nav_name);

  const fetchLoader = useSelector((state) => state.Search.fetchLoader);
  const cartDetails = useSelector((state) => state.cart);

  const furnanceLoaded = useSelector((state) => state.Search.furnanceLoad);
  const masterLayoutValueSelect = useSelector(
    (state) => state.Search.masterLayoutValue
  );
  const history = useHistory();

  const [onLoad, setOnLoad] = useState(false);
  const pageClear = useSelector((state) => state.Search.menuSelect);
  const totalFurnanceData = useSelector(
    (state) => state.Search.furnanceTotalData
  );
  const loader = useSelector((state) => {
    return state.loader;
  });

  const furnanceSearch = useSelector((state) => state.Search);

  const lists = useSelector((state) => state.Search.details);

  useEffect(() => {
    refPageWish.current = pageWish;
    listDataRef.current = lists;
    refReload.current = reload;
  });

  // useEffect(() => {
  //   if (cartDetails.savedCart.count > 0) {
  //     setStockCartItems(cartDetails.savedCart.items);
  //   }
  // }, [cartDetails]);

  // useEffect(() => {
  //   if (stockCartItems.length > 0) {
  //     let badCartItems = stockCartItems;
  //     badCartItems.map((ele, index) => {
  //       if (ele.sku_dtls.purchaseitem == "Y") {
  //         ele.sku_dtls.purchaseitem = "";
  //         ele.sku_dtls.timeadded = Moment.utc().format();
  //         // stockCartItems[index].db_time = 1;
  //         stockCartItems[index].db_time_live = localStorage.getItem("db_time_live") ? parseInt(localStorage.getItem("db_time_live")) : 45;
  //         stockCartItems[index].db_time_managed = localStorage.getItem("db_time_managed") ? parseInt(localStorage.getItem("db_time_managed")) : 45;
  //       }
  //       dispatch({
  //         type: "CART_DATA",
  //         payload: {
  //           detail: badCartItems,
  //         },
  //       });
  //     });
  //   }
  // }, [stockCartItems]);

  const ref = useRef(null);

  const handleTextAreaOpen = (data) => {
    setCommentDtls(data);
    setTextAreaOpen(true);
  };

  const userInfo = useSelector((state) => state.users.users);

  const dispatch = useDispatch();

  const handleTextAreaClose = (e) => {
    setTextAreaOpen(false);
  };

  useEffect(() => {
    sessionStorage.setItem("furnancePageNumber", JSON.stringify(1));
    const parms = new URLSearchParams(props.location.search);
    let pagenumber = parms.getAll("page")[0];
    console.log(pagenumber, "pagenumber");
    setPageWish(Number(pagenumber));
  }, []);

  const handleCommentValue = (commentVal) => {
    let valueComts = {
      sessionKey: userInfo.session_id,
      websku: commentDts["websku"],
      comment: commentVal,
    };
    addComment(valueComts, dispatch)
      .then((res) => {
        if (props && typeof props.location != "undefined") {
          let ID = props.location.search.split("=")[1].split("&")[0];
          let obj = {
            sessionKey: userInfo.session_id,
            search_value: "",
            search_key: "",
            size: "100",
            page: "1",
            cid: ID ? ID : "",
          };
          Searchfilters(obj, dispatch);
        }
      })
      .catch((error) => {
        throw error;
      });
    handleTextAreaClose();
  };

  const titleClick = (props) => {
    setdetailsPopUpData(props);
    setDetailsPopupToggle(true);
  };

  const detailspopupClose = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };
  const detailsPopupCloseFurnace = () => {
    setDetailsPopupToggle(false);
  };

  const imageError = (e) => {
    return (e.target.src =
      "https://auctionsoftwaremarketplace.com/seadwelling/images/default/NoImageAvailable.jpg");
  };
  const imgUrl = (imgdtls) => {
    if (imgdtls.inv_item == 1 || imgdtls.inv_item == 2) {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/live/${imgdtls.image}`;
    } else {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/static/${imgdtls.image}`;
    }
  };

  const tester = (imgdtls) => {
    return (
      <img
        src={imgdtls.image.replace("/thumb", "/thumb/compress")}
        alt="imgAlt"
        onError={imageError}
        className="furnace-img"
      />
    );
  };

  const furnaceImgOpen = (imgdtls) => {
    new popupClass(
      true,
      tester(imgdtls),
      imgdtls.description,
      "",
      "",
      "",
      "",
      dispatch,
      detailspopupClose,
      undefined,
      undefined,
      "furnace-img-popup"
    ).emitPopup();
  };

  useEffect(() => {
    if (pageClear && typeof pageClear !== "undefined" && pageClear) {
      setPageWish(1);
      dispatch({
        type: "MENU_SELECTED",
        payload: false,
      });
    }
  }, [pageClear]);

  const wishListPageChange = (event, value) => {
    setPageWish(value);
    const parms = new URLSearchParams(props.location.search);
    let ID = parms.getAll("key")[0];

    let masterLayoutVal = JSON.parse(sessionStorage.getItem("masterLayout"));

    let searchKey = parms.getAll("searchKey")[0];
    let searchValue = parms.getAll("searchValue")[0];

    history.push(
      `/furnance?key=${ID}&page=${value}&searchKey=${
        searchKey && searchKey !== "undefined" ? searchKey : ""
      }&searchValue=${
        searchValue && searchValue !== "undefined" ? searchValue : ""
      }`
    );

    let data = {
      sessionKey: userInfo.session_id,
      search_value: searchValue ? searchValue : "",
      search_key: searchKey ? searchKey : "",
      size: "100",
      page: value,
      cid: ID ? ID : "",
      sortbyprice: masterLayoutVal ? masterLayoutVal.sortbyprice : "",
      pricesearch: masterLayoutVal ? masterLayoutVal.pricesearch : "",
      main_coralType: masterLayoutVal ? masterLayoutVal.main_coralType : "",
    };

    Searchfilters(data, dispatch, true, true, true).then((res) => {
      if (res.status) {
        dispatch({
          type: "FETCH_LOAD",
          payload: false,
        });
      }
    });
  };

  const changeReload = () => {
    setReload(!refReload.current);
  };
  useEffect(() => {
    socketChangeInFurnace((data) => {
      if (Number(data.updated) === 1) {
        if (props && typeof props.location != "undefined") {
          let ID = props.location.search.split("=")[1];
          if (
            listDataRef.current &&
            typeof listDataRef.current !== "undefined"
          ) {
            let listData = listDataRef.current.data;
            if (
              listData &&
              typeof listData != "undefined" &&
              Array.isArray(listData[0])
            ) {
              let variableChanged = false;
              let changedArray = [];
              changedArray[0] = [];
              listData[0].map((ele) => {
                if (typeof data.new_stock[ele.sku.toString()] !== "undefined") {
                  variableChanged = true;
                  ele.quantity_available = data.new_stock[ele.sku.toString()];
                  changedArray[0].push(ele);
                  return true;
                } else {
                  changedArray[0].push(ele);
                  return true;
                }
              });
              if (variableChanged) {
                lists.data = changedArray[0];
                changeReload();
              }
            }
          }

          // let skuAdded = data.sku;

          // let currentPageSkus = listDataRef.current.filter((ele) =>
          //   skuAdded.includes(ele)
          // );
          // if (Boolean(currentPageSkus)) {
          //   let ID = props.location.search.split("=")[1];
          //   let obj = {
          //     sessionKey: userInfo.session_id,
          //     search_value: "",
          //     search_key: "",
          //     size: "100",
          //     page: refPageWish.current,
          //     cid: ID ? ID : "",
          //   };
          //   Searchfilters(obj, dispatch, false, true, true).then((res) => {
          //     if (res.status) {
          //       dispatch({
          //         type: "FETCH_LOAD",
          //         payload: false,
          //       });
          //     }
          //   });
          // }
        }
      }
    });
  }, []);
  useEffect(() => {
    if (
      masterLayoutValueSelect &&
      typeof masterLayoutValueSelect !== "undefined"
    ) {
      let masterLayoutVal = JSON.parse(sessionStorage.getItem("masterLayout"));
      setPageWish(masterLayoutVal.page);
      dispatch({
        type: "MASTER_LAYOUT_VALUE_SELECTED",
        payload: false,
      });
    }
  }, [masterLayoutValueSelect]);

  useEffect(() => {
    if (
      lists.data &&
      typeof lists.data != "undefined" &&
      lists.data.length === 1 &&
      Array.isArray(lists.data[0])
    ) {
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
    window.scrollTo(0, 0);
  }, [lists]);

  return (
    <>
      <MasterLayout {...props}>
        <div className="allFurnace mx-3" ref={ref}>
          <>
            <h4
              className=" sub-catergorie-name p-3"
              style={{ marginLeft: -30 }}
            >
              {nav_name}
            </h4>
            <div className="paginationAndAddAll" style={{ marginLeft: -30 }}>
              <div className="paginationSection">
                {totalFurnanceData &&
                typeof totalFurnanceData !== "undefined" ? (
                  <div className="mt-3">
                    <Pagination
                      page={pageWish}
                      className={"mt-2"}
                      count={Math.ceil(lists.total_row / 100)}
                      onChange={wishListPageChange}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            {loader.isLoading ? (
              <>
                <p
                  style={{
                    marginTop: "3rem",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Loading..
                </p>

                <Loader />
              </>
            ) : lists.data &&
              typeof lists.data != "undefined" &&
              lists.data[0] &&
              lists.data[0].length > 0 ? (
              <div style={{ paddingTop: 110 }}>
                <p className="furnace-notice-live-sale">
                  ****NOTICE**** THE ORDER QUANTITY BOX WILL APPEAR ONLY WHEN
                  THE SALE STARTS. <br />{" "}
                  <span>
                    Sale Runs from:{" "}
                    {Moment(lists.startdate).utc().format("LLLL")} to{" "}
                    {Moment(lists.enddate).utc().format("LLLL")}.
                  </span>
                </p>
                <div className="furnace" id="furnace">
                  <div className="furnaceWrapper">
                    {lists.data[0].map((key, value) =>
                      key.retail_price && key.quantity_available ? (
                        <>
                          <AllFurnance
                            {...key}
                            title={key.name}
                            price={key.retail_price}
                            special_price={key.special_price}
                            qty={key.quantity_available}
                            image={key.image}
                            cities={key.cities}
                            alt="No Image Available"
                            className={`m-2 furnaceChild`}
                            popOpen={handleTextAreaOpen}
                            titleClick={titleClick}
                            furnaceImgOpen={furnaceImgOpen}
                            // onDataChange={onstateChange}
                            listDate={lists}
                            pageNo={pageWish}
                          />
                        </>
                      ) : null
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {textAreaOpen ? (
              <TextAreaPopup
                {...props}
                {...commentDts}
                textOpen={textAreaOpen}
                close={handleTextAreaClose}
                commentChange={handleCommentValue}
              />
            ) : null}
            {lists.total_row === 0 && (
              <section
                className="container"
                style={{ textAlign: "center", paddingTop: 110 }}
              >
                <object data="./assets/svg/noData.svg" type="image/svg+xml" />
                <h2>We could not find any results for your query.</h2>
                <h3>Try searching with a different name.</h3>
              </section>
            )}
          </>
        </div>
      </MasterLayout>
      {detailsPopupToggle ? (
        <div className="allFurnaceDetailsPopUP">
          <DetailsPopup
            value={detailsPopupToggle}
            details={{ ...detailsPopUpData }}
            onpop1={detailsPopupCloseFurnace}
            fromDate={lists.startdate}
            toDate={lists.enddate}
            {...props}
          ></DetailsPopup>
        </div>
      ) : null}
    </>
  );
};
export default Furnance;
