import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Popup = (props) => {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      onClose={props.onCloseBackground}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={props.classNameDialog}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        className={props.classNameDialogTitle}
      >
        <span className="title">{props.heading}</span>
        <Close
          className={`float-right ${props.classNameClose}`}
          onClick={props.onClose}
        />
      </DialogTitle>
      <form onSubmit={props.onsubmit}>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            className={props.classNameDialogContentText}
          >
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={props.classNameDialogActions}>
          {props.actions}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Popup;
