const initalState = {
  exportedData: "",
};
export default function (state = initalState, action) {
  switch (action.type) {
    case "EXPORT_BULK_ORDER":
      return {
        ...state,
        exportedData: action.payload,
      };
    default:
      return state;
  }
}
