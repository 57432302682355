import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";

import "./MyAccount.css";
import Layout from "./Layout";
import ChangePasswordPopup from "../../components/ChangePasswordPopup";
import AddAddress from "../../components/AddAddress";
import AccountOverviewPopup from "../../components/AccontOverviewPopup";
import {
  accountOverview,
  getShippingDetails,
  updateShippingDetails,
  deleteShippingDetails,
} from "../../Store/Actions/userActions";
import { getSavedCart } from "../../Store/Actions/cart.actions";
import { materialtableState } from "./utils/accountOverview.state";
import testdata from "../../utils/testData";
import AccOverviewTable from "../../components/AccountOverviewTable/AccOverviewTable.component";
import Pagination from "../../components/Pagination/index";
import SecondaryButton from "../../components/SecondaryButton";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const AccountOverview = ({ accountDtlsInfo }) => {
  // Local States

  const [passwordPopup, setPasswordPopup] = useState(false);
  const [addressPopup, setAddressPopup] = useState(false);
  const [editContactPopup, setEditContactPopup] = useState(false);

  const [lshippingAddress, setLshippingAddress] = useState([]);
  const [ship_req, setShip_req] = useState({
    sessionKey: "",
    search_value: "",
    search_key: "",
    size: 5,
    page: 1,
    sort: "",
    dir: "",
  });

  const [editData, setEditData] = useState({});

  // END
  const handleChangePasswordOpen = () => {
    setPasswordPopup(true);
  };

  const handleChangePasswordClose = () => {
    setPasswordPopup(false);
  };

  const handlecChangeAddAddressOpen = (ele, type) => {
    switch (type) {
      case "edit":
        setEditData(ele);
        setAddressPopup(true);
        break;
      default:
        setEditData({});
        setAddressPopup(true);
    }
  };
  const handlecChangeAddAddressClose = () => {
    setEditData({});
    setAddressPopup(false);
  };

  const editContactInfoPopupOpen = () => {
    setEditContactPopup(true);
  };

  const editContactInfoPopupClose = () => {
    setEditContactPopup(false);
  };
  const fallbackFromPagination = (pageNumber) => {
    const requestObj = {};
    requestObj["sessionKey"] = userInfo["session_id"];
    requestObj["search_value"] = "";
    requestObj["search_key"] = "";
    requestObj["size"] = 5;
    requestObj["page"] = pageNumber;
    requestObj["sort"] = "";
    requestObj["dir"] = "";
    setShip_req({ ...ship_req, sessionKey: userInfo["session_id"] });
    getShippingDetails(requestObj, dispatch);
  };
  const userInfo = useSelector((state) => state.users.users);
  const userState = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const accountInfoUpdated = useSelector((state) => state.accountDtlsUpdated);

  useEffect(() => {
    const requestObj = {};
    requestObj["sessionKey"] = userInfo["session_id"];
    requestObj["search_value"] = "";
    requestObj["search_key"] = "";
    requestObj["size"] = 5;
    requestObj["page"] = 1;
    requestObj["sort"] = "";
    requestObj["dir"] = "";
    setShip_req({ ...ship_req, sessionKey: userInfo["session_id"] });
    getShippingDetails(requestObj, dispatch);
  }, []);

  useEffect(() => {
    accountOverview(
      { sessionKey: userInfo.session_id, type: "view" },
      dispatch
    );
  }, [accountInfoUpdated]);

  useEffect(() => {
    setLshippingAddress(userState.shippingAddress);
  }, [userState.shippingAddress]);
  useEffect(() => {
    let getValueOfSavedCart = {
      sessionKey: userInfo.session_id,
      search_value: "",
      search_key: "",
      size: "",
      page: "",
      sort: "",
      dir: "",
      fromDate: "",
      toDate: "",
    };
    getSavedCart(getValueOfSavedCart, dispatch);
  }, []);
  return (
    <div className="accountOverview">
      <Layout title="My Account">
        <hr />
        <div className="accHead d-flex justify-content-between align-items-center">
          <h4>Contact</h4>
          <div className="d-flex justify-content-end align-items-center">
            <SecondaryButton
              variant="outlined"
              color="primary"
              onsubmit={editContactInfoPopupOpen}
              className="second-button edit-contact-info"
            >
              Edit Contact Info
            </SecondaryButton>
            {accountDtlsInfo && Object.keys(accountDtlsInfo).length ? (
              <AccountOverviewPopup
                accountViewPopOpen={editContactPopup}
                accountViewPopClose={editContactInfoPopupClose}
                accountDtlsInfor={accountDtlsInfo}
              />
            ) : null}
            <SecondaryButton
              variant="outlined"
              onsubmit={handleChangePasswordOpen}
              className="mx-4 custom-button second-button change-password"
            >
              Change Password
            </SecondaryButton>
            <ChangePasswordPopup
              changePasswordOpen={passwordPopup}
              changePasswordClose={handleChangePasswordClose}
            />
          </div>
        </div>
        <div className="row contactTable">
          <div className="col-md-6">
            <table class="table table-bordered table-data">
              <tbody>
                <tr>
                  <td>Name</td>
                  <td className="p-3">
                    {accountDtlsInfo && accountDtlsInfo.detail
                      ? accountDtlsInfo.detail.first_name
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td className="p-3">
                    {accountDtlsInfo && accountDtlsInfo.detail
                      ? accountDtlsInfo.detail.phone
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td className="p-3">
                    {accountDtlsInfo && accountDtlsInfo.detail
                      ? accountDtlsInfo.detail.email
                      : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6">
            <table class="table table-bordered table-data">
              <tbody>
                <tr>
                  <td>Company Address</td>
                  <td className="p-3">
                    {accountDtlsInfo && accountDtlsInfo.detail
                      ? accountDtlsInfo.detail.address
                      : ""}
                  </td>
                </tr>
                {/* <tr>
                  <td>Tax Exempt</td>
                  <td className="p-3">No</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        <div className="accHead d-flex justify-content-between align-items-center">
          <h4 className="accountTitle">Shipping Address</h4>
          <SecondaryButton
            variant="outlined"
            color="primary"
            onsubmit={handlecChangeAddAddressOpen}
            className="second-button"
          >
            Add a new Address
          </SecondaryButton>
          {addressPopup ? (
            <AddAddress
              addressOpen={addressPopup}
              addressClose={handlecChangeAddAddressClose}
              data={editData}
              ship_req={ship_req}
            />
          ) : null}
        </div>
        {lshippingAddress && lshippingAddress.length ? (
          <>
            <AccOverviewTable
              ship_req={ship_req}
              data={lshippingAddress}
              open={handlecChangeAddAddressOpen}
            />
            <div id="accOve-pagination-div">
              <Pagination
                rowsPerPage={5}
                total_row_count={lshippingAddress["total_row"]}
                fallback={fallbackFromPagination}
              />
            </div>
          </>
        ) : null}
      </Layout>
    </div>
  );
};
export default React.memo(AccountOverview);
