import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MasterLayout from "../../components/MasterLayout";
import { shipingTracking } from "../../Store/Actions/userActions";

const ShipingTacking = (props) => {
  const dispatch = useDispatch();

  const shipingTrackingContent = useSelector(
    (state) => state.shipingTrackingContent.dynamicContent
  );

  useEffect(() => {
    shipingTracking({ var_name: "shipment_track" }, dispatch);
  }, []);

  const helpfulLinksView = () => {
    return { __html: shipingTrackingContent };
  };

  return (
    <MasterLayout {...props}>
      <section className="container shpMntCont">
        <div
          style={{ textAlign: "left" }}
          dangerouslySetInnerHTML={helpfulLinksView()}
        />
      </section>
    </MasterLayout>
  );
};

export default ShipingTacking;
