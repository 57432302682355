import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ListItem, RadioGroup, FormControlLabel } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import CustomCheckBox from "../CustomCheckBox";
import RadioBox, { GreenRadio } from "../RadioBox";
import "./SearchFilter.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const SearchFilter = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(true);
  const history = useHistory();

  const handleClick = () => {
    setOpen(!open);
  };

  let navValueNumber = JSON.parse(sessionStorage.getItem("navid"));

  return (
    <div className="filterOptions">
      <div className={classes.root}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography className={classes.heading}>All Categories</Typography>
          </AccordionSummary>
          {props.menu &&
            props.menu[0].map((data) => (
              <AccordionDetails>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography>{data.name}</Typography>
                  </AccordionSummary>

                  {data.detail &&
                    data.detail.map((val) => (
                      <AccordionDetails>
                        <CustomCheckBox
                          label={val.sub_name}
                          value={val.sub_id}
                          onclick={(e) => props.onchange(e)}
                          name={data.name}
                          checked={props.cid == val.sub_id ? true : false}
                        />
                      </AccordionDetails>
                    ))}
                </Accordion>
              </AccordionDetails>
            ))}
        </Accordion>
        {navValueNumber === 0 && history.location.pathname == "/furnance" ? (
          <>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography className={classes.heading}>Sku</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RadioGroup
                  aria-label="truck-turn"
                  name="sku"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="0"
                    control={<GreenRadio />}
                    name="sku"
                    label="By Sku"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="1"
                    control={<GreenRadio />}
                    label="Price- Low to High"
                    name="sku"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="2"
                    control={<GreenRadio />}
                    label="Price- High to Low"
                    name="sku"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="3"
                    control={<GreenRadio />}
                    label="Date Uploaded"
                    name="sku"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography className={classes.heading}>Price</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RadioGroup
                  aria-label="truck-turn"
                  name="sku"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value=""
                    control={<GreenRadio />}
                    label="All"
                    name="priceSearch"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="0"
                    control={<GreenRadio />}
                    label="$0.01-$9.99"
                    name="priceSearch"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="1"
                    control={<GreenRadio />}
                    label="$10.00-$19.99"
                    name="priceSearch"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="2"
                    control={<GreenRadio />}
                    label="$20.00-$39.99"
                    name="priceSearch"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="3"
                    control={<GreenRadio />}
                    label="$40.00-$69.99"
                    name="priceSearch"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="4"
                    control={<GreenRadio />}
                    label="$70.00-$99.99"
                    name="priceSearch"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="5"
                    control={<GreenRadio />}
                    label="$100.00 +"
                    name="priceSearch"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography className={classes.heading}>Coral Type</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RadioGroup
                  aria-label="truck-turn"
                  name="sku"
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value=""
                    control={<GreenRadio />}
                    label="All"
                    name="main_coralType"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="0"
                    control={<GreenRadio />}
                    label="Frags"
                    name="main_coralType"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                  <FormControlLabel
                    value="1"
                    control={<GreenRadio />}
                    label="Whole Colonies"
                    name="main_coralType"
                    onChange={(e) => props.furnanceChng(e)}
                  />
                </RadioGroup>
              </AccordionDetails>
            </Accordion>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default SearchFilter;
