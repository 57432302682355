import React from "react";
import { Link } from "react-router-dom";

import "./Header.css";
import TopHeader from "./TopHeader";
import BottomHeader from "./BottomHeader";
import MegaMenu from "../MegaMenu";
import Popover from "../Popover";
import InfoIcon from "@material-ui/icons/Info";
import MobileMegaMenu from "../MegaMenu/MobileMegaMenu";

const Header = () => {
  return (
    <header className="sdHeader">
      <TopHeader />
      <div className="hdrFrDsk">
        <div className="sdhMainHeader customContainer d-flex justify-content-between align-items-center">
          <div className="sdhLogo">
            <Link to="/specialProduct?page=1">
              <img
                src="./assets/images/logo.png"
                alt="Seadwelling logo"
                onError={(e) =>
                  (e.target.src =
                    "https://seadwellingadmin.ecommerce.auction/images/logo_old.png")
                }
              />
            </Link>
          </div>
          <MegaMenu />
        </div>
        {/* <BottomHeader /> */}
      </div>

      <div className="sdhMainHeader hdrFrMbl customContainer d-flex justify-content-between align-items-center">
        <div className="sdhLogo">
          <Link to="/specialProduct?page=1">
            <img
              src="./assets/images/logo.png"
              alt="Seadwelling logo"
              onError={(e) =>
                (e.target.src =
                  "https://seadwellingadmin.ecommerce.auction/images/logo_old.png")
              }
            />
          </Link>
        </div>
        <div className="d-flex ml-auto align-items-center">
          {/* <div className="updPrint mr-3">
            <Popover label={<InfoIcon />}>
              <BottomHeader />
            </Popover>
          </div> */}
          <MobileMegaMenu />
        </div>
      </div>
    </header>
  );
};
export default Header;
