import { combineReducers } from "redux";

import userReducer from "./UserReducer/userReducers";
import newRegistrationReducer from "./NewUserReducer/newUserReducer";
import tostifyReducer from "./TostifyReducer/tostifyReducer";
import HomepageContentReducer from "./HomePageReducer/homePageReducer";
import HelpfulLinkReducer from "./HelpfulLinkPageReducer/helpLinkPageReducer";
import ShipingTrackingReducer from "./ShipingTrackingPageReducer/shipingTrackingPageReducer";
import ChangePasswordReducer from "./ChangePasswordReducer/changPasswordReducer";
import EditUserInfoReducer from "./EditUserInfoReducer/editUserInfoReducer";
import Searchfilters from "./Searchfilters/filters";
import GetFtp from "./GetFtp/getFtpReducer";
import FeaturedProduct from "./FeaturedProduct/featuredProductReducer";
import GetFlyer from "./GetFlyer/getFlyerReducer";
import AccountOverView from "./AccountOviewReducer/accountOverviewReducer";
import AccountOverViewUpdated from "./AccountOverviewInfoUpdated/accountOverviewInfoUpdatedReducer";
import AccountOverViewAdmin from "./AccountOverviewAdmin/accountOverviewAdmin";
import ViewComment from "./AddViewComment/addViewComment";
import CartReducer from "./cartReducer/cart.reducer";
import SavedCart from "./SavedCart/savedCart";
import EditSavedCartItem from "./EditSavedCartItem/editSavedCartItem";
import PopupAlert from "./PopupAlert/popupAlert";
import checkout from "./checkout/checkout";
import loader from "./Loader/loader";
import exportStockLevel from "./ExportStockLevel/exportStockLevel";
import InitialServerTime from "./CurrentTime/currentTime";
import InitialFurnaceTime from "./CurrentTime/furnaceTime";
import LastInvTime from "./InventeryUpdatedTime/inventeryUpdatedTime";
import SpecialProduct from "./SpecialProduct/specialProduct";
import ImportExportCsv from "./ImportExportCsv/importExportCsv";
import CartTimer from "./cartTimer/cartTimer";
import CartTotal from "./CartTotal/CartTotal";
import CombineOrder from "./CombineOrder/combineOrder";
export default combineReducers({
  users: userReducer,
  newRegistration: newRegistrationReducer,
  tostify: tostifyReducer,
  homepageContent: HomepageContentReducer,
  helpfulLinkContent: HelpfulLinkReducer,
  shipingTrackingContent: ShipingTrackingReducer,
  changePasswrd: ChangePasswordReducer,
  editUserInfo: EditUserInfoReducer,
  Search: Searchfilters,
  getFtp: GetFtp,
  featuredProduct: FeaturedProduct,
  getFlyer: GetFlyer,
  accountDtls: AccountOverView,
  accountDtlsUpdated: AccountOverViewUpdated,
  accountOverViewAdmin: AccountOverViewAdmin,
  comment: ViewComment,
  cart: CartReducer,
  savedInfo: SavedCart,
  editSavedCartDtls: EditSavedCartItem,
  popupAlert: PopupAlert,
  checkout: checkout,
  loader: loader,
  exportStockLevel: exportStockLevel,
  serverTime: InitialServerTime,
  furnaceTime: InitialFurnaceTime,
  lastInvTime: LastInvTime,
  specialProduct: SpecialProduct,
  importExportCsv: ImportExportCsv,
  cartTimer: CartTimer,
  combineOrder: CombineOrder,
  cartTotal: CartTotal,
});
