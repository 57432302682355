import React, { useEffect, useState, useRef } from "react";
import MasterLayout from "../MasterLayout";
import SecondaryButton from "../SecondaryButton";
import "./importExportCsv.css";
import { useDispatch, useSelector } from "react-redux";
import {
  exportOrderItems,
  importOrderItems,
} from "../../Store/Actions/userActions";

import { CSVLink } from "react-csv";
import CustomCheckBox from "../CustomCheckBox";
import moment from "moment";
import { fetchCartItem } from "../../Store/Actions/cart.actions";
import Popup from "../../components/Popup";
import PrimaryButton from "../../components/PrimaryButton";
const ImportExportCsv = (props) => {
  const [exportData, setExportData] = useState(null);
  const [fileType, setFileType] = useState(false);
  const [importData, setImportData] = useState({
    csvFile: undefined,
    overWrite: false,
  });
  const dispatch = useDispatch();
  const expportedData = useSelector(
    (state) => state.importExportCsv.exportedData
  );
  const csvImport = useRef();
  const csvExport = useRef();
  const userInfo = useSelector((state) => state.users.users);

  useEffect(() => {
    if (exportData) {
      setTimeout(function () {
        csvExport.current.link.click();
      }, 1000);
    }
  }, [exportData]);

  useEffect(() => {
    if (
      typeof expportedData !== "undefined" &&
      expportedData &&
      typeof expportedData.exportDataInfo !== "undefined" &&
      expportedData.exportDataInfo
    ) {
      setExportData(expportedData.exportDataInfo);
    }
  }, [expportedData]);

  const onClickExport = () => {
    if (exportData) {
      csvExport.current.link.click();
    } else {
      exportOrderItems(dispatch);
    }
  };

  const fileTypePopup = () => {
    setFileType(false);
  };

  const importHandleChange = (e) => {
    let value = e.target.checked;
    let type = e.target.type;
    switch (type) {
      case "file":
        let file = e.target.files[0];
        setImportData({ ...importData, csvFile: file });
        break;
      case "checkbox":
        setImportData({ ...importData, overWrite: value });
    }
  };

  const convertIntoData = (impData) => {
    let formData = new FormData();
    Object.keys(impData).map((fileData) => {
      if (fileData === "csvFile") {
        if (importData[fileData] instanceof File) {
          formData.append("attachment", importData[fileData]);
          formData.append("sessionKey", userInfo.session_id);
        }
      } else if (fileData === "overWrite") {
        formData.append("overwrite", importData[fileData] ? "0" : "1");
      }
    });
    return formData;
  };

  const onImportSubmit = async (e) => {
    e.preventDefault();
    if (
      exportCsvValidation() &&
      typeof importData.csvFile !== "undefined" &&
      importData.csvFile.name.split(".").pop() === "csv"
    ) {
      const formDataObj = convertIntoData(importData);
      importOrderItems(formDataObj, dispatch).then((res) => {
        if (res.data.status) {
          fetchCartItem({
            sessionKey: userInfo.session_id,
          })(dispatch);
        }
      });
    }
  };

  const exportCsvValidation = () => {
    if (
      (typeof importData.csvFile !== "undefined" &&
        importData.csvFile.name.split(".").pop() !== "csv") ||
      typeof importData.csvFile === "undefined"
    ) {
      setFileType(true);
      return false;
    } else {
      return true;
    }
  };
  const errPopupButtons = () => {
    return (
      <PrimaryButton className="popup-exp-btn" onsubmit={fileTypePopup}>
        Yes
      </PrimaryButton>
    );
  };
  return (
    <MasterLayout {...props}>
      <div className="import-csv">
        <div className="container">
          <div className="d-flex flex-column justify-content-start align-items-center import-csv-main-area">
            <div className="w-100 text-left">
              <input
                name="logoImage"
                size="medium"
                type="file"
                id="logo"
                className="filestaxpath import-logo-bnr"
                ref={csvImport}
                onChange={(e) => importHandleChange(e)}
                accept=".csv"
              />
            </div>
            <div className="text-left w-100">
              <CustomCheckBox
                label="OverWrite Cart"
                id="orderWriteCart"
                name="orderWriteCart"
                className="mx-1"
                checked={importData.overWrite}
                type="checkbox"
                onclick={importHandleChange}
              />
            </div>

            <SecondaryButton
              className="import-csv-file import-export-button"
              onsubmit={onImportSubmit}
            >
              Import CSV File
            </SecondaryButton>

            <h6 className="mt-4 mb-2 text-left w-100 export-csv">
              Export Bulk Order CSV
            </h6>

            <SecondaryButton
              className="export-csv-file import-export-button"
              onsubmit={onClickExport}
            >
              Export CSV File
            </SecondaryButton>
            {exportData && (
              <CSVLink
                data={exportData}
                filename={`product_BULK_ORDER_${moment().format("l")}.csv`}
                headers={[
                  "IT ENABLE",
                  "qty_avail",
                  "Sku",
                  "SIZE",
                  "nm",
                  "retail_price",
                  "ORDER",
                  "ds",
                  "product_categories_vir",
                  "websku",
                ]}
                ref={csvExport}
                target="_blank"
              />
            )}
          </div>
        </div>

        {fileType && typeof fileType !== "undefined" ? (
          <Popup
            fullWidth={true}
            maxWidth={"sm"}
            open={fileType}
            heading={"File upload"}
            onClose={fileTypePopup}
            actions={errPopupButtons()}
            classNameDialogActions="popup-imprt-csv "
            classNameDialogTitle="popup-header product-checkout-close-icon"
            classNameDialogContentText="popup-checkout-content"
            classNameDialog="popup-checkout-alert common-popup"
          >
            Please Upload only .csv extension file.
          </Popup>
        ) : null}
      </div>
    </MasterLayout>
  );
};

export default ImportExportCsv;
