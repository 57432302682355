const intialState = {
  editUserInfo: "",
  status: false,
};

export default function (state = intialState, action) {
  switch (action.type) {
    case "EDIT_CUSTOMER_INFORMATION":
      return {
        ...state,
        editUserInfo: action.payload.detail[0],
        status: action.payload.status,
      };
    default:
      return state;
  }
}
