import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "./MyAccount.css";
import AccountOverview from "./AccountOverview";
import MasterLayout from "../../components/MasterLayout";
import Wishlist from "../../components/WishList";
import SavedWishlist from "../../components/SavedWishlist";
import { Fade } from "@material-ui/core";
import ManageAddress from "./ManageAddress";
import SavedCart from "./SavedCart";
import { useSelector, useDispatch } from "react-redux";
import ManageCustomer from "../../components/MangeCustomer";
import { getSavedCart } from "../../Store/Actions/cart.actions";
import CombineOrders from "../../components/CombineOrders";
import CombineOrder from "../../components/CombineOrders";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    paddingTop: 45,
  },
}));

const MyAccount = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();

  const accountDtlsInfo = useSelector((state) => state.accountDtls.dtls);
  const userInfo = useSelector((state) => state.users.users);

  useEffect(() => {
    if (props.location && typeof props.location != "undefined") {
      if (props.location.state && typeof props.location.state != "undefined") {
        if (
          props.location.state.tab &&
          typeof props.location.state.tab != "undefined"
        ) {
          setValue(props.location.state.tab);
        } else {
          console.log("props.location.state.tab is undefined");
        }
      } else {
        console.log("props.location.state is undefioned");
      }
    } else {
      console.log("props.location is undefined");
    }
  }, []);

  return (
    <MasterLayout {...props}>
      <div className="myAccount">
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={value}
              className="tabs"
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab
                label="Account Overview"
                className="tabs"
                {...a11yProps(0)}
              />
              <Tab label="Wishlist" className="tabs" {...a11yProps(1)} />
              {/* <Tab label="Manage Address" 
              className="tabs"
              {...a11yProps(2)} /> */}
              {/* <Tab label="Saved Cart" className="tabs" {...a11yProps(2)} /> */}
              <Tab label="Saved Wishlist" className="tabs" {...a11yProps(2)} />

              {userInfo &&
              userInfo.detail &&
              (Boolean(userInfo.detail.isadmin) ||
                userInfo.detail.account_type == "rep" ||
                userInfo.detail.account_type == "all") ? (
                <Tab label="Customer" className="tabs" {...a11yProps(3)} />
              ) : null}
              {userInfo &&
              userInfo.detail &&
              (Boolean(userInfo.detail.isadmin) ||
                userInfo.detail.account_type == "rep" ||
                userInfo.detail.account_type == "all") ? (
                <Tab
                  label="Combine Orders"
                  className="tabs"
                  {...a11yProps(4)}
                />
              ) : null}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <AccountOverview accountDtlsInfo={accountDtlsInfo} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Wishlist />
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            <ManageAddress />
          </TabPanel> */}
          {/* <TabPanel value={value} index={2}>
            <SavedCart />
          </TabPanel> */}
          <TabPanel value={value} index={2}>
            <SavedWishlist />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ManageCustomer />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <CombineOrder />
          </TabPanel>
        </div>
      </div>
    </MasterLayout>
  );
};

export default MyAccount;
