const locastorageState = sessionStorage.getItem("user")
  ? sessionStorage.getItem("user")
  : {};
const initialState =
  locastorageState &&
  typeof locastorageState !== "undefined" &&
  Object.keys(locastorageState).length
    ? {
        users: JSON.parse(locastorageState),
        loading: true,
        isLogin: JSON.parse(locastorageState).status,
        auth: !!JSON.parse(locastorageState).session_id,
        fullWishlist: {},
        alreadyLoggedIn: false,
      }
    : {
        users: {},
        loading: true,
        isLogin: false,
        auth: false,
        fullWishlist: {},
        alreadyLoggedIn: false,
      };

export default function (state = initialState, action) {
  switch (action.type) {
    case "GET_USERS":
      return {
        ...state,
        users: action.payload,
        isLogin: action.payload.status,
        auth: !!action.payload.session_id,
      };
    case "USER_ALREADY_LOGGED_IN":
      console.log("payload", action.payload);
      return {
        ...state,
        alreadyLoggedIn: action.payload,
      };
    case "LOGIN":
      return {
        ...state,
        isLogin: true,
      };
    case "SHIPPINGADDRESS":
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case "WISHLIST":
      return {
        ...state,
        wishlist: action.payload,
      };
    case "SAVED_WISHLIST":
      return {
        ...state,
        saved_wishlist: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        isLogin: false,
        users: action.payload,
      };
    case "FULL_WISHLIST":
      return {
        ...state,
        fullWishlist: action.payload,
      };
    default:
      return state;
  }
}
