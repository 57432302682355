const initialState = {
  adminUserInfo: [],
  selectAdminDtls: [],
  orderViewPrd: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_USER_DETAILS":
      return {
        ...state,
        adminUserInfo: action.payload,
      };
    case "SELECTED_ADMIN_USER_DTLS":
      return {
        ...state,
        selectAdminDtls: action.payload,
      };

    case "VIEW_ORDER_PRODCUT_DTLS":
      return {
        ...state,
        orderViewPrd: action.payload,
      };
    default:
      return state;
  }
}
