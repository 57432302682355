export default class {
  constructor(
    popupView,
    popupMessage,
    heading,
    actions,
    secondaryAction,
    okButtonClass,
    submitButtonClass,
    dispatch,
    okButtonCallBack,
    secondaryActionCallBack,
    closeButtonClass,
    classNameDialog,
    classNameDialogActions,
    popupMaxWidth,
    onCloseCross,
    headingClassName,
    contentClassName
  ) {
    this.popupView = popupView;
    this.popupMessage = popupMessage;
    this.heading = heading;
    this.actions = actions;
    this.secondaryAction = secondaryAction;
    this.okButtonClass = okButtonClass;
    this.submitButtonClass = submitButtonClass;
    this.dispatch = dispatch;
    this.okButtonCallBack = okButtonCallBack;
    this.secClickAction = secondaryActionCallBack;
    this.closeButtonClass = closeButtonClass;
    this.classNameDialog = classNameDialog;
    this.classNameDialogActions = classNameDialogActions;
    this.maxWidth = popupMaxWidth;
    this.onCloseCross = onCloseCross;
    this.headingClassName = headingClassName;
    this.contentClassName = contentClassName;
  }
  emitPopup() {
    this.dispatch({
      type: "POPUP_MESSAGE",
      payload: { ...this },
    });
  }
}
