import React from "react";

const Notfnd = () => {
  return (
    <>
      <object data="./assets/svg/noData.svg" type="image/svg+xml" />
      <h2> We could not find any results for your query.</h2>{" "}
      <h3>Try searching with a different name.</h3>
    </>
  );
};

export default Notfnd;
