import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  makeStyles,
  CardActionArea,
  CardMedia,
  CardActions,
  Tooltip,
} from "@material-ui/core";
import InputField from "../Input/InputField";
import "./AllFurnace.css";
import { addItemToCart, fetchCartItem } from "../../Store/Actions/cart.actions";
import { Searchfilters } from "../../Store/Actions/userActions";
import popupClass from "../../utils/emitPopup";
import { withRouter } from "react-router-dom";
import moment from "moment";

import noImage from "../../assets/images/NoImageAvailable.jpg";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const AllFurnance = (props) => {
  var titleprops = { ...props };
  delete titleprops.title;
  const [furnaceTimereached, setFurnaceTimereached] = useState(false);
  const [qty, setQty] = useState({
    val: "",
  });

  const [furnaceData, setFurnaceData] = useState({
    startTiming: moment.tz(
      moment(props.listDate.startdate).utc().format("YYYY-MM-DD HH:mm:ss"),
      "America/Los_Angeles"
    ),
    endTiming: moment.tz(
      moment(props.listDate.enddate).utc().format("YYYY-MM-DD HH:mm:ss"),
      "America/Los_Angeles"
    ),
  });
  const userInfo = useSelector((state) => state.users.users);
  const furnaceTime = useSelector((state) => state.furnaceTime);
  const serverTime = useSelector((state) => state.serverTime);

  const dispatch = useDispatch();

  useEffect(() => {
    let currentDateTime = moment(serverTime.currentTimeDtls).tz(
      "America/Los_Angeles"
    );
    if (
      currentDateTime &&
      currentDateTime.isBetween(furnaceData.startTiming, furnaceData.endTiming)
    ) {
      setFurnaceTimereached(true);
    } else {
      setFurnaceTimereached(false);
    }
  }, [serverTime.currentTimeDtls]);

  let startTiming = moment.tz(
    moment(props.listDate.startdate).utc().format("YYYY-MM-DD HH:mm:ss"),
    "America/Los_Angeles"
  );
  let endTiming = moment.tz(
    moment(props.listDate.enddate).utc().format("YYYY-MM-DD HH:mm:ss"),
    "America/Los_Angeles"
  );

  useEffect(() => {
    if (furnaceTime.furnaceData) {
      setFurnaceData({
        startTiming: moment.tz(
          moment(furnaceTime.furnaceData.start_time)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
          "America/Los_Angeles"
        ),
        endTiming: moment.tz(
          moment(furnaceTime.furnaceData.end_time)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
          "America/Los_Angeles"
        ),
      });
    }
  }, [furnaceTime]);

  const qtyChange = (e, data) => {
    let newVal = e.target.value;
    setQty({ ...qty, val: newVal });
    if (e.target.value > parseInt(data.quantity_available)) {
      new popupClass(
        true,
        "Insufficient Quantity",
        "Warning !!",
        "",
        "",
        "",
        "",
        dispatch,
        "",
        "",
        "",
        "insufficent-poup"
      ).emitPopup();
    } else if (
      e.target.value <= parseInt(data.quantity_available) &&
      e.target.value > 0
    ) {
      // let newVal = e.target.value;
      // setQty({ ...qty, val: newVal });
      let itemsAdd = {
        sessionKey: userInfo.session_id,
        webSKU: data.websku,
        SKU: data.sku,
        qty: parseInt(e.target.value),
      };

      sessionStorage.setItem("seccall", JSON.stringify(itemsAdd));
      addItemToCart(itemsAdd, dispatch, secondayCallBack, undefined).then(
        (res) => {
          setQty({ ...qty, val: "" });
          if (res.data.status) {
            if (props && typeof props.location != "undefined") {
              // props.onDataChange([], 1, false, false);
              let ID = props.location.search.split("=")[1].split("&")[0];
              let currentPage = JSON.parse(
                sessionStorage.getItem("furnancePageNumber")
              );

              let obj = {
                sessionKey: userInfo.session_id,
                search_value: "",
                search_key: "",
                size: "100",
                page: props.pageNo,
                cid: ID ? ID : "",
              };
              fetchCartItem({
                sessionKey: userInfo.session_id,
              })(dispatch);

              // Searchfilters(obj, dispatch, true, false, props.location.search);
            }
          } else {
            if (props && typeof props.location != "undefined") {
              // props.onDataChange([], 1, false, false);

              let ID = props.location.search.split("=")[1].split("&")[0];
              let obj = {
                sessionKey: userInfo.session_id,
                search_value: "",
                search_key: "",
                size: "100",
                page: props.pageNo,
                cid: ID ? ID : "",
              };

              Searchfilters(obj, dispatch, true, false, props.location.search);
            }
          }
        }
      );
    }
  };

  const imageError = (e) => {
    return (e.target.src =
      "https://auctionsoftwaremarketplace.com/seadwelling/images/default/NoImageAvailable.jpg");
  };

  const imgUrl = () => {
    if (props.inv_item == 1 || props.inv_item == 2) {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/live/${props.image}`;
    } else {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/static/${props.image}`;
    }
  };
  const secondayCallBack = () => {
    let a = sessionStorage.getItem("seccall");
    a = JSON.parse(a);
    a["override"] = 1;
    addItemToCart(a, dispatch).then(() => {
      if (props && typeof props.location != "undefined") {
        let ID = props.location.search.split("=")[1].split("&")[0];
        let routeName = props.location.pathname.split("/")[1];

        let obj = {
          sessionKey: userInfo.session_id,
          search_value: "",
          search_key: "",
          size: routeName == "furnance" ? "100" : "25",
          page: "1",
          cid: ID ? ID : "",
        };
        fetchCartItem({
          sessionKey: userInfo.session_id,
        })(dispatch);
        return Searchfilters(obj, dispatch).then(() => {
          dispatch({
            type: "POPUP_MESSAGE_CLOSE",
            payload: false,
          });
        });
      }
    });
  };
  return (
    <Card className={`furnaceCard`} {...titleprops}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt={props.alt}
          image={props.image}
          onError={imageError}
          className="furnaceImg"
          // title={props.title}
          onClick={(e) => props.furnaceImgOpen(props)}
        />
        <BootstrapTooltip
          title={props.title}
          onClick={(e) => props.titleClick(props)}
        >
          <h5 className="furnaceTitle">{props.title}</h5>
        </BootstrapTooltip>
        <div className="d-flex  justify-content-between furnaceDetails">
          {/* <p className="furnace-price">Price : {props.price}</p> */}
          {props.special_price &&
          parseFloat(props.price) > parseFloat(props.special_price) ? (
            <div className="d-flex">
              <p className="furnace-price">Price :</p>

              <>
                <span
                  className="furnace-price"
                  style={{
                    textDecoration: "line-through",
                    fontSize: "14px",
                    marginLeft: "5px",
                  }}
                >
                  ${props.price}
                </span>
                <p
                  className="furnace-price"
                  style={{ color: "red", fontSize: "14px", marginLeft: "5px" }}
                >
                  ${props.special_price}
                </p>
              </>
            </div>
          ) : (
            <p className="furnace-price">
              Price :
              <span
                style={{
                  fontSize: "14px",
                  marginLeft: "5px",
                }}
              >
                ${props.price}
              </span>
            </p>
          )}
          <p className="furnace-qty">Qty Avail: {props.qty}</p>
        </div>
      </CardActionArea>
      {furnaceTimereached ? (
        <CardActions
          className={`p-0 furnace-action ${
            furnaceTimereached ? "sale-open" : "sale-closed"
          }`}
        >
          <InputField
            label="Qty"
            type="text"
            size="small"
            value={qty.val}
            disabled={userInfo?.detail?.cities === "Y" && props.cities === "Y"}
            onchange={(e) => {
              qtyChange(e, props);
            }}
          />

          {/* <PrimaryButton
            btnSize="small"
            className="furnace-button"
            onsubmit={(e) => props.popOpen(props)}
          >
            <span>Add Comment</span>
          </PrimaryButton> */}
        </CardActions>
      ) : (
        <></>
      )}
    </Card>
  );
};
export default withRouter(AllFurnance);
