import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Layout from "../../components/MasterLayout";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartItem,
  fetchSavedCartNickName,
  saveCartWithNickName,
} from "../../Store/Actions/cart.actions";
import {
  Paper,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Button,
} from "@material-ui/core";
import SelectField from "../../components/Input/SelectField";
import { createFilterOptions } from "@material-ui/lab";
import SecondaryButton from "../../components/SecondaryButton";
const SaveToCart = (props) => {
  const userInfo = useSelector((state) => state.users.users);
  const savedNickName = useSelector((state) => state.savedInfo.savedNickName);
  const [type, setType] = useState("0");
  const [nickName, setNickName] = useState("");
  const dispatch = useDispatch();
  const secndaryFunction = () => {
    fetchCartItem({ sessionKey: userInfo.session_id })(dispatch).then(() => {
      dispatch({
        type: "POPUP_MESSAGE_CLOSE",
        payload: false,
      });
    });
  };
  useEffect(() => {
    fetchSavedCartNickName({
      sessionKey: userInfo.session_id,
    })(dispatch);
  }, []);
  const SaveCart = () => {
    let savedCart = {
      sessionKey: userInfo.session_id,
    };
    if (type == "0") {
      savedCart.saveType = "2";
      savedCart.id = nickName;
    } else {
      savedCart.saveType = "1";
      savedCart.name = nickName;
    }
    saveCartWithNickName(savedCart, dispatch, secndaryFunction);
  };
  return (
    <div className="sdCart container-fluid" style={{ paddingTop: 40 }}>
      <p
        className="text-left save-cart-title f-1"
        style={{ background: "lightgrey" }}
      >
        Save This Cart
      </p>
      <p className="text-left f-1">
        You can save this shopping cart and use it later to order/re-order
        products.
      </p>
      <p style={{ marginLeft: "25px" }} className="text-left f-1">
        Action:
      </p>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={type}
        onChange={(e) => {
          setType(e.target.value);
          setNickName("");
        }}
        style={{ marginLeft: "35px" }}
      >
        <FormControlLabel
          value="0"
          control={<Radio />}
          label="Add these items to a saved cart"
        />
        <FormControlLabel
          value="1"
          control={<Radio />}
          label="Create a new cart"
        />
      </RadioGroup>
      <div className="text-left">
        {type == 0 ? (
          <FormControl
            variant="outlined"
            align="bottom"
            style={{ width: "300px" }}
          >
            {/* <InputLabel id="demo-simple-select-outlined-label" align="left">
            Select One from the List
          </InputLabel> */}
            <SelectField
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={nickName}
              pc_onchange={(e) => {
                setNickName(e.target.value);
              }}
              label="Select One from the List"
              InputLabelProps={{
                shrink: true,
              }}
            >
              {savedNickName
                ? savedNickName.map((value) => (
                    <option value={value.id}>{value.nickname}</option>
                  ))
                : null}
            </SelectField>
          </FormControl>
        ) : (
          <TextField
            style={{ width: "300px" }}
            id="outlined-basic"
            variant="outlined"
            label="Enter the Nick Name"
            value={nickName}
            onChange={(e) => {
              setNickName(e.target.value);
            }}
            className="my-2"
          />
        )}
        <div className="d-flex w-100 cart-button  my-3">
          <SecondaryButton
            variant="contained"
            disabled={nickName != "" ? false : true}
            className="second-button"
            onClick={SaveCart}
          >
            Save
          </SecondaryButton>
          <SecondaryButton
            variant="contained"
            className="second-button"
            onClick={(e) => {
              props.close();
            }}
          >
            Cancel
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};

export default SaveToCart;
