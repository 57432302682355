const initalState = {
  savedCart: {
    count: 0,
    items: [],
  },
  cartPopup: false,
  deleteCart: false,
  outOfStock: "",
  wishlist: [],
  existData: "",
};

export default function (state = initalState, { type, payload }) {
  switch (type) {
    case "GETSAVEDCART":
      return {
        ...state,
        savedCart: payload,
      };
    case "INC_CART":
      return {
        ...state,
        savedCart: {
          ...state.savedCart,
          count: payload.total_cart_qty,
        },
      };
    case "CART_DATA":
      return {
        ...state,
        savedCart: {
          ...state.savedCart,
          items: payload.detail,
          wishlist: payload.wishlist,
        },
      };
    case "VIEW_CART_ITEM_INCREMENT":
      return {
        ...state,
        savedCart: {
          ...state.savedCart,
          count: payload.total_row,
          wishlist: payload.wishlist,
        },
      };
    case "CART_POPUP":
      return {
        ...state,
        cartPopup: payload,
      };
    case "DELETE_CART_UPDATE":
      return {
        ...state,
        deleteCart: payload,
      };
    case "OUT_OF_STOCK":
      return {
        ...state,
        outOfStock: payload,
      };
    case "CART_PRODUCTS_EXIST":
      return {
        ...state,
        existData: payload,
      };
    case "WISHLIST_DETAILS":
      return {
        ...state,
        wishlist: payload.detail,
      };
    case "SAVED_WISHLIST_DETAILS":
      return {
        ...state,
        saved_wishlist: payload.detail,
      };
    default:
      return state;
  }
}
