const apiEndPoints = {
  login: "/login",
  switchUser: "/switchuser",
  validateUser: "/validateUser",
  userLogOut: "/userLogOut",
  countries: "/registration",
  moreInfo: "/addMoreInfo",
  getDocs: "/getUploadedDocuments",
  homePageContent: "/homePageContent",
  getFlyer: "/get_flyer",
  forgetpassword: "/forgotPassword",
  changePassword: "/change_password",
  editRegistration: "/edit_registration",
  updateRegistration: "/update_registration",
  Searchfilters: "/home_page",
  smenu: "/siteMenu",
  ftpPage: "/get_ftp",
  featuredProduct: "/featuredProduct",
  addComment: "/product_comments",
  accountView: "/accountOverview",
  insertShipping: "/createShippingAddress",
  getShipping: "/getshippingAddress",
  getSavedCart: "/getSavedCart",
  editSavedCart: "/editSavedCart",
  accountOverviewAdmin: "/accountOverviewAdmin",
  updateShippingAddress: "/updateShippingAddress",
  deleteShippingAddress: "/deleteShippingAddress",
  getProductDetails: "/getProductDetails",
  addToCart: "/addToCart",
  addToCartBulk: "/addToCartBulk",
  viewCart: "/ViewCart",
  wishList: "/wishList",
  updateCart: "/updateCart",
  deleteCart: "/deleteCart",
  deletewishList: "/deleteWishList",
  getAllWishlist: "/getwishList",
  deleteSavedwishList: "/deleteSavedWishList",
  getAllSavedWishlist: "/getSavedWishList",
  addToSavedWishList: "/addToSavedWishList",
  savedCart: "/savedCart",
  orderConfirm: "/orderConfirm",
  uploadBanner: "/uploadBanner",
  editShippingAddress: "/editShippingAddress",
  placeOrder: "/placeOrder",
  deleteAllCart: "/deleteAllCart",
  deleteSavedCart: "/deleteSavedCart",
  removeOutOfStockItemFromCart: "/removeOutOfStockItemFromCart",
  exportStockLevelCsv: "/exportStockLevel_csv",
  updateSavedCart: "/updateSavedCart",
  purchaseFromSavedCart: "/purchaseFromSavedCart",
  updateSavedCartItem: "/updateSavedCartItem",
  getTime: "/get_time",
  deleteSavedItem: "/deleteSavedItem",
  deleteSavedWholeItem: "/deleteSavedWholeItem",
  invUpdatedtime: "/invUpdatedtime",
  exportOrder: "/exportOrder",
  importExcelBulkOrder: "/importExcelBulkOrder",
  viewLogoBanner: "/viewBanner",
  getEmployeeList: "/getEmployeeList",
  getUserOrders: "/getUserOrders",
  combineOrders: "/combineOrders",
  getUserOrderDetailsList: "/getUserOrderDetailsList",
  phoneNumberUpdate: "/updateUserMobileNumber",
  confirmRoi: "/confirmRoi",
};
export default apiEndPoints;
