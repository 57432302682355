import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      width: "100%",
      height: "60px",
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    borderRadius: "3px",
    boxShadow: "0px 4px 12px rgba(7, 78, 111, 20%)",
    backgroundColor: "#04364D",
    outline: 0,
    "&:hover": {
      backgroundColor: "#074e6f",
      boxShadow: "0px 4px 12px rgba(7, 78, 111, 20%)",
    },
    "&:focus": {
      outline: 0,
    },
  },
}))(Button);

const SecondaryButton = (props) => {
  const classes = useStyles();

  return (
    <div className={`${classes.root} ${props.btnSize} secButton`}>
      <ColorButton
        variant="contained"
        color="primary"
        onClick={props.onsubmit}
        type={props.type}
        size={props.size}
        {...props}
      >
        {props.iconLeft}
        {props.label}
        {props.children}
      </ColorButton>
    </div>
  );
};

export default SecondaryButton;
