import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ItemSmallView from "../../components/ProductView/ItemSmallView";
import DetailsPopup from "../../components/DetailsPopup/index";
import InitialChangePassword from "../../components/InitialChangePassword";

import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { featuredProductDetails } from "../../Store/Actions/userActions";
import MasterLayout from "../MasterLayout";
import Loader from "../../components/Loader";
import NotFnd from "../../components/NotFound/notFound";
import "./featuredProduct.css";
import { useHistory } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f8f8f8",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    border: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  body: {
    fontSize: 14,
    paddingTop: "10px",
    paddingBottom: "10px",
    border: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child": {
      // backgroundColor: theme.palette.action.hover,
      fontWeight: "500",
      width: "220px",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#e9e9e9",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderCollapse: "collapse",
  },
  container: {
    maxHeight: 600,
  },
});

const FeaturedProducts = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({
    details: [],
    pop: false,
  });
  const [size, setSize] = useState([]);
  const [pageWish, setPageWish] = useState(1);
  const [itemView, setItemView] = useState(false);
  const [lotListTable, setLotListTable] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [leftVal, setLeftVal] = useState(0);
  const [initalChngPopup, setInitialChngPopup] = useState(false);
  const [hoverTable, setHoverTable] = useState();
  const userInfo = useSelector((state) => state.users.users);
  const loader = useSelector((state) => {
    return state.loader;
  });
  const history = useHistory();

  const handleChange = (values) => (e) => {
    setState({ ...state, details: values, pop: true });
  };
  const onpop = () => {
    setState({ ...state, pop: false });
  };
  const dispatch = useDispatch();
  const lists = useSelector(
    (state) => state.featuredProduct.featuredInfoDetails
  );

  const lotPriceCheck = (value) => {
    try {
      if (typeof value.sku_dtls !== "undefined") {
        if (Array.isArray(value.sku_dtls)) {
          if (value.sku_dtls.length) {
            let lottems = value.sku_dtls.filter((ele) => {
              if (
                ele.special_price &&
                parseFloat(ele.retail_price) > parseFloat(ele.special_price)
              ) {
                return true;
              }
              return true;
            });
            if (lottems.length) {
            } else {
              return false;
            }
          }
        }
      }
    } catch (e) {
      return false;
    }
  };
  const hoverVal = (hoverStatus, index, hoverItem, indexSmall, xValue) => {
    try {
      setHoveredIndex(index);
      setItemView(true);
      setLotListTable(hoverItem.lot_dtls);
      setLeftVal(xValue);
      if (typeof hoverItem.lot_dtls === "undefined")
        throw "hoverItem.lot_dtls is undefined";
      let hovtabite = hoverItem.lot_dtls;
      if (typeof hovtabite !== "undefined") {
        if (Array.isArray(hovtabite) || Object.keys(hovtabite).length)
          if (Array.isArray(hovtabite) && hovtabite.length === 0)
            throw "lotlist table is an atrray and its empty";
        let keysOfQuantity = Object.keys(hovtabite["Quantity"]);
        let keysOfPrice = Object.keys(hovtabite["price"]);
        if (keysOfQuantity.length === keysOfPrice.length) {
          let hovTaArr = [];
          keysOfQuantity.map((ele, index) => {
            let price = "";
            let qty = "";
            if (Array.isArray(hovtabite)) {
              price = hovtabite[indexSmall]["price"][ele];
              qty = hovtabite[indexSmall]["Quantity"][ele];
            } else {
              price = hovtabite["price"][index];
              qty = hovtabite["Quantity"][index];
            }
            hovTaArr.push({
              quantity: qty,
              price: price,
            });
            return ele;
          });
          setHoverTable(hovTaArr);
        } else {
          throw "keysOfQuantity.length === keysOfPrice.length isw not equal";
        }
      } else {
        throw "lotListTable is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSpecial = () => {
    let featProDtls = {
      sessionKey: userInfo.session_id,
      cid: "",
      search_key: "",
      search_value: "",
      page: pageWish,
      size: "25",
      is_special: 0,
    };

    featuredProductDetails(featProDtls, dispatch);
  };

  const wishListPageChange = (event, value) => {
    setPageWish(value);
    const parms = new URLSearchParams(props.location.search);
    let ID = parms.getAll("key")[0];
    history.push(`/featureProduct?page=${value}`);
    let featProDtls = {
      sessionKey: userInfo.session_id,
      cid: "",
      search_key: "",
      search_value: "",
      page: value,
      size: "25",
      is_special: 0,
    };
    featuredProductDetails(featProDtls, dispatch);
  };
  useEffect(() => {
    // handleSpecial();
    const parms = new URLSearchParams(props.location.search);
    let pagenumber = parms.getAll("page")[0];
    console.log(pagenumber, "pagenumber");
    setPageWish(Number(pagenumber));
  }, []);
  const firstTimeResetPopup = () => {
    if (userInfo.detail.is_first_login) {
      setInitialChngPopup(true);
    } else {
      setInitialChngPopup(false);
    }
  };
  const initialChngPassClose = () => {
    setInitialChngPopup(false);
  };
  useEffect(() => {
    firstTimeResetPopup();
  }, [userInfo.isLogin]);

  let tdLength = [];
  let maxTd = 0;

  lists.data &&
    !(lists.data.length === 0) &&
    !Boolean(lists.is_live_sale) &&
    lists.data.map((data) => (
      <>
        {tdLength.push(data.sku_dtls.length)}
        {(maxTd = Math.max(...tdLength))}
      </>
    ));

  useEffect(() => {
    if (lists.data && typeof lists.data !== "undefined") {
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  }, [lists]);
  console.log(loader.isLoading, "loader.isLoading ");
  return (
    <>
      <InitialChangePassword
        initalChngPopup={initalChngPopup}
        initialChngPassClose={initialChngPassClose}
      />
      <MasterLayout {...props}>
        <div className="searchResults">
          {loader.isLoading ? (
            <>
              <Loader />
              <div style={{ height: "100vh" }}>
                <p style={{ marginTop: "3rem" }}>Loading..</p>
              </div>
            </>
          ) : (
            <>
              <h5 className=" sub-catergorie-name p-3">Featured</h5>

              <TableContainer component={Paper} className={classes.container}>
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-label="customized table"
                >
                  {lists.data && !Boolean(lists.is_live_sale) ? (
                    <TableBody>
                      {console.log(lists.data, "==> this is list data")}
                      {lists.data &&
                        lists.data.map((key, value) => (
                          <StyledTableRow>
                            <StyledTableCell align="center">
                              <HtmlTooltip
                                title={
                                  <div className="tableShortInfo">
                                    <img src={key.image} alt={key.websku} />
                                    <h3>{key.name}</h3>
                                  </div>
                                }
                              >
                                <Button
                                  className="pvTrigger"
                                  onClick={handleChange(lists.data[value])}
                                >
                                  {key.name}
                                  {lotPriceCheck(key) ? (
                                    <span className="sale-tag">Sale</span>
                                  ) : null}
                                </Button>
                              </HtmlTooltip>
                            </StyledTableCell>
                            {key.sku_dtls.map((featSkudtls, index) => (
                              <StyledTableCell>
                                <div
                                  id="smallItem"
                                  className="featured-product-small-item"
                                >
                                  <ItemSmallView
                                    className="smallitvi"
                                    id={featSkudtls.sku}
                                    indexofpar={value}
                                    itemSmall={index}
                                    {...featSkudtls}
                                    hoverVal={hoverVal}
                                    rp={featSkudtls.retail_price}
                                    sp={
                                      featSkudtls.special_price
                                        ? featSkudtls.special_price
                                        : ""
                                    }
                                    custLimit={featSkudtls.customer_limit}
                                    sku_limit={featSkudtls.sku_limit}
                                    websku_limit={featSkudtls.websku_limit}
                                    qtyAvbl={featSkudtls.quantity_available}
                                    details={featSkudtls}
                                    hoverTable={hoverTable}
                                    itemView={itemView}
                                    hoveredIndex={hoveredIndex}
                                    value_={value}
                                    lotPriceCheck={lotPriceCheck}
                                    key_={key}
                                  />
                                </div>
                              </StyledTableCell>
                            ))}
                            {Array.from(Array(maxTd - key.sku_dtls.length)).map(
                              () => (
                                <StyledTableCell></StyledTableCell>
                              )
                            )}
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  ) : (
                    <div style={{ height: "100vh" }}>
                      <p style={{ marginTop: "3rem" }}>Loading..</p>
                    </div>
                  )}
                  {lists &&
                  typeof lists !== "undefined" &&
                  lists.data &&
                  lists.data.length === 0 ? (
                    <NotFnd />
                  ) : null}
                </Table>
              </TableContainer>
              {lists.data &&
              !(lists.data.length === 0) &&
              Number(lists.total_row) > 25 ? (
                <Pagination
                  page={pageWish}
                  className={"mt-2"}
                  count={Math.ceil(lists.total_row / 25)}
                  onChange={wishListPageChange}
                />
              ) : null}
            </>
          )}

          <DetailsPopup
            value={state.pop}
            onpop1={onpop}
            details={state.details}
          />
        </div>
      </MasterLayout>
    </>
  );
};
export default FeaturedProducts;
