import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SecondaryButton from "../SecondaryButton";
import PrimaryButton from "../PrimaryButton";
import InputField from "../Input/InputField";
import DeleteIcon from "@material-ui/icons/Delete";
import Cancel from "@material-ui/icons/Cancel";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import toastifyClass from "../../utils/emitToastify";
import {
  deleteSavedItemIndividual,
  updateSavedCartName,
  editSavedCartSingleItemDelete,
  editSavedCartWholeItemDelete,
  editSavedCartItemCartUpdate,
  getEditSavedDtls,
  purchaseFromEditSavedCart,
  fetchCartItem,
} from "../../Store/Actions/cart.actions";
import "./editSavedDetails.css";
import Loader from "../../components/Loader";
import { useHistory } from "react-router-dom";
import NoImage from "../../assets/images/NoImageAvailable.jpg";
import popupClass from "../../utils/emitPopup";
import moment from "moment";

const EditSavedDetails = () => {
  const [editSavedDtls, setEditSavedDtls] = useState({
    nick_name: "",
    id: "",
    tableData: [],
  });

  const [editUpdateCartVal, setEditUpdateCartVal] = useState();

  const userInfo = useSelector((state) => state.users.users);

  const dispatch = useDispatch();

  const history = useHistory();

  const editsavedDtl = useSelector((state) => state.editSavedCartDtls);

  const loader = useSelector((state) => {
    return state.loader;
  });

  useEffect(() => {
    let filteredArr = {
      nick_name: "",
      id: "",
      tableData: [],
    };

    if (
      editsavedDtl &&
      editsavedDtl.editSavedCart &&
      typeof editsavedDtl.editSavedCart !== "undefined" &&
      Array.isArray(editsavedDtl.editSavedCart) &&
      editsavedDtl.editSavedCart.length !== 0
    ) {
      editsavedDtl.editSavedCart.map((ele) => {
        ele.details.map((el) => {
          filteredArr.nick_name = ele.nickname;
          filteredArr.id = el.savecart_id;
          let objVal = {
            nm: el.nm,
            proimage: el.proimage,
            websku: el.websku,
            qty: el.qty,
            qty_avail: el.qty_avail,
            userid: el.userid,
            sku: el.sku,
            retail_price: el.retail_price,
            id: el.id,
            status: el.status,
            timeadded: el.timeadded,
            updatedTime: el.updatedTime,
            savecart_id: el.savecart_id,
            qty_available: el.qty_available,
            unitprice: el.unitprice,
            size: el.size,
            inv_type: el.inv_type,
          };
          el.similarproduct = [objVal];
          if (filteredArr.tableData.length === 0) {
            filteredArr.tableData.push(el);
          } else {
            let indexOfVal = filteredArr.tableData.findIndex(
              (elez) => elez.websku === el.websku
            );
            if (indexOfVal === -1) {
              filteredArr.tableData.push(el);
            } else {
              let existIndex = filteredArr.tableData.find(
                (ar) => ar.websku === el.websku
              );

              existIndex.similarproduct.push(objVal);
            }
          }
        });
      });
      setEditSavedDtls(filteredArr);
    } else {
      setEditSavedDtls({ nick_name: "", id: "", tableData: [] });
    }
  }, [editsavedDtl]);

  useEffect(() => {
    let filteredArr = {
      nick_name: "",
      id: "",
      tableData: [],
    };
    setEditSavedDtls(filteredArr);
  }, []);

  const delteSavedItem = (id) => {
    let deleteDtls = {
      sessionKey: userInfo.session_id,
      id: id,
    };
    deleteSavedItemIndividual(deleteDtls, dispatch);
  };

  const updateNickName = () => {
    let nickChngDtls = {
      sessionKey: userInfo.session_id,
      cartid: editSavedDtls.id,
      type: "",
      nm: editSavedDtls.nick_name,
    };
    updateSavedCartName(nickChngDtls, dispatch)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          new toastifyClass(
            true,
            "Updated successfully",
            "success",
            dispatch
          ).emittoast();
        }
      })
      .then(() => {
        let editSaveDtls = {
          sessionKey: userInfo.session_id,
          id: editSavedDtls.id,
        };
        getEditSavedDtls(editSaveDtls, dispatch);
      });
  };

  const purchaseProuctEditsavedCart = () => {
    let purchaseItems = {
      sessionKey: userInfo.session_id,
      cartId: editSavedDtls.id,
      append: 1,
    };
    purchaseFromEditSavedCart(purchaseItems, dispatch).then((res) => {
      if (res.status) {
        history.push("/cart");
        let reqObj = {
          sessionKey: userInfo.session_id,
        };
        fetchCartItem(reqObj)(dispatch);
      }
    });
  };

  const onChangeEditSavedDtlsInfo = (e) => {
    setEditSavedDtls({ ...editSavedDtls, nick_name: e.target.value });
  };

  const onChngUpdateItem = (ind, index) => (e) => {
    let newChangeValue = [...editSavedDtls.tableData];
    if (!isNaN(e.target.value)) {
      newChangeValue[index].similarproduct[ind].qty = e.target.value;
      setEditSavedDtls({ ...editSavedDtls, ...newChangeValue });
      setEditUpdateCartVal(e.target.value);
    }
  };

  const updatedEditSavedCartProduct = (ele) => {
    if (ele && typeof ele !== "undefined") {
      let udpateEditSavedItem = {
        sessionKey: userInfo.session_id,
        cartid: Number(ele.savecart_id),
        sku: ele.sku,
        tot_qty: editUpdateCartVal,
      };
      editSavedCartItemCartUpdate(udpateEditSavedItem, dispatch).then((res) => {
        if (res.data.status) {
          let tostifyValue = {
            tostifyView: true,
            tostifyMessage: res.data.message,
            severity: "success",
          };
          dispatch({
            type: "TOSTIFY_MESSAGE",
            payload: tostifyValue,
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
          let editSaveDtls = {
            sessionKey: userInfo.session_id,
            id: editSavedDtls.id,
          };
          getEditSavedDtls(editSaveDtls, dispatch);
        } else {
          dispatch({
            type: "LOADING",
            payload: false,
          });
          new popupClass(
            true,
            res.data.msg,
            "Added to  your cart",
            "",
            "",
            "",
            "",
            dispatch
          ).emitPopup();

          let editSaveDtls = {
            sessionKey: userInfo.session_id,
            id: editSavedDtls.id,
          };
          getEditSavedDtls(editSaveDtls, dispatch);
        }
      });
    }
  };

  const onDeleteSingleItem = (ele) => {
    if (ele && typeof ele !== "undefined") {
      let editItemDelteSingle = {
        sessionKey: userInfo.session_id,
        cartid: Number(ele.savecart_id),
        sku: ele.sku,
        tot_qty: "",
        amount_payable_update: "",
        websku: ele.websku,
      };
      editSavedCartSingleItemDelete(editItemDelteSingle, dispatch).then(
        (res) => {
          if (res.data) {
            let tostifyValue = {
              tostifyView: true,
              tostifyMessage: "Cart Items has been removed",
              severity: "success",
            };
            dispatch({
              type: "TOSTIFY_MESSAGE",
              payload: tostifyValue,
            });
            dispatch({
              type: "LOADING",
              payload: false,
            });
            let editSaveDtls = {
              sessionKey: userInfo.session_id,
              id: editSavedDtls.id,
            };
            getEditSavedDtls(editSaveDtls, dispatch);
          }
        }
      );
    }
  };

  const onDeleteWholeWebsku = (ele) => {
    if (ele && typeof ele !== "undefined") {
      let wholeDelteWebsku = {
        sessionKey: userInfo.session_id,
        cartid: Number(ele.savecart_id),
        websku: ele.websku,
      };
      editSavedCartWholeItemDelete(wholeDelteWebsku, dispatch).then((res) => {
        if (res.data) {
          let tostifyValue = {
            tostifyView: true,
            tostifyMessage: "Cart Items has been removed",
            severity: "error",
          };
          dispatch({
            type: "TOSTIFY_MESSAGE",
            payload: tostifyValue,
          });
          dispatch({
            type: "LOADING",
            payload: false,
          });
          let editSaveDtls = {
            sessionKey: userInfo.session_id,
            id: editSavedDtls.id,
          };
          getEditSavedDtls(editSaveDtls, dispatch);
        }
      });
    }
  };

  const imageError = (e) => {
    e.target.src = NoImage;
  };

  useEffect(() => {
    let editSavedDtlsId = JSON.parse(sessionStorage.getItem("editSavedDtls"));
    const savdDtls = {
      sessionKey: userInfo.session_id,
      id: editSavedDtlsId,
    };
    getEditSavedDtls(savdDtls, dispatch);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div id="edit-saved-cart">
      {loader.isLoading ? (
        <div className="editSaved-loader">
          <Loader />
        </div>
      ) : (
        <>
          {editSavedDtls && typeof editSavedDtls !== "undefined" ? (
            <div className="mx-5">
              <h5 className="text-left">EDIT SAVED CART</h5>

              <div className="d-flex justify-content-between">
                <div>
                  <Link to={{ pathname: "/account", state: { tab: 2 } }}>
                    <p className="mx-3">
                      <ArrowBackIcon />
                      Go Back
                    </p>
                  </Link>
                </div>
                <PrimaryButton
                  className="mx-3 second-button "
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={updateNickName}
                >
                  Update
                </PrimaryButton>
              </div>

              <hr />
              <div className="d-flex justify-content-between">
                <InputField
                  className="mx-3"
                  label="Number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={editSavedDtls.id}
                  size="medium"
                  type="text"
                  id="id_no"
                />
                <InputField
                  className="mx-3"
                  label="Nick Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={editSavedDtls.nick_name}
                  size="medium"
                  type="text"
                  id="nick_name"
                  onChange={onChangeEditSavedDtlsInfo}
                />
              </div>
              {editSavedDtls &&
              typeof editSavedDtls.tableData !== "undefined" ? (
                <div className="table-responsive">
                  {editSavedDtls.tableData.map((item, index) => (
                    <table
                      className="table table-bordered edit-saved-cart-table"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      <thead>
                        <tr>
                          <th className="edit-title">Title</th>
                          <th>Image</th>
                          <th className="small-size-width">Size</th>
                          <th className="small-size-width">Qty Avail</th>
                          <th>Prize</th>
                          <th>Order</th>
                          <th className="delete-item">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {typeof item.similarproduct !== "undefined" &&
                        Array.isArray(item.similarproduct) &&
                        item.similarproduct.length
                          ? item.similarproduct.map((ele, ind) => {
                              return (
                                <tr>
                                  <td className="edit-table-name">{ele.nm}</td>
                                  <td className="edit-table-image">
                                    <img
                                      src={`http://seadwellnode.auctionsoftware.com/images/${ele.proimage}`}
                                      alt="no Fish"
                                      className="w-50"
                                      onError={imageError}
                                    />
                                  </td>
                                  <td>{ele.size === "" ? "-" : ele.size}</td>
                                  <td>
                                    {ele.inv_type == "static"
                                      ? "-"
                                      : ele.qty_avail}{" "}
                                  </td>
                                  <td>${ele.retail_price}</td>

                                  <td className="edit-table-update">
                                    <div className="d-flex justify-content-center">
                                      <InputField
                                        className="editSavedDtl-qty mx-3"
                                        size="small"
                                        value={ele.qty}
                                        onchange={onChngUpdateItem(ind, index)}
                                      />
                                      <SecondaryButton
                                        size="small"
                                        color="primary"
                                        variant="outlined"
                                        className="mx-2 my-3 second-button delete-edit-cart"
                                        onClick={() =>
                                          updatedEditSavedCartProduct(ele)
                                        }
                                      >
                                        UPDATE CART
                                      </SecondaryButton>
                                      <div
                                        className="mx-3 my-4 cancel-edit-saved-cart"
                                        onClick={() => onDeleteSingleItem(ele)}
                                      >
                                        <Cancel />
                                      </div>
                                    </div>
                                    <div>
                                      <p>
                                        Amount Payable: ${" "}
                                        {Number(ele.retail_price).toFixed(2)}
                                      </p>
                                    </div>
                                  </td>

                                  {ind === 0 ? (
                                    <td
                                      className="edit-menu-delete"
                                      rowSpan={item.similarproduct.length}
                                    >
                                      <DeleteIcon
                                        fontSize="large"
                                        style={{ color: "#c00" }}
                                        className="delete-save-item"
                                        onClick={() => onDeleteWholeWebsku(ele)}
                                      />
                                    </td>
                                  ) : null}
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  ))}
                </div>
              ) : null}

              <SecondaryButton
                size="small"
                color="primary"
                variant="outlined"
                className="mx-2 my-3 second-button delete-edit-cart"
                style={{ width: "150px" }}
                onClick={purchaseProuctEditsavedCart}
              >
                Purchase Now
              </SecondaryButton>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default EditSavedDetails;
