import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOrderDetails,
  fetchCartItem,
} from "../../Store/Actions/cart.actions";
import OrderShippingConfirmation from "../../components/OrderShippingCustomerInformation";
import OrderShipping from "../../components/OrderShippingConfirmation";
import { Beforeunload } from "react-beforeunload";

const Checkout = () => {
  const userInfo = useSelector((state) => state.users.users);
  const dispatch = useDispatch();
  const [screen, setScreen] = useState(0);
  const [data, setData] = useState({});
  useEffect(() => {
    fetchOrderDetails({
      sessionKey: userInfo.session_id,
    })(dispatch);
  }, []);
  useEffect(() => {
    if (typeof data != "undefined") {
      if (Object.keys(data).length) {
        setScreen(1);
      }
    }
  }, [data]);
  const goBack = () => {
    setScreen(0);
    fetchOrderDetails({
      sessionKey: userInfo.session_id,
    })(dispatch);
  };
  const changeScreen = (screen_data) => {
    setData(screen_data);
  };

  const locationChange = (e) => {
    let reqObj = {
      sessionKey: userInfo.session_id,
      viewTime: 2,
    };
    fetchCartItem(reqObj)(dispatch);
  };

  return screen == 0 ? (
    <>
      <Beforeunload onBeforeunload={locationChange} />
      <OrderShippingConfirmation changeScreen={changeScreen} />
    </>
  ) : (
    <>
      <Beforeunload onBeforeunload={locationChange} />
      <OrderShipping goBack={goBack} screen_data={data} />
    </>
  );
};

export default Checkout;
