import React, { memo, useState } from "react";
import "./AccOverviewTable.styles.css";
import Popup from "../Popup";
import PrimaryButton from "../PrimaryButton/index";
import {
  deleteShippingDetails,
  getShippingDetails,
} from "../../Store/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
const AccOverviewTable = ({ data, open, ship_req }) => {
  const [ispopupOpen, setisPopUpOpen] = useState(false);
  const [deleteEle, setdeleteEle] = useState(0);
  const dispatch = useDispatch();
  const userinfo = useSelector((state) => state.users.users);
  const deleteAddress = (isDelete) => {
    if (isDelete) {
      let reqobj = {};
      reqobj["sessionKey"] = userinfo["session_id"];
      reqobj["id"] = deleteEle;
      deleteShippingDetails(reqobj, dispatch).then(function () {
        setisPopUpOpen(false);
        getShippingDetails(ship_req, dispatch);
      });
    } else {
      setisPopUpOpen(false);
    }
  };
  const onDeleteClick = (ele) => {
    setdeleteEle(ele.id);
    setisPopUpOpen(true);
  };
  return (
    <>
      <div className="accTableWrapper">
        <table id="accOtable" className="table table-striped">
          <thead>
            <tr>
              <td scope="col">Nickname</td>
              <td scope="col">Address</td>
              <td scope="col">City</td>
              <td scope="col">State</td>
              <td scope="col">Zip</td>
              <td scope="col">Country</td>
              <td scope="col">Action</td>
            </tr>
          </thead>
          <tbody>
            {data && data.length
              ? data.map((ele, index) => {
                  return (
                    <>
                      <tr hey={index}>
                        <td>{ele.ship_nickname}</td>
                        <td>{ele.shipping_address}</td>
                        <td>{ele.shipping_city}</td>
                        <td>{ele.shipping_state}</td>
                        <td>{ele.shipping_zipcode}</td>
                        <td>{ele.shipping_country}</td>
                        <td>
                          <p className="cus">Contact Us</p>
                          <span
                            className="fa fa-edit"
                            onClick={(e) => open(ele, "edit")}
                          ></span>
                          <span
                            className="fa fa-trash"
                            onClick={(e) => onDeleteClick(ele)}
                          ></span>
                        </td>
                      </tr>
                    </>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
      {ispopupOpen ? (
        <Popup
          open={ispopupOpen}
          fullWidth={true}
          maxWidth="sm"
          heading="DELETE ADDRESS"
          classNameDialog=""
          classNameDialogTitle=""
          classNameDialogContentText=""
          classNameClose="changepass-close"
          actions={
            <>
              <PrimaryButton
                type="button"
                size="small"
                onsubmit={(e) => deleteAddress(0)}
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton
                type="button"
                size="small"
                onsubmit={(e) => deleteAddress(1)}
              >
                Confirm
              </PrimaryButton>
            </>
          }
        >
          <p>Do you want to delete the Address?</p>
        </Popup>
      ) : null}
    </>
  );
};

export default memo(AccOverviewTable);
