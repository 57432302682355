const intialState = {
  changePasswrdstaus: "",
  closePopup: false,
};

export default function (state = intialState, action) {
  switch (action.type) {
    case "CHANGED_PASSWORD":
      return {
        ...state,
        changePasswrdstaus: action.payload,
        closePopup: action.payload.status ? true : false,
      };

    case "CHANGED_PASSWORD_POPUP":
      return {
        ...state,
        closePopup: action.payload,
      };
    default:
      return state;
  }
}
