import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import MasterLayout from "../MasterLayout";
import InputField from "../Input/InputField";
import SelectField from "../Input/SelectField";
import CustomCheckBox from "../CustomCheckBox";
import PrimaryButton from "../PrimaryButton";
import "./ordersShippingCustomerInformation.css";
import formStateData from "./formState";
import { getCountries } from "../../Store/Actions/userActions";
import { fetchShippingDetails } from "../../Store/Actions/cart.actions";
import {
  phoneNumberUpdate,
  accountOverview,
} from "../../Store/Actions/userActions";
import toastifyClass from "../../utils/emitToastify";

const OrderShippingCustomerInformation = (props) => {
  // -------- Stae from Redux ------------//
  const dispatch = useDispatch();
  const checkout = useSelector((state) => state.checkout.checkout);
  const countryInfo = useSelector(
    (state) => state.newRegistration.newCountries
  );
  const userInfo = useSelector((state) => state.users.users);
  // ---------- END ------------- //

  // --------- LOCAL STATE ---------------- //
  const [formState, setFormState] = useState(formStateData);
  const [countries, setCountries] = useState([]);
  const [shipAddressArr, setShipAddressArr] = useState([]);
  const [validationErr, setValidationErr] = useState({});
  const [userError, setUserError] = useState({});
  const [phoneNumberUpdateCheck, setPhoneNumberUpdateCheck] = useState(false);

  // --------- EMD ---------------- //
  // ------------------ Effects ----------------- //
  useEffect(() => {
    getCountries(dispatch);
  }, []);
  useEffect(() => {
    try {
      if (typeof checkout != "undefined") {
        if (typeof checkout["shipping_address"] != "undefined") {
          if (Array.isArray(checkout["shipping_address"])) {
            if (checkout["shipping_address"].length) {
              setShipAddressArr(checkout["shipping_address"]);
            } else {
              // throw "checkout['shipping_address'] is  an empty array";
            }
          } else {
            // throw "checkout['shipping_address'] is not an array";
          }
        } else {
          // throw "checkout['shipping_address'] is undefined";
        }
        if (typeof checkout.user_dtls != "undefined") {
          if (Object.keys(checkout.user_dtls).length) {
            let prepopulateOb = {
              ...formState,
            };
            prepopulateOb["address"]["value"] =
              checkout["user_dtls"]["address"];
            prepopulateOb["address2"]["value"] =
              checkout["user_dtls"]["address"];
            prepopulateOb["email"]["value"] = checkout["user_dtls"]["email"];
            prepopulateOb["firstName"]["value"] =
              checkout["user_dtls"]["first_name"];
            prepopulateOb["lastName"]["value"] =
              checkout["user_dtls"]["last_name"];
            prepopulateOb["phone"]["value"] = checkout["user_dtls"]["phone"];
            prepopulateOb["mobilePhone"]["value"] =
              checkout["user_dtls"]["cellphone"];
            prepopulateOb["fax"]["value"] = checkout["user_dtls"]["fax"];
            prepopulateOb["country"]["value"] =
              checkout["user_dtls"]["countryname"];
            prepopulateOb["state"]["value"] = checkout["user_dtls"]["state"];
            prepopulateOb["city"]["value"] = checkout["user_dtls"]["city"];
            prepopulateOb["zipcode"]["value"] =
              checkout["user_dtls"]["zip_code"];
            prepopulateOb["companyName"]["value"] =
              checkout["user_dtls"]["companyname"];
            console.log(
              checkout["user_dtls"]["cellphone"].length,
              Boolean(checkout["user_dtls"]["cellphone"].length),
              "this is the ollean vale from the check out"
            );
            if (!Boolean(checkout["user_dtls"]["cellphone"].length)) {
              setPhoneNumberUpdateCheck(true);
            }
            setFormState({ ...prepopulateOb });
          } else {
            // throw "checkout is an empty obj";
          }
        }
      } else {
        // throw "checkout is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  }, [checkout]);

  useEffect(() => {
    try {
      if (typeof countryInfo.country != "undefined") {
        if (Array.isArray(countryInfo.country)) {
          if (countryInfo.country.length) {
            setCountries(countryInfo.country);
          } else {
            throw "countryInfo.country is an empty array";
          }
        } else {
          throw "countryInfo.country is not an array";
        }
      } else {
        throw " countryInfo.country is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  }, [countryInfo]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    let errorsElements = document.getElementsByClassName("error-theme");

    if (
      errorsElements &&
      typeof errorsElements != "undefined" &&
      errorsElements.length
    ) {
      let firstErrElement = errorsElements[0];

      firstErrElement.parentElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [userError]);
  // ------------------ END ----------------- //

  // ----------- event handlers ------------- //
  const handleChange = (e) => {
    try {
      if (typeof e.target === "undefined") throw "event is undefined";

      let { name, value, type } = e.target;
      if (typeof name === "undefined") throw "name is undefined";
      if (typeof value === "undefined") throw "value is undefined";
      if (typeof type === "undefined") throw "type is undefined";
      let currectFormObj = formState[name];
      if (typeof currectFormObj === "undefined")
        throw "currectFormObj is undefined";
      if (type === "checkbox") {
        currectFormObj["isChecked"] = !currectFormObj["isChecked"];
        if (currectFormObj["isChecked"]) {
          if (typeof checkout["user_dtls"] === "undefined")
            throw 'checkout["user_dtls"] is undefined';
          let prepopulateObj = { ...formState };
          prepopulateObj["saddress1"]["value"] =
            checkout["user_dtls"]["address"];
          prepopulateObj["saddress2"]["value"] =
            checkout["user_dtls"]["address"];
          prepopulateObj["scountry"]["value"] =
            checkout["user_dtls"]["countryname"];
          prepopulateObj["sstate"]["value"] = checkout["user_dtls"]["state"];
          prepopulateObj["scity"]["value"] = checkout["user_dtls"]["city"];
          prepopulateObj["szipcode"]["value"] =
            checkout["user_dtls"]["zip_code"];
          prepopulateObj["scompanyName"]["value"] =
            checkout["user_dtls"]["companyname"];
          prepopulateObj["orderPlacedBy"]["value"] =
            checkout["user_dtls"]["first_name"] +
            " " +
            checkout["user_dtls"]["last_name"];
          prepopulateObj["snickname"]["value"] =
            checkout["user_dtls"]["username"];
          prepopulateObj["semail"]["value"] = checkout["user_dtls"]["email"];
          prepopulateObj["sphone"]["value"] = checkout["user_dtls"]["phone"];
          setFormState({ ...formState, ...prepopulateObj });
          return;
        } else {
          let prepopulateObj = { ...formState };
          prepopulateObj["saddress1"]["value"] = "";
          prepopulateObj["saddress2"]["value"] = "";
          prepopulateObj["scountry"]["value"] = "";
          prepopulateObj["sstate"]["value"] = "";
          prepopulateObj["scity"]["value"] = "";
          prepopulateObj["szipcode"]["value"] = "";
          prepopulateObj["scompanyName"]["value"] = "";
          prepopulateObj["orderPlacedBy"]["value"] = "";
          prepopulateObj["snickname"]["value"] = "";
          prepopulateObj["semail"]["value"] = "";
          prepopulateObj["sphone"]["value"] = "";
          setFormState({ ...formState, ...prepopulateObj });
          return;
        }
      }
      if (name === "addressBook") {
        let reqobj = {
          sessionKey: userInfo["session_id"],
          id: value,
        };
        fetchShippingDetails(reqobj, dispatch).then((res) => {
          if (typeof res.data !== "undefined") {
            if (typeof res.data.detail != "undefined") {
              if (Array.isArray(res.data.detail)) {
                if (res.data.detail.length) {
                  if (typeof res.data.detail[0] != "undefined") {
                    let prepopulateObj = { ...formState };
                    prepopulateObj["saddress1"]["value"] =
                      res.data.detail[0]["shipping_address"];
                    prepopulateObj["saddress2"]["value"] =
                      res.data.detail[0]["shipping_address2"];
                    prepopulateObj["scountry"]["value"] =
                      res.data.detail[0]["shipping_country"];
                    prepopulateObj["sstate"]["value"] =
                      res.data.detail[0]["shipping_state"];
                    prepopulateObj["scity"]["value"] =
                      res.data.detail[0]["shipping_city"];
                    prepopulateObj["szipcode"]["value"] =
                      res.data.detail[0]["shipping_zipcode"];
                    prepopulateObj["scompanyName"]["value"] =
                      res.data.detail[0]["shipping_company"];
                    prepopulateObj["orderPlacedBy"]["value"] =
                      res.data.detail[0]["ship_nickname"];

                    setFormState({ ...formState, ...prepopulateObj });
                    return;
                  } else {
                    throw "res.data.detail[0] is undefined";
                  }
                } else {
                  throw "res.data.detail is an empty array";
                }
              } else {
                throw "res.data.detail is not an array";
              }
            } else {
              throw "res.data.detail is undefined";
            }
          } else {
            throw "res.data is undefined";
          }
        });
      }
      currectFormObj["value"] = value;
      setFormState({ ...formState, [name]: currectFormObj });
      setUserError({
        ...userError,
        [name]: "",
      });
      setValidationErr({
        ...validationErr,
        [name]: "",
      });
    } catch (e) {
      console.log(e);
    }
  };
  const shippingOrderCustomerValidation = () => {
    let valErr = {};
    Object.keys(formState).map((val) => {
      if (val === "email" || val === "semail") {
        const emailvalid =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!emailvalid.test(formState[val].value))
          valErr[val] = "Please enter the valid email address";
      } else if (
        val === "phone" ||
        val === "mobilePhone" ||
        val === "fax" ||
        val === "sphone"
      ) {
        if (!(formState[val].value === ""))
          if (formState[val].value.length < 10) {
            valErr[val] = "Phone number should be minimum 10 digits";
          } else if (formState[val].value.length > 15) {
            valErr[val] = "Phone number should be maximum 15 digits only";
          }
      } else if (val === "zipcode" || val === "szipcode") {
        if (formState[val].value.length < 5) {
          valErr[val] = "Zip code should be minimum 5 digits";
        } else if (formState[val].value.length > 13) {
          valErr[val] = "Zip code should be maximum 13 digits only";
        }
      } else if (val === "firstName" || val === "lastName") {
        const textFormat = /^[a-zA-Z\s]*$/;
        if (!textFormat.test(formState[val].value))
          valErr[val] = "Enter the correct name";
      }
    });
    setValidationErr(valErr);
    return valErr;
  };
  const validate = () => {
    let err = {};
    Object.keys(formState).map((user) => {
      if (formState[user].value === "" && formState[user].isRequired) {
        err[user] = formState[user]["errMsg"];
      }
    });
    setUserError(err);
    return err;
  };

  const onOrderShippingConfirm = (e) => {
    e.preventDefault();
    if (Object.keys(validate()).length === 0) {
      if (Object.keys(shippingOrderCustomerValidation()).length === 0)
        if (phoneNumberUpdateCheck) {
          addPhoneNumber();
        }
      props.changeScreen(formState);
    }
    // props.changeScreen(formState);
  };
  // -------------- END ---------------------- //

  const addPhoneNumber = async (e) => {
    let phonenumberUpdate = {
      sessionKey: userInfo.session_id,
      mobile_phone: formState["mobilePhone"]["value"],
    };
    const res = await phoneNumberUpdate(phonenumberUpdate, dispatch);
    try {
      if (res.data.status === true) {
        accountOverview(
          { sessionKey: userInfo.session_id, type: "view" },
          dispatch
        );
        new toastifyClass(true, res.data.msg, "success", dispatch).emittoast();
      } else {
        new toastifyClass(true, res.data.msg, "error", dispatch).emittoast();
      }
    } catch (err) {
      console.log(err, "==> err from update phone number");
    }
  };

  return (
    <MasterLayout {...props}>
      <div style={{ paddingTop: 45 }}>
        <p className="topHeaderMessage">
          Items are reserved during checkout for 5 Minutes and then moved to
          your wishlist
        </p>
        <form onSubmit={onOrderShippingConfirm}>
          <div id="shipping-order-confirm" />
          <div className="my-contact">
            <p className="heading text-left">My Contact Information</p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Email
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="email"
                  size="medium"
                  type="email"
                  id="email"
                  value={formState["email"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.email ? (
                  <span className="error-theme">{userError.email}</span>
                ) : null}
                {validationErr.email ? (
                  <span className="error-theme">{validationErr.email}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      First Name
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="firstName"
                  size="medium"
                  type="text"
                  id="firstName"
                  value={formState["firstName"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.firstName ? (
                  <span className="error-theme">{userError.firstName}</span>
                ) : null}
                {validationErr.firstName ? (
                  <span className="error-theme">{validationErr.firstName}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Last Name
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="lastName"
                  size="medium"
                  type="text"
                  id="lastName"
                  value={formState["lastName"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.lastName ? (
                  <span className="error-theme">{userError.lastName}</span>
                ) : null}
                {validationErr.lastName ? (
                  <span className="error-theme">{validationErr.lastName}</span>
                ) : null}
              </div>

              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Phone
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="phone"
                  size="medium"
                  type="tel"
                  id="phone"
                  value={formState["phone"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.phone ? (
                  <span className="error-theme">{userError.phone}</span>
                ) : null}
                {validationErr.phone ? (
                  <span className="error-theme">{validationErr.phone}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Mobile Phone
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="mobilePhone"
                  size="medium"
                  type="tel"
                  id="mobilePhone"
                  value={formState["mobilePhone"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.mobilePhone ? (
                  <span className="error-theme">{userError.mobilePhone}</span>
                ) : null}
                {validationErr.mobilePhone ? (
                  <span className="error-theme">
                    {validationErr.mobilePhone}
                  </span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Fax"
                  name="fax"
                  size="medium"
                  type="number"
                  id="fax"
                  value={formState["fax"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {validationErr.fax ? (
                  <span className="error-theme">{validationErr.fax}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="billing-info">
            <p className="heading text-left">Billing Information</p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Company Name
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="companyName"
                  size="medium"
                  type="text"
                  id="companyName"
                  value={formState["companyName"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.companyName ? (
                  <span className="error-theme">{userError.companyName}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Address
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="address"
                  size="medium"
                  type="text"
                  id="address"
                  value={formState["address"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.address ? (
                  <span className="error-theme">{userError.address}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Address 2"
                  name="address2"
                  size="medium"
                  type="text"
                  id="address2"
                  value={formState["address2"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="input-box">
                <SelectField
                  label={
                    <span>
                      Country
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="country"
                  id="country"
                  value={formState["country"]["value"]}
                  pc_onchange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value="">Select Country</option>
                  {countries && countries.length
                    ? countries.map((c, index) => (
                        <option key={index} value={c.ccode}>
                          {c.cname}
                        </option>
                      ))
                    : null}
                </SelectField>
                {userError.country ? (
                  <span className="error-theme">{userError.country}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      City
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="city"
                  size="medium"
                  type="text"
                  id="city"
                  value={formState["city"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.city ? (
                  <span className="error-theme">{userError.city}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      State
                      {/* <span className="asterk">&nbsp;*</span> */}
                    </span>
                  }
                  name="state"
                  size="medium"
                  type="text"
                  id="state"
                  value={formState["state"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.state ? (
                  <span className="error-theme">{userError.state}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Zip Code
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="zipcode"
                  size="medium"
                  type="text"
                  id="zipcode"
                  value={formState["zipcode"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.zipcode ? (
                  <span className="error-theme">{userError.zipcode}</span>
                ) : null}
                {validationErr.zipcode ? (
                  <span className="error-theme">{validationErr.zipcode}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="shipping-info">
            <p className="heading text-left">Shipping Information</p>
            <div className="">
              <div className="input-box text-left">
                <CustomCheckBox
                  label="Shipping Address is same as Billing address"
                  name="shippingInformation"
                  value={formState["shippingInformation"]["value"]}
                  onclick={handleChange}
                  checked={formState["shippingInformation"]["isChecked"]}
                />
              </div>
              <div className="input-box">
                <SelectField
                  name="addressBook"
                  id="addressBook"
                  label="Your Address Book"
                  value={formState["addressBook"]["value"]}
                  pc_onchange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={formState["shippingInformation"]["isChecked"]}
                >
                  <option value="">New Address</option>
                  {shipAddressArr && shipAddressArr.length
                    ? shipAddressArr.map((c, index) => (
                        <option key={index} value={c.id}>
                          {`${c.ship_nickname} - ${c.shipping_address} - ${c.shipping_zipcode}`}
                        </option>
                      ))
                    : null}
                </SelectField>
              </div>
            </div>
          </div>
          <div className="shipping-contact-info">
            <p className="heading text-left">Shipping Contact Information</p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  label="Nick Name"
                  name="snickname"
                  size="medium"
                  type="text"
                  id="snickname"
                  value={formState["snickname"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="input-box">
                <InputField
                  label="Phone"
                  name="sphone"
                  size="medium"
                  type="tel"
                  id="sphone"
                  value={formState["sphone"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {validationErr.sphone ? (
                  <span className="error-theme">{validationErr.sphone}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Email"
                  name="semail"
                  size="medium"
                  type="text"
                  id="semail"
                  value={formState["semail"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {validationErr.semail ? (
                  <span className="error-theme">{validationErr.semail}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="shipping-address">
            <p className="heading text-left">Shipping Address</p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Order Placed By
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="orderPlacedBy"
                  size="medium"
                  type="text"
                  id="orderPlacedBy"
                  value={formState["orderPlacedBy"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.orderPlacedBy ? (
                  <span className="error-theme">{userError.orderPlacedBy}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Company Name"
                  name="scompanyName"
                  size="medium"
                  type="text"
                  id="scompanyName"
                  value={formState["scompanyName"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Address
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="saddress1"
                  size="medium"
                  type="text"
                  id="saddress1"
                  value={formState["saddress1"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.saddress1 ? (
                  <span className="error-theme">{userError.saddress1}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Address 2"
                  name="saddress2"
                  size="medium"
                  type="text"
                  id="saddress2"
                  value={formState["saddress2"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="input-box">
                {" "}
                <SelectField
                  label={
                    <span>
                      Country
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="scountry"
                  id="scountry"
                  value={formState["scountry"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value="">Select Country</option>
                  {countries && countries.length
                    ? countries.map((c, index) => (
                        <option key={index} value={c.ccode}>
                          {c.cname}
                        </option>
                      ))
                    : null}
                </SelectField>
                {userError.scountry ? (
                  <span className="error-theme">{userError.scountry}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      City
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="scity"
                  size="medium"
                  type="text"
                  id="scity"
                  value={formState["scity"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.scity ? (
                  <span className="error-theme">{userError.scity}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      State
                      {/* <span className="asterk">&nbsp;*</span> */}
                    </span>
                  }
                  name="sstate"
                  size="medium"
                  type="text"
                  id="sstate"
                  value={formState["sstate"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.sstate ? (
                  <span className="error-theme">{userError.sstate}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Zip
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  name="szipcode"
                  size="medium"
                  type="text"
                  id="szipcode"
                  value={formState["szipcode"]["value"]}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.szipcode ? (
                  <span className="error-theme">{userError.szipcode}</span>
                ) : null}
                {validationErr.szipcode ? (
                  <span className="error-theme">{validationErr.szipcode}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="save-proceed my-2">
            <PrimaryButton
              type="submit"
              btnSize="small"
              // onClick={props.changeScreen}
            >
              Save and Proceed
            </PrimaryButton>
          </div>
        </form>
      </div>
    </MasterLayout>
  );
};

export default OrderShippingCustomerInformation;
