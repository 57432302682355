import React from "react";

const Layout = (props) => {
  return (
    <div className="myAccountLayout">
      <h3 className="accountTitle">{props.title}</h3>
      {props.children}
    </div>
  );
};

export default Layout;
