const initalState = {
  hoursValue: "",
  minutesValue: "",
  secondsValue: "",
  cartTimeDtls: [],
  timeRunning: [],
};

export default function (state = initalState, { type, payload }) {
  switch (type) {
    case "HOURS_VALUE":
      return {
        ...state,
        hoursValue: payload,
      };
    case "MINIUTES_VALUE":
      return {
        ...state,
        minutesValue: payload,
      };
    case "SECONDS_VALUE":
      return {
        ...state,
        secondsValue: payload,
      };
    case "CART_TIME_DETAILS":
      return {
        ...state,
        cartTimeDtls: payload,
      };
    case "TIMERUNNING":
      return {
        ...state,
        timeRunning: payload,
      };
    default:
      return state;
  }
}
