import React, { useState, useEffect, useRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import toastifyClass from "../../utils/emitToastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { Searchfilters } from "../../Store/Actions/userActions";
import Pagination from "@material-ui/lab/Pagination";
import PrimaryButton from "../../components/PrimaryButton";

import ItemSmallView from "../../components/ProductView/ItemSmallView";
import DetailsPopup from "../../components/DetailsPopup/index";
import MasterLayout from "../MasterLayout";
import "./dryGoods.css";
import { keys } from "@material-ui/core/styles/createBreakpoints";
import Loader from "../../components/Loader";
import NotFnd from "../../components/NotFound/notFound";
import { useHistory } from "react-router-dom";
import { socketChangeInFurnace } from "../../Store/Actions/socketActions";
import { addToCartBulk, fetchCartItem } from "../../Store/Actions/cart.actions";
import popupClass from "../../utils/emitPopup";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f8f8f8",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    border: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  body: {
    fontSize: 14,
    paddingTop: "10px",
    paddingBottom: "10px",
    border: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child": {
      // backgroundColor: theme.palette.action.hover,
      fontWeight: "500",
      width: "220px",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#D3D3D3",
    },
    // "&:hover": {
    //   backgroundColor: "#e9e9e9",
    // },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderCollapse: "collapse",
    marginTop: 110,
  },
  container: {
    // maxHeight: 600,
  },
});

const DryGoods = (props) => {
  const classes = useStyles();
  const [dryView, setDryView] = useState({
    details: [],
    pop: false,
  });
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [itemView, setItemView] = useState(false);
  const [leftVal, setLeftVal] = useState(0);
  const [hoverTable, setHoverTable] = useState();
  const [lotListTable, setLotListTable] = useState([]);
  const [pageWish, setPageWish] = useState(1);
  const [reload, setReload] = useState(false);
  const [addAllToCart, setAddAllToCart] = useState([]);
  const history = useHistory();
  const [isBulkAdded, setIsBulKAdded] = useState(false);

  const loader = useSelector((state) => {
    return state.loader;
  });
  const pageClear = useSelector((state) => state.Search.menuSelect);
  const dispatch = useDispatch();
  const tableCell = useRef(null);
  const refPageWish = useRef(1);
  const listOfSkuItems = useRef([]);
  const refReload = useRef(false);
  const listDataRef = useRef(null);

  const lists = useSelector((state) => state.Search.details);
  const nav_name = useSelector((state) => state.Search.nav_name);
  const userInfo = useSelector((state) => state.users.users);
  const cartDetails = useSelector((state) => state.cart);

  const handleDrygoodView = (values) => (e) => {
    setDryView({ ...dryView, details: values, pop: true });
  };
  const onHidePopup = () => {
    setDryView({ ...dryView, pop: false });
  };
  const changeReload = () => {
    setReload(!refReload.current);
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: "GET_SEARCH_RESULT",
        payload: {},
      });
    };
    const parms = new URLSearchParams(props.location.search);
    let pagenumber = parms.getAll("page")[0];
    console.log(pagenumber, "pagenumber");
    setPageWish(Number(pagenumber));
  }, []);

  useEffect(() => {
    if (pageClear && typeof pageClear !== "undefined" && pageClear) {
      setPageWish(1);
      dispatch({
        type: "MENU_SELECTED",
        payload: false,
      });
    }
  }, [pageClear]);

  useEffect(() => {
    refPageWish.current = pageWish;
    listDataRef.current = lists;
    refReload.current = reload;
  });
  useEffect(() => {
    socketChangeInFurnace((data) => {
      if (Number(data.updated) === 1) {
        if (props && typeof props.location != "undefined") {
          if (
            listDataRef.current &&
            typeof listDataRef.current !== "undefined"
          ) {
            let listData = listDataRef.current.data;
            if (
              listData &&
              typeof listData != "undefined" &&
              Array.isArray(listData)
            ) {
              let variableChanged = false;
              let newSkuArr = [];
              listData.map((eles) => {
                let receivedEle = eles;
                let changedArray = [];
                eles.sku_dtls.map((ele) => {
                  if (
                    typeof data.new_stock[ele.sku.toString()] !== "undefined"
                  ) {
                    variableChanged = true;
                    ele.quantity_available = data.new_stock[ele.sku.toString()];
                    changedArray.push(ele);
                    return true;
                  } else {
                    changedArray.push(ele);
                    return true;
                  }
                });
                receivedEle.sku_dtls = changedArray;
                newSkuArr.push(receivedEle);
              });
              if (variableChanged) {
                lists.data = newSkuArr;
                changeReload();
              }
            }
          }
          // let skuAdded = data.sku;
          // let currentPageSkus = listOfSkuItems.current.filter((ele) =>
          //   skuAdded.includes(ele)
          // );
          // if (Boolean(currentPageSkus)) {
          //   let ID = props.location.search.split("=")[1];
          //   let obj = {
          //     sessionKey: userInfo.session_id,
          //     search_value: "",
          //     search_key: "",
          //     size: "25",
          //     page: refPageWish.current,
          //     cid: ID ? ID : "",
          //   };
          //   Searchfilters(obj, dispatch, false, true, true).then((res) => {
          //     if (res.status) {
          //       dispatch({
          //         type: "FETCH_LOAD",
          //         payload: false,
          //       });
          //     }
          //   });
          // }
        }
      }
    });
  }, []);
  useEffect(() => {
    try {
      if (
        lists &&
        typeof lists !== "undefined" &&
        lists.data &&
        lists.data.length > 0 &&
        typeof lists.data !== "undefined"
      ) {
        dispatch({
          type: "LOADING",
          payload: false,
        });
        let dataSkus = lists.data.map((ele) => {
          return ele.sku_dtls;
        });
        let skuDtls = dataSkus.flat().map((ele) => {
          return ele.sku;
        });
        listOfSkuItems.current = skuDtls;
      }
    } catch (err) {
      console.log(err, "==> this is the err from dry goods");
    }
    window.scrollTo(0, 0);
  }, [lists]);

  useEffect(() => {
    try {
      if (props && typeof props.location != "undefined") {
        if (listDataRef.current && typeof listDataRef.current !== "undefined") {
          let listData = listDataRef.current.data;
          if (
            listData &&
            typeof listData != "undefined" &&
            Array.isArray(listData)
          ) {
            let variableChanged = false;
            let newSkuArr = [];
            listData.map((eles) => {
              let receivedEle = eles;
              let changedArray = [];
              eles.sku_dtls.map((ele) => {
                let cart_data = cartDetails.savedCart.items.filter((item) =>
                  item.sku_dtls.sku.includes(ele.sku.toString())
                );
                if (cart_data.length > 0) {
                  variableChanged = true;
                  ele.cart_qty = cart_data[0].sku_dtls.owned_quantity;
                  changedArray.push(ele);
                  return true;
                } else {
                  ele.cart_qty = 0;
                  changedArray.push(ele);
                  return true;
                }
              });
              receivedEle.sku_dtls = changedArray;
              newSkuArr.push(receivedEle);
            });
            if (variableChanged) {
              lists.data = newSkuArr;
              changeReload();
            }
          }
        }
      }
    } catch (err) {
      console.log(err, "==> this is cart err");
    }
  }, [cartDetails]);

  const lotPriceCheck = (value) => {
    try {
      if (typeof value.sku_dtls !== "undefined") {
        if (Array.isArray(value.sku_dtls)) {
          if (value.sku_dtls.length) {
            let lottems = value.sku_dtls.filter((ele) => {
              if (
                ele.special_price &&
                parseFloat(ele.retail_price) > parseFloat(ele.special_price)
              ) {
                return true;
              }
              return false;
            });
            if (lottems.length) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    } catch (e) {
      return false;
    }
  };

  const wishListPageChange = (event, value) => {
    setPageWish(value);
    const parms = new URLSearchParams(props.location.search);
    let ID = parms.getAll("key")[0];
    history.push(`/drygoods?key=${ID}&page=${value}`);
    let data = {
      sessionKey: userInfo.session_id,
      search_value: "",
      search_key: "",
      size: "25",
      page: value,
      cid: ID ? ID : "",
      sortbyprice: "",
      pricesearch: "",
      main_coralType: "",
    };
    Searchfilters(data, dispatch, false, true, true).then((res) => {
      if (res.status) {
        dispatch({
          type: "FETCH_LOAD",
          payload: false,
        });
      }
    });
  };

  const secondayCallBack = () => {
    addAllToCart[0].items.map((ele) => {
      ele.override = 1;
    });
    AddAllToCartHandler();
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const AddAllToCartHandler = async () => {
    let zeroQty = [];

    var payload = {
      sessionKey: userInfo.session_id,
      bulk: addAllToCart,
    };

    console.log("addAllToCart", addAllToCart);

    addAllToCart[0].items.map((ele) => {
      if (ele.qty <= 0) {
        zeroQty.push(ele.sku);
      }
    });
    if (zeroQty.length > 0) {
      new popupClass(
        true,
        "Zero Qty choosen on Sku: " + zeroQty.join(","),
        "Zero Quantity",
        "",
        "",
        "",
        "",
        dispatch
      ).emitPopup();
      zeroQty = [];
    } else {
      const result = await addToCartBulk(payload, dispatch);
      if (result.data.status) {
        setAddAllToCart([]);
        setIsBulKAdded(!isBulkAdded);
        if (result.data.flag !== "exist") {
          return fetchCartItem({
            sessionKey: userInfo.session_id,
          })(dispatch);
        } else {
          return Promise.reject();
        }
      } else {
        console.log(result.data.flag);
        if (result.data.flag === "exist") {
          let stringValue = (
            <p>
              The Following Items already is in your Cart, Are you sure you want
              to add more? <br /> {result.data.skuDetail.join(",")}
            </p>
          );

          new popupClass(
            true,
            stringValue,
            "Oops !! Already Available in Cart",
            "No",
            "Yes",
            "ok-popup-app",
            "no-popup-app",
            dispatch,
            undefined,
            secondayCallBack,
            "",
            "already-avail-cart",
            "already-add-action"
          ).emitPopup();
        } else {
          new toastifyClass(
            true,
            result.data.msg && typeof result.data.msg == "string"
              ? result.data.msg
              : result.data.detail && typeof result.data.detail == "string"
              ? result.data.detail
              : "",
            "error",
            dispatch
          ).emittoast();
        }
      }
    }
  };

  const hoverVal = (hoverStatus, index, hoverItem, indexSmall, xValue) => {
    try {
      setHoveredIndex(index);
      setItemView(true);
      setLotListTable(hoverItem.lot_dtls);
      setLeftVal(xValue);
      if (typeof hoverItem.lot_dtls === "undefined")
        throw "hoverItem.lot_dtls is undefined";
      let hovtabite = hoverItem.lot_dtls;
      if (typeof hovtabite !== "undefined") {
        if (Array.isArray(hovtabite) || Object.keys(hovtabite).length)
          if (Array.isArray(hovtabite) && hovtabite.length === 0)
            throw "lotlist table is an atrray and its empty";
        let keysOfQuantity = Object.keys(hovtabite["Quantity"]);
        let keysOfPrice = Object.keys(hovtabite["price"]);
        if (keysOfQuantity.length === keysOfPrice.length) {
          let hovTaArr = [];
          keysOfQuantity.map((ele, index) => {
            let price = "";
            let qty = "";
            if (Array.isArray(hovtabite)) {
              price = hovtabite[indexSmall]["price"][ele];
              qty = hovtabite[indexSmall]["Quantity"][ele];
            } else {
              price = hovtabite["price"][index];
              qty = hovtabite["Quantity"][index];
            }
            hovTaArr.push({
              quantity: qty,
              price: price,
            });
            return ele;
          });
          setHoverTable(hovTaArr);
        } else {
          throw "keysOfQuantity.length === keysOfPrice.length isw not equal";
        }
      } else {
        throw "lotListTable is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  };

  let tdLength = [];
  let maxTd = 0;

  lists.data &&
    !(lists.data.length === 0) &&
    !Boolean(lists.is_live_sale) &&
    lists.data.map((data) => (
      <>
        {tdLength.push(data.sku_dtls.length)}
        {(maxTd = Math.max(...tdLength))}
      </>
    ));

  const imageError = (e) => {
    return (e.target.src =
      "https://auctionsoftwaremarketplace.com/seadwelling/images/default/NoImageAvailable.jpg");
  };

  const imgUrl = (key) => {
    if (key.inv_item == 1 || key.inv_item == 2) {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/live/${key.image}`;
    } else {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/static/${key.image}`;
    }
  };
  return (
    <MasterLayout {...props}>
      <div className="dry-goods">
        <>
          <h5 className=" sub-catergorie-name p-3">{nav_name}</h5>

          <div className="paginationAndAddAll">
            <div className="paginationSection">
              {lists.data && !(lists.data.length === 0) ? (
                <Pagination
                  page={pageWish}
                  className={"mt-2"}
                  count={Math.ceil(lists.total_row / 25)}
                  onChange={wishListPageChange}
                />
              ) : null}
            </div>
            <div className="addAllSection">
              {lists.data && !(lists.data.length === 0) ? (
                <>
                  <div className="addAllToCartButton">
                    <PrimaryButton
                      onClick={AddAllToCartHandler}
                      disabled={!(addAllToCart.length > 0)}
                      style={{ height: "30px" }}
                    >
                      Add to Cart
                    </PrimaryButton>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <TableContainer component={Paper} className={`${classes.container}`}>
            {loader.isLoading ? (
              <>
                <Loader />
                <div style={{ height: "100vh" }}>
                  <p style={{ marginTop: "3rem" }}>Loading..</p>
                </div>
              </>
            ) : lists.data && lists.data.length > 0 ? (
              <Table
                className={`${classes.table} search-table`}
                stickyHeader
                aria-label="customized table"
              >
                {lists.data &&
                !(lists.data.length === 0) &&
                !Boolean(lists.is_live_sale) ? (
                  <TableBody>
                    {lists.data && lists.data.length > 0
                      ? lists.data.map((key, value, index) =>
                          key.sku_dtls.length > 0 ? (
                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {/* <HtmlTooltip
                                  title={
                                    <div className="tableShortInfo">
                                      <img
                                        src={key.image}
                                        onError={imageError}
                                        alt={key.websku}
                                      />
                                      <h3>{key.description}</h3>
                                    </div>
                                  }
                                > */}
                                <Button
                                  className="pvTrigger"
                                  onClick={handleDrygoodView(lists.data[value])}
                                >
                                  {lotPriceCheck(key) ? (
                                    <div style={{ margin: "1px 2px" }}>
                                      {/* <span className="dots">&#10003;</span> */}
                                      <span className="dots saleImg">
                                        <img src="./assets/images/saleBadge.png" />
                                      </span>
                                    </div>
                                  ) : null}
                                  <div className="imageThumbnail">
                                    <img
                                      src={key.image}
                                      onError={imageError}
                                      alt={key.websku}
                                    />
                                  </div>
                                  {key.description}
                                  {/* {lotPriceCheck(key) ? (
                                    <span className="sale-tag">Sale</span>
                                  ) : null} */}
                                </Button>
                                {/* </HtmlTooltip> */}
                              </StyledTableCell>

                              {key.sku_dtls.map((value1, index) =>
                                value1 &&
                                typeof value1 !== "undefined" &&
                                value1 !== null ? (
                                  <StyledTableCell
                                    ref={tableCell}
                                    className={`${
                                      props.cart_qty > 0 ? "borderred" : ""
                                    }`}
                                    align="center"
                                    key={"itemToCart" + index}
                                  >
                                    <div
                                      id="smallItem"
                                      className="featured-product-small-item"
                                    >
                                      <ItemSmallView
                                        className="smallitvi"
                                        id={value1.sku}
                                        indexofpar={value}
                                        itemSmall={index}
                                        {...value1}
                                        hoverVal={hoverVal}
                                        rp={value1.retail_price}
                                        sp={
                                          value1.special_price
                                            ? value1.special_price
                                            : ""
                                        }
                                        custLimit={value1.customer_limit}
                                        sku_limit={value1.sku_limit}
                                        websku_limit={value1.websku_limit}
                                        qtyAvbl={value1.quantity_available}
                                        details={value1}
                                        hoverTable={hoverTable}
                                        itemView={itemView}
                                        hoveredIndex={hoveredIndex}
                                        value_={value}
                                        lotPriceCheck={lotPriceCheck}
                                        setAddAllToCart={setAddAllToCart}
                                        isBulkAdded={isBulkAdded}
                                        totalListData={lists.data}
                                        addAllToCart={addAllToCart}
                                        key_={key}
                                        pageNo={pageWish}
                                      />
                                    </div>
                                  </StyledTableCell>
                                ) : null
                              )}
                              {Array.from(
                                Array(maxTd - key.sku_dtls.length)
                              ).map(() => (
                                <StyledTableCell></StyledTableCell>
                              ))}
                            </StyledTableRow>
                          ) : null
                        )
                      : null}
                  </TableBody>
                ) : null}
              </Table>
            ) : (
              <div style={{ height: "100vh" }}>
                <p style={{ marginTop: "3rem" }}>Loading..</p>
              </div>
            )}
          </TableContainer>

          {lists &&
          typeof lists !== "undefined" &&
          lists.data &&
          lists.data.length === 0 ? (
            <NotFnd />
          ) : null}
        </>
        {dryView.pop && typeof dryView.details != "undefined" ? (
          <DetailsPopup
            value={dryView.pop}
            onpop1={onHidePopup}
            details={dryView.details}
          />
        ) : null}
      </div>
    </MasterLayout>
  );
};

export default DryGoods;
