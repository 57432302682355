import React from "react";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";
const Thankyou = (props) => {
  return (
    <div className="sdHome">
      <div className="bnrBack">
        <div className="sdhBanner">
          <div className="sdhLogo">
            <img
              src="./assets/images/logo_home.png"
              alt="Seadwelling logo"
            ></img>
          </div>
        </div>
      </div>
      <Container>
        <div className="text-left pt-5">
          <h2>Thank you!</h2>
          <p>
            Thank you for submitting an new customer application with sea
            Dwelling Creatures, Inc. Please allow up to 3 business days for
            approval.
          </p>
          <p>
            Our New Account manager will be reviewing your application, and will
            contact with you questions.
          </p>
          <p>
            Please fell free to reach out to us with any questions via email to:{" "}
            <a href="mailto:newaccounts@seadwelling.com">
              newaccounts@seadwelling.com
            </a>
          </p>
          <p>We look forward to working with you,</p>
          <p>SDC</p>
        </div>
        <Link to="/" className="text-center">
          Click here to go home
        </Link>
      </Container>
    </div>
  );
};

export default Thankyou;
