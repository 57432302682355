import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import MasterLayout from "../../components/MasterLayout";
import SearchResults from "./SearchResults";
import {
  Sitemenu,
  Searchfilters,
  accountOverview,
} from "../../Store/Actions/userActions";
import InitialChangePassword from "../../components/InitialChangePassword";
import "./Search.css";
import { getSavedCart } from "../../Store/Actions/cart.actions";
import moment from "moment-timezone";

const Search = (props) => {
  const [initalChngPopup, setInitialChngPopup] = useState(false);

  const userInfo = useSelector((state) => state.users.users);
  const cartDetails = useSelector((state) => state.cart);
  const nav_name = useSelector((state) => state.Search.nav_name);

  const dispatch = useDispatch();

  const initialvalues = {
    sessionKey: userInfo.session_id,
    search_value: "",
    search_key: "",
    size:
      nav_name === "All Invertebrates" || nav_name === "All Fish"
        ? "100"
        : "25",
    page: "1",
    cid: "",
  };

  const [stateInitial, setStateInitial] = useState(initialvalues);
  const [stockCartItems, setStockCartItems] = useState([]);

  const initialChngPassClose = () => {
    setInitialChngPopup(false);
  };

  // useEffect(() => {
  //   if (cartDetails.savedCart.count > 0) {
  //     setStockCartItems(cartDetails.savedCart.items);
  //   }
  // }, [cartDetails]);

  // useEffect(() => {
  //   if (stockCartItems.length > 0) {
  //     let badCartItems = stockCartItems;
  //     badCartItems.map((ele, index) => {
  //       if (ele.sku_dtls.purchaseitem == "Y") {
  //         ele.sku_dtls.purchaseitem = "";
  //         ele.sku_dtls.timeadded = moment.utc().format();
  //         // stockCartItems[index].db_time = 1;
  //         stockCartItems[index].db_time_live = localStorage.getItem("db_time_live") ? parseInt(localStorage.getItem("db_time_live")) : 45;
  //         stockCartItems[index].db_time_managed = localStorage.getItem("db_time_managed") ? parseInt(localStorage.getItem("db_time_managed")) : 45;
  //       }
  //       dispatch({
  //         type: "CART_DATA",
  //         payload: {
  //           detail: badCartItems,
  //         },
  //       });
  //     });
  //   }
  // }, [stockCartItems]);

  // useEffect(() => {
  //   featureProductDetailsInfo();
  // }, []);

  const firstTimeResetPopup = () => {
    if (userInfo.detail.is_first_login) {
      setInitialChngPopup(true);
    } else {
      setInitialChngPopup(false);
    }
  };

  useEffect(() => {
    firstTimeResetPopup();
  }, [userInfo.isLogin]);
  return (
    <>
      <InitialChangePassword
        initalChngPopup={initalChngPopup}
        initialChngPassClose={initialChngPassClose}
      />
      <MasterLayout {...props}>
        <SearchResults {...props} />
      </MasterLayout>
    </>
  );
};
export default Search;
