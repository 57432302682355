const intialState = {
  status: "",
  dynamicContent: "",
};

export default function (state = intialState, action) {
  switch (action.type) {
    case "GET_FTP":
      return {
        ...state,
        status: action.payload.status,
        dynamicContent: action.payload.detail[0].ftpsettings,
      };
    default:
      return state;
  }
}
