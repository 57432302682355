import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InputField from "../Input/InputField";
// import "./savedWishlist.css";
import CustomDropDown from "../CustomDropDown";
import {
  savedwishlistList,
  deleteSavedWatchList,
  Searchfilters,
} from "../../Store/Actions/userActions";
import {
  addItemToCart,
  fetchCartItem,
  deleteOutOfStock,
  addToCartBulk,
} from "../../Store/Actions/cart.actions";
// import { addToCartBulk, fetchCartItem } from "../../Store/Actions/cart.actions";

import PopupAlert from "../../components/PopupAlert/index";
import CustomPagination from "../../components/Pagination";
import Pagination from "@material-ui/lab/Pagination";
import { Button } from "@material-ui/core";
import SecondaryButton from "../../components/SecondaryButton";
import PrimaryButton from "../../components/PrimaryButton";
import popupClass from "../../utils/emitPopup";

function createData(
  ds,
  sku,
  qtyAvailable,
  qty_ordered,
  orderedQty,
  wishlistQty,
  price,
  image,
  date_added
) {
  return {
    ds,
    sku,
    qtyAvailable,
    qty_ordered,
    orderedQty,
    wishlistQty,
    price,
    image,
    date_added,
  };
}
export const AddToCart = ({ qtChange, data, cartQty, cartAddedClick }) => {
  return (
    // <div className="addItem d-flex justify-content-center align-items-center">
    //   <InputField
    //     size="small"
    //     label="Qty"
    //     onChange={qtChange(data)}
    //     value={cartQty}
    //   />
    //   <Button color="primary" onClick={cartAddedClick(data)}>
    //     <span className="material-icons">add_shopping_cart</span>
    //   </Button>
    // </div>
    <>
      <InputField
        className="editSavedDtl-qty mx-3 qtyBox"
        size="small"
        onchange={qtChange(data)}
        value={cartQty}
      />

      <span
        class="material-icons py-4 add-to-cart"
        onClick={cartAddedClick(data)}
      >
        add_shopping_cart
      </span>
    </>
  );
};

let insufficientQty = [];
let zeroQty = [];
let outOfStockSku = [];

const SavedWishlist = () => {
  const [wish_req, setWish_req] = useState({
    sessionKey: "",
    search_value: "",
    search_key: "",
    size: 25,
    page: 1,
    sort: "",
    dir: "",
  });
  const [deleteWishListPopup, setDeleteWishListPopup] = useState(false);
  const [deleteWishlistData, setDeleteWishlistData] = useState({});
  const [orgWishlist, setOrgwishlist] = useState([]);
  const [cartQty, setCartQty] = useState([]);
  const [rows, setRows] = React.useState([]);
  const [pageArr, setPageArr] = useState([]);
  const [pageWish, setPageWish] = useState(1);
  const [okbuttonData, setOkButtonData] = useState({});
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.users.users);
  const wishlist = useSelector((state) => state.users.saved_wishlist);
  const cart = useSelector((state) => state.cart);
  const dataCount = [5, 10, 25, 50, 100];
  const history = useHistory();
  const outOfStock = useSelector((state) => state.cart.outOfStock);

  const onSearchChange = (e) => {
    let search_value = e.target.value;
    setWish_req({ ...wish_req, search_value });
    let wishListValues = {
      sessionKey: userInfo.session_id,
      search_value: search_value ? search_value : "",
      search_key: "",
      size: wish_req.size,
      page: wish_req.page,
      sort: "",
      dir: "",
    };
    savedwishlistList(wishListValues, dispatch);
  };

  const okClose = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  // // ----------- Effects --------------- //
  useEffect(() => {
    wish_req["sessionKey"] = userInfo["session_id"];
    setWish_req(wish_req);
    savedwishlistList(wish_req, dispatch);
  }, []);
  useEffect(() => {
    savedwishlistList(wish_req, dispatch);
  }, [wish_req]);
  useEffect(() => {
    try {
      if (typeof wishlist != "undefined") {
        if (typeof wishlist.detail != "undefined") {
          if (Array.isArray(wishlist.detail)) {
            if (wishlist.detail) {
              setRows(
                wishlist.detail.map((ele) =>
                  // createData(ele.sku, ele.qty, addToCart())
                  // sku, qtyAvail, wishlistQty, addToCart, price
                  // sku, qtyAvailable, orderedQty, wishlistQty, price

                  createData(
                    ele.ds,
                    ele.sku,
                    ele.qty_available,
                    ele.qty_ordered,
                    ele.owned_quantity,
                    ele.wishlist_qty,
                    ele.price,
                    ele.image,
                    ele.date_added
                  )
                )
              );
              setOrgwishlist(wishlist.detail);
              setCartQty(
                wishlist.detail.map((ele) => {
                  return { ...ele, value: ele.wishlist_qty };
                })
              );
            } else {
              throw "wishlist.detail is an empty array";
            }
          } else {
            throw "wishlist.detail is not an array";
          }
        } else {
          throw "wishlist.detail is undefined";
        }
      } else {
        throw "wishlist is undefined";
      }
      if (typeof wishlist.total_row != "undefined") {
        let noOfP = Math.ceil(
          Number(wishlist.total_row) / Number(wish_req.size)
        );
        let arr = [];
        for (let i = 0; i < noOfP; i++) {
          if (i === 0) arr.push(1 * 5);
          else arr.push((i + 1) * 5);
        }

        setPageArr([...arr]);
      } else {
        throw "wishlist.total_row is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  }, [wishlist]);

  useEffect(() => {
    console.log("rows", rows);
  }, [rows]);

  useEffect(() => {
    deleteWishlistPopupCallback();
  }, [deleteWishlistData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // // ----------- End --------------- //
  // // ----------- Handlers --------------- //
  const deleteWishlist = (row) => {
    setDeleteWishlistData(row);
    setDeleteWishListPopup(true);
  };
  const deleteWishlistPopupCallback = () => {
    try {
      if (rows && typeof rows != "undefined") {
        if (Array.isArray(rows)) {
          if (rows.length) {
            let { id } = orgWishlist.find(
              (ele) => ele.sku === deleteWishlistData.sku
            );

            let reqObj = {
              sessionKey: userInfo["session_id"],
              id,
            };
            deleteSavedWatchList(reqObj, dispatch).then(function () {
              savedwishlistList(wish_req, dispatch).then(() => {
                setDeleteWishListPopup(false);
              });
            });
          } else {
            throw "rows is an empty array";
          }
        } else {
          throw "rows is Not an array";
        }
      } else {
        throw "rows is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  };
  const deleteWishlistClose = (row) => {
    setDeleteWishListPopup(false);
  };
  const qtyChange = (data) => (e) => {
    let cc = [...cartQty];
    let { value } = e.target;
    let i = cc.findIndex((ele) => ele.sku === data.sku);
    cc[i]["value"] = value;
    setCartQty([...cc]);
  };

  const okButtonCallback = () => {
    okbuttonData["override"] = "1";
    addItemToCart(okbuttonData, dispatch)
      .then(() => savedwishlistList(wish_req, dispatch))
      .then(() => {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
      });
  };
  const cartAddedClick = (data) => (e) => {
    let cc = [...cartQty];
    let i = cc.findIndex((ele) => ele.sku === data.sku);
    let AddToCartObj = cc[i];
    if (parseInt(AddToCartObj.value) > 0) {
      let itemVal = {
        sessionKey: userInfo.session_id,
        webSKU: AddToCartObj.websku,
        SKU: AddToCartObj.sku,
        qty: parseInt(AddToCartObj.value),
        is_saved_wishlist: 1,
      };
      setOkButtonData(itemVal);
      if (
        Number(AddToCartObj.value) <= AddToCartObj.qty_available ||
        AddToCartObj.qty_available === "-"
      ) {
        addItemToCart(
          itemVal,
          dispatch,
          okButtonCallback,
          undefined,
          undefined,
          undefined,
          okClose
        ).then(() => {
          savedwishlistList(wish_req, dispatch);
          fetchCartItem({
            sessionKey: userInfo.session_id,
          })(dispatch);
        });
      } else if (Number(AddToCartObj.value) > AddToCartObj.qty_available) {
        new popupClass(
          true,
          "Insufficient QTY",
          "Added to  your cart",
          "",
          "",
          "",
          "",
          dispatch
        ).emitPopup();
      }
    } else {
      new popupClass(
        true,
        "Zero Qty choosen on Sku: " + AddToCartObj.sku,
        "Zero Quantity",
        "",
        "",
        "",
        "",
        dispatch
      ).emitPopup();
    }
  };

  const wishListPageChange = (event, value) => {
    setPageWish(value);

    let wishListValues = {
      sessionKey: userInfo.session_id,
      search_value: "",
      search_key: "",
      size: wish_req.size,
      page: value,
      sort: "",
      dir: "",
    };
    savedwishlistList(wishListValues, dispatch);
  };

  const getCartQtyVal = (row) => {
    let c = cartQty.filter((ele) => ele.sku === row.sku);
    //return c[0].owned_quantity === 0 ? c[0].value : c[0].owned_quantity;
    return c[0].value;
  };

  const dropdownChangeWishist = (dropValue) => {
    setWish_req({ ...wish_req, size: Number(dropValue), page: 1 });
    let wishListValues = {
      sessionKey: userInfo.session_id,
      search_value: wish_req.search_value,
      search_key: "",
      size: Number(dropValue),
      page: wish_req.page,
      sort: "",
      dir: "",
    };
    savedwishlistList(wishListValues, dispatch);
  };
  const outOfStockItems = () => {
    return (
      <>
        <p>There are items in your order which are no longer available.</p>
        <p>Please remove or replace them before checkout.</p>
        <ul>
          {outOfStock.detail.map((item) => (
            <li>{item.name}</li>
          ))}
        </ul>
      </>
    );
  };
  const onClickRemoveOutofStock = () => {
    deleteOutOfStock({ sessionKey: userInfo.session_id }, dispatch).then(
      (res) => {
        if (res.data.status) {
          let freqObj = {
            sessionKey: userInfo["session_id"],
          };
          fetchCartItem(freqObj)(dispatch);
          dispatch({
            type: "POPUP_MESSAGE_CLOSE",
            payload: false,
          });
        }
      }
    );
  };
  const onClickClosePopupOutofStock = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    history.push("/account");
  };
  const onClickCloseReturnCheckout = () => {
    if (!outOfStock.status) {
      new popupClass(
        true,
        outOfStockItems(),
        "No Longer Available",
        "Remove For Me And Checkout",
        "Return To Wishlist",
        "cart-remove-for-me",
        "cart-return-to-cart",
        dispatch,
        onClickRemoveOutofStock,
        onClickClosePopupOutofStock,
        "out-of-stock-close",
        "",
        "out-of-stock-buttons"
      ).emitPopup();
    } else {
      if (
        userInfo.detail &&
        (userInfo.detail.account_type == "rep" ||
          userInfo.detail.account_type == "all")
      ) {
        alert(
          "Rep account is not be allowed to submit order. Please switch user."
        );
        return false;
      }
      let reqObj = {
        sessionKey: userInfo.session_id,
        viewTime: 1,
      };
      fetchCartItem(reqObj)(dispatch);
      history.push({ pathname: "/checkout" });
    }
  };
  const imageError = (e) => {
    return (e.target.src =
      "https://auctionsoftwaremarketplace.com/seadwelling/images/default/NoImageAvailable.jpg");
  };

  const onButtonCallback = async () => {
    let checkoutItem = [];
    orgWishlist.map((ele) => {
      if (outOfStockSku.indexOf(ele.sku) == -1) {
        let itemVal = {
          websku: ele.websku,
          items: [
            {
              sku: ele.sku,
              qty: cartQty.filter((ele1) => ele.sku === ele1.sku)[0].value,
            },
          ],
        };
        checkoutItem.push(itemVal);
      }
    });

    const payload = {
      bulk: checkoutItem,
      sessionKey: userInfo.session_id,
      is_saved_wishlist: 1,
    };

    const result = await addToCartBulk(payload, dispatch);
    outOfStockSku = [];
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    if (result && result?.data?.status === true) {
      savedwishlistList(wish_req, dispatch);
      fetchCartItem({
        sessionKey: userInfo.session_id,
      })(dispatch);
      history.push("/cart");
    } else if (result && result?.data?.status === false) {
      new popupClass(
        true,
        result?.data?.msg,
        "Added to  your cart",
        "",
        "",
        "",
        "",
        dispatch
      ).emitPopup();
    }
  };

  const checkoutclick = async () => {
    let checkoutItem = [];
    if (typeof orgWishlist != "undefined") {
      if (Array.isArray(orgWishlist)) {
        if (orgWishlist) {
          orgWishlist.map((ele) => {
            let value = cartQty.filter((ele1) => ele.sku === ele1.sku)[0].value;
            if (Number(value) > ele.qty_available) {
              insufficientQty.push(ele.sku);
            } else if (Number(value) <= 0 && ele.qty_available > 0) {
              zeroQty.push(ele.sku);
            } else {
              let itemVal = {
                websku: ele.websku,
                items: [
                  {
                    sku: ele.sku,
                    qty: cartQty.filter((ele1) => ele.sku === ele1.sku)[0]
                      .value,
                  },
                ],
              };
              checkoutItem.push(itemVal);
            }
          });
        }
        const payload = {
          bulk: checkoutItem,
          sessionKey: userInfo.session_id,
          is_saved_wishlist: 1,
        };

        if (insufficientQty.length === 0) {
          if (zeroQty.length === 0) {
            const result = await addToCartBulk(payload, dispatch);
            if (result && result?.data?.status === true) {
              savedwishlistList(wish_req, dispatch);
              fetchCartItem({
                sessionKey: userInfo.session_id,
              })(dispatch);
              history.push("/cart");
            } else if (result && result?.data?.status === false) {
              if (result?.data?.stock_val) {
                outOfStockSku = result.data.sku_dtls;
                new popupClass(
                  true,
                  result?.data?.msg + ". Proceed without these items?",
                  "Added to  your cart",
                  "No",
                  "Yes",
                  "ok-popup-app",
                  "no-popup-app",
                  dispatch,
                  "",
                  onButtonCallback,
                  "",
                  "already-avail-cart",
                  "already-add-action"
                ).emitPopup();
              } else {
                new popupClass(
                  true,
                  result?.data?.msg,
                  "Added to  your cart",
                  "",
                  "",
                  "",
                  "",
                  dispatch
                ).emitPopup();
              }
            }
          } else {
            new popupClass(
              true,
              "Zero Qty choosen on Sku: " + zeroQty.join(","),
              "Zero Quantity",
              "",
              "",
              "",
              "",
              dispatch
            ).emitPopup();
            zeroQty = [];
          }
        } else {
          new popupClass(
            true,
            "Insufficient QTY on Sku's " + insufficientQty.join(","),
            "Added to  your cart",
            "",
            "",
            "",
            "",
            dispatch
          ).emitPopup();
          insufficientQty = [];
        }
      }
    }
  };

  // ----------- END -------------------- //
  return (
    <div className="wishlist mx-3">
      <h6 className="text-left mx-2">Saved WishLists</h6>
      <div className="wishlist-header-value">
        <div className="dropdwn-val">
          <span>Row per page : </span>
          <CustomDropDown
            filterData={dataCount}
            selectValue={wish_req.size}
            handleDropDownChange={dropdownChangeWishist}
          />
        </div>
        <div className="form-group has-search">
          <span class="material-icons form-control-feedback">search</span>
          <input
            className="input-field form-control"
            type="text"
            placeholder="Search"
            value={wish_req.search_value}
            onChange={onSearchChange}
          />
        </div>
      </div>

      {wishlist &&
        typeof wishlist !== "undefined" &&
        wishlist.last_updated != "" && (
          <div className="text-left mx-2">
            <h5>
              Last updated: <span>{wishlist.last_updated}</span>
            </h5>
          </div>
        )}

      <hr />
      <div className="table-respon">
        <table className="table table-bordered edit-saved-cart-table">
          <thead>
            <tr>
              <th className="no-wrap-tablehead">Sku</th>
              <th className="no-wrap-tablehead">Description</th>
              <th className="no-wrap-tablehead">Qty Avail</th>
              <th className="no-wrap-tablehead">
                Qty <br />
                Ordered
              </th>
              <th className="no-wrap-tablehead">Price</th>
              <th className="wishlist-addtocart-heading">Add to cart</th>
              <th className="no-wrap-tablehead">Action</th>
              <th className="no-wrap-tablehead">Date added</th>
            </tr>
          </thead>
          <tbody>
            {wishlist &&
            typeof wishlist !== "undefined" &&
            rows &&
            typeof rows !== "undefined" &&
            rows.length !== 0
              ? rows.map((ele, index) => (
                  <tr>
                    <td>
                      {ele.image ? (
                        <div className="imageThumbnail">
                          <img
                            src={ele.image}
                            alt={ele.websku}
                            onError={imageError}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {ele.sku}
                    </td>
                    <td>{ele.ds}</td>
                    <td>{ele.qtyAvailable}</td>
                    <td>
                      {ele.orderedQty === 0 ? ele.qty_ordered : ele.orderedQty}
                    </td>
                    <td>${ele.price}</td>
                    <td className="add-to-cart-wishlist">
                      <div className="d-flex justify-content-center atc">
                        <AddToCart
                          data={ele}
                          qtChange={qtyChange}
                          cartQty={getCartQtyVal(ele)}
                          cartAddedClick={cartAddedClick}
                        />
                      </div>
                    </td>
                    <td>
                      <span
                        class="material-icons wishlist-delete"
                        onClick={(e) => deleteWishlist(ele)}
                      >
                        delete
                      </span>
                    </td>
                    <td>{ele.date_added}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
        {wishlist &&
        typeof wishlist !== "undefined" &&
        rows &&
        typeof rows !== "undefined" &&
        rows.length === 0 ? (
          <p>No data available in table</p>
        ) : null}
      </div>

      {wishlist &&
      typeof wishlist !== "undefined" &&
      rows &&
      typeof rows !== "undefined" &&
      rows.length !== 0 ? (
        <>
          <Pagination
            page={pageWish}
            count={Math.ceil(wishlist.total_row / wish_req.size)}
            onChange={wishListPageChange}
          />
        </>
      ) : null}

      <hr />
      <div>
        <div style={{ display: "inline", marginRight: "10px" }}>
          <Button onClick={() => history.push("/specialProduct?page=1")}>
            Continue Shopping
          </Button>
        </div>
        {orgWishlist.length > 0 && (
          <div style={{ display: "inline" }}>
            <Button
              onClick={checkoutclick}
              className="continue-shopping-proceed"
            >
              Add to cart
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SavedWishlist;
