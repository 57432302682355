import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Popup from "../Popup";
import PrimaryButton from "../PrimaryButton";
import InputField from "../Input/InputField";
import "./initialChangePassword.css";
import { change_password } from "../../Store/Actions/userActions";

const InitialChangePassword = ({ initalChngPopup, initialChngPassClose }) => {
  const [initialChangePassword, setInitialChangePassword] = useState({
    current_password: {
      value: "",
      error: "Current Password is Required",
    },
    new_password: {
      value: "",
      error: "New Password is Required",
    },
    confirm_password: {
      value: "",
      error: "Confirm Password is Required",
    },
  });

  const [error, setError] = useState({});

  const [passwrdExisting, setPasswrdExisting] = useState({});

  const [newCurrentPass, setNewCurrentPass] = useState({});

  const [minDigit, setMinDigit] = useState({});

  const [currentPasswrdError, setCurrentPasswrdError] = useState({});

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.users.users);

  const changeResponce = useSelector((state) => state.changePasswrd);

  const onChangeInitialchngPasswrd = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let Obj = {
      value: value,
      error: initialChangePassword[name]["error"],
    };
    setError({
      ...error,
      [name]: "",
    });
    setInitialChangePassword({
      ...initialChangePassword,
      [name]: Obj,
    });
    setPasswrdExisting({});
    setNewCurrentPass({});
    setMinDigit({});
    setCurrentPasswrdError({});
  };

  const passwordValidation = () => {
    let minD = {};
    if (initialChangePassword.new_password.value.length < 6) {
      minD["error"] = "Minimum 6 digit is required";
    }
    setMinDigit(minD);

    return minD;
  };

  const validate = () => {
    let error_ = {};
    Object.keys(initialChangePassword).map((el) => {
      if (initialChangePassword[el].value === "") {
        error_[el] = initialChangePassword[el]["error"];
      }
    });
    setError(error_);

    return error_;
  };

  const passwordMatch = () => {
    let passMtch = {};
    if (
      initialChangePassword.current_password.value.localeCompare(
        initialChangePassword.new_password.value
      ) === 0
    ) {
      passMtch["error"] =
        "Old Password and New Password should not be the same";
    }
    setPasswrdExisting(passMtch);

    return passMtch;
  };

  const resetChangePassword = () => {
    setInitialChangePassword({
      current_password: {
        value: "",
        error: "Current Password is Required",
      },
      new_password: {
        value: "",
        error: "New Password is Required",
      },
      confirm_password: {
        value: "",
        error: "Confirm Password is Required",
      },
    });
  };

  const newCurrentPasswrdMatch = () => {
    let newPassMtch = {};
    if (
      !(
        initialChangePassword.new_password.value.localeCompare(
          initialChangePassword.confirm_password.value
        ) === 0
      )
    ) {
      newPassMtch["error"] = "Confirm password should be same as new password";
    }
    setNewCurrentPass(newPassMtch);

    return newPassMtch;
  };

  const intitalChngPassWrdSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(validate()).length === 0) {
      if (
        Object.keys(passwordMatch()).length === 0 &&
        Object.keys(passwordValidation()).length === 0 &&
        Object.keys(newCurrentPasswrdMatch()).length === 0
      )
        if (
          initialChangePassword.new_password.value.length >= 6 &&
          initialChangePassword.new_password.value.localeCompare(
            initialChangePassword.confirm_password.value
          ) === 0
        ) {
          change_password(
            {
              sessionKey: userInfo.session_id,
              current_password: initialChangePassword.current_password.value,
              new_password: initialChangePassword.new_password.value,
              confirm_password: initialChangePassword.confirm_password.value,
            },
            dispatch
          );
        }
    }
  };

  useEffect(() => {
    if (changeResponce.closePopup) {
      resetChangePassword();
      initialChngPassClose();
      dispatch({
        type: "CHANGED_PASSWORD_POPUP",
        payload: false,
      });
    }
  }, [changeResponce]);

  useEffect(() => {
    if (!changeResponce.changePasswrdstaus.status) {
      setCurrentPasswrdError({ error: changeResponce.changePasswrdstaus.msg });
    }
  }, [changeResponce.changePasswrdstaus]);

  useEffect(() => {
    if (changeResponce.changePasswrdstaus.status) {
      let chngeValue = JSON.parse(sessionStorage.getItem("user"));
      chngeValue.detail.is_first_login = 0;
      sessionStorage.setItem("user", JSON.stringify(chngeValue));
      dispatch({
        type: "GET_USERS",
        payload: chngeValue,
      });
    }
  }, [changeResponce]);

  return (
    <Popup
      open={initalChngPopup}
      fullWidth={true}
      maxWidth="sm"
      onsubmit={intitalChngPassWrdSubmit}
      heading="CHANGE PASSWORD"
      classNameDialog=""
      classNameDialogTitle=""
      classNameDialogContentText=""
      classNameClose="changepass-close"
      actions={
        <PrimaryButton
          type="submit"
          size="small"
          onsubmit={intitalChngPassWrdSubmit}
        >
          Submit
        </PrimaryButton>
      }
    >
      <InputField
        label="Current Password"
        placeholder="Current Password "
        name="current_password"
        size="medium"
        type="password"
        id="current_password"
        onchange={onChangeInitialchngPasswrd}
        value={initialChangePassword.current_password.value}
      />
      {error.current_password ? (
        <span className="error-theme">{error.current_password} </span>
      ) : currentPasswrdError.error ? (
        <span className="error-theme">{currentPasswrdError.error} </span>
      ) : passwrdExisting.error ? (
        <span className="error-theme">{passwrdExisting.error} </span>
      ) : null}
      <InputField
        label="New Password"
        placeholder="New Password"
        name="new_password"
        size="medium"
        type="password"
        id="new_password"
        onchange={onChangeInitialchngPasswrd}
        value={initialChangePassword.new_password.value}
      />
      {error.new_password ? (
        <span className="error-theme">{error.new_password}</span>
      ) : null}
      {minDigit.error ? (
        <span className="error-theme">{minDigit.error}</span>
      ) : null}
      <InputField
        label="Confirm Password"
        placeholder="Confirm Password"
        name="confirm_password"
        size="medium"
        type="password"
        id="confirm_password"
        onchange={onChangeInitialchngPasswrd}
        value={initialChangePassword.confirm_password.value}
      />
      {error.confirm_password ? (
        <span className="error-theme">{error.confirm_password}</span>
      ) : null}

      {newCurrentPass.error ? (
        <span className="error-theme">{newCurrentPass.error}</span>
      ) : null}
    </Popup>
  );
};

export default InitialChangePassword;
