import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { helpfulLink } from "../../Store/Actions/userActions";

const HelpfulLinks = () => {
  const dispatch = useDispatch();
  const helpfulLinkContent = useSelector(
    (state) => state.helpfulLinkContent.dynamicContent
  );
  useEffect(() => {
    helpfulLink({ var_name: "helpful" }, dispatch);
  }, []);

  const helpfulLinksView = () => {
    return { __html: helpfulLinkContent };
  };
  return (
    <section className="container helpfulLinksCnt">
      <div
        style={{ textAlign: "left" }}
        dangerouslySetInnerHTML={helpfulLinksView()}
      />
    </section>
  );
};

export default HelpfulLinks;
