const initialState = {
  popupView: false,
  popupMessage: "",
  heading: "",
  actions: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "POPUP_MESSAGE":
      return {
        ...state,
        popupView: action.payload.popupView,
        popupMessage: action.payload.popupMessage,
        heading: action.payload.heading,
        actions: action.payload.actions,
        okbuttonCallback: action.payload.okButtonCallBack,
        ...action.payload,
      };
    case "POPUP_MESSAGE_CLOSE":
      return {
        ...state,
        popupView: action.payload,
      };
    default:
      return state;
  }
}
