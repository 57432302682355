import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "../SearchFilter/SearchFilter.css";
import "./MasterLayout.css";
import InputField from "../Input/InputField";
import SelectField from "../Input/SelectField";
import SecondaryButton from "../SecondaryButton";
import { Tooltip, Button } from "@material-ui/core";
import SearchFilter from "../SearchFilter";
import Carousel from "../Carousel";
import { useDispatch, useSelector } from "react-redux";
import {
  Searchfilters,
  Sitemenu,
  specialProductDetails,
  getCurrentTime,
  getInventoryDtlsUpdatedTime,
} from "../../Store/Actions/userActions";
import { useHistory, useLocation } from "react-router-dom";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    width: "100%",
    alignItems: "flex-start",
    padding: "0",
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: "absolute",
    height: "100%",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Layout = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const menues = [useSelector((state) => state.Search.menues)];
  const userInfo = useSelector((state) => state.users.users);
  const lists = useSelector((state) => state.Search.details);
  const nav_name = useSelector((state) => state.Search.nav_name);

  const searchedValueDetails = useSelector(
    (state) => state.Search.searchedValueDetails
  );

  const [open, setOpen] = React.useState("");

  useEffect(() => {
    {
      window.matchMedia("(max-width: 700px)").matches
        ? setOpen(false)
        : setOpen(true);
    }
  }, []);

  const [filters, setFilters] = React.useState(false);
  const [state, setState] = useState({
    search_key: "3",
    search_value: "",
    cid: "",
  });
  const [firstTimeLoad, setFirstTimeLoad] = useState(0);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handlechange1 = (e) => {
    let data;
    if (e.target.value === state.cid) {
      setState({ ...state, cid: "" });
      data = {
        sessionKey: userInfo.session_id,
        search_value: "",
        search_key: "",
        size: e.target.name == "The Furnace" ? "100" : "25",
        page: "1",
        cid: "",
        sortbyprice: "",
        pricesearch: "",
        main_coralType: "",
      };
    } else {
      data = {
        sessionKey: userInfo.session_id,
        search_value: "",
        search_key: "",
        size: e.target.name == "The Furnace" ? "100" : "25",
        page: "1",
        cid: e.target.value,
        sortbyprice: "",
        pricesearch: "",
        main_coralType: "",
      };
      setState({ ...state, cid: e.target.value });
    }
    dispatch({
      type: "nav_name",
      payload: e.target.name,
    });
    Searchfilters(data, dispatch, true, true, true);

    if (e.target.name == "The Furnace") {
      history.push(
        `/furnance?key=${e.target.value}&page=1` /*+ "name=" + name*/
      );
    } else if (e.target.name == "Dry Goods") {
      history.push(
        `/drygoods?key=${e.target.value}&page=1` /*+ "name=" + name*/
      );
    } else {
      history.push(`/search?key=${e.target.value}&page=1` /*+ "name=" + name*/);
    }
  };

  const furnanceChanges = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let data;
    if (name == "sku") {
      dispatch({
        type: "MASTERLAYOUT_SKU",
        payload: value,
      });
      data = {
        sessionKey: userInfo.session_id,
        search_value: "",
        search_key: "",
        size: "",
        page: 1,
        cid: "169",
        sortbyprice: value,
        pricesearch: "",
        main_coralType: "",
      };
      dispatch({
        type: "MASTER_LAYOUT_VALUE_SELECTED",
        payload: true,
      });
      sessionStorage.setItem("masterLayout", JSON.stringify(data));
    } else if (name == "priceSearch") {
      dispatch({
        type: "MASTERLAYOUT_PRICE",
        payload: value,
      });
      data = {
        sessionKey: userInfo.session_id,
        search_value: "",
        search_key: "",
        size: "",
        page: 1,
        cid: "169",
        sortbyprice: "",
        pricesearch: value,
        main_coralType: "",
      };
      dispatch({
        type: "MASTER_LAYOUT_VALUE_SELECTED",
        payload: true,
      });
      sessionStorage.setItem("masterLayout", JSON.stringify(data));
    } else if (name == "main_coralType") {
      dispatch({
        type: "MASTERLAYOUT_CORAL_TYPE",
        payload: value,
      });
      data = {
        sessionKey: userInfo.session_id,
        search_value: "",
        search_key: "",
        size: "",
        page: 1,
        cid: "169",
        sortbyprice: "",
        pricesearch: "",
        main_coralType: value,
      };
      dispatch({
        type: "MASTER_LAYOUT_VALUE_SELECTED",
        payload: true,
      });
      sessionStorage.setItem("masterLayout", JSON.stringify(data));
    }
    dispatch({
      type: "FURNANCE_LOAD_DATA",
      payload: true,
    });
    Searchfilters(data, dispatch, true, false, "special").then(() => {
      dispatch({
        type: "FURNANCE_LOAD_DATA",
        payload: false,
      });
    });
    history.push("/furnance?key=169" /*+ "name=" + name*/);
  };

  const onSubmit = (e) => {
    setFirstTimeLoad(1);

    // history.push({
    //   pathname: "/search",
    //   search: state.search_key + "&" + state.search_value,
    // });
    let ID = "";
    if (window.location.pathname != "/cart") {
      const parms = new URLSearchParams(props.location.search);
      ID = parms.getAll("key")[0];
    }

    if (state.search_key == "4") {
      sessionStorage.setItem("nav_name", "Furnace Search Results");
      dispatch({
        type: "nav_name",
        payload: "Furnace Search Results",
      });
      history.push(
        `/furnance?key=169&searchKey=${state.search_key}&searchValue=${state.search_value}&page1`
      );
    } else {
      sessionStorage.setItem("nav_name", "Search Results");
      dispatch({
        type: "nav_name",
        payload: "Search Results",
      });
      history.push(
        `/search?key=${
          ID && ID !== "undefined" ? (ID == 169 ? "" : ID) : ""
        }&searchKey=${state.search_key}&searchValue=${
          state.search_value
        }&page=1`
      );
    }

    // history.push(
    //     `/search?key=${ID && ID !== "undefined" ? ID : ""}&searchKey=${
    //       state.search_key
    //     }&searchValue=${state.search_value}&page=1`
    //   );

    let searchedValues = {
      sessionKey: userInfo.session_id,
      search_value: state.search_value,
      search_key: state.search_key,
      key: ID,
      size: "100",
      page: 1,
      cid: state.search_key === "4" ? 169 : "",
      sortbyprice: "",
      pricesearch: "",
    };

    location.search = location == "" ? "?page=1" : location.search;

    Searchfilters(searchedValues, dispatch, true, false, location.search);
    dispatch({
      type: "SEARCHED_VALUE",
      payload: searchedValues,
    });
  };
  const GetMenues = (e) => {
    Sitemenu(userInfo.session_id, dispatch);
  };
  useEffect(() => {
    let sessionValue = {
      sessionKey: userInfo.session_id,
    };
    if (!menues[0]) {
      GetMenues();
      getInventoryDtlsUpdatedTime(sessionValue, dispatch);
    }
    setFirstTimeLoad(firstTimeLoad + 1);
  }, []);

  useEffect(() => {
    if (
      lists &&
      Object.keys(lists).length &&
      lists.data &&
      lists.data.length > 0
    ) {
      if (lists.data.length > 1) {
        lists.data.every((element) => {
          if (element.sku_dtls.length >= 5) {
            setOpen(false);
            return false;
          }
          return true;
        });
      }
    }
  }, [lists]);

  // useEffect(() => {
  //   getCurrentTime(dispatch);
  // }, []);
  return (
    <>
      {/* <Toolbar className="cstmFltrIcnTlbr">
        <Tooltip title="Show Filters">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={`${clsx(
              classes.menuButton,
              open && classes.hide
            )} cstmFltrIcn`}
          >
            <span className="material-icons">filter_alt</span>
          </IconButton>
        </Tooltip>
      </Toolbar> */}
      <div className="sdSearch container-fluid position-relative sticky-inner">
        <div className={classes.root}>
          {/* <CssBaseline /> */}

          {/* <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          > */}
          {/* <div className={classes.drawerHeader}>
            <div className="d-flex justify-content-between align-items-center w-100">
              <h3 className="sfTitle">Search Filters</h3>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
          </div> */}
          <div
            className={`searchFilter ${
              userInfo?.detail?.cities === "Y" && "srchFltFlx"
            }`}
          >
            {/* <form> */}
            <SelectField
              label="Search by"
              name="search_key"
              pc_onchange={handleChange}
              defaultValue={"3"}
            >
              <option name="search_key" value="3">
                Search All
              </option>
              <option name="search_key" value="4">
                Search Furnace only
              </option>
              <option name="search_key" value="0">
                Search titles & descriptions
              </option>
              <option name="search_key" value="1">
                Search titles only
              </option>
              <option name="search_key" value="2">
                Search Websku only
              </option>
            </SelectField>

            <InputField
              style={{ width: "40%" }}
              label="Search"
              placeholder="Search for items here"
              onchange={handleChange}
              value={state.search_value}
              name={"search_value"}
              onKeyDown={(event) =>
                event.key === "Enter" ? onSubmit(event) : ""
              }
            />
            <SecondaryButton
              label={<span className="material-icons">search</span>}
              onClick={onSubmit}
            />
            {userInfo?.detail?.cities === "Y" && (
              <p className="exprtTxt">EXPORT PERMIT REQUIRED</p>
            )}
            {/* </form> */}
            {/* {filters && (
              <SearchFilter
                menu={menues}
                cid={state.cid}
                onchange={handlechange1}
                furnanceChng={furnanceChanges}
              />
            )}
            <Button className="fltsBtn" onClick={() => setFilters(!filters)}>
              {filters === true ? (
                <span className="d-flex align-items-center">
                  <span
                    className="material-icons"
                    style={{ paddingRight: "10px" }}
                  >
                    remove
                  </span>{" "}
                  Less Filters
                </span>
              ) : (
                <span className="d-flex align-items-center">
                  <span
                    className="material-icons"
                    style={{ paddingRight: "10px" }}
                  >
                    add
                  </span>{" "}
                  More Filters
                </span>
              )}
            </Button> */}
          </div>
          {/* <div className="featuredProducts">
            <Carousel />
          </div> */}
          {/* </Drawer> */}
          {/* <main
            className={`${clsx(classes.content, {
              [classes.contentShift]: open,
            })} master-layout-main furnace-width`}
          > */}
          <main className={` master-layout-main furnace-width`}>
            {props.children}
          </main>
        </div>
      </div>
    </>
  );
};

export default React.memo(Layout);
