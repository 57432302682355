import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Badge, MenuItem, Menu, Button } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";
import {
  userLogOut,
  wishlistList,
  AddToWatchList,
  getCurrentTime,
  Searchfilters,
  Sitemenu,
  specialProductDetails,
} from "../../Store/Actions/userActions";
import {
  fetchCartItem,
  intemateBackendToDelete,
} from "../../Store/Actions/cart.actions";
import axios from "axios";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import moment, { duration } from "moment-timezone";
import popupClass from "../../utils/emitPopup";
import {
  socketServerTime,
  socketLiveFurnaceTime,
  socketRemoveCart,
} from "../../Store/Actions/socketActions";
import { exportStockLevel } from "../../Store/Actions/userActions";
import { CSVLink } from "react-csv";

const TopHeader = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [saltWaterdataSet, setSaltWaterDataSet] = useState(null);
  const [freshWaterdataSet, setFreshWaterDataSet] = useState(null);
  const [state, setState] = useState({
    details: "",
  });
  const cart = useSelector((state) => state.cart);
  const cartTotal = useSelector((state) => state.cartTotal);
  const userInfo = useSelector((state) => state.users.users);
  const wishlist = useSelector((state) => state.users.wishlist);
  const intialTime = useSelector((state) => state.serverTime);
  const timeRunnig = useSelector((state) => state.cartTimer);
  const outOfStock = useSelector((state) => state.cart.outOfStock);

  let currentDateTime = moment(intialTime.currentTimeDtls)
    .tz("America/Los_Angeles")
    .format("HH:mm:ss");

  let freshDowloadData = useSelector(
    (state) => state.exportStockLevel.exportFreshData
  );
  let saltDowloadData = useSelector(
    (state) => state.exportStockLevel.exportSaltData
  );
  // const userInfoLogout = useSelector((state) => state.users);
  const history = useHistory();
  const location = useLocation();
  const [lastlocation, setLastlocation] = useState("");
  const saltCsvExport = useRef();
  const freshCsvExport = useRef();

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    handleClose();
    // history.push("/");
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    userLogOut(dispatch);
  };

  const locationChange = () => {
    console.log("Location changed", location);
    let reqObj = {
      sessionKey: userInfo.session_id,
      viewTime: 2,
    };
    fetchCartItem(reqObj)(dispatch);
  };

  useEffect(() => {
    if (cart) {
      let chngData = [];

      cart.savedCart.items
        .slice()
        .filter((ele) => {
          if (typeof props.match !== "undefined") {
            if (typeof props.match.params !== "undefined") {
              if (typeof props.match.params.timer !== "undefined") {
                if (props.match.params.timer === "timer") {
                  return (
                    ele.sku_dtls.live === 1 ||
                    ele.sku_dtls.live === 2 ||
                    ele.sku_dtls.live === 3
                  );
                }
              }
            }
          }
          return true;
        })
        .map((item) => {
          let obj = {
            name: item.name,
            websku: item.websku,
            sku_dtls: item.sku_dtls,
          };
          item["similar_items"] = [obj];
          if (chngData.length === 0) {
            chngData.push(item);
          } else {
            let indexist = chngData.findIndex(
              (ele) => ele.websku === item.websku
            );
            if (indexist === -1) {
              chngData.push(item);
            } else {
              let alreadyexistsObj = chngData.find(
                (ele) => ele.websku === item.websku
              );
              alreadyexistsObj["similar_items"].push(obj);
            }
          }

          return item;
        });
      let priceArr = chngData.map(({ similar_items }) => {
        return similar_items.reduce((acc, val) => {
          let totalVal =
            acc +
            parseFloat(val.sku_dtls.owned_quantity * val.sku_dtls.retail_price);
          return totalVal;
        }, 0);
      });

      let totalValArr = priceArr.reduce((acc, val) => acc + parseFloat(val), 0);
      console.log("totalValArr", totalValArr);
      dispatch({
        type: "CART_TOTAL",
        payload: totalValArr.toFixed(2),
      });
    }
  }, [cart]);

  useEffect(() => {
    if (lastlocation == "/checkout" && location.pathname != "/checkout") {
      locationChange();
    }
    setLastlocation(location.pathname);
  }, [location]);

  useEffect(() => {
    if (anchorEl !== null) {
      setTimeout(() => {
        setAnchorEl(null);
      }, 10000);
    }
  }, [anchorEl]);

  useEffect(() => {
    if (saltWaterdataSet) {
      setTimeout(function () {
        saltCsvExport.current.link.click();
      }, 1000);
    }
  }, [saltWaterdataSet]);

  useEffect(() => {
    if (freshWaterdataSet) {
      setTimeout(function () {
        freshCsvExport.current.link.click();
      }, 1000);
    }
  }, [freshWaterdataSet]);

  useEffect(() => {
    if (
      typeof freshDowloadData !== "undefined" &&
      freshDowloadData &&
      typeof freshDowloadData.exportstocklevel_details !== "undefined" &&
      freshDowloadData.exportstocklevel_details
    ) {
      setFreshWaterDataSet(freshDowloadData.exportstocklevel_details);
    }

    if (
      typeof saltDowloadData !== "undefined" &&
      saltDowloadData &&
      typeof saltDowloadData.exportstocklevel_details !== "undefined" &&
      saltDowloadData.exportstocklevel_details
    ) {
      setSaltWaterDataSet(saltDowloadData.exportstocklevel_details);
    }
  }, [freshDowloadData, saltDowloadData]);

  const onClickFreshExport = () => {
    if (freshWaterdataSet) {
      freshCsvExport.current.link.click();
    } else {
      exportStockLevel(
        { sessionKey: userInfo.session_id, name: "Freshwater" },
        dispatch
      );
    }
  };

  const onClickSaltExport = () => {
    if (saltWaterdataSet) {
      saltCsvExport.current.link.click();
    } else {
      exportStockLevel(
        { sessionKey: userInfo.session_id, name: "Saltwater" },
        dispatch
      );
    }
  };

  const cartPage = () => {
    history.push({ pathname: "/cart", state: { tab: 1 } });

    dispatch({
      type: "CART_POPUP",
      payload: false,
    });
  };

  // const Timerdetails = () => {
  //   setState({
  //     ...state,
  //     details: moment().tz("America/Los_Angeles").format("HH:mm:ss"),
  //   });
  // };

  useEffect(() => {
    if (
      cart &&
      typeof cart.savedCart !== "undefined" &&
      cart.savedCart.items.length &&
      Array.isArray(cart.savedCart.items)
    ) {
      let timArr = cart.savedCart.items
        .filter(
          (ele) =>
            (ele.sku_dtls.live === 1 ||
              ele.sku_dtls.live === 2 ||
              ele.sku_dtls.live === 3) &&
            ele.sku_dtls.purchaseitem != "Y"
        )
        .map((ele) => {
          let db_time =
            ele.sku_dtls.inv_type == "managed"
              ? ele.db_time_managed
              : ele.db_time_live;
          let timeadded = moment(ele.sku_dtls.timeadded);
          timeadded = moment(timeadded).add(Number(db_time), "minutes");
          let due = moment.duration(
            timeadded.diff(moment(new Date(intialTime.currentTimeDtls)))
          );
          let diffinHours = due.hours();
          let diffInMinutes = due.minutes();
          let diffInSeconds = due.seconds();

          let timeDiffValue = {
            h: diffinHours,
            m: diffInMinutes,
            s: diffInSeconds,
            sku: ele.sku_dtls.sku,
            inv_type: ele.sku_dtls.inv_type,
            sku_dtls: ele.sku_dtls,
            dbTime: db_time,
          };

          if (
            timeDiffValue.h === 0 &&
            timeDiffValue.m === 0 &&
            timeDiffValue.s === 0 &&
            timeDiffValue.inv_type == "managed"
          ) {
            timerZero(timeDiffValue);
          } else if (
            timeDiffValue.h === 0 &&
            timeDiffValue.m === 0 &&
            timeDiffValue.s === 0 &&
            timeDiffValue.inv_type == "live"
          ) {
            deletingLiveItems(timeDiffValue);
          }
          return timeDiffValue;
        });

      if (Array.isArray(timArr)) {
        dispatch({
          type: "TIMERUNNING",
          payload: timArr,
        });
      }
    }
  }, [intialTime]);

  const deletingLiveItems = (item) => {
    let freqObj = {
      sessionKey: userInfo["session_id"],
    };
    if (item && typeof item !== "undefined") {
      let deletePayload = {
        sessionKey: userInfo.session_id,
        userid: userInfo.detail.user_id,
        sku: item.sku_dtls.sku,
      };
      intemateBackendToDelete(deletePayload, dispatch).then(() => {
        // fetchCartItem(freqObj)(dispatch);

        let ID = props.location.search.split("key=")[1];
        if (ID && typeof ID !== "undefined") {
          ID = ID.split("&")[0];
        }
        let obj = {
          sessionKey: userInfo.session_id,
          search_value: "",
          search_key: "",
          size: "25",
          page: "1",
          cid: ID ? ID : "",
        };
        let currentLocation = props.location.search;
        Searchfilters(
          obj,
          dispatch,
          true,
          false,
          props.location.pathname.includes("/specialProduct")
            ? "special"
            : currentLocation,
          true
        );

        fetchCartItem(freqObj)(dispatch).then((res) => {
          let timArr = res.data.detail
            .filter(
              (ele) =>
                ele.sku_dtls.live === 1 ||
                ele.sku_dtls.live === 2 ||
                ele.sku_dtls.live === 3
            )
            .map((ele) => {
              let db_time =
                ele.sku_dtls.inv_type == "managed"
                  ? ele.db_time_managed
                  : ele.db_time_live;
              let timeadded = moment(ele.sku_dtls.timeadded);
              timeadded = moment(timeadded).add(Number(db_time), "minutes");
              let due = moment.duration(
                timeadded.diff(moment(new Date(intialTime.currentTimeDtls)))
              );

              let diffinHours = due.hours();
              let diffInMinutes = due.minutes();
              let diffInSeconds = due.seconds();

              let timeDiffValue = {
                h: diffinHours,
                m: diffInMinutes,
                s: diffInSeconds,
                sku: ele.sku_dtls.sku,
                inv_type: ele.sku_dtls.inv_type,
                sku_dtls: ele.sku_dtls,
                dbTime: db_time,
              };

              return timeDiffValue;
            });
          if (Array.isArray(timArr)) {
            dispatch({
              type: "TIMERUNNING",
              payload: timArr,
            });
          }
        });
      });
    }
  };

  const timerZero = (item, index) => {
    if (item && typeof item !== "undefined") {
      let skuArr = [
        {
          sku: item.sku_dtls.sku,
          qty: item.sku_dtls.owned_quantity,
        },
      ];
      let reqObj = {
        sessionKey: userInfo.session_id,
        websku: item.sku_dtls.websku,
        items: skuArr,
      };
      AddToWatchList(reqObj, dispatch).then(function (res) {
        if (props && typeof props.location != "undefined") {
          let ID = props.location.search.split("key=")[1];
          if (ID && typeof ID !== "undefined") {
            ID = ID.split("&")[0];
          }
          let obj = {
            sessionKey: userInfo.session_id,
            search_value: "",
            search_key: "",
            size: "25",
            page: "1",
            cid: ID ? ID : "",
          };

          let currentLocation = props.location.search;

          Searchfilters(
            obj,
            dispatch,
            true,
            false,
            props.location.pathname.includes("/specialProduct")
              ? "special"
              : currentLocation,
            true
          );
          let wish_req = {
            sessionKey: userInfo.session_id,
            search_value: "",
            search_key: "",
            size: 5,
            page: 1,
            sort: "",
            dir: "",
          };

          let freqObj = {
            sessionKey: userInfo["session_id"],
          };
          // let livefilertedArr = cartDetails.items.filter(
          //   (ele) => ele.sku_dtls.live === 1 || ele.sku_dtls.live === 2 ||
          // ele.sku_dtls.live === 3
          // );

          wishlistList(wish_req, dispatch);

          let deletePayload = {
            sessionKey: userInfo.session_id,
            userid: userInfo.detail.user_id,
            sku: item.sku_dtls.sku,
          };
          intemateBackendToDelete(deletePayload, dispatch).then(() => {
            // fetchCartItem(freqObj)(dispatch);

            fetchCartItem(freqObj)(dispatch).then((res) => {
              let timArr = res.data.detail
                .filter(
                  (ele) =>
                    ele.sku_dtls.live === 1 ||
                    ele.sku_dtls.live === 2 ||
                    ele.sku_dtls.live === 3
                )
                .map((ele) => {
                  let db_time =
                    ele.sku_dtls.inv_type == "managed"
                      ? ele.db_time_managed
                      : ele.db_time_live;
                  let timeadded = moment(ele.sku_dtls.timeadded);
                  timeadded = moment(timeadded).add(Number(db_time), "minutes");
                  let due = moment.duration(
                    timeadded.diff(moment(new Date(intialTime.currentTimeDtls)))
                  );

                  let diffinHours = due.hours();
                  let diffInMinutes = due.minutes();
                  let diffInSeconds = due.seconds();

                  let timeDiffValue = {
                    h: diffinHours,
                    m: diffInMinutes,
                    s: diffInSeconds,
                    sku: ele.sku_dtls.sku,
                    inv_type: ele.sku_dtls.inv_type,
                    sku_dtls: ele.sku_dtls,
                    dbTime: db_time,
                  };

                  return timeDiffValue;
                });
              if (Array.isArray(timArr)) {
                dispatch({
                  type: "TIMERUNNING",
                  payload: timArr,
                });
              }
            });

            if (props && typeof props.location != "undefined") {
              if (props.location.pathname.includes("/specialProduct")) {
                console.log("specialProduct reload");
                let specialProductDtls = JSON.parse(
                  sessionStorage.getItem("specialPage")
                );
                if (
                  specialProductDtls === null ||
                  specialProductDtls === "undefined" ||
                  Object.keys(specialProductDtls).length === 0
                ) {
                  specialProductDtls = {
                    sessionKey: userInfo.session_id,
                    cid: "",
                    search_key: "",
                    search_value: "",
                    page: props.pageNo || 1,
                    size: "25",
                    is_special: 1,
                  };
                }
                //setItemLoad(true);
                specialProductDetails(specialProductDtls, dispatch, false).then(
                  (res) => {
                    //setItemLoad(false);
                  }
                );
              }
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    // const interval = setInterval(() => {
    //   Timerdetails();
    // }, 1000);
    // Timerdetails();
    let reqObj = {
      sessionKey: userInfo["session_id"],
      search_value: "",
      search_key: "",
      size: 5,
      page: 1,
      sort: "",
      dir: "",
    };
    wishlistList(reqObj, dispatch);
    let freqObj = {
      sessionKey: userInfo["session_id"],
    };
    fetchCartItem(freqObj)(dispatch);

    // return () => clearInterval(interval);
  }, []);

  const logoutConfirmation = () => {
    new popupClass(
      true,
      "Are you sure want to logout ?",
      "Logout",
      "No",
      "yes",
      "logout-cancel",
      "logout-confirm",
      dispatch,
      "",
      logout,
      "",
      "",
      "cart-delete-confirm",
      "xs",
      "",
      "logout-heading",
      "logout-content"
    ).emitPopup();
  };

  useEffect(() => {
    socketServerTime((data) => {
      dispatch({
        type: "INITIAL_SERVER_TIME",
        payload: data.dTime,
      });
    });

    socketLiveFurnaceTime((data) => {
      console.log("live_furnace_time", data);
      dispatch({
        type: "INITIAL_FURNACE_TIME",
        payload: data,
      });
    });

    socketRemoveCart((data) => {
      console.log("remove_cart", data);
      if (data.userid == userInfo.detail.user_id) {
        let reqObj = {
          sessionKey: userInfo["session_id"],
          search_value: "",
          search_key: "",
          size: 5,
          page: 1,
          sort: "",
          dir: "",
        };
        wishlistList(reqObj, dispatch);
        let freqObj = {
          sessionKey: userInfo["session_id"],
        };
        fetchCartItem(freqObj)(dispatch);
        props.history.push("/account");
      }
    });
  }, []);

  return (
    <div className="topHeader customContainer d-flex justify-content-end align-items-center">
      {timeRunnig.timeRunning[0] &&
      (timeRunnig.timeRunning[0].h != 0 ||
        timeRunnig.timeRunning[0].m != 0 ||
        timeRunnig.timeRunning[0].s != 0) ? (
        <>
          {cart &&
          cart.savedCart &&
          cart.savedCart.items.length &&
          // Boolean(
          //   cart.savedCart.items.filter(
          //     (ele) => ele.sku_dtls.purchaseitem === "Y"
          //   ).length <= 0
          // ) &&
          timeRunnig.timeRunning[0].h >= 0 &&
          timeRunnig.timeRunning[0].m >= 0 &&
          timeRunnig.timeRunning[0].s >= 0 ? (
            <div className="reservedItemsMsg">
              <span className="topHeaderMessage">
                Items are reserved in your cart for{"  "}
              </span>
              <Link to="/cart/timer">
                <CartTimerMinutes
                  {...props}
                  isStyle={true}
                  isTooltip={true}
                  isHeaderLoad={true}
                />
              </Link>
              <span className="topHeaderMessage">
                and then moved to your wishlist to be added at checkout |{" "}
              </span>
            </div>
          ) : null}
        </>
      ) : (
        ""
      )}
      <h5 className="thChild thTimer timer-style">
        {" "}
        {currentDateTime != "Invalid date" ? `${currentDateTime} pst` : ""}{" "}
      </h5>
      <Button className="thChild thCart" onClick={cartPage}>
        <Badge
          badgeContent={cart ? (cart.savedCart ? cart.savedCart.count : 0) : 0}
          max={999}
          color="primary"
          showZero
        >
          <span className="material-icons">shopping_cart</span>
        </Badge>
        <p>${cartTotal && cartTotal.cartTotal}</p>
      </Button>
      <Link
        className="wishListCont"
        to={{ pathname: "/account", state: { tab: 1 } }}
      >
        <span className="wishlist-tooltip">
          <Button className="thChild thWishlist wwishl">
            <Badge
              badgeContent={
                typeof wishlist != "undefined" &&
                typeof wishlist.total_row != "undefined"
                  ? wishlist.total_row
                  : 0
              }
              max={999}
              color="primary"
            >
              <span className="material-icons">favorite</span>
            </Badge>
          </Button>
          <span className="wishlist-tooltiptext">Wishlist</span>
        </span>
      </Link>
      <div className="thChild thProfile user-name-header">
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <span className="user-name-character">
            {userInfo &&
            userInfo.detail &&
            typeof userInfo.detail !== "undefined"
              ? userInfo.detail.username
              : null}
          </span>
        </Button>
        <Menu
          id="simple-menu"
          className="topheader-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link to="/account">
              <div className="d-flex">
                <span> My account </span> &nbsp;|&nbsp;
                <span className="account-name">
                  {userInfo &&
                  typeof userInfo !== "undefined" &&
                  userInfo.detail &&
                  typeof userInfo.detail !== "undefined"
                    ? userInfo.detail.first_name
                    : null}
                </span>
              </div>
            </Link>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <Link to="/uploadCsv">
              Import/Export Order
              <PublishIcon />
            </Link>
          </MenuItem>
          {/* {userInfo && userInfo.detail && Boolean(userInfo.detail.isadmin) ? (
            <>
              <MenuItem onClick={handleClose}>
                <Link to="/uploadBanner">
                  Import Logo/Banner <GetAppIcon />
                </Link>
              </MenuItem>
            </>
          ) : null} */}
          <MenuItem onClick={() => onClickSaltExport()}>
            Download Saltwater Stock Report
            <span className="material-icons">download</span>
            {saltWaterdataSet && (
              <CSVLink
                data={saltWaterdataSet}
                filename={`product_SALT_WATER_STOCK_${moment().format(
                  "l"
                )}.csv`}
                target="_blank"
                ref={saltCsvExport}
              />
            )}
          </MenuItem>
          <MenuItem onClick={() => onClickFreshExport()}>
            Download Full Freshwater Stock Report
            <span className="material-icons">download</span>
            {freshWaterdataSet && (
              <CSVLink
                data={freshWaterdataSet}
                filename={`product_FRESH_WATER_STOCK_${moment().format(
                  "l"
                )}.csv`}
                target="_blank"
                ref={freshCsvExport}
              />
            )}
          </MenuItem>
          <MenuItem onClick={logoutConfirmation}>Logout</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export const CartTimer = (props) => {
  let [globalInterval, setGlobalInterval] = useState(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.users.users);
  const cartDetails = useSelector((state) => {
    return state.cart.savedCart;
  });
  const timeRunnig = useSelector((state) => state.cartTimer);
  const [hourValue, setHourValue] = useState(null);
  const [liveArrLength, setLiveArrLength] = useState();
  const [minutesValue, setMinutesValue] = useState(null);
  const [secondsValue, setSecondsValue] = useState(null);
  const [toolTipValue, setTooltipValue] = useState({});
  const [productDbTime, setProductDbTime] = useState();
  const okCallBack = () => {
    props.history.push("/cart");
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const cartTimerPopupdata = () => {
    return (
      <p>
        You have items in your cart that have less than 3 minutes to be
        purchased or they will be removed from your cart.... If you need more
        time to complete your order you may add those items to a wish list and
        check your wish list when you are ready to complete your order.
      </p>
    );
  };

  useEffect(() => {
    if (
      timeRunnig &&
      typeof timeRunnig.timeRunning !== "undefined" &&
      Array.isArray(timeRunnig.timeRunning) &&
      Boolean(timeRunnig.timeRunning.length)
    ) {
      if (props.isHeaderLoad) {
        setProductDbTime(timeRunnig.timeRunning[0].dbTime);
        if (Number(timeRunnig.timeRunning[0].h) >= 0) {
          setHourValue(timeRunnig.timeRunning[0].h);
        } else {
          setHourValue(null);
        }

        if (Number(timeRunnig.timeRunning[0].m) >= 0) {
          setMinutesValue(timeRunnig.timeRunning[0].m);
        } else {
          setMinutesValue(null);
        }

        if (Number(timeRunnig.timeRunning[0].s) >= 0) {
          setSecondsValue(timeRunnig.timeRunning[0].s);
        } else {
          setSecondsValue(null);
        }
      } else {
        let cartIndex = props.cartIndex;
        if (cartDetails && cartDetails.items.length > 0) {
          cartIndex =
            cartDetails.items.findIndex((p) => p.sku_dtls.sku == props.sku) >= 0
              ? cartDetails.items.findIndex((p) => p.sku_dtls.sku == props.sku)
              : cartIndex;
        }
        if (timeRunnig?.timeRunning[cartIndex]) {
          setProductDbTime(timeRunnig?.timeRunning[cartIndex]?.dbTime);
          if (Number(timeRunnig.timeRunning[cartIndex].h) >= 0) {
            setHourValue(timeRunnig.timeRunning[cartIndex].h);
          } else {
            setHourValue(null);
          }
          if (Number(timeRunnig.timeRunning[cartIndex].m) >= 0) {
            setMinutesValue(timeRunnig.timeRunning[cartIndex].m);
          } else {
            setMinutesValue(null);
          }
          if (Number(timeRunnig.timeRunning[cartIndex].s) >= 0) {
            setSecondsValue(timeRunnig.timeRunning[cartIndex].s);
          } else {
            setSecondsValue(null);
          }
        }
        // setHourValue(timeRunnig.timeRunning[props.cartIndex].h);
        // setMinutesValue(timeRunnig.timeRunning[props.cartIndex].m);
        // setSecondsValue(timeRunnig.timeRunning[props.cartIndex].s);
      }
    }
  }, [timeRunnig.timeRunning]);

  useEffect(() => {
    let interval;

    try {
      if (typeof cartDetails === "undefined") throw "cartDetails is undefined";
      if (typeof cartDetails.items === "undefined")
        throw "cartDetails.items is undefined";

      if (secondsValue === 0 && minutesValue === 0 && hourValue === 0) {
        // let freqObj = {
        //   sessionKey: userInfo["session_id"],
        // };
        // let livefilertedArr = cartDetails.items.filter(
        //   (ele) => ele.sku_dtls.live === 1 || ele.sku_dtls.live === 2||
        // ele.sku_dtls.live === 3;
        // );
        // let deletePayload = {
        //   sessionKey: userInfo.session_id,
        //   userid: userInfo.detail.user_id,
        //   sku: livefilertedArr[0].sku_dtls.sku,
        // };
        // console.log(livefilertedArr, "ths is the live filtererd item arr");
        // intemateBackendToDelete(deletePayload, dispatch).then(() =>
        //   fetchCartItem(freqObj)(dispatch)
        // );
        return;
      }

      // hour value

      // hour value END

      // 3min popup
      if (typeof cartDetails === "undefined") throw "cartDetails is undefined";
      if (typeof cartDetails.items === "undefined")
        throw "cartDetails.items is undefined";
      if (!Array.isArray(cartDetails.items))
        throw "cartDetails.items is not an array";
      let liveItemsArr = cartDetails.items.filter((ele) => {
        return ele.sku_dtls.live === 1 || ele.sku_dtls.live === 2;
      });
      if (liveItemsArr.length === 0) throw "liveItems Array is zero";
      let firstElement_ = {};
      if (props.isHeaderLoad) {
        // firstElement_ = liveItemsArr[liveItemsArr.length - 1];
        firstElement_ = liveItemsArr[0];
      } else {
        let indd = liveItemsArr.findIndex(
          (ele) => ele.sku_dtls.sku === props.sku
        );
        firstElement_ = liveItemsArr[indd];
      }

      // if (
      //   minutesValue === 3 &&
      //   secondsValue === 0 &&
      //   firstElement_.sku_dtls.inv_type == "managed"
      // ) {
      //   new popupClass(
      //     true,
      //     cartTimerPopupdata(),

      //     "Insufficient QTY",
      //     "Add To Wishlist",
      //     "OK",
      //     "",
      //     "",
      //     dispatch,
      //     addtowishListCallBack,
      //     okCallBack,
      //     "cart-timer-close",
      //     "cart-timer-popup"
      //   ).emitPopup();
      // }
      //3min popup END

      setGlobalInterval(interval);
      return () => clearInterval(interval);
    } catch (e) {
      clearInterval(interval);
      console.log(e);
    }
  }, [secondsValue, minutesValue, hourValue]);

  const filteredLiveItems = () => {
    let filteredArray = cartDetails.items.filter((ele) => {
      return (
        ele.sku_dtls.live === 1 ||
        ele.sku_dtls.live === 2 ||
        ele.sku_dtls.live === 3
      );
    });
    return filteredArray;
  };

  return cartDetails.items.length > 0 ? (
    <div className="tooltiptopheader">
      {toolTipValue && Object.keys(toolTipValue).length && props.isTooltip ? (
        <div>
          {filteredLiveItems().length > 1 ? (
            <span className="toottipcontent">Click here to View More</span>
          ) : (
            <span className="toottipcontent">
              Time left for the Product{" "}
              {toolTipValue.name ? toolTipValue.name : ""}
              {toolTipValue.name.length > 20 ? "" : ""} to order
            </span>
          )}
        </div>
      ) : null}

      {/* <strong>
        {productDbTime}
        {Number(productDbTime) > 1 ? "Minutes" : "Minute"}{" "}
      </strong> */}
      <h5
        className={`thChild thTimer  ${
          (minutesValue > 0 || secondsValue > 0) && props.isStyle
            ? "cart-timer"
            : ""
        } `}
      >
        {secondsValue > 0 || minutesValue > 0
          ? hourValue
            ? `${hourValue < 10 ? `0${hourValue}` : hourValue}:`
            : "00:"
          : null}
        {minutesValue > 0
          ? minutesValue < 10
            ? `0${minutesValue}`
            : minutesValue
          : secondsValue > 0
          ? "00"
          : null}
        {secondsValue > 0 || minutesValue > 0 ? ":" : null}
        {secondsValue > 0 || minutesValue > 0
          ? secondsValue < 10
            ? `0${secondsValue}`
            : secondsValue
          : secondsValue > 0
          ? "00"
          : null}
      </h5>
    </div>
  ) : null;
};

export const CartTimerMinutes = (props) => {
  let [globalInterval, setGlobalInterval] = useState(null);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.users.users);
  const cartDetails = useSelector((state) => {
    return state.cart.savedCart;
  });
  const timeRunnig = useSelector((state) => state.cartTimer);
  const [hourValue, setHourValue] = useState(null);
  const [liveArrLength, setLiveArrLength] = useState();
  const [minutesValue, setMinutesValue] = useState(null);
  const [secondsValue, setSecondsValue] = useState(null);
  const [toolTipValue, setTooltipValue] = useState({});
  const [productDbTime, setProductDbTime] = useState();
  const okCallBack = () => {
    props.history.push("/cart");
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const cartTimerPopupdata = () => {
    return (
      <p>
        You have items in your cart that have less than 3 minutes to be
        purchased or they will be removed from your cart.... If you need more
        time to complete your order you may add those items to a wish list and
        check your wish list when you are ready to complete your order.
      </p>
    );
  };
  const addtowishListCallBack = () => {
    let reqObj = {
      sessionKey: userInfo.session_id,
      websku: toolTipValue["websku"],
      items: [{ sku: toolTipValue["sku_dtls"]["sku"] }],
    };
    AddToWatchList(reqObj, dispatch)
      .then(() => {
        let reqObj = {
          sessionKey: userInfo["session_id"],
          search_value: "",
          search_key: "",
          size: 5,
          page: 1,
          sort: "",
          dir: "",
        };
        return wishlistList(reqObj, dispatch);
      })
      .then(() => {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
      });
  };

  useEffect(() => {
    if (
      timeRunnig &&
      typeof timeRunnig.timeRunning !== "undefined" &&
      Array.isArray(timeRunnig.timeRunning) &&
      Boolean(timeRunnig.timeRunning.length)
    ) {
      if (props.isHeaderLoad) {
        setProductDbTime(timeRunnig.timeRunning[0].dbTime);
      } else {
        setProductDbTime(timeRunnig.timeRunning[props.cartIndex].dbTime);
      }
    }
  }, [timeRunnig.timeRunning]);

  useEffect(() => {
    let interval;

    try {
      if (typeof cartDetails === "undefined") throw "cartDetails is undefined";
      if (typeof cartDetails.items === "undefined")
        throw "cartDetails.items is undefined";

      if (secondsValue === 0 && minutesValue === 0 && hourValue === 0) {
        // let freqObj = {
        //   sessionKey: userInfo["session_id"],
        // };
        // let livefilertedArr = cartDetails.items.filter(
        //   (ele) => ele.sku_dtls.live === 1 || ele.sku_dtls.live === 2
        // );
        // let deletePayload = {
        //   sessionKey: userInfo.session_id,
        //   userid: userInfo.detail.user_id,
        //   sku: livefilertedArr[0].sku_dtls.sku,
        // };
        // console.log(livefilertedArr, "ths is the live filtererd item arr");
        // intemateBackendToDelete(deletePayload, dispatch).then(() =>
        //   fetchCartItem(freqObj)(dispatch)
        // );
        return;
      }

      // hour value

      // hour value END

      // 3min popup
      if (typeof cartDetails === "undefined") throw "cartDetails is undefined";
      if (typeof cartDetails.items === "undefined")
        throw "cartDetails.items is undefined";
      if (!Array.isArray(cartDetails.items))
        throw "cartDetails.items is not an array";
      let liveItemsArr = cartDetails.items.filter((ele) => {
        return (
          ele.sku_dtls.live === 1 ||
          ele.sku_dtls.live === 2 ||
          ele.sku_dtls.live === 3
        );
      });
      if (liveItemsArr.length === 0) throw "liveItems Array is zero";
      let firstElement_ = {};
      if (props.isHeaderLoad) {
        // firstElement_ = liveItemsArr[liveItemsArr.length - 1];
        firstElement_ = liveItemsArr[0];
      } else {
        let indd = liveItemsArr.findIndex(
          (ele) => ele.sku_dtls.sku === props.sku
        );
        firstElement_ = liveItemsArr[indd];
      }

      // if (
      //   minutesValue === 3 &&
      //   secondsValue === 0 &&
      //   firstElement_.sku_dtls.inv_type == "managed"
      // ) {
      //   new popupClass(
      //     true,
      //     cartTimerPopupdata(),

      //     "Insufficient QTY",
      //     "Add To Wishlist",
      //     "OK",
      //     "",
      //     "",
      //     dispatch,
      //     addtowishListCallBack,
      //     okCallBack,
      //     "cart-timer-close",
      //     "cart-timer-popup"
      //   ).emitPopup();
      // }
      //3min popup END

      setGlobalInterval(interval);
      return () => clearInterval(interval);
    } catch (e) {
      clearInterval(interval);
      console.log(e);
    }
  }, [secondsValue, minutesValue, hourValue]);

  const filteredLiveItems = () => {
    let filteredArray = cartDetails.items.filter((ele) => {
      return (
        ele.sku_dtls.live === 1 ||
        ele.sku_dtls.live === 2 ||
        ele.sku_dtls.live === 3
      );
    });
    return filteredArray;
  };

  return cartDetails.items.length > 0 ? (
    <div className="tooltiptopheader">
      {toolTipValue && Object.keys(toolTipValue).length && props.isTooltip ? (
        <div>
          {filteredLiveItems().length > 1 ? (
            <span className="toottipcontent">Click here to View More</span>
          ) : (
            <span className="toottipcontent">
              Time left for the Product{" "}
              {toolTipValue.name ? toolTipValue.name : ""}
              {toolTipValue.name.length > 20 ? "" : ""} to order
            </span>
          )}
        </div>
      ) : null}

      <span className="prdt-dbtime">
        {productDbTime}
        {Number(productDbTime) > 1 ? "Minutes" : "Minute"}{" "}
      </span>
    </div>
  ) : null;
};

export default withRouter(TopHeader);
