import React, { useEffect, useState } from "react";

import "./billReceipt.css";
import MasterLayout from "../MasterLayout";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCartItem,
  confirmOrderId,
} from "../../Store/Actions/cart.actions";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import SecondaryButton from "../SecondaryButton";
import { height } from "@material-ui/system";

const BillReceipt = (props) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.users.users);

  // ------- LOCAL STATE ------------//
  const [totalPrice, setTotalPrice] = useState(0);
  const [imcommingDetail, setIncommingDetail] = useState({
    shipping_adddress: {},
    order_id: 0,
    billing_address: {},
    poNumber: "",
    comments: "",
  });
  const [cinfo, setCinfo] = useState([]);
  // ----------- END ------------ //

  // ----------- REDUX STATE ------------ //
  // const dispatch = useDispatch()
  const cartDetails = useSelector((state) => state.cart);
  // const userInfo = useSelector((state) => state.users.users);

  // ----------- END ------------ //

  // ----------- BILL DOWNLOAD --------------- //
  const downloadInvoice = () => {
    html2canvas(document.getElementById("billReceipt"), {
      scale: 0.8,
      allowTaint: true,
      useCORS: true,
    })
      .then((canvas) => {
        // Your IMAGE_DATA_URI
        var imgData = canvas.toDataURL("image/png");
        // Make pdf
        let options = { pagesplit: true, orientation: "l" };
        const pdf = new jsPDF(options);

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        const marginX = (pageWidth - canvasWidth) / 2;
        const marginY = (pageHeight - canvasHeight) / 2;

        pdf.addImage(
          imgData,
          "PNG",
          marginX,
          marginY,
          canvasWidth,
          canvasHeight
        );
        // Save document
        pdf.save("Order Confirmation.pdf");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // ------------- END ---------------- //

  // ------------ EFFECTS ----------------- //
  useEffect(() => {
    let reqObj = {
      sessionKey: userInfo.session_id,
    };
    fetchCartItem(reqObj)(dispatch);
    try {
      if (typeof props.location !== "undefined") {
        if (typeof props.location.state !== "undefined") {
          if (Object.keys(props.location.state).length) {
            if (typeof props.location.state.cartInfo !== "undefined") {
              if (Array.isArray(props.location.state.cartInfo)) {
                if (props.location.state.cartInfo.length) {
                  let priceArr = props.location.state.cartInfo.map((ele) =>
                    parseFloat(ele.retail_price * ele.owned_quantity)
                  );

                  setTotalPrice(
                    parseFloat(
                      priceArr.reduce((acc, crr) => acc + crr)
                    ).toFixed(2)
                  );
                  setCinfo(props.location.state.cartInfo);
                } else {
                  throw "props.location.state.cartInfo ins not an aray ";
                }
              } else {
                throw "props.location.state.cartInfo ins not an aray ";
              }
            } else {
              throw "props.location.state.cartInfo in undefined";
            }
            if (typeof props.location.state.headObj !== "undefined") {
              if (Object.keys(props.location.state.headObj).length) {
                /**     
                 * Data in the headObj
                 * billing_address: {first_name: "sammy", last_name: "sammu", username: "4500", companyname: "Develop",…}
                      * address: "No 25 Rajaji Street"
                          address2: ""
                          alt_phone: "8"
                          city: "CHENNAI"
                          companyname: "Develop"
                          countryname: "IND"
                          email: "gobinath.auctionsoftware@gmail.com"
                          first_name: "sarathy"
                          last_name: "emanual"
                          phone: "01234567895"
                          username: "4500"
                          zip_code: "600059"
                    order_comments: "ppp"
                    order_id: 10
                    payment_method: "123"
                    purchase_order: 10
                    shipping_address: {shipping_nickname: "12", shipping_company: "Develop", ship_placedby: "12",…}
                    ship_address: "No 25 Rajaji Street"
                        ship_city: "CHENNAI"
                        ship_country: "USA"
                        ship_placedby: "12"
                        ship_state: ""
                        ship_zipcode: 600059
                        shipping_company: "Develop"
                        shipping_email: "jakir.auctionsoftware@gmail.com"
                        shipping_nickname: "12"
                        shipping_phone: "01234567895"
                    status: true 
                 * 
                 */
                let incObj = {
                  billing_address: props.location.state.headObj.billing_address,
                  order_id: props.location.state.headObj.order_id,
                  shipping_address:
                    props.location.state.headObj.shipping_address,
                  poNumber: props.location.state.headObj.poNumber,
                  comments: props.location.state.headObj.comments,
                  order_date: props.location.state.headObj.order_date,
                };
                confirmOrderId({
                  sessionKey: userInfo.session_id,
                  order_id: props.location.state.headObj.order_id,
                })
                  .then((res) => {})
                  .catch((err) =>
                    console.log(err, "this is error from the confirm order id")
                  );
                setIncommingDetail(incObj);
              } else {
                throw "props.location.state.headObj is an empty object";
              }
            } else {
              throw "props.location.state.headObj is undefined";
            }
          } else {
            throw "props.location.state is empty object";
          }
        } else {
          throw "props.location.state is undefined";
        }
      } else {
        throw "props.location is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  // ---------- END -------------//
  // ------------------ END --------------------------- //
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MasterLayout {...props}>
      <div className="downloadBillReceipt">
        <SecondaryButton
          onClick={() => downloadInvoice()}
          style={{ width: "100px", height: "40px" }}
        >
          Download
        </SecondaryButton>
      </div>
      <div className="bill-receipt" id="billReceipt">
        <div className="thank-you">
          <p className="py-3 thank-you-text">Thank you for your order!</p>
          <div className="d-flex justify-content-between">
            <div>
              <p className="order-no px-2">
                Order#{" "}
                {imcommingDetail["order_id"] ? imcommingDetail["order_id"] : ""}
              </p>
            </div>
            <div>
              <p className="date px-2">
                {imcommingDetail["order_date"]
                  ? imcommingDetail["order_date"]
                  : ""}
              </p>
            </div>
          </div>
        </div>

        <div className="dtls-info d-flex justify-content-between">
          <div className="shipping-address-info my-3">
            <p className="shipping-address-info-text px-2">
              Shipping Information
            </p>
            {imcommingDetail["shipping_address"] ? (
              <div className="address px-2">
                <p>
                  {imcommingDetail["shipping_address"]["shipping_nickname"]}
                </p>
                <p>{imcommingDetail["shipping_address"]["shipping_company"]}</p>
                <p>{imcommingDetail["shipping_address"]["ship_placedby"]}</p>
                <p>{imcommingDetail["shipping_address"]["ship_address"]}</p>
                <p>
                  {imcommingDetail["shipping_address"]["ship_city"]}{" "}
                  {imcommingDetail["shipping_address"]["ship_zipcode"]}
                </p>
                <p>{imcommingDetail["shipping_address"]["ship_country"]}</p>
                <p>{imcommingDetail["shipping_address"]["shipping_phone"]}</p>
                <p>{imcommingDetail["shipping_address"]["shipping_email"]}</p>
              </div>
            ) : null}
          </div>
          <div className="billing-info my-3">
            <p className="billing-info-text px-2">Billing Information</p>
            {imcommingDetail["billing_address"] ? (
              <div className="address px-2">
                <p>{imcommingDetail["billing_address"]["username"]}</p>
                <p>{imcommingDetail["billing_address"]["companyname"]}</p>
                <p>
                  {imcommingDetail["billing_address"]["first_name"]}{" "}
                  {imcommingDetail["billing_address"]["first_name"]}
                </p>
                <p>
                  {imcommingDetail["billing_address"]["address"]},{" "}
                  {imcommingDetail["billing_address"]["address2"]}
                </p>
                <p>
                  {imcommingDetail["billing_address"]["city"]}{" "}
                  {imcommingDetail["billing_address"]["zip_code"]}
                </p>
                <p>{imcommingDetail["billing_address"]["countryname"]}</p>
                <p>{imcommingDetail["billing_address"]["phone"]}</p>
                <p>{imcommingDetail["billing_address"]["email"]}</p>
              </div>
            ) : null}
          </div>
        </div>
        <div className="order-info">
          <p className="order-info-text py-3">Order Information</p>
          <div className="dtls-info d-flex justify-content-between">
            <p className="px-2">
              PO Number :{" "}
              {imcommingDetail["poNumber"] ? imcommingDetail["poNumber"] : 0}
            </p>
            <p>
              Purchase Order #{" "}
              {imcommingDetail["order_id"] ? imcommingDetail["order_id"] : 0}
            </p>
            <p className="px-2" style={{ lineBreak: "anywhere" }}>
              Comments{" "}
              {imcommingDetail["comments"] ? imcommingDetail["comments"] : ""}
            </p>
          </div>
        </div>

        <div className="order-dtls my-3">
          <p className="order-dtls-text my-3 px-2">Order Details</p>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Qty</th>
                <th>Size</th>
                <th className="ordr-dtls-desc">Description</th>
                <th>Unit Price</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {cinfo && cinfo.length
                ? cinfo.map((ele, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <tr>
                          <td>{ele.owned_quantity}</td>
                          <td>{ele.size}</td>
                          <td>{ele.name}</td>
                          <td>${ele.retail_price}</td>
                          <td>
                            $
                            {(ele.retail_price * ele.owned_quantity).toFixed(2)}
                            <p></p>
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <div className="sub-total my-3">
          <p className="sub-total-text py-2 mx-2 px-2">
            Sub Total: $ {totalPrice ? totalPrice : 0}
          </p>
        </div>
      </div>
    </MasterLayout>
  );
};

export default withRouter(BillReceipt);
