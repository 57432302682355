import { getDisplayDate } from "@material-ui/pickers/_helpers/text-field-helper";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import "./land.css";
import Carousel from "react-bootstrap/Carousel";
import {
  Sitemenu,
  Searchfilters,
  accountOverview,
  specialProductDetails,
} from "../../Store/Actions/userActions";
import { Container, Button } from "@material-ui/core";
import InitialChangePassword from "../../components/InitialChangePassword";

const Landing = (props) => {
  const [state, setState] = useState({
    display: [],
    images: [],
  });

  const userInfo = useSelector((state) => state.users.users);

  const [initalChngPopup, setInitialChngPopup] = useState(false);

  const initialChngPassOpen = () => {
    setInitialChngPopup(true);
  };

  const initialChngPassClose = () => {
    setInitialChngPopup(false);
  };

  const initialvalues = {
    sessionKey: userInfo.session_id,
    search_value: "",
    search_key: "",
    size: "20",
    page: "1",
    cid: "",
  };

  const [stateInitial, setStateInitial] = useState(initialvalues);

  const dispatch = useDispatch();

  const getData = async (e) => {
    try {
      const res = await Axios.post(
        "http://seadwellnode.auctionsoftware.com/sdc/get_flyer",
        { sessionKey: userInfo.session_id }
      );

      setState({
        ...state,
        display:
          res.data && typeof res.data != "undefined" ? res.data.detail[0] : "",
        images:
          res.data && typeof res.data != "undefined"
            ? res.data.detail[0].flyerimages.split(";")
            : "",
      });
    } catch (e) {
      console.log(e, "error");
    }
  };

  useEffect(() => {
    getData();
  }, [userInfo.status]);

  useEffect(() => {
    let data = {
      sessionKey: userInfo.session_id,
      cid: "",
      search_key: "",
      search_value: "",
      page: 1,
      size: 25,
      is_special: 1,
    };

    specialProductDetails(data, dispatch);
  }, []);

  const accountDtls = () => {
    accountOverview(
      { sessionKey: userInfo.session_id, type: "view" },
      dispatch
    );
  };

  const firstTimeResetPopup = () => {
    if (userInfo.detail.is_first_login) {
      setInitialChngPopup(true);
    } else {
      setInitialChngPopup(false);
    }
  };

  useEffect(() => {
    accountDtls();
  }, []);

  useEffect(() => {
    firstTimeResetPopup();
  }, [userInfo.isLogin]);

  return (
    <Container>
      <InitialChangePassword
        initalChngPopup={initalChngPopup}
        initialChngPassClose={initialChngPassClose}
      />
      <div className="searchResults mx-auto">
        <h4 style={{ fontSize: "2rem" }}>{state.display.flyerdesc}</h4>
        <Carousel className="landing-slider">
          {state.images.map((data, key) => (
            <Carousel.Item>
              <img
                className="d-block w-75 mx-auto"
                src={
                  "https://auctionsoftwaremarketplace.com/seadnew/images/flyer/" +
                  data
                }
                alt="First slide"
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </Container>
  );
};
export default React.memo(Landing);
