import React, { useState, useRef, useEffect } from "react";

import MasterLayout from "../MasterLayout";
import SecondaryButton from "../SecondaryButton";
import "./importLogoBannerAdmin.css";
import { useDispatch, useSelector } from "react-redux";
import {
  bannerLogoUpload,
  viewLogoBanner,
} from "../../Store/Actions/userActions";

const ImportLogoBannerAdmin = (props) => {
  const [imageUpload, setImageUpload] = useState({
    logoImage: null,
    bannerImage: null,
  });
  const userInfo = useSelector((state) => state.users.users);
  const LogoImageUpload = useRef();
  const BannerImageUpload = useRef();

  const dispatch = useDispatch();

  const onChangeFileUpload = (e) => {
    let nameVal = e.target.name;
    let uploadFile = e.target.files[0];
    setImageUpload({ ...imageUpload, [nameVal]: uploadFile });
  };
  const secondbutUpload = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("sessionKey", userInfo.session_id);
    Object.keys(imageUpload).map((ele) => {
      formData.append(ele, imageUpload[ele]);
    });
    bannerLogoUpload(formData, dispatch).then((res) => {
      if (res.data.status) {
        // setImageUpload({
        //   logoImage: null,
        //   bannerImage: null,
        // });
        let viewlogobanner = {
          sessionKey: userInfo.session_id,
        };
        viewLogoBanner(viewlogobanner, dispatch).then((res) => {
          if (res.data.status) {
            setImageUpload({
              logoImage: res.data.logo,
              bannerImage: res.data.banner,
            });
          } else {
            setImageUpload({
              logoImage: null,
              bannerImage: null,
            });
          }
        });
        LogoImageUpload.current.value = null;
        BannerImageUpload.current.value = null;
      }
    });
  };
  useEffect(() => {
    let viewlogobanner = {
      sessionKey: userInfo.session_id,
    };
    viewLogoBanner(viewlogobanner, dispatch).then((res) => {
      if (res.data.status) {
        setImageUpload({
          logoImage: res.data.logo,
          bannerImage: res.data.banner,
        });
      }
    });
  }, []);

  return (
    <MasterLayout {...props}>
      <div className="logo-banner">
        <p className="title">IMPORT LOGO/BANNER</p>
        <hr />
        <div className="container">
          <div className="d-flex flex-column justify-content-center align-items-center banner-upload-main-area">
            <div>
              <input
                name="logoImage"
                size="medium"
                type="file"
                id="logo"
                onChange={onChangeFileUpload}
                ref={LogoImageUpload}
                className="filestaxpath import-logo-bnr"
              />
              <h6 className=" banner-image-format">
                Logo Upload Only Jpeg Image
              </h6>
            </div>
            <div>
              <input
                name="bannerImage"
                size="medium"
                type="file"
                id="banner"
                onChange={onChangeFileUpload}
                className="filestaxpath import-logo-bnr"
                ref={BannerImageUpload}
              />
              <h6 className=" banner-image-format">
                Banner Upload Only Jpeg Image
              </h6>
            </div>
            <SecondaryButton
              className="banner-upload"
              onClick={secondbutUpload}
            >
              Upload
            </SecondaryButton>
          </div>
          {Boolean(imageUpload.logoImage) && imageUpload.logoImage !== null ? (
            <div>
              <img
                className="image-upload-logo my-4"
                src={
                  imageUpload.logoImage
                    ? typeof imageUpload.logoImage === "string"
                      ? imageUpload.logoImage
                      : URL.createObjectURL(imageUpload.logoImage)
                    : null
                }
                alt={imageUpload.logoImage ? imageUpload.logoImage.name : null}
              />
            </div>
          ) : null}
          {Boolean(imageUpload.bannerImage) &&
          imageUpload.bannerImage !== null ? (
            <div>
              <img
                className="image-upload-logo my-4"
                src={
                  imageUpload.bannerImage
                    ? typeof imageUpload.bannerImage === "string"
                      ? imageUpload.bannerImage
                      : URL.createObjectURL(imageUpload.bannerImage)
                    : null
                }
                alt={
                  imageUpload.bannerImage ? imageUpload.bannerImage.name : null
                }
              />
            </div>
          ) : null}
        </div>
      </div>
    </MasterLayout>
  );
};

export default ImportLogoBannerAdmin;
