import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import "./ProductView.css";
import InputField from "../Input/InputField";
import { Button } from "@material-ui/core";
import { addItemToCart, fetchCartItem } from "../../Store/Actions/cart.actions";
import {
  Searchfilters,
  specialProductDetails,
} from "../../Store/Actions/userActions";
import { withRouter } from "react-router-dom";
import popupClass from "../../utils/emitPopup";
import SmallLoader from "../SmallLoader";
import toastifyClass from "../../utils/emitToastify";
import PrimaryButton from "../PrimaryButton";

const ItemSmallView = (props) => {
  const userInfo = useSelector((state) => state.users.users);
  const [addToCart, setAddToCart] = useState(false);
  const [qty, setQty] = useState("");
  const [itemLoad, setItemLoad] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const serverTime = useSelector((state) => state.serverTime);
  const furnaceTime = useSelector((state) => state.furnaceTime);
  const [furnaceTimereached, setFurnaceTimereached] = useState(false);
  const [shippingTime, setShippingTime] = useState(null);

  const [furnaceData, setFurnaceData] = useState({
    startTiming: moment.tz(
      moment(props.details.livesalestartdate)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      "America/Los_Angeles"
    ),
    endTiming: moment.tz(
      moment(props.details.livesaleenddate).utc().format("YYYY-MM-DD HH:mm:ss"),
      "America/Los_Angeles"
    ),
  });

  let windowSize = window.innerWidth;

  useEffect(() => {
    if (props.shipping_time) {
      let currentDate = moment(serverTime.currentTimeDtls)
        .tz("America/Los_Angeles")
        .format("YYYY-MM-DD");

      if (currentDate <= props.shipping_time) {
        setShippingTime(true);
      } else {
        setShippingTime(false);
      }
    }
    if (props.details.inv_type === "live") {
      let currentDateTime = moment(serverTime.currentTimeDtls).tz(
        "America/Los_Angeles"
      );
      if (
        currentDateTime &&
        currentDateTime.isBetween(
          furnaceData.startTiming,
          furnaceData.endTiming
        )
      ) {
        setFurnaceTimereached(true);
      } else {
        setFurnaceTimereached(false);
      }
    }
  }, [serverTime.currentTimeDtls]);

  useEffect(() => {
    if (furnaceTime.furnaceData) {
      setFurnaceData({
        startTiming: moment.tz(
          moment(furnaceTime.furnaceData.start_time)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
          "America/Los_Angeles"
        ),
        endTiming: moment.tz(
          moment(furnaceTime.furnaceData.end_time)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
          "America/Los_Angeles"
        ),
      });
    }
  }, [furnaceTime]);

  useEffect(() => {
    console.log(
      "furnaceTimereached",
      furnaceTimereached,
      serverTime.currentTimeDtls,
      furnaceTime.furnaceData.start_time,
      furnaceTime.furnaceData.end_time
    );
  }, [furnaceTime]);

  const addItemToCartFnt = (item) => {
    if (
      (item &&
        parseInt(qty) <= parseInt(item.quantity_available) &&
        qty <= item.sku_limit &&
        item.inv_type == "managed") ||
      (item &&
        parseInt(qty) <= parseInt(item.quantity_available) &&
        item.sku_limit === 0 &&
        item.inv_type == "managed") ||
      (item && item.inv_type == "static") ||
      (item && item.inv_type == "live")
    ) {
      if (
        item &&
        item.inv_type == "static" &&
        item.sku_limit != undefined &&
        item.sku_limit != "" &&
        item.sku_limit !== 0 &&
        qty > item.sku_limit
      ) {
        let propupValue = (
          <p>
            Sku {item.sku} is limited to {item.sku_limit} per customer.
          </p>
        );
        new popupClass(
          true,
          propupValue,
          "Added to  your cart",
          "",
          "",
          "",
          "",
          dispatch
        ).emitPopup();
      } else {
        if (parseInt(qty) <= 0) {
          new popupClass(
            true,
            "Zero Qty choosen on Sku: " + item.sku,
            "Zero Quantity",
            "",
            "",
            "",
            "",
            dispatch
          ).emitPopup();
        } else {
          let itemVal = {
            sessionKey: userInfo.session_id,
            webSKU: item.websku,
            SKU: item.sku,
            qty: parseInt(qty),
            override: item.override ? item.override : 0,
          };
          return addItemToCart(
            itemVal,
            dispatch,
            undefined,
            secondayCallBack,
            undefined,
            false
          )
            .then((res) => {
              if (res.data.status) {
                new toastifyClass(
                  true,
                  res.data.msg && typeof res.data.msg == "string"
                    ? res.data.msg
                    : res.data.detail && typeof res.data.detail == "string"
                    ? res.data.detail
                    : "",
                  "success",
                  dispatch
                ).emittoast();
                dispatch({
                  type: "INC_CART",
                  payload: res.data,
                });
              } else {
                dispatch({
                  type: "CART_PRODUCTS_EXIST",
                  payload: res.data,
                });
                if (res.data.flag == "exist") {
                  let stringValue = (
                    <p>
                      The Following Items already is in your Cart, Are you sure
                      you want to add more? <br /> 1. {res.data.skuDetail.nm} -
                      ({res.data.skuDetail.sku})
                    </p>
                  );

                  new popupClass(
                    true,
                    stringValue,
                    "Oops !! Already Available in Cart",
                    "No",
                    "Yes",
                    "ok-popup-app",
                    "no-popup-app",
                    dispatch,
                    undefined,
                    secondayCallBack,
                    "",
                    "already-avail-cart",
                    "already-add-action"
                  ).emitPopup();

                  // new popupClass(
                  //   true,
                  //   stringValue,
                  //   "Oops !! Already Available in Cart",
                  //   "No",
                  //   "Yes",
                  //   "ok-popup-app",
                  //   "no-popup-app",
                  //   dispatch,
                  //   undefined,
                  //   item &&
                  //   (item.qtyAvbl > item.quantity_available ||
                  //     qty > item.quantity_available)
                  //     ? insufficentQty
                  //     : secondayCallBack,
                  //   "",
                  //   "already-avail-cart",
                  //   "already-add-action"
                  // ).emitPopup();
                } else {
                  if (!res.data.status && item.inv_type == "live") {
                    let starttime = moment(res.data.starttime)
                      .utc()
                      .format("Do MMMM YYYY, LT");
                    let endtime = moment(res.data.endtime)
                      .utc()
                      .format("Do MMMM YYYY, LT");
                    let dateTimeDtls = `Sale starts at ${starttime} and ends at ${endtime}.  Please wait. Thanks.`;
                    new popupClass(
                      true,
                      dateTimeDtls,
                      "Oops !!! Sale Timings",
                      "",
                      "",
                      "",
                      "",
                      dispatch,
                      "",
                      "",
                      "",
                      "sale-end-popup",
                      ""
                    ).emitPopup();
                  } else if (
                    res.data.flag == "error" &&
                    item.inv_type == "static"
                  ) {
                    new popupClass(
                      true,
                      "Invalid Quantity",
                      "Added to cart",
                      "",
                      "",
                      "",
                      "",
                      dispatch,
                      "",
                      "",
                      "",
                      "",
                      ""
                    ).emitPopup();
                  } else if (
                    res.data.flag == "error" &&
                    item.inv_type == "managed"
                  ) {
                    new popupClass(
                      true,
                      res.data.msg,
                      "Added to  your cart",
                      "",
                      "",
                      "",
                      "",
                      dispatch,
                      okClose,
                      "",
                      "",
                      "insufficent-poup"
                    ).emitPopup();
                  }
                }
              }
              return res;
            })
            .then((res) => {
              if (res.data.flag !== "exist") {
                return fetchCartItem({
                  sessionKey: userInfo.session_id,
                })(dispatch);
              } else {
                return Promise.reject();
              }
            })
            .then(async (res) => {
              if (props && typeof props.location != "undefined") {
                if (location.pathname === "/specialProduct") {
                  let specialProductDtls = JSON.parse(
                    sessionStorage.getItem("specialPage")
                  );
                  if (
                    specialProductDtls === null ||
                    specialProductDtls === "undefined" ||
                    Object.keys(specialProductDtls).length === 0
                  ) {
                    specialProductDtls = {
                      sessionKey: userInfo.session_id,
                      cid: "",
                      search_key: "",
                      search_value: "",
                      page: props.pageNo,
                      size: "25",
                      is_special: 1,
                    };
                  } else {
                    specialProductDtls = {
                      ...specialProductDtls,
                      page: props.pageNo,
                    };
                  }
                  setItemLoad(true);
                  await specialProductDetails(
                    specialProductDtls,
                    dispatch,
                    false
                  );
                  setItemLoad(false);
                } else {
                  let ID = props.location.search.split("=")[1].split("&")[0];
                  let searchedValueDetails =
                    sessionStorage.getItem("searchValue");
                  searchedValueDetails = JSON.parse(searchedValueDetails);
                  let mainNav = sessionStorage.getItem("mainNav");
                  let stockAvl = JSON.parse(sessionStorage.getItem("stockAvl"));
                  let obj = {
                    sessionKey: userInfo.session_id,
                    search_value: searchedValueDetails?.search_value
                      ? searchedValueDetails?.search_value
                      : "",
                    search_key: searchedValueDetails?.search_key
                      ? searchedValueDetails?.search_key
                      : "",
                    size: "25",
                    page: props.pageNo,
                    // size: mainNav == "The Furnace" ? "25" : stockAvl + 3000,
                    // page: mainNav == "The Furnace" ? "1" : "",
                    cid: searchedValueDetails?.cid
                      ? searchedValueDetails?.cid
                      : ID
                      ? ID
                      : "",
                  };

                  // setItemLoad(true);
                  // Searchfilters(
                  //   obj,
                  //   dispatch,
                  //   false,
                  //   "undefined",
                  //   location.search
                  // ).then((respnce) => {
                  //   setItemLoad(false);
                  // });
                  return false;
                }
              }
              return res;
            })
            .then((res) => {
              setQty("");
              removeAddedItemFromAllCartState(item);
              return res;
            });
          // } else {
          //   console.log("qty is either empty or 0 or undefined");
          // }
        }
      }
    } else {
      if (
        item &&
        item.inv_type == "managed" &&
        qty > item.sku_limit &&
        item.sku_limit !== 0
      ) {
        let propupValue = (
          <p>
            Sku {item.sku} is limited to {item.sku_limit} per customer.
          </p>
        );
        new popupClass(
          true,
          propupValue,
          "Added to  your cart",
          "",
          "",
          "",
          "",
          dispatch
        ).emitPopup();
      } else if (
        (item &&
          item.inv_type == "managed" &&
          parseInt(qty) > parseInt(item.quantity_available)) ||
        (item &&
          item.inv_type == "live" &&
          parseInt(qty) > parseInt(item.quantity_available))
      ) {
        new popupClass(
          true,
          "Insufficient QTY",
          "Added to  your cart",
          "",
          "",
          "",
          "",
          dispatch
        ).emitPopup();
      }
    }
  };

  const handleCartQuantityKeyDown = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      addItemToCartFnt(props);
    }
  };

  const insufficentQty = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    new popupClass(
      true,
      "Insufficient QTY",
      "Added to  your cart",
      "",
      "",
      "",
      "",
      dispatch
    ).emitPopup();
  };

  const secondayCallBack = () => {
    let obj = { ...props };
    obj["override"] = 1;
    addItemToCartFnt(obj).then((res) => {
      fetchCartItem({
        sessionKey: userInfo.session_id,
      })(dispatch);

      if (
        props.inv_type == "static" ||
        (props.inv_type == "managed" &&
          parseInt(qty) <= props.quantity_available) ||
        (props.inv_type == "managed" &&
          parseInt(qty) + props.cart_qty <= props.sku_limit)
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
      }
    });
  };

  const removeAddedItemFromAllCartState = (data) => {
    if (props.addAllToCart && props.setAddAllToCart) {
      var stateClone = props.addAllToCart;
      var webSkuObjectIndex = stateClone.findIndex(
        (item) => item.websku === data.websku
      );
      if (webSkuObjectIndex !== -1) {
        var skuObjectIndex = stateClone[webSkuObjectIndex].items.findIndex(
          (item) => item.sku === data.sku
        );
        if (skuObjectIndex !== -1) {
          stateClone[webSkuObjectIndex].items.splice(skuObjectIndex, 1);
          if (stateClone[webSkuObjectIndex].items.length == 0) {
            stateClone.splice(webSkuObjectIndex, 1);
          }
          props.setAddAllToCart(stateClone);
        }
      }
    }
  };

  const UpdateAddAllToCartState = (data, quantity) => {
    if (props.addAllToCart && props.setAddAllToCart) {
      var stateClone = props.addAllToCart;
      if (
        (data.quantity_available &&
          parseInt(quantity) <= parseInt(data.quantity_available)) ||
        !data.quantity_available ||
        data.quantity_available == "-" ||
        quantity === ""
      ) {
        var webSkuObjectIndex = stateClone.findIndex(
          (item) => item.websku === data.websku
        );
        if (webSkuObjectIndex !== -1) {
          var skuObjectIndex = stateClone[webSkuObjectIndex].items.findIndex(
            (item) => item.sku === data.sku
          );
          if (skuObjectIndex !== -1) {
            if (parseInt(quantity) === 0 || quantity === "") {
              stateClone[webSkuObjectIndex].items.splice(skuObjectIndex, 1);
              if (stateClone[webSkuObjectIndex].items.length == 0) {
                stateClone.splice(webSkuObjectIndex, 1);
              }
            } else {
              stateClone[webSkuObjectIndex].items[skuObjectIndex].qty =
                parseInt(quantity);
            }
          } else {
            stateClone[webSkuObjectIndex].items.push({
              sku: data.sku,
              qty: parseInt(quantity),
              override: data.override ? data.override : 0,
            });
          }
          props.setAddAllToCart(stateClone);
        } else {
          stateClone.push({
            websku: data.websku,
            items: [
              {
                sku: data.sku,
                qty: parseInt(quantity),
                override: data.override ? data.override : 0,
              },
            ],
          });
          props.setAddAllToCart(stateClone);
        }
      }
    }
  };

  useEffect(() => {
    setQty("");
  }, [props.isBulkAdded]);

  const changeQty = (e, data, quantity) => {
    let addedQuantity = quantity !== undefined ? quantity : e.target.value;
    switch (data.inv_type) {
      case "managed":
        if (addedQuantity.length > data.quantity_available) {
          setQty(addedQuantity);
          new popupClass(
            true,
            "Insufficient QTY",
            "Added to  your cart",
            "",
            "",
            "",
            "",
            dispatch
          ).emitPopup();
        } else if (addedQuantity.length <= data.quantity_available) {
          setQty(addedQuantity);
          UpdateAddAllToCartState(data, addedQuantity);
        }
        break;

      case "static":
        if (data.sku_limit == 0) {
          setQty(addedQuantity);
          UpdateAddAllToCartState(data, addedQuantity);
        } else if (!isNaN(data.quantity_available)) {
          if (addedQuantity <= data.quantity_available) {
            setQty(addedQuantity);
            UpdateAddAllToCartState(data, addedQuantity);
          }
        } else {
          if (data.quantity_available === "-") {
            setQty(addedQuantity);
            UpdateAddAllToCartState(data, addedQuantity);
          }
        }
        break;

      case "live":
        if (addedQuantity && typeof addedQuantity !== "undefined") {
          setQty(addedQuantity);
          UpdateAddAllToCartState(data, addedQuantity);
        } else {
          setQty(addedQuantity);
          UpdateAddAllToCartState(data, addedQuantity);
        }
        break;

      default:
        if (!data.inv_type) {
          setQty(addedQuantity);
          UpdateAddAllToCartState(data, addedQuantity);
        }
        break;
    }
  };

  const lotPriceLogo = () => {
    if (Number(props.lotprice) === 1) {
      return <span className="float-right lot-price">Lot price available</span>;
    } else {
      return null;
    }
  };

  const okClose = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const onMouseEnterSmallItem = (items, e) => {
    setAddToCart(true);
    try {
      if (typeof props.hoverVal !== "undefined") {
        props.hoverVal(true, props.indexofpar, props, items.itemSmall);
      }
    } catch (e) {
      console.log(e, "err1");
    }
  };

  const onMouseLeaveSmallItem = (items) => {
    // if (qty && typeof qty !== "undefined" && qty > 0) {
    //   setAddToCart(true);
    // } else {
    //   setAddToCart(false);
    // }
    setAddToCart(false);
    try {
      if (typeof props.hoverVal !== "undefined")
        props.hoverVal(false, null, props, items.itemSmall);
    } catch (e) {
      console.log(e, "err2");
    }
  };

  return (
    <>
      {itemLoad ? (
        <SmallLoader />
      ) : (
        <div
          className="ItemSmallView"
          onMouseEnter={() => onMouseEnterSmallItem(props)}
          onMouseLeave={(e) => onMouseLeaveSmallItem(props)}
        >
          {/* props.qtyAvbl == "-" */}
          {props.qtyAvbl == undefined || props.qtyAvbl == "" ? (
            ""
          ) : (
            <>
              {addToCart ? (
                <div style={{ opacity: 0 }}>
                  {props.cart_qty > 0 ? (
                    <div style={{ margin: "1px 2px" }}>
                      {/* <span className="dots">&#10003;</span> */}
                      <span className="dots addedCrtImg">
                        <img src="/assets/images/aedCrt.png" />
                      </span>
                    </div>
                  ) : null}

                  {shippingTime && props.shipping_time && (
                    <div style={{ color: "red", fontWeight: "500" }}>
                      Available for shipping on {props.shipping_time}
                    </div>
                  )}

                  {props.size ? (
                    <p>
                      <span>{props.size ? props.size : "--"}</span>
                    </p>
                  ) : (
                    <span>--</span>
                  )}
                  {props.sp && parseFloat(props.rp) > parseFloat(props.sp) ? (
                    <>
                      <p style={{ textDecoration: "line-through" }}>
                        ${props.rp}
                      </p>
                      <p className="isvSpecial">${props.sp}</p>
                    </>
                  ) : (
                    <p>${props.rp}</p>
                  )}
                  {props.sku_limit > 0 ? (
                    <p className="isvLimit">
                      <span>Size Limit:</span> {props.sku_limit}
                    </p>
                  ) : null}
                  {props.websku_limit > 0 ? (
                    <p className="isvLimit">
                      <span>Cust Limit:</span> {props.websku_limit}
                    </p>
                  ) : null}
                  <p className="isvQtyAvbl">
                    <span>Qty Avail:</span>{" "}
                    {props.details && props.details.inv_type != "static"
                      ? isNaN(props.qtyAvbl)
                        ? "-"
                        : props.qtyAvbl
                      : "-"}
                  </p>

                  {lotPriceLogo()}
                </div>
              ) : (
                <div>
                  {props.cart_qty > 0 ? (
                    <div style={{ margin: "1px 2px" }}>
                      {/* <span className="dots">&#10003;</span> */}
                      <span className="dots addedCrtImg">
                        <img src="/assets/images/aedCrt.png" />
                      </span>
                    </div>
                  ) : null}

                  {shippingTime && props.shipping_time && (
                    <div style={{ color: "red", fontWeight: "500" }}>
                      Available for shipping on {props.shipping_time}
                    </div>
                  )}

                  {props.size ? (
                    <p>
                      <span>{props.size ? props.size : "--"}</span>
                    </p>
                  ) : (
                    <span>--</span>
                  )}
                  {props.details.udf_inches ? (
                    <p>
                      <span>
                        {props.details.udf_inches
                          ? "INCHES " + props.details.udf_inches
                          : ""}
                      </span>
                    </p>
                  ) : (
                    <div></div>
                  )}
                  {props.sp && parseFloat(props.rp) > parseFloat(props.sp) ? (
                    <>
                      <p style={{ textDecoration: "line-through" }}>
                        ${props.rp}
                      </p>
                      <p className="isvSpecial">${props.sp}</p>
                    </>
                  ) : (
                    <p>${props.rp}</p>
                  )}
                  {props.sku_limit > 0 ? (
                    <p className="isvLimit">
                      <span>Size Limit:</span> {props.sku_limit}
                    </p>
                  ) : null}
                  {props.websku_limit > 0 ? (
                    <p className="isvLimit">
                      <span>Cust Limit:</span> {props.websku_limit}
                    </p>
                  ) : null}
                  <p className="isvQtyAvbl">
                    <span>Qty Avail:</span>{" "}
                    {props.details && props.details.inv_type != "static"
                      ? isNaN(props.qtyAvbl)
                        ? "-"
                        : props.qtyAvbl
                      : "-"}
                  </p>

                  {lotPriceLogo()}
                </div>
              )}

              {addToCart && (
                <>
                  <form id="samllFieldForm">
                    {props.lot_dtls &&
                    typeof props.lot_dtls !== "undefined" &&
                    Object.keys(props.lot_dtls) !== 0 ? (
                      <div>
                        <div
                          className={`${
                            props.key_.sku_dtls[0].sku === props.details.sku &&
                            windowSize < 1520 &&
                            props.lotPriceCheck(props.key_) &&
                            props.totalListData?.length - 1 !== props.indexofpar
                              ? "tooltipsearSmall"
                              : "tooltipsear"
                          }  hde ${
                            props.itemView &&
                            props.hoveredIndex === props.value_ &&
                            props.lotPriceCheck(props.key_)
                              ? "dispbl"
                              : "dispnon"
                          } ${
                            props.key_.sku_dtls[0].sku === props.details.sku &&
                            windowSize < 1520 &&
                            props.lotPriceCheck(props.key_) &&
                            props.totalListData?.length - 1 === props.indexofpar
                              ? "tooltipsearSmall-screen"
                              : ""
                          }`}
                        >
                          <div className="tooltipsearContent">
                            {/* <p className="table-sp">
                              <span>SP:</span> ${props.rp}
                            </p> */}
                            <table
                              className="table table-bordered qty-table"
                              style={{
                                backgroundColor: "#f3f3f3",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th style={{ fontSize: "13px" }}>
                                    Quantity (each)
                                  </th>
                                  <th style={{ fontSize: "13px" }}>
                                    Price (each)
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.hoverTable && props.hoverTable.length
                                  ? props.hoverTable.map((ele) => {
                                      return (
                                        <>
                                          <tr>
                                            <td>{ele.quantity}</td>
                                            <td>{ele.price}</td>
                                          </tr>
                                        </>
                                      );
                                    })
                                  : null}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="addItem d-flex  flex-column align-items-top adCardItems ">
                      <div className="inputFieldSection">
                        <InputField
                          size="small"
                          label="Qty"
                          value={qty}
                          className="input-field-qty my-1"
                          onChange={(e) => {
                            changeQty(e, props);
                          }}
                          type="number"
                          min="0"
                          onKeyDown={handleCartQuantityKeyDown}
                          disabled={
                            (props.inv_type === "live" &&
                              !furnaceTimereached) ||
                            (userInfo?.detail?.cities === "Y" &&
                              props.cities === "Y")
                          }
                        />
                        <div className="addRemoveButtons">
                          <Button
                            disabled={
                              (props.inv_type === "live" &&
                                !furnaceTimereached) ||
                              (userInfo?.detail?.cities === "Y" &&
                                props.cities === "Y")
                            }
                            onClick={(e) =>
                              qty === ""
                                ? changeQty(e, props, "1")
                                : changeQty(e, props, `${parseInt(qty) + 1}`)
                            }
                          >
                            <span className="material-icons">add</span>
                          </Button>
                          <Button
                            disabled={
                              (props.inv_type === "live" &&
                                !furnaceTimereached) ||
                              (userInfo?.detail?.cities === "Y" &&
                                props.cities === "Y")
                            }
                            onClick={(e) =>
                              parseInt(qty) > 0
                                ? parseInt(qty) === 1
                                  ? changeQty(e, props, "")
                                  : changeQty(e, props, `${parseInt(qty) - 1}`)
                                : ""
                            }
                          >
                            <span className="material-icons">remove</span>
                          </Button>
                        </div>
                      </div>

                      <div className="addtoCartButton">
                        <PrimaryButton
                          className="py-1"
                          style={{ color: "white" }}
                          disabled={
                            (props.inv_type === "live" &&
                              !furnaceTimereached) ||
                            (userInfo?.detail?.cities === "Y" &&
                              props.cities === "Y")
                          }
                        >
                          <span
                            className="material-icons"
                            onClick={(e) => {
                              addItemToCartFnt(props);
                            }}
                          >
                            add_shopping_cart
                          </span>
                        </PrimaryButton>
                      </div>
                    </div>
                  </form>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
export default withRouter(ItemSmallView);
