import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      {window.location.pathname.includes("/search") ||
      window.location.pathname.includes("/specialProduct") ||
      window.location.pathname.includes("/drygoods") ? (
        ""
      ) : (
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-sm-4 sdLeft">
                <p>Sea Dwelling Creatures, Inc</p>
                <ul>
                  <li>5515 W. 104th Street, Los Angeles, CA 90045</li>
                  <li>PH: 310-676-9697</li>
                  <li>FX: 310-676-9699</li>
                </ul>
                <p>Customer Service</p>
              </div>
              <div className="col-sm-4 sdCenter uhuws">
                <img src="/assets/images/logo.png" alt="" />
                <span>SEA DWELLING CREATURES, INC.</span>
              </div>
              <div className="col-sm-4 sdRight">
                ©2015 Sea Dwelling Creatures, Inc. All rights reserved. Powered
                by Auctionsoftware.com
                <p className="mt-5 d-flex align-items-center">
                  <span className="material-icons mr-2">facebook</span>Follow us
                  on Facebook
                </p>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;
