import React from "react";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const TostifyAlert = (props) => {
  return (
    <Alert
      onClose={props.handleClose}
      severity={props.severity}
      style={{ textTransform: "capitalize" }}
    >
      {props.children}
    </Alert>
  );
};

export default TostifyAlert;
