export default {
  nickName: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  attention: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  phone: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  cell_phone: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  fax: {
    value: "",
    err_message: "This field is required",
    isRequired: false,
  },
  email: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  company_name: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  address_1: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  address_2: {
    value: "",
    err_message: "This field is required",
    isRequired: false,
  },
  country: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  city: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  state: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  zip_code: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
  enable: {
    value: "",
    err_message: "This field is required",
    isRequired: true,
  },
};
