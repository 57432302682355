import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import {
  DialogContentText,
  DialogContent,
  Dialog,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputField from "../Input/InputField";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ItemSmallView from "../../components/ProductView/ItemSmallView";
import DetailsPopup from "../../components/DetailsPopup/index";
import moment from "moment-timezone";
import InitialChangePassword from "../../components/InitialChangePassword";
import Pagination from "@material-ui/lab/Pagination";

import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import {
  phoneNumberUpdate,
  accountOverview,
} from "../../Store/Actions/userActions";
import toastifyClass from "../../utils/emitToastify";
import {
  specialProductDetails,
  deleteWatchList,
  Sitemenu,
  wishlistList,
  homePage,
  Searchfilters,
} from "../../Store/Actions/userActions";
import NotFnd from "../../components/NotFound/notFound";
import MasterLayout from "../MasterLayout";
import Loader from "../../components/Loader";
import "../FeaturedProduct/featuredProduct.css";
import PrimaryButton from "../../components/PrimaryButton";
import Popup from "../../components/Popup";
import { useHistory } from "react-router-dom";
import {
  addItemToCart,
  fetchCartItem,
  getAllUsersAdmin,
} from "../../Store/Actions/cart.actions";
import "./specialPage.css";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f8f8f8",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    border: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  body: {
    fontSize: 14,
    paddingTop: "10px",
    paddingBottom: "10px",
    border: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child": {
      // backgroundColor: theme.palette.action.hover,
      fontWeight: "500",
      width: "220px",
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
    "&:hover": {
      backgroundColor: "#e9e9e9",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderCollapse: "collapse",
    marginTop: 110,
  },
  container: {
    // maxHeight: 600,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const SpecialProducts = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({
    details: [],
    pop: false,
  });
  const [size, setSize] = useState([]);
  const [itemView, setItemView] = useState(false);
  const [lotListTable, setLotListTable] = useState([]);
  const [stockCartItems, setStockCartItems] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [leftVal, setLeftVal] = useState(0);
  const [initalChngPopup, setInitialChngPopup] = useState(false);
  const [hoverTable, setHoverTable] = useState();
  const [initialWishlist, SetInitialWishlist] = useState(false);
  const [addPhoneNumberOpen, setAddPhoneNumberOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState();
  const [loading, setLoading] = useState(false);
  const [okbuttonData, setOkButtonData] = useState({});
  const [pageWish, setPageWish] = useState(1);
  const [loginPageContent, setLoginPageConent] = useState(false);
  const homePageContent = useSelector(
    (state) => state.homepageContent.specialContent
  );
  const loginInitalPopupWishlist = useSelector(
    (state) => state.Search.loginInitalWishlist
  );
  const userInfo = useSelector((state) => state.users.users);
  const userInfoWishlist = useSelector((state) => state.users);
  const cartDetails = useSelector((state) => state.cart);

  const history = useHistory();
  const loader = useSelector((state) => {
    return state.loader;
  });
  const nav_name = useSelector((state) => state.Search.nav_name);

  const loginContentPageClose = () => {
    setLoginPageConent(!loginPageContent);
    localStorage.setItem("initalpopup", JSON.stringify(0));
  };

  const homePageView = () => {
    return { __html: homePageContent.detail };
  };

  const wishListPageChange = (event, value) => {
    setPageWish(value);
    let data = {
      sessionKey: userInfo.session_id,
      cid: "",
      search_key: "",
      search_value: "",
      page: value,
      size: "25",
      is_special: 1,
    };
    sessionStorage.setItem("specialPage", JSON.stringify(data));
    specialProductDetails(data, dispatch, true, false, "special");
    window.scrollTo(0, 0);
    let url = new URLSearchParams(props.location.search);
    url.set("page", value);
    props.history.replace({
      pathname: props.location.pathname,
      search: "?" + url.toString(),
    });
  };

  const pageTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
  }, []);

  // useEffect(() => {
  //   if (cartDetails.savedCart.count > 0) {
  //     setStockCartItems(cartDetails.savedCart.items);
  //   }
  // }, [cartDetails]);

  // useEffect(() => {
  //   if (stockCartItems.length > 0) {
  //     let badCartItems = stockCartItems;
  //     badCartItems.map((ele, index) => {
  //       if (ele.sku_dtls.purchaseitem == "Y") {
  //         ele.sku_dtls.purchaseitem = "";
  //         ele.sku_dtls.timeadded = moment.utc().format();
  //         // stockCartItems[index].db_time = 1;
  //         stockCartItems[index].db_time_live = localStorage.getItem(
  //           "db_time_live"
  //         )
  //           ? parseInt(localStorage.getItem("db_time_live"))
  //           : 45;
  //         stockCartItems[index].db_time_managed = localStorage.getItem(
  //           "db_time_managed"
  //         )
  //           ? parseInt(localStorage.getItem("db_time_managed"))
  //           : 45;
  //       }
  //       dispatch({
  //         type: "CART_DATA",
  //         payload: {
  //           detail: badCartItems,
  //         },
  //       });
  //     });
  //   }
  // }, [stockCartItems]);

  const handleChange = (values) => (e) => {
    setState({ ...state, details: values, pop: true });
  };
  const onpop = () => {
    setState({ ...state, pop: false });
  };

  useEffect(() => {
    if (homePageContent.status) {
      setLoginPageConent(homePageContent.status);
    }
  }, [homePageContent]);

  const dispatch = useDispatch();
  const lists = useSelector((state) => state.specialProduct.specialInfoDetails);
  console.log(lists, "lists from special page");
  const lotPriceCheck = (value) => {
    try {
      if (typeof value.sku_dtls !== "undefined") {
        if (Array.isArray(value.sku_dtls)) {
          if (value.sku_dtls.length) {
            let lottems = value.sku_dtls.filter((ele) => {
              if (
                ele.special_price &&
                parseFloat(ele.retail_price) > parseFloat(ele.special_price)
              ) {
                return true;
              }
              return false;
            });
            if (lottems.length) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    } catch (e) {
      return false;
    }
  };

  let tdLength = [];
  let maxTd = 0;

  lists.data &&
    !(lists.data.length === 0) &&
    !Boolean(lists.is_live_sale) &&
    lists.data.map((data) => (
      <>
        {tdLength.push(data.sku_dtls.length)}
        {(maxTd = Math.max(...tdLength))}
      </>
    ));

  const hoverVal = (hoverStatus, index, hoverItem, indexSmall, xValue) => {
    try {
      setHoveredIndex(index);
      setItemView(true);
      setLotListTable(hoverItem.lot_dtls);
      setLeftVal(xValue);
      if (typeof hoverItem.lot_dtls === "undefined")
        throw "hoverItem.lot_dtls is undefined";
      let hovtabite = hoverItem.lot_dtls;
      if (typeof hovtabite !== "undefined") {
        if (Array.isArray(hovtabite) || Object.keys(hovtabite).length)
          if (Array.isArray(hovtabite) && hovtabite.length === 0)
            throw "lotlist table is an atrray and its empty";
        let keysOfQuantity = Object.keys(hovtabite["Quantity"]);
        let keysOfPrice = Object.keys(hovtabite["price"]);
        if (keysOfQuantity.length === keysOfPrice.length) {
          let hovTaArr = [];
          keysOfQuantity.map((ele, index) => {
            let price = "";
            let qty = "";
            if (Array.isArray(hovtabite)) {
              price = hovtabite[indexSmall]["price"][ele];
              qty = hovtabite[indexSmall]["Quantity"][ele];
            } else {
              price = hovtabite["price"][index];
              qty = hovtabite["Quantity"][index];
            }
            hovTaArr.push({
              quantity: qty,
              price: price,
            });
            return ele;
          });
          setHoverTable(hovTaArr);
        } else {
          throw "keysOfQuantity.length === keysOfPrice.length isw not equal";
        }
      } else {
        throw "lotListTable is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSpecial = () => {
    let featProDtls = {
      sessionKey: userInfo.session_id,
      cid: "",
      search_key: "",
      search_value: "",
      page: 1,
      size: 25,
      is_special: 1,
    };

    specialProductDetails(featProDtls, dispatch);
  };

  useEffect(() => {
    if (Array.isArray(lists)) {
      handleSpecial();
    }
  }, [lists]);

  // useEffect(() => {
  //   console.log("============redirect to All Fish");
  //   sessionStorage.setItem("nav_item", "All Fish");
  //   dispatch({
  //     type: "nav_name",
  //     payload: "All Fish",
  //   });
  // });

  // useEffect(() => {
  //   if (nav_name == "All Fish") {
  //     let data = {
  //       sessionKey: userInfo.session_id,
  //       search_value: "",
  //       search_key: "",
  //       size: "25",
  //       page: "1",
  //       cid: "143",
  //     };
  //     Searchfilters(data, dispatch, true, true, true);
  //     history.push(`/search?key=143&page=1`);
  //   }
  // }, [nav_name]);

  useEffect(() => {
    // handleSpecial();
    const parms = new URLSearchParams(props.location.search);
    let pagenumber = parms.getAll("page")[0];
    setPageWish(Number(pagenumber));
    // try {
    //   if (!userInfo.detail.cellphone) {
    //     setAddPhoneNumberOpen(true);
    //   }
    // } catch (err) {
    //   console.log(err, "==> error for phone number");
    // }
  }, []);
  const firstTimeResetPopup = () => {
    if (userInfo.detail.is_first_login) {
      setInitialChngPopup(true);
    } else {
      setInitialChngPopup(false);
    }
  };
  const initialChngPassClose = () => {
    setInitialChngPopup(false);
    dispatch({
      type: "LOGIN_WISHLIST_POPUP",
      payload: false,
    });
  };
  useEffect(() => {
    try {
      firstTimeResetPopup();
    } catch (err) {
      console.log(err, "==> first time reset popup");
    }
  }, [userInfo.isLogin]);

  useEffect(() => {
    if (
      lists &&
      typeof lists !== "undefined" &&
      lists.data &&
      lists.data.length > 0 &&
      typeof lists.data !== "undefined"
    ) {
      dispatch({
        type: "LOADING",
        payload: false,
      });

      if (state.pop) {
        lists.data.map((eles) => {
          if (
            state.pop &&
            state.details.websku &&
            eles.websku == state.details.websku
          ) {
            setState({ ...state, details: eles });
          }
        });
      }
    } else if (
      lists &&
      typeof lists !== "undefined" &&
      lists.data &&
      lists.data.length === 0 &&
      typeof lists.data !== "undefined"
    ) {
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
  }, [lists]);

  // useEffect(() => {
  //   if (
  //     userInfo &&
  //     typeof userInfo !== "undefined" &&
  //     userInfo.wishlist_items &&
  //     typeof userInfo.wishlist_items !== "undefined" &&
  //     userInfo.wishlist_items.length > 0 &&
  //     loginInitalPopupWishlist &&
  //     typeof loginInitalPopupWishlist !== "undefined" &&
  //     loginInitalPopupWishlist
  //   ) {
  //     SetInitialWishlist(true);
  //   }
  // }, []);

  // const onCloseInitialWishlistPopup = () => {
  //   SetInitialWishlist(false);
  //   dispatch({
  //     type: "LOGIN_WISHLIST_POPUP",
  //     payload: false,
  //   });
  // };

  // const redirectToWishlist = () => {
  //   SetInitialWishlist(false);
  //   dispatch({
  //     type: "LOGIN_WISHLIST_POPUP",
  //     payload: false,
  //   });
  //   if (
  //     userInfoWishlist &&
  //     typeof userInfoWishlist !== "undefined" &&
  //     userInfoWishlist.fullWishlist &&
  //     typeof userInfoWishlist.fullWishlist !== "undefined" &&
  //     userInfoWishlist.fullWishlist.detail &&
  //     typeof userInfoWishlist.fullWishlist.detail !== "undefined"
  //   ) {
  //     userInfoWishlist.fullWishlist.detail.forEach(addingToCart);
  //   }
  // };

  const okClose = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const productPopupButtons = () => {
    return (
      <>
        <div className="ok-close-offer">
          <PrimaryButton onsubmit={loginContentPageClose} btnSize="small">
            Ok
          </PrimaryButton>
        </div>
      </>
    );
  };

  // const productPopupButtons = () => {
  //   return (
  //     <>
  //       <PrimaryButton onsubmit={redirectToWishlist}>Yes</PrimaryButton>

  //       <PrimaryButton onsubmit={onCloseInitialWishlistPopup}>No</PrimaryButton>
  //     </>
  //   );
  // };
  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    if (e.target.value.length >= 10 && e.target.value.length <= 15) {
      setPhoneNumberError("");
    }
  };

  const validatePhoneNumber = () => {
    let error = "";
    if (phoneNumber.length) {
      if (phoneNumber.length < 10) {
        error = "Phone number should be minimum 10 digits";
      } else if (phoneNumber.length > 15) {
        error = "Phone number should be maximum 15 digits only";
      }
    } else {
      error = "Enter Valid Phone number";
    }
    setPhoneNumberError(error);
    return error;
  };

  const addPhoneNumber = async (e) => {
    if (validatePhoneNumber().length === 0) {
      setLoading(true);
      const res = await phoneNumberUpdate(
        {
          sessionKey: userInfo.session_id,
          mobile_phone: phoneNumber,
        },
        dispatch
      );
      setLoading(false);
      if (res.data.status === true) {
        setAddPhoneNumberOpen(false);
        accountOverview(
          { sessionKey: userInfo.session_id, type: "view" },
          dispatch
        );
        new toastifyClass(true, res.data.msg, "success", dispatch).emittoast();
      } else {
        setPhoneNumberError(res.data.detail);
      }
    }
  };

  return (
    <>
      <InitialChangePassword
        initalChngPopup={initalChngPopup}
        initialChngPassClose={initialChngPassClose}
      />
      <MasterLayout {...props}>
        <div className="searchResults">
          {loader.isLoading ? (
            <>
              <Loader />
              {/* <div style={{ height: "100vh" }}>
                <p style={{ marginTop: "3rem" }}>Loading..</p>
              </div> */}
            </>
          ) : (
            <>
              <h5 className=" sub-catergorie-name p-3 mb-0">Specials</h5>

              {/* <br></br> */}

              <div className="paginationAndAddAll">
                <div className="paginationSection">
                  {lists.data &&
                  !(lists.data.length === 0) &&
                  Number(lists.total_row) > 25 ? (
                    <Pagination
                      page={pageWish}
                      className={"mt-2"}
                      count={Math.ceil(lists.total_row / 25)}
                      onChange={wishListPageChange}
                    />
                  ) : null}
                </div>
              </div>

              <TableContainer
                component={Paper}
                className={`${classes.container}`}
              >
                {lists.data &&
                !(lists.data.length === 0) &&
                !Boolean(lists.is_live_sale) ? (
                  <Table
                    className={classes.table}
                    stickyHeader
                    aria-label="customized table"
                  >
                    <TableBody>
                      {lists.data &&
                        lists.data.map((key, value) => (
                          <StyledTableRow>
                            <StyledTableCell align="left">
                              {/* <HtmlTooltip
                                title={
                                  <div className="tableShortInfo">
                                    <img src={key.image} alt={key.websku} />
                                    <h3>{key.name}</h3>
                                  </div>
                                }
                              > */}
                              <Button
                                className="pvTrigger"
                                onClick={handleChange(lists.data[value])}
                                id="pvTrigger"
                              >
                                {lotPriceCheck(key) ? (
                                  <div style={{ margin: "1px 2px" }}>
                                    {/* <span className="dots">&#10003;</span> */}
                                    <span className="dots saleImg">
                                      <img src="./assets/images/saleBadge.png" />
                                    </span>
                                  </div>
                                ) : null}
                                <div className="imageThumbnail">
                                  <img src={key.image} alt={key.websku} />
                                </div>
                                {key.description}
                                {/* {lotPriceCheck(key) ? (
                                  <span className="sale-tag">Sale</span>
                                ) : null} */}
                              </Button>
                              {/* </HtmlTooltip> */}
                            </StyledTableCell>
                            {key.sku_dtls.map((featSkudtls, index) => (
                              <StyledTableCell>
                                <div
                                  id="smallItem"
                                  className="featured-product-small-item"
                                >
                                  <ItemSmallView
                                    className="smallitvi"
                                    id={featSkudtls.sku}
                                    indexofpar={value}
                                    itemSmall={index}
                                    {...featSkudtls}
                                    hoverVal={hoverVal}
                                    rp={featSkudtls.retail_price}
                                    sp={
                                      featSkudtls.special_price
                                        ? featSkudtls.special_price
                                        : ""
                                    }
                                    custLimit={featSkudtls.customer_limit}
                                    sku_limit={featSkudtls.sku_limit}
                                    websku_limit={featSkudtls.websku_limit}
                                    qtyAvbl={featSkudtls.quantity_available}
                                    details={featSkudtls}
                                    hoverTable={hoverTable}
                                    itemView={itemView}
                                    hoveredIndex={hoveredIndex}
                                    value_={value}
                                    lotPriceCheck={lotPriceCheck}
                                    key_={key}
                                    pageNo={pageWish}
                                  />
                                </div>
                              </StyledTableCell>
                            ))}
                            {Array.from(Array(maxTd - key.sku_dtls.length)).map(
                              () => (
                                <StyledTableCell></StyledTableCell>
                              )
                            )}
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                ) : (
                  // <div style={{ height: "100vh" }}>
                  //   <p style={{ marginTop: "3rem" }}>Loading..</p>
                  // </div>
                  <h1>No special items available now</h1>
                )}
              </TableContainer>
            </>
          )}

          {lists &&
          typeof lists !== "undefined" &&
          lists.data &&
          lists.data.length === 0 ? (
            <div className="mt-5">
              {pageTop()}
              <br></br>
              <object data="./assets/svg/noData.svg" type="image/svg+xml" />
              <h2> No special items available now.</h2>{" "}
            </div>
          ) : null}
          <DetailsPopup
            value={state.pop}
            onpop1={onpop}
            details={state.details}
            pageNo={pageWish}
          />
          <Dialog
            open={addPhoneNumberOpen}
            fullWidth="true"
            maxWidth="md"
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setAddPhoneNumberOpen(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className="phone-number-popup"
          >
            <DialogTitle id="alert-dialog-slide-title" className="popup-header">
              <span className="popup-title">Add Phone Number</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div className="phone-number-input">
                  <InputField
                    type="number"
                    value={phoneNumber}
                    label="Phone Number"
                    name={"phoneNumber"}
                    onChange={handlePhoneNumberChange}
                    autoFocus
                  />
                  {phoneNumberError ? (
                    <span className="error-theme">{phoneNumberError}</span>
                  ) : null}
                </div>
                <div className="phone-number-add-button">
                  {loading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <PrimaryButton
                      onClick={addPhoneNumber}
                      style={{ height: "55px" }}
                    >
                      Add
                    </PrimaryButton>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Popup
            open={
              loginPageContent &&
              Boolean(JSON.parse(localStorage.getItem("initalpopup")))
            }
            fullWidth={true}
            maxWidth="sm"
            classNameDialog=""
            classNameDialogTitle=""
            classNameDialogContentText=""
            onClose={loginContentPageClose}
            classNameClose="logincontent-close"
            actions={productPopupButtons()}
          >
            <section className="specialsSection ">
              <div dangerouslySetInnerHTML={homePageView()} />
            </section>
          </Popup>
          {/* {initialWishlist && !loader.isLoading ? (
            <Popup
              fullWidth={true}
              maxWidth={"sm"}
              open={initialWishlist}
              heading={"Wishlist"}
              onClose={onCloseInitialWishlistPopup}
              actions={productPopupButtons()}
              classNameDialogActions="popup-buttons-checkout"
              classNameDialogTitle="popup-header product-checkout-close-icon"
              classNameDialogContentText="popup-checkout-content"
              classNameDialog="popup-checkout-alert"
            >
              There are some items in the wishlist page. Do you want to add to
              the cart?
            </Popup>
          ) : null} */}
        </div>
      </MasterLayout>
    </>
  );
};
export default SpecialProducts;
