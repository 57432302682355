import React, { useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import toastifyClass from "../../utils/emitToastify";
// import { DataGrid } from "@material-ui/data-grid";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import "./Search.css";
import ItemSmallView from "../../components/ProductView/ItemSmallView";
import DetailsPopup from "../../components/DetailsPopup/index";
import Loader from "../../components/Loader";
import NotFnd from "../../components/NotFound/notFound";
import { Searchfilters } from "../../Store/Actions/userActions";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import { socketChangeInFurnace } from "../../Store/Actions/socketActions";
import Popup from "../../components/Popup";
import PrimaryButton from "../../components/PrimaryButton";
import { addToCartBulk, fetchCartItem } from "../../Store/Actions/cart.actions";
import popupClass from "../../utils/emitPopup";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f8f8f8",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    border: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  body: {
    fontSize: 14,
    paddingTop: "10px",
    paddingBottom: "10px",
    border: "1px solid rgba(224, 224, 224, 1)",
    "&:first-child": {
      // backgroundColor: theme.palette.action.hover,
      fontWeight: "500",
      width: "220px",
      // border: "1px solid red"
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fff",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#e0e0e0",
    },
    // "&:hover": {
    // backgroundColor: "#e9e9e9",
    // },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    borderCollapse: "collapse",
    marginTop: 110,
  },
  container: {
    // maxHeight: 600,
  },
});

const SearchResults = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({
    details: [],
    pop: false,
  });
  const [size, setSize] = useState([]);
  const [itemView, setItemView] = useState(false);
  const [lotListTable, setLotListTable] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [leftVal, setLeftVal] = useState(0);
  const [hoverTable, setHoverTable] = useState();
  const [listData, setListData] = useState([]);
  const [pageWish, setPageWish] = useState(1);
  const [reload, setReload] = useState(false);
  const [addAllToCart, setAddAllToCart] = useState([]);
  const [isBulkAdded, setIsBulKAdded] = useState(false);
  const [addBulkToCartPopupOpen, setAddBulkToCartPopupOpen] = useState(false);
  const listOfSkuItems = useRef([]);
  const lists = useSelector((state) => state.Search.details);
  const nav_name = useSelector((state) => state.Search.nav_name);
  const pageClear = useSelector((state) => state.Search.menuSelect);
  const cartDetails = useSelector((state) => state.cart);
  const loader = useSelector((state) => {
    return state.loader;
  });
  const [loginPageContent, setLoginPageConent] = useState(false);
  const homePageContent = useSelector(
    (state) => state.homepageContent.specialContent
  );
  const tableCell = useRef(null);
  const searchTableFirstRow = useRef(null);
  const infiniteScroll = useRef(null);
  const userInfo = useSelector((state) => state.users.users);
  const refPageWish = useRef(1);
  const listDataRef = useRef(null);
  const refReload = useRef(false);
  const handleChange = (values) => (e) => {
    setState({ ...state, details: values, pop: true });
    // setState({ ...state, pop: true });
  };
  const history = useHistory();
  const changeReload = () => {
    setReload(!refReload.current);
  };
  const onpop = () => {
    setState({ ...state, pop: false });
  };

  const loginContentPageClose = () => {
    setLoginPageConent(!loginPageContent);
    localStorage.setItem("initalpopup", JSON.stringify(0));
  };
  const productPopupButtons = () => {
    return (
      <>
        <div className="ok-close-offer">
          <PrimaryButton onsubmit={loginContentPageClose} btnSize="small">
            Ok
          </PrimaryButton>
        </div>
      </>
    );
  };
  const homePageView = () => {
    return { __html: homePageContent.detail };
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (nav_name == "" || nav_name == "All Fish") {
      sessionStorage.setItem("nav_item", "All Fish");
      dispatch({
        type: "nav_name",
        payload: "All Fish",
      });
    }
    const parms = new URLSearchParams(props.location.search);
    let pagenumber = parms.getAll("page")[0];
    console.log(pagenumber, "pagenumber");
    setPageWish(Number(pagenumber));
    // return () => {
    //   setSize([]);
    //   sessionStorage.setItem("searchValue", JSON.stringify({}));
    //   dispatch({
    //     type: "GET_SEARCH_RESULT",
    //     payload: {},
    //   });
    // };
  }, []);

  useEffect(() => {
    if (homePageContent.status) {
      setLoginPageConent(homePageContent.status);
    }
  }, [homePageContent]);

  useEffect(() => {
    if (pageClear && typeof pageClear !== "undefined" && pageClear) {
      setPageWish(1);
      dispatch({
        type: "MENU_SELECTED",
        payload: false,
      });
    }
  }, [pageClear]);

  useEffect(() => {
    try {
      if (typeof lists.data === "undefined") throw "lists.data is undefined";
      if (!Array.isArray(lists.data)) throw "lists.data is not an array";
      if (lists.data.length === 0) throw "lists.data is  an empty array";
      let skuArr = [];
      lists.data.map((ele) => {
        if (typeof ele.sku_dtls !== "undefined") {
          ele.sku_dtls.map((skd) => {
            if (skuArr.findIndex((skdd) => skdd === skd.size) === -1)
              skuArr.push(skd.size);
          });
        }
      });
      setSize(skuArr);
    } catch (e) {
      console.log(e);
    }
    window.scrollTo(0, 0);
    // if (searchTableFirstRow.current) {
    //   scrollToTop();
    // }
  }, [lists]);
  useEffect(() => {
    refPageWish.current = pageWish;
    listDataRef.current = lists;
    refReload.current = reload;
  });

  useEffect(() => {
    socketChangeInFurnace((data) => {
      // let listOfSku = lists.lists.data.map((ele) => {
      //   return ele.sku;
      // });
      if (Number(data.updated) === 1) {
        if (props && typeof props.location != "undefined") {
          if (
            listDataRef.current &&
            typeof listDataRef.current !== "undefined"
          ) {
            let listData = listDataRef.current.data;
            if (
              listData &&
              typeof listData != "undefined" &&
              Array.isArray(listData)
            ) {
              let variableChanged = false;
              let newSkuArr = [];
              listData.map((eles) => {
                let receivedEle = eles;
                let changedArray = [];
                if (eles.sku_dtls) {
                  eles.sku_dtls.map((ele) => {
                    if (
                      typeof data.new_stock[ele.sku.toString()] !== "undefined"
                    ) {
                      variableChanged = true;
                      ele.quantity_available =
                        data.new_stock[ele.sku.toString()];
                      changedArray.push(ele);
                      return true;
                    } else {
                      changedArray.push(ele);
                      return true;
                    }
                  });
                }
                receivedEle.sku_dtls = changedArray;
                newSkuArr.push(receivedEle);
              });
              if (variableChanged) {
                lists.data = newSkuArr;
                changeReload();
              }
            }
          }
          // let skuAdded = data.sku;
          // let currentPageSkus = listOfSkuItems.current.filter((ele) =>
          //   skuAdded.includes(ele)
          // );
          // if (Boolean(currentPageSkus)) {
          //   let ID = props.location.search.split("=")[1];
          //   let obj = {
          //     sessionKey: userInfo.session_id,
          //     search_value: "",
          //     search_key: "",
          //     size: "25",
          //     page: refPageWish.current,
          //     cid: ID ? ID : "",
          //   };
          //   Searchfilters(obj, dispatch, false, true, true).then((res) => {
          //     if (res.status) {
          //       dispatch({
          //         type: "FETCH_LOAD",
          //         payload: false,
          //       });
          //     }
          //   });
          // }
        }
      }
    });
  }, []);

  useEffect(() => {
    try {
      if (props && typeof props.location != "undefined") {
        if (listDataRef.current && typeof listDataRef.current !== "undefined") {
          let listData = listDataRef.current.data;
          if (
            listData &&
            typeof listData != "undefined" &&
            Array.isArray(listData)
          ) {
            let variableChanged = false;
            let newSkuArr = [];
            listData.map((eles) => {
              let receivedEle = eles;
              let changedArray = [];
              eles.sku_dtls.map((ele) => {
                let cart_data = cartDetails.savedCart.items.filter((item) =>
                  item.sku_dtls.sku.includes(ele.sku.toString())
                );
                if (cart_data.length > 0) {
                  variableChanged = true;
                  ele.cart_qty = cart_data[0].sku_dtls.owned_quantity;
                  changedArray.push(ele);
                  return true;
                } else {
                  ele.cart_qty = 0;
                  changedArray.push(ele);
                  return true;
                }
              });
              receivedEle.sku_dtls = changedArray;
              newSkuArr.push(receivedEle);
            });
            if (variableChanged) {
              lists.data = newSkuArr;
              changeReload();
            }
          }
        }
      }
    } catch (err) {
      console.log(err, "==> this is cart err");
    }
  }, [cartDetails]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    searchTableFirstRow.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    let data = [];
    if (
      lists &&
      typeof lists !== "undefined" &&
      lists.data &&
      lists.data.length > 0 &&
      typeof lists.data !== "undefined"
    ) {
      dispatch({
        type: "LOADING",
        payload: false,
      });
    }
    //   data.push(lists.data);
    //   let dataSkus = lists.data.map((ele) => {
    //     return ele.sku_dtls;
    //   });
    //   let skuDtls = dataSkus.flat().map((ele) => {
    //     return ele.sku;
    //   });
    //   listOfSkuItems.current = skuDtls;
    //   console.log(
    //     dataSkus.flat(),
    //     skuDtls,
    //     "this is the sku from teh search result page"
    //   );
    // }
    // setListData(data.flat());
  }, [lists]);

  const lotPriceCheck = (value) => {
    try {
      if (typeof value.sku_dtls !== "undefined") {
        if (Array.isArray(value.sku_dtls)) {
          if (value.sku_dtls.length) {
            let lottems = value.sku_dtls.filter((ele) => {
              if (
                ele.special_price &&
                parseFloat(ele.retail_price) > parseFloat(ele.special_price)
              ) {
                return true;
              }
              return false;
            });
            if (lottems.length) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    } catch (e) {
      return false;
    }
  };
  const hoverVal = (hoverStatus, index, hoverItem, indexSmall, xValue) => {
    try {
      setHoveredIndex(index);
      setItemView(true);
      setLotListTable(hoverItem.lot_dtls);
      setLeftVal(xValue);
      if (typeof hoverItem.lot_dtls === "undefined")
        throw "hoverItem.lot_dtls is undefined";
      let hovtabite = hoverItem.lot_dtls;
      if (typeof hovtabite !== "undefined") {
        if (Array.isArray(hovtabite) || Object.keys(hovtabite).length)
          if (Array.isArray(hovtabite) && hovtabite.length === 0)
            throw "lotlist table is an atrray and its empty";
        let keysOfQuantity = Object.keys(hovtabite["Quantity"]);
        let keysOfPrice = Object.keys(hovtabite["price"]);
        if (keysOfQuantity.length === keysOfPrice.length) {
          let hovTaArr = [];
          keysOfQuantity.map((ele, index) => {
            let price = "";
            let qty = "";
            if (Array.isArray(hovtabite)) {
              price = hovtabite[indexSmall]["price"][ele];
              qty = hovtabite[indexSmall]["Quantity"][ele];
            } else {
              price = hovtabite["price"][index];
              qty = hovtabite["Quantity"][index];
            }
            hovTaArr.push({
              quantity: qty,
              price: price,
            });
            return ele;
          });
          setHoverTable(hovTaArr);
        } else {
          throw "keysOfQuantity.length === keysOfPrice.length isw not equal";
        }
      } else {
        throw "lotListTable is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  };

  let tdLength = [];
  let maxTd = 0;

  lists.data &&
    !(lists.data.length === 0) &&
    !Boolean(lists.is_live_sale) &&
    lists.data.map((data) => (
      <>
        {tdLength.push(data.sku_dtls.length)}
        {(maxTd = Math.max(...tdLength))}
      </>
    ));
  console.log(window.location.href, "==> locatopnm");

  const wishListPageChange = (event, value) => {
    setPageWish(value);

    if (addAllToCart.length > 0) {
      setAddBulkToCartPopupOpen(true);
      return;
    }

    const parms = new URLSearchParams(props.location.search);
    let ID = parms.getAll("key")[0];
    let searchedValueDetails = sessionStorage.getItem("searchValue");
    searchedValueDetails = JSON.parse(searchedValueDetails);

    let searchKey = parms.getAll("searchKey")[0];
    let searchValue = parms.getAll("searchValue")[0];
    history.push(
      `/search?key=${
        ID && ID !== "undefined" ? ID : ""
      }&page=${value}&searchKey=${
        searchKey && searchKey !== "undefined" ? searchKey : ""
      }&searchValue=${
        searchValue && searchValue !== "undefined" ? searchValue : ""
      }`
    );
    let data = {
      sessionKey: userInfo.session_id,
      search_value: searchValue ? searchValue : "",
      search_key: searchKey ? searchKey : "",
      size:
        nav_name === "All Invertebrates" || nav_name === "All Fish"
          ? "100"
          : "25",
      page: value,
      cid:
        searchValue.length === 0
          ? searchedValueDetails.cid
            ? searchedValueDetails.cid
            : ID
            ? ID
            : ""
          : "",
      key: "",
      sortbyprice: "",
      pricesearch: "",
      main_coralType: "",
    };
    Searchfilters(data, dispatch, true, true, true).then((res) => {
      if (res.status) {
        dispatch({
          type: "FETCH_LOAD",
          payload: false,
        });
      }
    });
  };

  //   page: "1"
  // search_key: "3"
  // search_value: "bicolor"

  const imageError = (e) => {
    return (e.target.src =
      "https://auctionsoftwaremarketplace.com/seadwelling/images/default/NoImageAvailable.jpg");
  };

  const imgUrl = (key) => {
    if (key.inv_item == 1 || key.inv_item == 2) {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/live/${key.image}`;
    } else {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/static/${key.image}`;
    }
  };

  const secondayCallBack = () => {
    addAllToCart[0].items.map((ele) => {
      ele.override = 1;
    });
    console.log("secondayCallBack", addAllToCart);
    AddAllToCartHandler();
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const AddAllToCartHandler = async () => {
    let zeroQty = [];

    var payload = {
      sessionKey: userInfo.session_id,
      bulk: addAllToCart,
    };
    addAllToCart[0].items.map((ele) => {
      if (ele.qty <= 0) {
        zeroQty.push(ele.sku);
      }
    });
    if (zeroQty.length > 0) {
      new popupClass(
        true,
        "Zero Qty choosen on Sku: " + zeroQty.join(","),
        "Added to  your cart",
        "",
        "",
        "",
        "",
        dispatch
      ).emitPopup();
      zeroQty = [];
    } else {
      const result = await addToCartBulk(payload, dispatch);
      if (result.data.status) {
        setAddAllToCart([]);
        setIsBulKAdded(!isBulkAdded);
        if (result.data.flag !== "exist") {
          return fetchCartItem({
            sessionKey: userInfo.session_id,
          })(dispatch);
        } else {
          return Promise.reject();
        }
      } else {
        console.log(result.data.flag);
        if (result.data.flag === "exist") {
          let stringValue = (
            <p>
              The Following Items already is in your Cart, Are you sure you want
              to add more? <br /> {result.data.skuDetail.join(",")}
            </p>
          );

          new popupClass(
            true,
            stringValue,
            "Oops !! Already Available in Cart",
            "No",
            "Yes",
            "ok-popup-app",
            "no-popup-app",
            dispatch,
            undefined,
            secondayCallBack,
            "",
            "already-avail-cart",
            "already-add-action"
          ).emitPopup();
        } else {
          new toastifyClass(
            true,
            result.data.msg && typeof result.data.msg == "string"
              ? result.data.msg
              : result.data.detail && typeof result.data.detail == "string"
              ? result.data.detail
              : "",
            "error",
            dispatch
          ).emittoast();
        }
      }
    }
  };

  useEffect(() => {
    if (addAllToCart.length <= 0 && addBulkToCartPopupOpen) {
      setAddBulkToCartPopupOpen(false);
      wishListPageChange("", pageWish);
    }
  }, [addAllToCart]);

  return (
    <div className="searchResults cstmSrchRslt">
      <>
        <h5 className=" sub-catergorie-name p-3">{nav_name}</h5>
        <div className="paginationAndAddAll">
          <div className="paginationSection">
            {lists.data && !(lists.data.length === 0) ? (
              <Pagination
                page={pageWish}
                className={"mt-2"}
                count={Math.ceil(
                  lists.total_row /
                    (nav_name === "All Invertebrates" || nav_name === "All Fish"
                      ? 100
                      : 25)
                )}
                onChange={wishListPageChange}
              />
            ) : null}
          </div>
          <div className="addAllSection">
            {lists.data && !(lists.data.length === 0) ? (
              <>
                <div className="addAllToCartButton">
                  <PrimaryButton
                    onClick={AddAllToCartHandler}
                    disabled={!(addAllToCart.length > 0)}
                    style={{ height: "30px" }}
                  >
                    Add to Cart
                  </PrimaryButton>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <TableContainer
          component={Paper}
          className={`${classes.container}`}
          ref={searchTableFirstRow}
        >
          {loader.isLoading ? (
            <>
              <Loader />
              {/* <div style={{ height: "100vh" }}>
                <p style={{ marginTop: "3rem" }}>Loading..</p>
              </div> */}
            </>
          ) : lists.data ? (
            <Table
              className={`${classes.table} search-table`}
              stickyHeader
              aria-label="customized table"
            >
              {lists.data &&
              !(lists.data.length === 0) &&
              !Boolean(lists.is_live_sale) ? (
                <TableBody id="furnace" ref={infiniteScroll}>
                  {/* <StyledTableRow ref={searchTableFirstRow}></StyledTableRow> */}
                  {/* This is used to scroll to the top of the table */}
                  {lists.data
                    ? lists.data.map((key, value) =>
                        key.sku_dtls.length > 0 ? (
                          <StyledTableRow>
                            {key.description.includes("***") ? (
                              <StyledTableCell>
                                <div
                                  className="pvTrigger"
                                  style={{
                                    fontSize: "15px",
                                    textAlign: "left",
                                  }}
                                >
                                  <strong>
                                    {key.description
                                      .replace("***", "")
                                      .replace("***", "")}
                                  </strong>
                                </div>
                              </StyledTableCell>
                            ) : (
                              <>
                                <StyledTableCell align="center">
                                  {/* <HtmlTooltip
                                title={
                                  <div className="tableShortInfo">
                                    <img
                                      src={key.image}
                                      onError={imageError}
                                      alt={key.websku}
                                    />
                                    <h3>{key.description}</h3>
                                  </div>
                                }
                              > */}
                                  <Button
                                    className="pvTrigger"
                                    onClick={handleChange(lists.data[value])}
                                  >
                                    {lotPriceCheck(key) ? (
                                      <div style={{ margin: "1px 2px" }}>
                                        {/* <span className="dots">&#10003;</span> */}
                                        <span className="dots saleImg">
                                          <img src="./assets/images/saleBadge.png" />
                                        </span>
                                      </div>
                                    ) : null}
                                    <div className="imageThumbnail">
                                      <img
                                        src={key.image}
                                        onError={imageError}
                                        alt={key.websku}
                                      />
                                    </div>
                                    {key.description}
                                    {/* {lotPriceCheck(key) ? (
                                      <span className="sale-tag">Sale</span>
                                    ) : null} */}
                                  </Button>
                                  {/* </HtmlTooltip> */}
                                </StyledTableCell>

                                {key.sku_dtls.map((value1, index) =>
                                  value1 &&
                                  typeof value1 !== "undefined" &&
                                  value1 !== null ? (
                                    <StyledTableCell
                                      ref={tableCell}
                                      className={`${
                                        props.cart_qty > 0 ? "borderred" : ""
                                      }`}
                                      align="center"
                                      key={"itemToCart" + index}
                                    >
                                      <div id="smallItem">
                                        <ItemSmallView
                                          className="smallitvi"
                                          id={value1.sku}
                                          indexofpar={value}
                                          itemSmall={index}
                                          {...value1}
                                          hoverVal={hoverVal}
                                          rp={value1.retail_price}
                                          sp={
                                            value1.special_price
                                              ? value1.special_price
                                              : ""
                                          }
                                          custLimit={value1.customer_limit}
                                          sku_limit={value1.sku_limit}
                                          websku_limit={value1.websku_limit}
                                          qtyAvbl={value1.quantity_available}
                                          details={value1}
                                          hoverTable={hoverTable}
                                          itemView={itemView}
                                          hoveredIndex={hoveredIndex}
                                          value_={value}
                                          lotPriceCheck={lotPriceCheck}
                                          key_={key}
                                          pageNo={pageWish}
                                          totalListData={lists.data}
                                          addAllToCart={addAllToCart}
                                          setAddAllToCart={setAddAllToCart}
                                          isBulkAdded={isBulkAdded}
                                        />
                                      </div>
                                    </StyledTableCell>
                                  ) : null
                                )}
                                {Array.from(
                                  Array(maxTd - key.sku_dtls.length)
                                ).map(() => (
                                  <StyledTableCell></StyledTableCell>
                                ))}
                              </>
                            )}
                          </StyledTableRow>
                        ) : null
                      )
                    : null}
                </TableBody>
              ) : null}
            </Table>
          ) : (
            <div style={{ height: "100vh" }}>
              <p style={{ marginTop: "3rem" }}>Loading..</p>
            </div>
          )}
        </TableContainer>

        {lists &&
        typeof lists !== "undefined" &&
        lists.data &&
        lists.data.length === 0 ? (
          <NotFnd />
        ) : null}
        {lists.data && !(lists.data.length === 0) ? (
          <>
            <Button
              title="Scroll To Top"
              id="scrollToTopButton"
              onClick={() => scrollToTop()}
            >
              <span className="material-icons">file_upload</span>
            </Button>
          </>
        ) : null}
      </>

      {state.pop && typeof state.details != "undefined" ? (
        <DetailsPopup
          {...props}
          value={state.pop}
          onpop1={onpop}
          details={state.details}
        />
      ) : null}

      <Popup
        open={
          loginPageContent &&
          Boolean(JSON.parse(localStorage.getItem("initalpopup")))
        }
        fullWidth={true}
        maxWidth="sm"
        classNameDialog=""
        classNameDialogTitle=""
        classNameDialogContentText=""
        onClose={loginContentPageClose}
        classNameClose="logincontent-close"
        actions={productPopupButtons()}
      >
        <section className="specialsSection ">
          <div dangerouslySetInnerHTML={homePageView()} />
        </section>
      </Popup>
      <Popup
        open={addBulkToCartPopupOpen}
        onClose={() => setAddBulkToCartPopupOpen(false)}
      >
        There are items marked on this page, would you like to add them to your
        cart?
        <PrimaryButton onClick={() => AddAllToCartHandler()}>
          ADD TO CART
        </PrimaryButton>
        <br />
        <PrimaryButton
          style={{ backgroundColor: "#ff0000" }}
          onClick={() => setAddAllToCart([])}
        >
          PROCEED WITHOUT ADDING TO CART
        </PrimaryButton>
      </Popup>
    </div>
  );
};
export default SearchResults;
