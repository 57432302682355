import React from "react";
import { Snackbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import "./tostifyWrapper.css";
function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: "15rem",
    },
  },
}));

const TostifyWrapper = (props) => {
  const classes = useStyles();

  return (
    <div className="tostify-message">
      <Snackbar
        // className={classes.root}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.open}
        onClose={props.handleClose}
        autoHideDuration={2000}
      >
        {props.children}
      </Snackbar>
    </div>
  );
};

export default TostifyWrapper;
