const initalState = {
  furnaceData: "",
};

export default function (state = initalState, action) {
  switch (action.type) {
    case "INITIAL_FURNACE_TIME":
      return {
        ...state,
        furnaceData: action.payload,
      };
    default:
      return state;
  }
}
