import React, { useState, useEffect } from "react";

import "./MegaMenu.css";
import { ListItem, List } from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Searchfilters,
  featuredProductDetails,
  specialProductDetails,
} from "../../Store/Actions/userActions";
import { FormatListBulletedRounded } from "@material-ui/icons";

const MegaMenu = (props) => {
  let history = useHistory();
  const location = useLocation();
  const [Menu, setMenu] = useState(false);
  const [state, setState] = useState({
    key: "",
    name: "",
  });
  const [clickedHov, setClickedHov] = useState("hover");

  const menues = [useSelector((state) => state.Search.menues)];

  const userInfo = useSelector((state) => state.users.users);
  const nav_name = useSelector((state) => state.Search.nav_name);

  const searchedValueDetails = useSelector(
    (state) => state.Search.searchedValueDetails
  );

  const dispatch = useDispatch();

  const handleMenuOpen = (value, clickOrhover, name) => (e) => {
    sessionStorage.setItem("searchValue", JSON.stringify({}));
    setMenu(true);
    setState({ ...state, key: value, name: name });
    setClickedHov(clickOrhover);
  };

  const handleMenuClose = () => {
    setMenu(false);
  };

  const handlechange = (id, keyNum, name, stockAvl, mainNav) => (e) => {
    console.log("this is called ", id, keyNum, name, stockAvl, mainNav);
    sessionStorage.removeItem("masterLayout");
    sessionStorage.setItem("searchValue", JSON.stringify({}));
    sessionStorage.setItem("nav_name", name);
    sessionStorage.setItem("navNumber", id);
    sessionStorage.setItem("navid", JSON.stringify(keyNum));
    sessionStorage.setItem("mainNav", JSON.stringify(mainNav));
    sessionStorage.setItem("stockAvl", JSON.stringify(stockAvl));
    console.log(mainNav == "The Furnace", "mainNavThe Furnace");
    dispatch({
      type: "NAV_ID",
      payload: keyNum,
    });
    dispatch({
      type: "nav_name",
      payload: name,
    });
    dispatch({
      type: "NAV_SUB_ID",
      payload: id,
    });

    dispatch({
      type: "MENU_SELECTED",
      payload: true,
    });

    let data = {
      sessionKey: userInfo.session_id,
      search_value: "",
      search_key: "",
      size:
        mainNav === "The Furnace" ||
        name === "All Fish" ||
        name === "All Invertebrates"
          ? "100"
          : "25",
      page: "1",
      cid: id,
    };

    if (state.name == "The Furnace") {
      history.push(`/furnance?key=${id}&page=1`);
      dispatch({
        type: "FURNANCE_LOAD_DATA",
        payload: false,
      });
      dispatch({
        type: "MASTERLAYOUT_SKU",
        payload: "",
      });
      dispatch({
        type: "MASTERLAYOUT_PRICE",
        payload: "",
      });
      dispatch({
        type: "MASTERLAYOUT_CORAL_TYPE",
        payload: "",
      });
    } else if (state.name == "Dry Goods") {
      history.push(`/drygoods?key=${id}&page=1`);
    } else {
      history.push(`/search?key=${id}&page=1`);
    }
    Searchfilters(data, dispatch, true, false, true);
  };

  useEffect(() => {
    try {
      if (
        location.pathname === "/allfurnace" ||
        location.pathname === "/search" ||
        location.pathname === "/drygoods" ||
        location.pathname === "/furnance"
      ) {
        if (location && typeof location != "undefined") {
          const parms = new URLSearchParams(location.search);
          let routeName = location.pathname.split("/")[1];
          let ID = parms.getAll("key")[0];
          let pageno = parms.getAll("page")[0];
          console.log(
            location.pathname.split("/"),
            "==> this is the props location"
          );
          let searchedValue = parms.getAll("searchValue")[0];
          let searchedKey = parms.getAll("searchKey")[0];
          console.log(parms.getAll("page"), "==>");
          let obj = {
            sessionKey: userInfo.session_id,
            search_value: searchedValueDetails.search_value
              ? searchedValueDetails.search_value
              : searchedValue
              ? searchedValue
              : "",
            search_key: searchedValueDetails.search_key
              ? searchedValueDetails.search_key
              : searchedKey
              ? searchedKey
              : "",
            size:
              routeName == "furnance" || ID == "143" || ID == "144"
                ? "100"
                : "25",
            page: pageno,
            // size: mainNav == "The Furnace" ? "25" : stockAvl + 3000,
            // page: mainNav == "The Furnace" ? "1" : "",

            cid:
              searchedValue && searchedValue.length !== 0
                ? ""
                : searchedValueDetails.cid
                ? searchedValueDetails.cid
                : ID
                ? ID
                : "",
          };
          console.log(location.search, "this si pros location");
          Searchfilters(obj, dispatch, true, false, true);
          sessionStorage.setItem("searchValue", JSON.stringify(obj));
          dispatch({
            type: "nav_name",
            payload:
              typeof searchedValueDetails !== "undefined" &&
              Object.keys(searchedValueDetails).length !== 0
                ? "Search Results"
                : nav_name,
          });
          dispatch({
            type: "SEARCHED_VALUE",
            payload: {},
          });
        }
      } else if (location.pathname === "/specialProduct") {
        const handleSpecial = () => {
          let featProDtls = {
            sessionKey: userInfo.session_id,
            cid: "",
            search_key: "",
            search_value: "",
            page: 1,
            size: 25,
            is_special: 1,
          };

          specialProductDetails(featProDtls, dispatch);
        };
        handleSpecial();
      } else if (location.pathname === "/featureProduct") {
        const handleFeatured = () => {
          let featProDtls = {
            sessionKey: userInfo.session_id,
            cid: "",
            search_key: "",
            search_value: "",
            page: 1,
            size: 25,
            is_special: 0,
          };
          featuredProductDetails(featProDtls, dispatch);
        };
        handleFeatured();
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const handleSubMenuClose = () => {
    setMenu(false);
  };
  const handleSpecial = () => {
    let featProDtls = {
      sessionKey: userInfo.session_id,
      cid: "",
      search_key: "",
      search_value: "",
      page: 1,
      size: "25",
      is_special: 1,
    };
    sessionStorage.setItem("specialPage", JSON.stringify(featProDtls));

    specialProductDetails(featProDtls, dispatch, true, false, "special");
  };
  useEffect(() => {
    if (
      menues &&
      typeof menues !== "undefined" &&
      menues[0] &&
      typeof menues[0] !== "undefined" &&
      menues[0][state.key] &&
      typeof menues[0][state.key] !== "undefined" &&
      menues[0][state.key].detail &&
      typeof menues[0][state.key].detail !== "undefined"
    ) {
      if (clickedHov === "click") {
        handlechange(
          menues[0][state.key]["detail"][0]["sub_id"],
          state.key,
          menues[0][state.key]["detail"][0]["sub_name"],
          menues[0][state.key]["detail"][0]["stock_available"],
          menues[0][state.key]["name"]
        )({});
      }
    }
  }, [clickedHov]);

  useEffect(() => {
    let sessionValue = {
      sessionKey: userInfo.session_id,
    };
  }, []);

  return (
    <div className="megaMenu">
      <ul className="menuKey d-flex float-right">
        {userInfo?.isFlyer &&
          userInfo?.isFlyer[0] &&
          userInfo?.isFlyer[0]?.flyerenable &&
          userInfo?.isFlyer[0]?.flyerenable == "Y" && (
            <li>
              <Link to="/flyer">NEWS AND PROMOS</Link>
            </li>
          )}
        <li onClick={handleSpecial}>
          <Link to="/specialProduct?page=1">SPECIALS</Link>
        </li>

        {menues &&
        typeof menues !== "undefined" &&
        menues.length > 0 &&
        (menues[0] || []).length > 0 ? (
          <>
            {menues[0] &&
              menues[0].map((ele, index) =>
                ele && typeof ele !== "undefined" && ele.detail.length !== 0 ? (
                  <li
                    onMouseOver={handleMenuOpen(index, "hover", ele.name)}
                    onClick={handleMenuOpen(index, "click", ele.name)}
                    // onMouseOut={Menu ? null : handleSubMenuClose}
                  >
                    {ele.name + "(" + ele.main_stock_available + ")"}
                    <span className="material-icons">expand_more</span>
                  </li>
                ) : null
              )}
          </>
        ) : null}
        <li>
          <Link to="/shippingTracking">SHIPPING TRACKING </Link>
        </li>
        <li>
          <Link to="/helpfulLinks">HELPFUL LINKS </Link>
        </li>
      </ul>
      {Menu ? (
        <div>
          <div className="bckDropWrapper" onMouseOver={handleMenuClose}></div>
          <div
            className={`menuValues ${
              state["key"] === 0 ? "boxChange furnanceClass" : ""
            }`}
            // onMouseLeave={handleMenuClose}
            onClick={() => setTimeout(handleMenuClose, 500)}
          >
            {state.key !== undefined &&
            menues &&
            typeof menues !== "undefined" ? (
              <List component="nav" aria-labelledby="nested-list-subheader">
                {menues[0] &&
                menues[0][state.key] &&
                menues[0][state.key].detail.length !== 0 ? (
                  menues[0] &&
                  menues[0][state.key] &&
                  menues[0][state.key].detail.map((data) => (
                    <ListItem
                      button
                      onClick={handlechange(
                        data.sub_id,
                        state.key,
                        data.sub_name,
                        data.stock_available,
                        menues[0][state.key]["name"]
                      )}
                    >
                      <div id="chevr">
                        <span>
                          {data.sub_name + "(" + data.stock_available + ")"}
                        </span>
                        <span className="material-icons">chevron_right</span>
                      </div>
                    </ListItem>
                  ))
                ) : (
                  <div className="no-result-menu">
                    <p>No result found </p>
                  </div>
                )}
              </List>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default MegaMenu;
