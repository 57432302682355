export const formstate = (editUserStatus) => {
  return {
    checkedValue: [],
    filestaxpath: {
      files: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.filestaxpath
        : "",
      isAuth: false,
    },
    fileftaxpath: {
      files: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.fileftaxpath
        : "",
      isAuth: true,
    },
    business_attachments1: {
      files: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.business_attachments1
        : "",
      isAuth: false,
    },
    business_attachments2: {
      files: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.business_attachments2
        : "",
      isAuth: false,
    },
    business_attachments3: {
      files: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.business_attachments3
        : "",
      isAuth: false,
    },
    business_attachments4: {
      files: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.business_attachments4
        : "",
      isAuth: false,
    },
    retailStores: {
      value: "",
      checked: false,
      isAuth: false,
    },
    onlineRetailer: {
      value: "",
      checked: false,
      isAuth: false,
    },
    serviceMaintenanceBusiness: {
      value: "",
      checked: false,
      isAuth: false,
    },
    educationalInstitution: {
      value: "",
      checked: false,
      isAuth: false,
    },
    publicPrivateAquarium: {
      value: "",
      checked: false,
      isAuth: false,
    },
    refBy: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.refBy
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    busiName: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.busiName
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    address1: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.address1
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    address2: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.address2
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    address3: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.address3
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    country: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.country
        : "",
      error: "This Field is mandatory",
      isAuth: true,
      defaultValue: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.country
        : "",
    },
    city: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.city
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    state: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.state
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    zipCode: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.zipCode
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    phone: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.phone
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    fax: {
      value: editUserStatus.editUserInfo ? editUserStatus.editUserInfo.fax : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    altPhone: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.altPhone
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    email: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.email
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    allWASS: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.allWASS
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    years_in_business: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.years_in_business
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    years_at_current_address: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.years_at_current_address
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    contactPerson: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.contactPerson
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    altPerson: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.altPerson
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    alternatePhone1: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.alternatePhone1
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    ssTax: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.ssTax
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },

    ownerName: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.ownerName
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    homeAddress1: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.homeAddress1
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    homeCity: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.homeCity
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    oState: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.oState
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    oZip: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.oZip
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    oPhone: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.oPhone
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },
    oMobile: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.oMobile
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    federalTax: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.federalTax
        : "",
      error: "This Field is mandatory",
      isAuth: true,
    },

    preferCarr: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.preferCarr
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    airportCode: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.airportCode
        : "",
      error: "This Field is mandatory",
      isAuth: false,
      defaultValue: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.airportCode
        : "",
    },
    createdate: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.createdate
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
    app_type: {
      value: editUserStatus.editUserInfo
        ? editUserStatus.editUserInfo.apptype
        : "",
      error: "This Field is mandatory",
      isAuth: false,
    },
  };
};
