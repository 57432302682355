const initialState = {
  savedInfo: {},
  savedNickName: [],
  savedCartTab: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "SAVED_CART":
      return {
        ...state,
        savedInfo: action.payload,
      };
    case "SAVED_CART_NICKNAME":
      return {
        ...state,
        savedNickName: action.payload,
      };

    case "SAVED_CART_TAB":
      return {
        ...state,
        savedCartTab: action.payload,
      };
    default:
      return state;
  }
}
