import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import InputField from "../Input/InputField";
import PrimaryButton from "../PrimaryButton";
import { user_login, getFlyers } from "../../Store/Actions/userActions";
import { Sitemenu } from "../../Store/Actions/userActions";
import Popup from "../../components/Popup";
import PopupAlert from "../PopupAlert/index";
const Login = () => {
  var OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var nameOffset, verOffset, ix;
  // In Opera, the true version is after "Opera" or after "Version"
  if ((verOffset = nAgt.indexOf("Opera")) != -1) {
    browserName = "Opera";
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
    browserName = "Microsoft Internet Explorer";
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
    browserName = "Chrome";
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
    browserName = "Safari";
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
    browserName = "Firefox";
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if (
    (nameOffset = nAgt.lastIndexOf(" ") + 1) <
    (verOffset = nAgt.lastIndexOf("/"))
  ) {
    browserName = nAgt.substring(nameOffset, verOffset);
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }
  var latlog = "";
  var location = navigator.geolocation.getCurrentPosition(function (position) {
    latlog = position.coords.latitude + ", " + position.coords.longitude;
  });

  let [error, setError] = useState({});
  const [formData, setFormData] = useState({
    username: {
      value: "",
      error: "UserName is required",
    },
    password: {
      value: "",
      error: "Password is required",
    },
    validate: function () {
      let error_ = {};
      Object.keys(this).map((ele) => {
        if (this[ele].value === "") {
          error_[ele] = this[ele]["error"];
        }
      });
      setError(error_);
      return error_;
    },
  });

  const userInfo = useSelector((state) => state.users);
  const userInfoSession = useSelector((state) => state.users.users);

  const alreadyLoggedIn = useSelector((state) => state.users.alreadyLoggedIn);
  // const userInfoLogin = useSelector((state) => state.users.users)
  const dispatch = useDispatch();

  const history = useHistory();
  const [LogginedInOtherBrowser, SetLogginedInOtherBrowser] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let Obj = {
      value: value,
      error: formData[name]["error"],
    };

    setError({
      ...error,
      [name]: "",
    });

    setFormData({
      ...formData,
      [name]: Obj,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(formData.validate()).length === 0) {
      user_login({
        username: formData.username.value,
        password: formData.password.value,
        os: OSName,
        browser: browserName,
        agent: navigator.userAgent,
        location: latlog,
      })(dispatch);
    }
  };

  const onCloseLogginedInOtherBrowser = () => {
    dispatch({
      type: "USER_ALREADY_LOGGED_IN",
      payload: false,
    });
  };
  const redirectLogginedInOtherBrowser = () => {
    user_login({
      username: formData.username.value,
      password: formData.password.value,
      os: OSName,
      browser: browserName,
      agent: navigator.userAgent,
      location: latlog,
      sessionDestory: 1,
    })(dispatch);
    onCloseLogginedInOtherBrowser();
  };

  const LogginedPopupButtons = () => {
    return (
      <>
        <PrimaryButton onsubmit={redirectLogginedInOtherBrowser}>
          Yes
        </PrimaryButton>

        <PrimaryButton onsubmit={onCloseLogginedInOtherBrowser}>
          No
        </PrimaryButton>
      </>
    );
  };

  useEffect(() => {
    if (userInfo.isLogin) {
      dispatch({
        type: "LOGIN_WISHLIST_POPUP",
        payload: true,
      });
      if (
        userInfo &&
        userInfo?.users &&
        userInfo?.users?.isFlyer &&
        userInfo?.users?.isFlyer[0] &&
        userInfo?.users?.isFlyer[0]?.flyerenable &&
        userInfo?.users?.isFlyer[0]?.flyerenable == "Y"
      ) {
        history.push("/flyer");
      } else {
        history.push("/specialProduct?page=1");
      }
    } else {
      history.push("/");
    }
  }, [userInfo.isLogin]);

  // useEffect(() => {
  //   getFlyers({ sessionKey: userInfoLogin.session_id }, dispatch);
  // }, [userInfoLogin.status])

  return (
    <>
      {userInfo.isLogin === false ? (
        <div className="login">
          <form onSubmit={onSubmit}>
            <h2>Returning Customer ?</h2>
            <InputField
              label="USERNAME"
              name="username"
              placeholder="Enter your username"
              type="text"
              id="username"
              value={formData.username.value}
              onChange={handleChange}
            />
            {error.username ? (
              <span style={{ color: "red" }}>{error.username}</span>
            ) : null}
            <InputField
              label="PASSWORD"
              name="password"
              placeholder="Enter your password"
              type="password"
              id="password"
              value={formData.password.value}
              onChange={handleChange}
            />
            {error.password ? (
              <span style={{ color: "red" }}>{error.password}</span>
            ) : null}

            <PrimaryButton type="submit" label="Submit" size="large" />
          </form>
        </div>
      ) : null}

      {alreadyLoggedIn ? (
        <Popup
          fullWidth={true}
          maxWidth={"sm"}
          open={alreadyLoggedIn}
          heading={"User Information"}
          onClose={onCloseLogginedInOtherBrowser}
          actions={LogginedPopupButtons()}
          classNameDialogActions="popup-buttons-checkout"
          classNameDialogTitle="popup-header product-checkout-close-icon"
          classNameDialogContentText="popup-checkout-content"
          classNameDialog="popup-checkout-alert"
        >
          You are already logged in, or you did not log out of your last visit.
          If you Click "Yes", your other session will be disconnected
        </Popup>
      ) : null}
    </>
  );
};
export default React.memo(Login);
