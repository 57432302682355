import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import "./blinkingText.css";

const inner = {
  padding: "5px 15px",
  background: "#ffcece",
  color: "#cc0000",
  fontWeight: 600,
  display: "inline-block",
};

const StyledButton = withStyles({
  root: {
    marginTop: "10px",
    float: "left",
  },
  label: {
    width: "100px",
  },
})(Button);
const BlinkingText = (props) => {
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    blinkerTextOn();
  }, []);
  let timer;
  const blinkerTextOn = () => {
    setValue(props.value);
    timer = setTimeout(blinkerTextOff, 500);
  };
  const blinkerTextOff = () => {
    setValue("\u00A0  ");
    timer = setTimeout(blinkerTextOn, 1000);
  };
  return (
    <StyledButton variant="contained" align="left" style={{ ...inner }}>
      {value}
    </StyledButton>
  );
};

export default BlinkingText;
