import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import "./Input.css";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginBottom: "3em",
      width: "100%",
    },
    "& .MuiInput-underline": {
      paddingBottom: "1em",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#48BF84",
    },
    input: {
      fontFamily: "Ubuntu",
    },
    "label.MuiFormLabel-root.Mui-focused": {
      color: "#48BF84",
      borderColor: "#212121",
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f8f8f8",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const InputField = (props) => {
  const classes = useStyles();

  return (
    <>
      {props.toolTipTitle ? (
        <HtmlTooltip title={props.toolTipTitle}>
          <TextField
            id={props.id}
            label={props.label}
            placeholder={props.placeholder}
            type={props.type}
            size={props.size}
            name={props.name}
            value={props.value}
            onChange={props.onchange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={props.disabled}
            {...props}
          />
        </HtmlTooltip>
      ) : (
        <TextField
          id={props.id}
          label={props.label}
          placeholder={props.placeholder}
          type={props.type}
          size={props.size}
          name={props.name}
          value={props.value}
          onChange={props.onchange}
          variant="outlined"
          fullWidth
          margin="normal"
          disabled={props.disabled}
          {...props}
        />
      )}
    </>
  );
};

export default InputField;
