const initialState = {
  tostifyView: false,
  tostifyMessage: "",
  severity: "",
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "TOSTIFY_MESSAGE":
      return {
        ...state,
        tostifyView: action.payload.tostifyView,
        tostifyMessage: action.payload.tostifyMessage,
        severity: action.payload.severity,
      };
    default:
      return state;
  }
}
