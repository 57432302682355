const initialState = {
  // editSavedCart: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "EDIT_SAVED_CART_ITEM":
      return {
        ...state,
        editSavedCart: action.payload.detail,
        status: action.payload,
      };
    default:
      return state;
  }
}
