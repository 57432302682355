const intialState = {
  newUser: {},
  newCountries: {},
  newRegInfo: {},
  savedStatus: {},
};

export default function (state = intialState, action) {
  switch (action.type) {
    case "NEW_USER":
      return {
        ...state,
        newUser: action.payload,
      };
    case "NEW_COUNTRIES":
      return {
        ...state,
        newCountries: action.payload,
      };
    case "NEW_REGISTERED_INFO":
      return {
        ...state,
        newRegInfo: action.payload,
      };

    case "SAVED_REGISTRATION_STATUS":
      return {
        ...state,
        savedStatus: action.payload,
      };
    default:
      return state;
  }
}
