import { IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Slider from "react-slick/lib/slider";
import MasterLayout from "../MasterLayout";
import "./Flyer.css";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { validateUser } from "../../Store/Actions/userActions";
import Popup from "../../components/Popup";
import PrimaryButton from "../../components/PrimaryButton";

const Flyer = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const [images, setImages] = useState([]);
  const [flyerDesc, setFlyerDesc] = useState("");
  const [loginPageContent, setLoginPageConent] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.users.users);
  const homePageContent = useSelector(
    (state) => state.homepageContent.specialContent
  );

  useEffect(() => {
    if (homePageContent.status) {
      setLoginPageConent(homePageContent.status);
    }
  }, [homePageContent]);

  useEffect(() => {
    if (
      userInfo &&
      userInfo?.isFlyer &&
      userInfo?.isFlyer[0] &&
      userInfo?.isFlyer[0]?.flyerenable &&
      userInfo?.isFlyer[0]?.flyerenable == "Y"
    ) {
      setFlyerDesc(userInfo?.isFlyer[0]?.flyerdesc);
      if (userInfo?.isFlyer[0]?.flyerimages) {
        setImages(userInfo.isFlyer[0].flyerimages.split(";"));
      } else {
        setImages([]);
      }
    } else if (
      userInfo &&
      userInfo?.isFlyer &&
      userInfo?.isFlyer[0] &&
      userInfo?.isFlyer[0]?.flyerenable &&
      userInfo?.isFlyer[0]?.flyerenable == "N"
    ) {
      history.push("/specialProduct?page=1");
    }
  }, [userInfo]);

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      let user = JSON.parse(sessionStorage.getItem("user"));
      if (user?.detail?.username && user?.detail?.logged_in) {
        validateUser(
          {
            username: user.detail.username,
            session_id: user.detail.logged_in,
          },
          dispatch
        );
      }
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const loginContentPageClose = () => {
    setLoginPageConent(!loginPageContent);
    localStorage.setItem("initalpopup", JSON.stringify(0));
  };
  const productPopupButtons = () => {
    return (
      <>
        <div className="ok-close-offer">
          <PrimaryButton onsubmit={loginContentPageClose} btnSize="small">
            Ok
          </PrimaryButton>
        </div>
      </>
    );
  };
  const homePageView = () => {
    return { __html: homePageContent.detail };
  };

  // let images = [
  //   {
  //     img: 'https://images.unsplash.com/photo-1558822551-1f02f13efe71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1550016728-6e898923de4c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2961&q=80',
  //   },
  //   {
  //     img: 'https://images.unsplash.com/photo-1630771077377-674b39a13f58?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2970&q=80',
  //   },
  // ];

  return (
    <MasterLayout {...props}>
      <div className="extPadWrpr">
        <div className="flyrContainer container">
          <div className="topSdCntnr d-flex align-items-center justify-content-center">
            {/* <IconButton>
              <span className="material-icons">chevron_left</span>
            </IconButton> */}
            <p className="crntSldrTtle m-0">{flyerDesc}</p>
            {/* <IconButton>
              <span className="material-icons">chevron_right</span>
            </IconButton> */}
          </div>
          <div className="crslCntnr">
            <Slider {...settings}>
              {images.map((data, index) => (
                <div>
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/uploads/weekly_flyer/thumb/${data}`}
                    alt={"img_" + index}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <Popup
        open={
          loginPageContent &&
          Boolean(JSON.parse(localStorage.getItem("initalpopup")))
        }
        fullWidth={true}
        maxWidth="sm"
        classNameDialog=""
        classNameDialogTitle=""
        classNameDialogContentText=""
        onClose={loginContentPageClose}
        classNameClose="logincontent-close"
        actions={productPopupButtons()}
      >
        <section className="specialsSection ">
          <div dangerouslySetInnerHTML={homePageView()} />
        </section>
      </Popup>
    </MasterLayout>
  );
};

export default Flyer;
