import React, { useEffect, useState, useRef } from "react";
import { ArrowBack, ContactSupportRounded } from "@material-ui/icons";
import { Container, IconButton, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import "./NewCustomerInformation.css";
import PrimaryButton from "../PrimaryButton";
import CustomCheckBox from "../CustomCheckBox";
import InputField from "../Input/InputField";
import SelectField from "../Input/SelectField";
import { getCountries, submitNewUser } from "../../Store/Actions/userActions";
import EditExistingInformationMailPopup from "../EditExistingInfoMailPopup";

const userInitialState = {
  checkedValue: [],
  filestaxpath: {
    files: {},
    isAuth: false,
  },
  fileftaxpath: {
    files: {},
    isAuth: true,
  },
  uploadproof1: {
    files: {},
    isAuth: true,
  },
  uploadproof2: {
    files: {},
    isAuth: true,
  },
  uploadproof3: {
    files: {},
    isAuth: true,
  },
  uploadproof4: {
    files: {},
    isAuth: true,
  },
  retailStores: {
    value: "",
    checked: false,
    isAuth: false,
  },
  onlineRetailer: {
    value: "",
    checked: false,
    isAuth: false,
  },
  serviceMaintenanceBusiness: {
    value: "",
    checked: false,
    isAuth: false,
  },
  educationalInstitution: {
    value: "",
    checked: false,
    isAuth: false,
  },
  publicPrivateAquarium: {
    value: "",
    checked: false,
    isAuth: false,
  },
  terms: {
    value: "",
    checked: false,
    isAuth: false,
  },
  refBy: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  busiName: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  address1: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  address2: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  address3: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  country: {
    value: "USA",
    error: "This Field is mandatory",
    isAuth: true,
    defaultValue: "",
  },
  city: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  state: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  zipCode: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  phone: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  fax: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  altPhone: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  email: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  allWASS: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  contactPerson: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  altPerson: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  alternatePhone1: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  ssTax: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  years_in_business: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  years_at_current_address: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  ownerName: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  homeAddress1: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  homeCity: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  oState: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  oZip: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  oPhone: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },
  oMobile: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  federalTax: {
    value: "",
    error: "This Field is mandatory",
    isAuth: true,
  },

  preferCarr: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
  airportCode: {
    value: "",
    error: "This Field is mandatory",
    isAuth: false,
  },
};

const NewCustomerInformation = (props) => {
  const classes = props;
  // const theme = useTheme();

  const [newUser, setNewUser] = useState(userInitialState);

  const [userError, setUserError] = useState({});

  const [validationErr, setValidationErr] = useState({});

  const [editExistingPopup, setEditExistingPopup] = useState(false);

  const [terms, setTerms] = useState([]);

  const [termsconditionModal, setTermsconditionModal] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false);

  const [isTermChecked, setIsTermChecked] = useState(false);

  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();

  const history = useHistory();

  const countries = useSelector((state) => state.newRegistration.newCountries);

  const userInfo = useSelector((state) => state.users);

  const registrationStatus = useSelector(
    (state) => state.newRegistration.savedStatus
  );

  useEffect(() => {
    if (countries?.terms) {
      setTerms(countries?.terms);
    }
  }, [countries.terms]);

  const countryInfo = useSelector((state) => state.newRegistration.newRegInfo);
  const onUserInfoChange = (e) => {
    let name = e.target.name;
    let type = e.target.type;
    switch (type) {
      case "file":
        let file = e.target.files[0];
        let Objts = {
          files: file,
          isAuth: newUser[name]["isAuth"],
        };
        setNewUser({
          ...newUser,
          [name]: Objts,
        });

        if (file) {
          setUserError({ ...userError, [name]: "" });
        }

        break;

      default:
        let value = e.target.value;

        let Obj = {
          value,
          error: newUser[name]["error"],
        };

        if (newUser[name].hasOwnProperty("checked")) {
          Obj["checked"] = !newUser[name]["checked"];
          if (Obj["checked"] === true) {
            newUser.checkedValue.push(Obj.value);
          } else if (Obj["checked"] === false) {
            let copyCheckedValue = newUser.checkedValue;
            let checkedValueIndex = copyCheckedValue.indexOf(Obj.value);
            copyCheckedValue.splice(checkedValueIndex, 1);
            setNewUser({ checkedValue: copyCheckedValue });
          }
        }

        setNewUser({
          ...newUser,
          [name]: Obj,
        });

        setValidationErr({
          ...validationErr,
          [name]: "",
        });

        if (type === "checkbox") {
          if (newUser.checkedValue.length > 0) {
            setUserError({ ...userError, checkedValue: "" });
          }
        } else if (newUser[name].value == "") {
          let newErrState = userError;
          delete newErrState[name];
          setUserError({
            ...userError,
            [name]: "",
          });
        }
        break;
    }
  };

  const detailUsa = () => {
    if (newUser.country) {
      if (newUser.country.value === "USA") {
        return { __html: terms[0] ? terms[0].terms : null };
      } else {
        return { __html: terms[1] ? terms[1].terms : null };
      }
    } else {
      return { __html: terms[0] ? terms[0].terms : null };
    }
  };

  const validate = () => {
    let err = {};
    Object.keys(newUser).map((user) => {
      if (Array.isArray(newUser[user])) {
        if (newUser[user].length === 0) {
          err[user] = "Please select the field";
        }
      } else if (
        user === "fileftaxpath" &&
        newUser[user].isAuth &&
        Object.keys(newUser[user].files).length === 0 &&
        newUser[user].files.constructor === Object
      ) {
        err[user] = "Please select the file";
      } else if (newUser[user].value === "" && newUser[user].isAuth) {
        err[user] = newUser[user]["error"];
      }
    });

    setUserError(err);
    return err;
  };

  const VaildationFormat = () => {
    let valErr = {};
    Object.keys(newUser).map((property) => {
      if (
        property === "phone" ||
        property === "fax" ||
        property === "altPhone" ||
        property === "oPhone" ||
        property === "oMobile"
      ) {
        if (!(newUser[property].value === ""))
          if (newUser[property].value.length < 10) {
            valErr[property] = "Phone number should be minimum 10 digits";
          } else if (newUser[property].value.length > 15) {
            valErr[property] = "Phone number should be maximum 15 digits only";
          }
      } else if (property === "email") {
        const emailvalid =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!emailvalid.test(newUser[property].value))
          valErr[property] = "Please enter the valid email address";
      } else if (property === "oZip" || property === "zipCode") {
        if (newUser[property].value.length < 5) {
          valErr[property] = "Zip code should be minimum 5 digits";
        } else if (newUser[property].value.length > 13) {
          valErr[property] = "Zip code should be maximum 13 digits only";
        }
      }
      // else if (
      //   property === "busiName" ||
      //   property === "contactPerson" ||
      //   property === "altPerson" ||
      //   property === "ownerName"
      // ) {
      //   const textFormat = /^[a-zA-Z\s]*$/;
      //   if (!textFormat.test(newUser[property].value))
      //     valErr[property] = "Enter the correct name";
      // }
    });

    setValidationErr(valErr);

    return valErr;
  };

  const convertIntoFormDqata = (registerData) => {
    let formData = new FormData();
    Object.keys(registerData).map((reg, regIndex) => {
      if (
        reg === "filestaxpath" ||
        reg === "fileftaxpath" ||
        reg === "uploadproof1" ||
        reg === "uploadproof2" ||
        reg === "uploadproof3" ||
        reg === "uploadproof4"
      ) {
        if (registerData[reg]["files"] instanceof File) {
          formData.append(reg, registerData[reg]["files"]);
        } else {
          formData.append(reg, "");
        }
      } else if (reg === "checkedValue")
        formData.append("retailStore", registerData[reg].toString());
      else formData.append(reg, registerData[reg].value);
    });
    return formData;
  };

  const resetUserValue = () => {
    let resetValue = JSON.stringify(userInitialState);
    let conveterdResetValue = JSON.parse(resetValue);
    setNewUser({ ...userInitialState, conveterdResetValue });
    let airportCode = document.getElementById("airportCode");
    let country = document.getElementById("country");

    airportCode.defaultValue = "";
    country.defaultValue = "";
  };

  const onUserInfoSubmit = async (e) => {
    e.preventDefault();
    setIsSubmit(true);
    const formDataObj = convertIntoFormDqata(newUser);
    if (Object.keys(validate()).length === 0) {
      if (Object.keys(VaildationFormat()).length === 0) {
        if (termsChecked) {
          dispatch({
            type: "NEW_REGISTERED_INFO",
            payload: newUser,
          });
          submitNewUser(formDataObj, dispatch).then((res) => {
            if (res.data.status === true || res.data.status === false) {
              dispatch({
                type: "SAVED_REGISTRATION_STATUS",
                payload: res.data,
              });
            }

            return Promise.resolve(res);
          });
        } else {
          setIsTermChecked(false);
        }
      }
    }
  };
  useEffect(() => {
    getCountries(dispatch);
  }, []);
  useEffect(() => {
    let errorsElements = document.getElementsByClassName("error-theme");
    if (
      errorsElements &&
      typeof errorsElements != "undefined" &&
      errorsElements.length
    ) {
      let firstErrElement = errorsElements[0];
      firstErrElement.parentElement.parentElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [userError]);

  // useEffect(() => {
  //   if (userInfo.isLogin) {
  //     history.push({
  //       pathname: "/newcustomer",
  //       state: { from: this.props.location.pathname },
  //     });
  //   }
  // }, [userInfo.isLogin]);
  useEffect(() => {
    if (Object.keys(registrationStatus).length && !userInfo.isLogin) {
      if (registrationStatus.status) {
        resetUserValue();
        let tostifyValue = {};
        tostifyValue["tostifyView"] = true;
        tostifyValue["severity"] = "success";
        tostifyValue["tostifyMessage"] = "Registration completed successfully";
        dispatch({
          type: "TOSTIFY_MESSAGE",
          payload: tostifyValue,
        });
        history.push("/thankyou");
      } else if (
        typeof registrationStatus.status != "undefined" &&
        !registrationStatus.status
      ) {
        let tostifyValue = {};
        tostifyValue["tostifyView"] = true;
        tostifyValue["severity"] = "error";
        tostifyValue["tostifyMessage"] = registrationStatus.detail[0];
        dispatch({
          type: "TOSTIFY_MESSAGE",
          payload: tostifyValue,
        });
      }
    }
  }, [registrationStatus]);

  /* Change the existing user information */

  const onEditExistingPopupClose = () => {
    setEditExistingPopup(false);
  };

  const onEditExistingPopupOpen = () => {
    setEditExistingPopup(true);
  };
  const uploadLiscenceFile = () => {
    document.getElementById("fileftaxpath").click();
  };

  const uploadProof = (id) => {
    document.getElementById(id).click();
  };
  return (
    <div className="sdHome">
      <div className="bnrBack">
        <div className="sdhBanner">
          <div className="sdhLogo">
            <img
              src="./assets/images/logo_home.png"
              alt="Seadwelling logo"
            ></img>
          </div>
        </div>
      </div>
      <Container>
        <div className="new-customer-information pt-5">
          <div className="heading  py-3 ">
            <p className=" heading-text text-white text-left mx-3 my-0">
              New Customer Information
              <IconButton className="float-right  p-0">
                <Link to="/">
                  <ArrowBack className="text-white heading-arrow my-1" />
                </Link>
              </IconButton>
            </p>
          </div>
        </div>
        <div style={{ textAlign: "start" }}>
          <p>
            <b>SDC DOES NOT SELL TO HOBBYISTS OR END USERS.</b> If you are a
            consumer, and would like information on how to purchase our
            products, feel free to contact us{" "}
            <a href="mailto:storelocator@seadwelling.com">
              storelocator@seadwelling.com
            </a>{" "}
            for information on a retail store in your area.
          </p>

          <p>To qualify as an account, you must:</p>

          <ul>
            <li>
              Possess a valid business license in the city, county or state
              where you conduct business;
            </li>
            <li>
              Be a "re-seller" who conducts business from a commercial
              environment (non-residential) OR have a "viable" web presence with
              products listed for sale (testing may be conducted). Note: Selling
              on social media or via blogs alone, may not qualify you as a
              "viable" web presence.  Selling at "Swaps" only , may not
              qualify.  "Service Companies" must provide proof of business,
              including "commercial equipment".
            </li>
            <li>CALIFORNIA CUSTOMERS must possess a re-sellers license.</li>
          </ul>
        </div>
        <div className="business-information d-flex justify-content-between my-3">
          <p className="business-text my-2">Business Information</p>
          <PrimaryButton
            type="button"
            size="small"
            className="business-button edit-exist-info"
            onsubmit={onEditExistingPopupOpen}
          >
            Edit Existing Application
          </PrimaryButton>
        </div>
        <EditExistingInformationMailPopup
          popupOpen={editExistingPopup}
          popupClose={onEditExistingPopupClose}
        />
        <form onSubmit={onUserInfoSubmit}>
          <div className="business-types">
            <p className="text-left">
              Types of Business: <span className="asterk">&nbsp;*</span>
            </p>
            <div className="float-left">
              <CustomCheckBox
                label="Retail Store"
                value="retailStores"
                name="retailStores"
                onclick={onUserInfoChange}
                checked={newUser.retailStores.checked}
              />
              <CustomCheckBox
                label="Online Retailer"
                value="onlineRetailer"
                name="onlineRetailer"
                onclick={onUserInfoChange}
                checked={newUser.onlineRetailer.checked}
              />
              <CustomCheckBox
                label="Service Maintenance Business"
                value="serviceMaintenanceBusiness"
                name="serviceMaintenanceBusiness"
                onclick={onUserInfoChange}
                checked={newUser.serviceMaintenanceBusiness.checked}
              />
              <CustomCheckBox
                label="Educational Institution"
                value="educationalInstitution"
                name="educationalInstitution"
                onclick={onUserInfoChange}
                checked={newUser.educationalInstitution.checked}
              />
              <CustomCheckBox
                label="Public Aquarium/Zoo"
                value="publicPrivateAquarium"
                name="publicPrivateAquarium"
                onclick={onUserInfoChange}
                checked={newUser.publicPrivateAquarium.checked}
              />

              {userError.checkedValue ? (
                <div>
                  <p className="error-theme">{userError.checkedValue}</p>
                </div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between flex-wrap w-100 "></div>
          <div className="owner-info mt-3">
            {/* <p className="text-left">Owner Information</p> */}
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  // placeholder="Referred by:(Name of Magazine, Company, Trade show or Other) "
                  name="refBy"
                  size="medium"
                  type="text"
                  id="refBy"
                  value={newUser.refBy.value}
                  onChange={onUserInfoChange}
                  label={
                    <span>
                      Referred by:(Name of Magazine, Company, Trade show or
                      Other)
                      {/* <span className="asterk">&nbsp;*</span> */}
                    </span>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.refBy ? (
                  <span className="error-theme">{userError.refBy}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Business Name
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Business Name"
                  name="busiName"
                  size="medium"
                  type="text"
                  id="busiName"
                  value={newUser.busiName.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.busiName ? (
                  <span className="error-theme">{userError.busiName}</span>
                ) : null}
                {validationErr.busiName ? (
                  <span className="error-theme">{validationErr.busiName}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="Address 1"
                  name="address1"
                  size="medium"
                  type="text"
                  id="address1"
                  label={
                    <span>
                      Address 1<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.address1.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.address1 ? (
                  <span className="error-theme">{userError.address1}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Address 2"
                  // placeholder="Address 2"
                  name="address2"
                  size="medium"
                  type="text"
                  id="address2"
                  value={newUser.address2.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.address2 ? (
                  <span className="error-theme">{userError.address2}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Address 3"
                  // placeholder="Address 3"
                  name="address3"
                  size="medium"
                  type="text"
                  id="address3"
                  value={newUser.address3.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.address3 ? (
                  <span className="error-theme">{userError.address3}</span>
                ) : null}
              </div>
              <div className="input-box">
                <SelectField
                  name="country"
                  label={
                    <span>
                      Country<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  id="country"
                  pc_onchange={onUserInfoChange}
                  // value={newUser.country.value}
                  defaultValue={newUser.country.defaultValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value="">Select Country</option>
                  {countries.country
                    ? countries.country.map((c, index) => (
                        <option
                          key={index}
                          value={c.ccode}
                          selected={c.ccode === "USA" ? true : false}
                        >
                          {c.cname}
                        </option>
                      ))
                    : null}
                </SelectField>
                {userError.country ? (
                  <span className="error-theme">{userError.country}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="City"
                  name="city"
                  size="medium"
                  type="text"
                  id="city"
                  label={
                    <span>
                      City<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.city.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.city ? (
                  <span className="error-theme">{userError.city}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="State"
                  name="state"
                  size="medium"
                  type="text"
                  id="state"
                  label={
                    <span>
                      State<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.state.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.state ? (
                  <span className="error-theme">{userError.state}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="Zip Code"
                  name="zipCode"
                  size="medium"
                  type="text"
                  id="zipCode"
                  label={
                    <span>
                      Zip Code<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.zipCode.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.zipCode ? (
                  <span className="error-theme">{userError.zipCode}</span>
                ) : null}

                {validationErr.zipCode ? (
                  <span className="error-theme">{validationErr.zipCode}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="Main Telephone"
                  name="phone"
                  size="medium"
                  type="number"
                  id="phone"
                  label={
                    <span>
                      Main Telephone<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  inputProps={{ minLength: 10, maxLength: 15 }}
                  value={newUser.phone.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.phone ? (
                  <span className="error-theme">{userError.phone}</span>
                ) : null}

                {validationErr.phone ? (
                  <span className="error-theme">{validationErr.phone}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Fax"
                  // placeholder="Fax"
                  name="fax"
                  size="medium"
                  type="number"
                  id="fax"
                  value={newUser.fax.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.fax ? (
                  <span className="error-theme">{userError.fax}</span>
                ) : null}

                {validationErr.fax ? (
                  <span className="error-theme">{validationErr.fax}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Alt.Phone"
                  // placeholder="Alt.Phone"
                  name="altPhone"
                  size="medium"
                  type="number"
                  id="altPhone"
                  value={newUser.altPhone.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.altPhone ? (
                  <span className="error-theme">{userError.altPhone}</span>
                ) : null}
                {validationErr.altPhone ? (
                  <span className="error-theme">{validationErr.altPhone}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="Email"
                  name="email"
                  size="medium"
                  type="text"
                  id="email"
                  label={
                    <span>
                      Email<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.email.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.email ? (
                  <span className="error-theme">{userError.email}</span>
                ) : null}
                {validationErr.email ? (
                  <span className="error-theme">{validationErr.email}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="Please list all Websites and Social Media Sites"
                  name="allWASS"
                  size="medium"
                  type="text"
                  label={
                    <span>
                      Please list all Websites and Social Media Sites
                      {/* <span className="asterk">&nbsp;*</span> */}
                    </span>
                  }
                  id="allWASS"
                  value={newUser.allWASS.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.allWASS ? (
                  <span className="error-theme">{userError.allWASS}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="Contact Person(s)"
                  name="contactPerson"
                  size="medium"
                  type="text"
                  id="contactPerson"
                  value={newUser.contactPerson.value}
                  onChange={onUserInfoChange}
                  label={
                    <span>
                      Contact Person(s)
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.contactPerson ? (
                  <span className="error-theme">{userError.contactPerson}</span>
                ) : null}
                {validationErr.contactPerson ? (
                  <span className="error-theme">
                    {validationErr.contactPerson}
                  </span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Alt.Contact Name(s)"
                  // placeholder="Alt.Contact Name(s)"
                  name="altPerson"
                  size="medium"
                  type="text"
                  id="altPerson"
                  value={newUser.altPerson.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.altPerson ? (
                  <span className="error-theme">{userError.altPerson}</span>
                ) : null}
                {validationErr.altPerson ? (
                  <span className="error-theme">{validationErr.altPerson}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Alternate Phone No"
                  // placeholder="Alternate Phone No"
                  name="alternatePhone1"
                  size="medium"
                  type="text"
                  id="alternatePhone1"
                  value={newUser.alternatePhone1.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.alternatePhone1 ? (
                  <span style={{ color: "red" }}>
                    {userError.alternatePhone1}
                  </span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  label="Years in Business"
                  // placeholder="Alternate Phone No"
                  name="years_in_business"
                  size="medium"
                  type="text"
                  id="years_in_business"
                  value={newUser.years_in_business.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.years_in_business ? (
                  <span style={{ color: "red" }}>
                    {userError.years_in_business}
                  </span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  label="Years at Current Address"
                  // placeholder="Alternate Phone No"
                  name="years_at_current_address"
                  size="medium"
                  type="text"
                  id="years_at_current_address"
                  value={newUser.years_at_current_address.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.years_at_current_address ? (
                  <span style={{ color: "red" }}>
                    {userError.years_at_current_address}
                  </span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  label="*(California Customers) State Sales Tax Resale #: (Must include copy)"
                  // placeholder="*(California Customers) State Sales Tax Resale #: (Must include copy)"
                  name="ssTax"
                  size="medium"
                  type="text"
                  id="ssTax"
                  value={newUser.ssTax.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.ssTax ? (
                  <span className="error-theme">{userError.ssTax}</span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <input
                  name="filestaxpath"
                  size="medium"
                  type="file"
                  id="filestaxpath"
                  onChange={onUserInfoChange}
                  className="filestaxpath"
                />
                {userError.filestaxpath ? (
                  <span className="error-theme">{userError.filestaxpath}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="owner-info mt-3">
            <p className="text-left">Owner Information</p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  // placeholder="Owner(s) Name"
                  name="ownerName"
                  size="medium"
                  type="text"
                  id="ownerName"
                  label={
                    <span>
                      Owner(s) Name
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.ownerName.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.ownerName ? (
                  <span className="error-theme">{userError.ownerName}</span>
                ) : null}
                {validationErr.ownerName ? (
                  <span className="error-theme">{validationErr.ownerName}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="Home Address 1"
                  name="homeAddress1"
                  size="medium"
                  type="text"
                  id="homeAddress1"
                  label={
                    <span>
                      Home Address 1<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.homeAddress1.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.homeAddress1 ? (
                  <span className="error-theme">{userError.homeAddress1}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="City"
                  name="homeCity"
                  size="medium"
                  type="text"
                  id="homeCity"
                  label={
                    <span>
                      City<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.homeCity.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.homeCity ? (
                  <span className="error-theme">{userError.homeCity}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="State"
                  name="oState"
                  size="medium"
                  type="text"
                  id="oState"
                  label={
                    <span>
                      State<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.oState.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.oState ? (
                  <span className="error-theme">{userError.oState}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  // placeholder="Zip"
                  name="oZip"
                  size="medium"
                  type="text"
                  id="oZip"
                  label={
                    <span>
                      Zip<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.oZip.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.oZip ? (
                  <span className="error-theme">{userError.oZip}</span>
                ) : null}

                {validationErr.oZip ? (
                  <span className="error-theme">{validationErr.oZip}</span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  // placeholder="Home Phone"
                  name="oPhone"
                  size="medium"
                  type="number"
                  id="oPhone"
                  label={
                    <span>
                      Home Phone<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.oPhone.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.oPhone ? (
                  <span className="error-theme">{userError.oPhone}</span>
                ) : null}
                {validationErr.oPhone ? (
                  <span className="error-theme">{validationErr.oPhone}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Cell Phone"
                  // placeholder="Cell Phone"
                  name="oMobile"
                  size="medium"
                  type="number"
                  id="oMobile"
                  value={newUser.oMobile.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.oMobile ? (
                  <span className="error-theme">{userError.oMobile}</span>
                ) : null}

                {validationErr.oMobile ? (
                  <span className="error-theme">{validationErr.oMobile}</span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  // placeholder="Business License or Federal Tax ID#: (Must include copy)"
                  name="federalTax"
                  size="medium"
                  type="text"
                  id="federalTax"
                  label={
                    <span>
                      Business License or Federal Tax ID#: (Must include copy)
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  value={newUser.federalTax.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.federalTax ? (
                  <span className="error-theme">{userError.federalTax}</span>
                ) : null}
              </div>{" "}
              <div className="upload-box my-3">
                <div className="d-flex justify-content-between">
                  <PrimaryButton
                    type="button"
                    btnSize="small"
                    onsubmit={uploadLiscenceFile}
                    className="p-0 business-button  fileftaxpath float-left mx-4"
                  >
                    Upload Licence
                    <input
                      name="fileftaxpath"
                      size="small"
                      type="file"
                      id="fileftaxpath"
                      onChange={onUserInfoChange}
                      style={{ display: "none", width: "50%" }}
                    />
                  </PrimaryButton>
                  <span
                    style={{ color: "red", fontSize: "15px" }}
                    className="mx-2 "
                  >
                    *
                  </span>
                  {newUser.fileftaxpath.files.name ? (
                    <span className="py-2 file-choosen ">
                      {" "}
                      {newUser.fileftaxpath.files.name}{" "}
                    </span>
                  ) : (
                    <span className="py-2 file-choosen">
                      {"No file chosen"}
                    </span>
                  )}
                </div>
                {userError.fileftaxpath ? (
                  <span className="error-theme mx-4 my-4">
                    {userError.fileftaxpath}
                  </span>
                ) : null}
                {validationErr.fileftaxpath ? (
                  <span className="error-theme">
                    {validationErr.fileftaxpath}
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="shipping-info mt-3">
            <p className="text-left">Shipping Information</p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  label="Preferred Carrier(Airline, Door-to-Door or Trucking)"
                  // placeholder="Preferred Carrier(Airline, Door-to-Door or Trucking)"
                  name="preferCarr"
                  size="medium"
                  type="text"
                  id="preferCarr"
                  value={newUser.preferCarr.value}
                  onChange={onUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {userError.preferCarr ? (
                  <span className="error-theme">{userError.preferCarr}</span>
                ) : null}
              </div>

              <div className="input-box">
                <SelectField
                  name="airportCode"
                  value={newUser.airportCode.value}
                  id="airportCode"
                  pc_onchange={onUserInfoChange}
                  defaultValue={newUser.airportCode.defaultValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Airport Code"
                >
                  <option value="">Select Airport Code</option>
                  {countries.aitport_code
                    ? countries.aitport_code.map((a, index) => (
                        <option key={index} value={a.airport_code}>
                          {a.airport_code}
                        </option>
                      ))
                    : null}
                </SelectField>
                {userError.airportCode ? (
                  <span className="error-theme">{userError.airportCode}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="shipping-info mt-3">
            <p className="text-left">Additional Information</p>
            <p className="text-left" style={{ fontSize: "14px" }}>
              Please provide up to 4 additional images or documents as proof of
              business. So that we may process your application as quickly as
              possible.{" "}
              <strong>Suggested images or documents to upload include:</strong>{" "}
              Photo of your Service Vehicle with Signage or wrap, Photo of
              commercial Service Equipment inside of vehicle, Photo of outside
              and inside your retail facility,  Photo of Business checking
              account or Credit Card.
            </p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="upload-box my-3">
                <div className="d-flex justify-content-between">
                  <PrimaryButton
                    type="button"
                    btnSize="small"
                    onsubmit={() => uploadProof("uploadproof1")}
                    className="p-0 business-button  fileftaxpath float-left mx-4"
                  >
                    Upload proof 1
                    <input
                      name="uploadproof1"
                      size="small"
                      type="file"
                      id="uploadproof1"
                      onChange={onUserInfoChange}
                      style={{ display: "none", width: "50%" }}
                    />
                  </PrimaryButton>
                  <span
                    style={{ color: "red", fontSize: "15px" }}
                    className="mx-2 "
                  >
                    {/* * */}
                  </span>
                  {newUser.uploadproof1.files.name ? (
                    <span className="py-2">
                      {" "}
                      {newUser.uploadproof1.files.name}{" "}
                    </span>
                  ) : (
                    <span className="py-2">{"No file chosen"}</span>
                  )}
                </div>
                {userError.uploadproof1 ? (
                  <span className="error-theme mx-4 my-4">
                    {userError.uploadproof1}
                  </span>
                ) : null}
                {validationErr.uploadproof1 ? (
                  <span className="error-theme">
                    {validationErr.uploadproof1}
                  </span>
                ) : null}
              </div>
              <div className="upload-box my-3">
                <div className="d-flex justify-content-between">
                  <PrimaryButton
                    type="button"
                    btnSize="small"
                    onsubmit={() => uploadProof("uploadproof2")}
                    className="p-0 business-button  fileftaxpath float-left mx-4"
                  >
                    Upload proof 2
                    <input
                      name="uploadproof2"
                      size="small"
                      type="file"
                      id="uploadproof2"
                      onChange={onUserInfoChange}
                      style={{ display: "none", width: "50%" }}
                    />
                  </PrimaryButton>
                  <span
                    style={{ color: "red", fontSize: "15px" }}
                    className="mx-2 "
                  >
                    {/* * */}
                  </span>
                  {newUser.uploadproof2.files.name ? (
                    <span className="py-2">
                      {" "}
                      {newUser.uploadproof2.files.name}{" "}
                    </span>
                  ) : (
                    <span className="py-2">{"No file chosen"}</span>
                  )}
                </div>
                {userError.uploadproof2 ? (
                  <span className="error-theme mx-4 my-4">
                    {userError.uploadproof2}
                  </span>
                ) : null}
                {validationErr.uploadproof2 ? (
                  <span className="error-theme">
                    {validationErr.uploadproof2}
                  </span>
                ) : null}
              </div>
              <div className="upload-box my-3">
                <div className="d-flex justify-content-between">
                  <PrimaryButton
                    type="button"
                    btnSize="small"
                    onsubmit={() => uploadProof("uploadproof3")}
                    className="p-0 business-button  fileftaxpath float-left mx-4"
                  >
                    Upload proof 3
                    <input
                      name="uploadproof3"
                      size="small"
                      type="file"
                      id="uploadproof3"
                      onChange={onUserInfoChange}
                      style={{ display: "none", width: "50%" }}
                    />
                  </PrimaryButton>
                  <span
                    style={{ color: "red", fontSize: "15px" }}
                    className="mx-2 "
                  >
                    {/* * */}
                  </span>
                  {newUser.uploadproof3.files.name ? (
                    <span className="py-2">
                      {" "}
                      {newUser.uploadproof3.files.name}{" "}
                    </span>
                  ) : (
                    <span className="py-2">{"No file chosen"}</span>
                  )}
                </div>
                {userError.uploadproof3 ? (
                  <span className="error-theme mx-4 my-4">
                    {userError.uploadproof3}
                  </span>
                ) : null}
                {validationErr.uploadproof3 ? (
                  <span className="error-theme">
                    {validationErr.uploadproof3}
                  </span>
                ) : null}
              </div>
              <div className="upload-box my-3">
                <div className="d-flex justify-content-between">
                  <PrimaryButton
                    type="button"
                    btnSize="small"
                    onsubmit={() => uploadProof("uploadproof4")}
                    className="p-0 business-button  fileftaxpath float-left mx-4"
                  >
                    Upload proof 4
                    <input
                      name="uploadproof4"
                      size="small"
                      type="file"
                      id="uploadproof4"
                      onChange={onUserInfoChange}
                      style={{ display: "none", width: "50%" }}
                    />
                  </PrimaryButton>
                  <span
                    style={{ color: "red", fontSize: "15px" }}
                    className="mx-2 "
                  >
                    {/* * */}
                  </span>
                  {newUser.uploadproof4.files.name ? (
                    <span className="py-2">
                      {" "}
                      {newUser.uploadproof4.files.name}{" "}
                    </span>
                  ) : (
                    <span className="py-2">{"No file chosen"}</span>
                  )}
                </div>
                {userError.uploadproof4 ? (
                  <span className="error-theme mx-4 my-4">
                    {userError.uploadproof4}
                  </span>
                ) : null}
                {validationErr.uploadproof4 ? (
                  <span className="error-theme">
                    {validationErr.uploadproof4}
                  </span>
                ) : null}
              </div>
            </div>
          </div>

          <div className="text-left">
            <CustomCheckBox
              label={
                <>
                  By Clicking this box, I accept the
                  <Link onClick={() => setTermsconditionModal((e) => !e)}>
                    <span> Terms and Conditions</span>
                  </Link>
                </>
              }
              value="terms"
              name="terms"
              onclick={() => {
                setTermsChecked((e) => !e);
                setIsTermChecked((e) => !e);
              }}
            />
            <br />
            {isSubmit && !isTermChecked && (
              <span className="error-theme mx-4">
                Please accept terms and conditions before submitting
              </span>
            )}
          </div>
          <div className="col-12 submit-button mt-4 text-center fmSbmt">
            <PrimaryButton type="submit" className="p-0 new-customer-submit">
              Submit
            </PrimaryButton>
          </div>
        </form>
        <Modal
          open={termsconditionModal}
          onClose={() => {
            setTermsconditionModal(false);
          }}
        >
          <div className="termsConditionModal">
            <h3>Terms & Conditions</h3>
            <div
              className="pt-5 text-left"
              dangerouslySetInnerHTML={detailUsa()}
            />
          </div>
        </Modal>
      </Container>
    </div>
  );
};
// const StyledApp = withStyles(styles)(NewCustomerInformation);

export default NewCustomerInformation;
