import React, { useState, useEffect } from "react";
import "./MegaMenu.css";
import { ListItem, List } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Searchfilters,
  specialProductDetails,
} from "../../Store/Actions/userActions";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
// import MobileMegaMenu from '../MegaMenu/MobileMegaMenu'

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const MobileMegaMenu = (props) => {
  let history = useHistory();
  const [Menu, setMenu] = useState(false);
  const [MainMenu, setMainMenu] = useState(true);
  const [state, setState] = useState({
    key: "",
    name: "",
  });
  const [clickedHov, setClickedHov] = useState("hover");
  const dispatch = useDispatch();
  const handleMenuOpen = (value, clickOrhover, name) => (e) => {
    sessionStorage.setItem("searchValues", JSON.stringify({}));
    setMenu(true);
    setState({ ...state, key: value, name: name });
    setClickedHov(clickOrhover);
    sessionStorage.setItem("navNumber", JSON.stringify(value));
    setMainMenu(false);
  };

  const handleMenuClose = () => {
    setMenu(false);
    setMainMenu(true);
    // setMainMenu(false);
  };

  const menues = [useSelector((state) => state.Search.menues)];

  const userInfo = useSelector((state) => state.users.users);

  const handlechange = (id, keyNum, name) => (e) => {
    sessionStorage.setItem("searchValue", JSON.stringify({}));
    sessionStorage.setItem("nav_name", name);
    sessionStorage.setItem("navNumber", id);
    setMainMenu(false);

    dispatch({
      type: "NAV_ID",
      payload: keyNum,
    });
    dispatch({
      type: "nav_name",
      payload: name,
    });
    dispatch({
      type: "NAV_SUB_ID",
      payload: id,
    });
    let data = {
      sessionKey: userInfo.session_id,
      search_value: "",
      search_key: "",
      size: "25",
      page: "1",
      cid: id,
    };

    Searchfilters(data, dispatch, true, false, true);
    if (state.name == "The Furnace") {
      history.push("/furnance?key=" + id /*+ "name=" + name*/);
    } else if (state.name == "Dry Goods") {
      history.push("/drygoods?key=" + id /*+ "name=" + name*/);
    } else {
      history.push("/search?key=" + id /*+ "name=" + name*/);
    }
  };

  // const handleSubMenuClose = () => {
  //   setMenu(false);
  // };

  const handleSpecial = () => {
    let featProDtls = {
      sessionKey: userInfo.session_id,
      cid: "",
      search_key: "",
      search_value: "",
      page: 1,
      size: 25,
      is_special: 1,
    };

    specialProductDetails(featProDtls, dispatch);
  };
  useEffect(() => {
    if (
      menues &&
      typeof menues !== "undefined" &&
      menues[0] &&
      typeof menues[0] !== "undefined" &&
      menues[0][state.key] &&
      typeof menues[0][state.key] !== "undefined" &&
      menues[0][state.key].detail &&
      typeof menues[0][state.key].detail !== "undefined"
    ) {
      if (clickedHov === "click") {
        handlechange(
          menues[0][state.key]["detail"][0]["sub_id"],
          state.key,
          menues[0][state.key]["detail"][0]["sub_name"]
        )({});
      }
    }
  }, [clickedHov]);

  useEffect(() => {
    let sessionValue = {
      sessionKey: userInfo.session_id,
    };
  }, []);

  /*sidebar functions */

  const classes = useStyles();
  const [crstate, setCrState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setCrState({ ...crstate, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="megaMenu">
        {MainMenu ? (
          <>
            <h5 className="cstmHdrForNav">MAIN MENU</h5>
            <ul className="menuKey d-flex float-right">
              {userInfo?.isFlyer &&
                userInfo?.isFlyer[0] &&
                userInfo?.isFlyer[0]?.flyerenable &&
                userInfo?.isFlyer[0]?.flyerenable == "Y" && (
                  <li>
                    <Link to="/flyer">NEWS AND PROMOS</Link>
                  </li>
                )}

              <li onClick={(handleSpecial, toggleDrawer(anchor, false))}>
                <Link to="/specialProduct?page=1">SPECIALS</Link>
              </li>
              {menues[0] &&
                menues[0].map((ele, index) =>
                  ele &&
                  typeof ele !== "undefined" &&
                  ele.detail.length !== 0 ? (
                    <li onClick={handleMenuOpen(index, "click", ele.name)}>
                      {ele.name}
                      <span className="material-icons">expand_more</span>
                    </li>
                  ) : null
                )}
              {/* <li
                // onMouseOver={handleMenuOpen(0, "hover")}
                onClick={handleMenuOpen(0, "click", 'The Furnace')}
              >
                The Furnace<span className="material-icons">expand_more</span>
              </li>
              <li onClick={handleMenuOpen(1, "click", 'Fish')}>
                Fish<span className="material-icons">expand_more</span>
              </li>
              <li onClick={handleMenuOpen(2, "click", 'Invertebrates')}>
                Invertebrates<span className="material-icons">expand_more</span>
              </li>
              <li onClick={handleMenuOpen(3, "click", 'Dry Goods')}>
                Dry Goods <span className="material-icons">expand_more</span>
              </li>
              <li onClick={handleMenuOpen(4, "click", 'Freshwater')}>
                Freshwater<span className="material-icons">expand_more</span>
              </li> */}
              <li>
                <Link
                  to="/shippingTracking"
                  onClick={toggleDrawer(anchor, false)}
                >
                  SHIPPING TRACKING{" "}
                </Link>
              </li>
              <li>
                <Link to="/helpfulLinks" onClick={toggleDrawer(anchor, false)}>
                  HELPFUL LINKS{" "}
                </Link>
              </li>
            </ul>
          </>
        ) : null}
        {Menu ? (
          <div
            className="menuValues"
            onClick={() => setTimeout(handleMenuClose, 500)}
          >
            {state.key !== undefined &&
            menues &&
            typeof menues !== "undefined" ? (
              <>
                <h5 className="cstmHdrForNav pl-1" onClick={handleMenuClose}>
                  {" "}
                  <ChevronLeftIcon /> BACK TO MAIN MENU
                </h5>
                <List component="nav" aria-labelledby="nested-list-subheader">
                  {menues[0] &&
                    menues[0][state.key] &&
                    menues[0][state.key].detail.map((data) => (
                      <ListItem
                        button
                        onClick={handlechange(
                          data.sub_id,
                          state.key,
                          data.sub_name
                        )}
                      >
                        <div id="chevr" onClick={toggleDrawer(anchor, false)}>
                          <span>{data.sub_name}</span>
                          <span className="material-icons">chevron_right</span>
                        </div>
                      </ListItem>
                    ))}
                </List>
              </>
            ) : (
              ""
            )}
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div className="navbar-light">
            <Button onClick={toggleDrawer(anchor, true)} class="navbar-toggler">
              <span class="navbar-toggler-icon"></span>
            </Button>
          </div>
          <SwipeableDrawer
            anchor={anchor}
            open={crstate[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
};
export default MobileMegaMenu;
