import React, { useEffect, useState, useRef } from "react";
import {
  ArrowBack,
  ContactSupportRounded,
  FormatListBulletedOutlined,
} from "@material-ui/icons";
import { Container, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../components/Loader";

import "./AddMoreInformation.css";
import PrimaryButton from "../PrimaryButton";
import CustomCheckBox from "../CustomCheckBox";
import InputField from "../Input/InputField";
import SelectField from "../Input/SelectField";
import {
  getCountries,
  submitMoreInfo,
  getUploadedDocuments,
} from "../../Store/Actions/userActions";
import EditExistingInformationMailPopup from "../EditExistingInfoMailPopup";

const total_length = 5;

const AddMoreInformation = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [email, setEmail] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [isMoreInfo, setIsMoreInfo] = useState(false);
  const [isFiles, setIsFiles] = useState(false);
  const [loading, setLoading] = useState(false);

  const [uploadedDocs, setUploadedDocs] = useState([]);

  const [files, setFiles] = useState([{ index: Math.random(), file: "" }]);

  const removeFile = (i) => {
    setFiles(files.filter((f) => f.index != i));
  };

  const addFile = (e, i) => {
    files[e.target.dataset.id]["file"] = e.target.files[0];
    setIsFiles(false);
  };

  const onUserInfoSubmit = async (e) => {
    if (email.trim() == "") {
      setIsEmail(true);
    } else if (files.filter((f) => f.file != "").length == 0) {
      setIsFiles(true);
    } else {
      e.preventDefault();
      setLoading(true);
      let formData = new FormData();
      files.map((f, i) => {
        formData.append("filestaxpath" + i, f.file);
      });

      formData.append("email", email);
      formData.append("moreinfo", moreInfo);

      submitMoreInfo(formData, dispatch).then((res) => {
        setLoading(false);

        if (res.data.status === true) {
          let tostifyValue = {};
          tostifyValue["tostifyView"] = true;
          tostifyValue["severity"] = "success";
          tostifyValue["tostifyMessage"] = "Document uploaded successfully";
          dispatch({
            type: "TOSTIFY_MESSAGE",
            payload: tostifyValue,
          });
          history.push("/");
        } else {
          let tostifyValue = {};
          tostifyValue["tostifyView"] = true;
          tostifyValue["severity"] = "error";
          tostifyValue["tostifyMessage"] = res.data.detail;
          dispatch({
            type: "TOSTIFY_MESSAGE",
            payload: tostifyValue,
          });
        }
        return Promise.resolve(res);
      });
    }
  };

  const getUploadedDocs = () => {
    if (email.trim() == "") {
      setIsEmail(true);
    } else {
      const formData = new FormData();

      formData.append("email", email);
      getUploadedDocuments(formData, dispatch).then((res) => {
        if (res.data.status === true) {
          setUploadedDocs(res.data.detail);
          let tostifyValue = {};
          tostifyValue["tostifyView"] = true;
          tostifyValue["severity"] = "success";
          tostifyValue["tostifyMessage"] = "Documents Retrived";
          dispatch({
            type: "TOSTIFY_MESSAGE",
            payload: tostifyValue,
          });
        } else {
          let tostifyValue = {};
          tostifyValue["tostifyView"] = true;
          tostifyValue["severity"] = "error";
          tostifyValue["tostifyMessage"] = res.data.detail;
          dispatch({
            type: "TOSTIFY_MESSAGE",
            payload: tostifyValue,
          });
        }

        return Promise.resolve(res);
      });
    }
  };

  return (
    <div className="sdHome admrInfoWrpr">
      <div className="sdhBanner">
        <div className="sdhLogo">
          <img src="./assets/images/logo.png" alt="Seadwelling logo"></img>
        </div>
      </div>
      <Container>
        {loading && <Loader />}

        <div className="new-customer-information pt-5">
          <div className="heading  py-3 ">
            <p className=" heading-text text-white text-left mx-3 my-0">
              New Customer Information
              <IconButton className="float-right  p-0">
                <Link to="/">
                  <ArrowBack className="text-white heading-arrow my-1" />
                </Link>
              </IconButton>
            </p>
          </div>
        </div>
        {/* <div className="business-information d-flex justify-content-between my-3"> */}
        <h5 className="business-text my-2 text-left mt-5">
          Add More Information
        </h5>
        {/* <form onSubmit={onUserInfoSubmit}> */}
        {/* <div className="owner-info mt-3"> */}
        <div className="d-flex justify-content-between flex-wrap w-100">
          <div className="input-box">
            <InputField
              name="email"
              size="medium"
              type="email"
              id="email"
              required={true}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setIsEmail(false);
              }}
              label={<span>Business email</span>}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {isEmail && (
              <p className="text-danger text-left">Enter business email</p>
            )}
          </div>
          <div className="input-box">
            <InputField
              label={<span>More details</span>}
              // placeholder="Business Name"
              name="moreInfo"
              size="medium"
              type="text"
              id="moreInfo"
              value={moreInfo}
              onChange={(e) => {
                setMoreInfo(e.target.value);
                // setIsMoreInfo(false);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* {isMoreInfo && (
              <p className='text-danger text-left'>Enter More Info</p>
            )} */}
          </div>
        </div>

        <h5 className="business-text mb-3 text-left mt-4">
          Upload more images of liscense, Equipment, Advertisements etc...
        </h5>
        <div className="row justify-content-between flex-wrap">
          <div className="col-md-4 col-12">
            <p className="text-left">Add photo copy</p>
          </div>
          <div className="col-md-8 col-12">
            {files.map((f, i) => (
              <div
                key={f.index}
                className="admrInptDiv"
                style={{ margin: "10px" }}
              >
                <input
                  type="file"
                  className="inputFile"
                  data-id={i}
                  onChange={(e) => addFile(e, f.index)}
                ></input>

                {i == 0 ? (
                  <>
                    <button
                      className="addButton"
                      onClick={() => {
                        files.length < 5 &&
                          setFiles([
                            ...files,
                            { index: Math.random(), file: "" },
                          ]);
                      }}
                    >
                      Add More
                    </button>
                    <button className="upldDocBtn" onClick={getUploadedDocs}>
                      Uploaded Documents
                    </button>
                  </>
                ) : (
                  <button
                    className="removeButton"
                    onClick={() => {
                      removeFile(f.index);
                    }}
                    style={{ margin: "0" }}
                  >
                    Remove
                  </button>
                )}
              </div>
            ))}
            {isFiles && (
              <p className="text-danger text-left pl-2">Upload files</p>
            )}
          </div>
        </div>
        <div className="submit-button my-2">
          <PrimaryButton
            type="button"
            btnSize="small"
            className="p-0 new-customer-submit"
            onClick={onUserInfoSubmit}
          >
            Submit
          </PrimaryButton>
        </div>
        {/* </form> */}
        {uploadedDocs.length > 0 && (
          <h5 className="business-text mb-3 text-left mt-4">
            View uploaded documents
          </h5>
        )}
        <div className="gridContainer">
          {uploadedDocs.map((e, i) => (
            <a href={e} target="_blank">
              <div className="docWrapper">
                <span className="material-icons">file_copy</span>
                View Document{i + 1}
              </div>
            </a>
          ))}
        </div>
      </Container>
    </div>
  );
};
// const StyledApp = withStyles(styles)(NewCustomerInformation);

export default AddMoreInformation;
