import React, { useEffect, useState } from "react";
import InputField from "../Input/InputField";
import MasterLayout from "../MasterLayout";
import CustomCheckBox from "../CustomCheckBox";
import PrimaryButton from "../PrimaryButton";
import "./orderShippingConfirmation.css";
import Modal from "@material-ui/core/Modal";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  placeOrder_req,
  fetchCartItem,
  deleteOutOfStock,
} from "../../Store/Actions/cart.actions";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";
import TermsAndCondition from "../TermsAndCondition";
import { useHistory } from "react-router-dom";
import popupClass from "../../utils/emitPopup";
import toastifyClass from "../../utils/emitToastify";
import axios from "axios";

const OrderShippingConfirmation = (props) => {
  // ------- LOCAL STATE ------------//
  const [data, setData] = useState({});
  const [cartInfo, setCartInfo] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [buttonDisable, setbuttonDisable] = useState(false);
  const [termsFlag, setTermsFlag] = useState(false);
  const [termsconditionModal, setTermsconditionModal] = useState(false);
  const [impStatus, setinpStatus] = useState({
    poNumber: {
      value: "",
      isRequired: false,
      errMsg: "PO Number is Required",
    },
    promoCode: {
      value: "",
      isRequired: false,
    },
    comments: {
      value: "",
      isRequired: false,
      errMsg: "Order Comments is Required",
    },
    terms: {
      value: "",
      isRequired: true,
      errMsg: "Please accept the terms and conditions",
      isChecked: true,
    },
    error: {},
  });
  // ------------ END --------------//

  // ----------- REDUX STATE ------------ //
  const dispatch = useDispatch();
  const cartDetails = useSelector((state) => state.cart);
  const userInfo = useSelector((state) => state.users.users);
  const loader = useSelector((state) => state.loader);
  const countryInfo = useSelector((state) => state.newRegistration.newRegInfo);
  const checkout = useSelector((state) => state.checkout.checkout);

  const [terms, setTerms] = useState(
    "By Clicking this box, I accept the Terms and Conditions"
  );
  const [termsChecked, setTermsChecked] = useState(false);
  const termsCondition = useSelector((state) => state.newRegistration);
  var outOfStock = "";
  const history = useHistory();
  // ----------- END ------------ //

  // ---------- EFFECTS -------------//
  useEffect(() => {
    if (typeof props.screen_data != "undefined") {
      if (Object.keys(props.screen_data).length) {
        setData(props.screen_data);
      }
    }
  }, [props.screen_data]);

  useEffect(() => {
    async function getTermsCheck() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/sdc/getTermsCheck`,
        { sessionKey: user.session_id }
      );
      if (data && data.terms_checked == 1) {
        setTermsFlag(true);
        setTermsChecked(true);
      } else {
        setTermsChecked(false);
      }
    }

    getTermsCheck();
  }, []);

  // useEffect(() => {
  //   if(userInfo && userInfo.detail && userInfo.detail.terms_checked != undefined){
  //     if(userInfo.detail.terms_checked == 1) {
  //       setTermsFlag(true)
  //       setTermsChecked(true)
  //     } else {
  //       setTermsChecked(false)
  //     }
  //   }
  // }, [userInfo])

  useEffect(() => {
    const termsCond = termsCondition.newCountries.terms;
    if (checkout.user_dtls.countryname && termsCondition.newCountries.terms) {
      if (
        checkout.user_dtls.countryname === "USA" &&
        termsCondition.newCountries.terms
      ) {
        if (termsCond[0].name) {
          setTerms(termsCond[0].name);
        }
      } else {
        if (termsCond[0].name) {
          setTerms(termsCond[1].name);
        }
      }
    } else {
      if (termsCond[0].name) {
        setTerms(termsCond[0].name);
      }
    }
  }, [checkout.user_dtls, termsCondition.newCountries]);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);
  useEffect(() => {
    let receivedData = [];
    setbuttonDisable(true);
    if (
      cartDetails &&
      cartDetails.savedCart &&
      cartDetails.savedCart.items &&
      typeof cartDetails.savedCart.items != "undefined"
    ) {
      for (let i = 0; i < cartDetails.savedCart.items.length; i++) {
        setbuttonDisable(false);
        receivedData.push({
          size: cartDetails.savedCart.items[i].sku_dtls.size,
          quantity_available:
            cartDetails.savedCart.items[i].sku_dtls.quantity_available,
          name: cartDetails.savedCart.items[i].sku_dtls.name,
          owned_quantity:
            cartDetails.savedCart.items[i].sku_dtls.owned_quantity,
          retail_price: cartDetails.savedCart.items[i].sku_dtls.retail_price,
          websku: cartDetails.savedCart.items[i].sku_dtls.websku,
        });
      }
    }

    console.log(receivedData, receivedData.length, "receivedData");

    setCartInfo(receivedData);

    if (Array.isArray(receivedData) && receivedData.length) {
      if (receivedData.length == 1) {
        setTotalPrice(
          (
            receivedData[0]["retail_price"] * receivedData[0]["owned_quantity"]
          ).toFixed(2)
        );
      } else {
        let priceArr = receivedData.map((ele) =>
          parseFloat(ele.retail_price * ele.owned_quantity)
        );
        console.log(priceArr, "priceArr");
        let totalValArr = priceArr.reduce(
          (acc, val) => acc + parseFloat(val),
          0
        );
        setTotalPrice(totalValArr.toFixed(2));
      }
    }
  }, [cartDetails]);
  // ---------- END -------------//
  // -------------- Handlers ------------------- //
  const handleChange = (e) => {
    try {
      if (typeof e.target === "undefined") throw "r.target is undefined";
      let { value, name, type } = e.target;

      if (name === "terms" && type === "checkbox") {
        setTermsChecked((e) => !e);
      }

      if (impStatus["error"][name]) delete impStatus["error"][name];
      if (typeof value === "undefined") throw "value is undefined";
      if (typeof name === "undefined") throw "name is undefined";
      if (typeof type === "undefined") throw "type is undefined";

      let currentObj = impStatus[name];
      if (typeof currentObj === "undefined") throw "currnetobj is undefined";
      if (type === "checkbox") {
        if (impStatus["error"]["terms"]) {
          delete impStatus["error"]["terms"];
        }
        currentObj["isChecked"] = !currentObj["isChecked"];
      }
      currentObj["value"] = value;
      setinpStatus({ ...impStatus, [name]: currentObj });
    } catch (e) {
      console.log(e);
    }
  };

  const validate = () => {
    let err = {};
    Object.keys(impStatus).map((ele) => {
      if (typeof impStatus !== "undefined") {
        if (typeof impStatus[ele].value !== "undefined") {
          if (impStatus[ele].value === "" && impStatus[ele].isRequired) {
            if (impStatus[ele].hasOwnProperty("isChecked")) {
              if (!impStatus[ele]["isChecked"]) {
                err[ele] = impStatus["error"][ele] = impStatus[ele]["errMsg"]
                  ? impStatus[ele]["errMsg"]
                  : "This is required";
              }
            } else {
              err[ele] = impStatus["error"][ele] = impStatus[ele]["errMsg"]
                ? impStatus[ele]["errMsg"]
                : "This is required";
            }
          }
        }
      }
    });
    setinpStatus({ ...impStatus, error: err });
    return err;
  };
  const outOfStockItems = () => {
    return (
      <>
        <p>There are items in your order which are no longer available.</p>
        <p>Please remove or replace them before place order.</p>
        <ul>
          {outOfStock.map((item) => (
            <li>{item.name}</li>
          ))}
        </ul>
      </>
    );
  };
  const onClickRemoveOutofStock = () => {
    deleteOutOfStock({ sessionKey: userInfo.session_id }, dispatch).then(
      (res) => {
        if (res.data.status) {
          new toastifyClass(
            true,
            res.data.msg,
            "success",
            dispatch
          ).emittoast();
          let freqObj = {
            sessionKey: userInfo["session_id"],
          };
          fetchCartItem(freqObj)(dispatch).then(() => {
            console.log("cartInfo", cartInfo);
          });
          dispatch({
            type: "POPUP_MESSAGE_CLOSE",
            payload: false,
          });
        }
      }
    );
  };
  const onClickClosePopupOutofStock = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    history.push("/cart");
  };

  const placeOrder = () => {
    if (termsFlag && termsChecked) {
      if (
        userInfo.detail &&
        (userInfo.detail.account_type == "rep" ||
          userInfo.detail.account_type == "all")
      ) {
        alert(
          "Rep account is not be allowed to submit order. Please switch user."
        );
        return false;
      }
      if (Object.keys(validate()).length === 0) {
        let reqObj = {};
        reqObj["sessionKey"] = userInfo["session_id"];
        reqObj["email"] = data["email"]["value"];
        reqObj["nickname"] = data["snickname"]["value"];
        reqObj["first_name"] = data["firstName"]["value"];
        reqObj["last_name"] = data["lastName"]["value"];
        reqObj["alt_phone"] = data["mobilePhone"]["value"];
        reqObj["phone"] = data["phone"]["value"];
        reqObj["fax"] = data["fax"]["value"];
        reqObj["billing_address"] = data["address"]["value"];
        reqObj["billing_address2"] = data["address2"]["value"];
        reqObj["billing_city"] = data["city"]["value"];
        reqObj["billing_zipcode"] = data["zipcode"]["value"];
        reqObj["billing_companyname"] = data["companyName"]["value"];
        reqObj["shippingsame"] = "1";
        reqObj["ship_phone"] = data["sphone"]["value"];
        reqObj["ship_email"] = data["semail"]["value"];
        reqObj["new_ship"] = "1";
        reqObj["shipping_name"] = data["snickname"]["value"];
        reqObj["po_number"] = impStatus["poNumber"]["value"];
        reqObj["order_comments"] = impStatus["comments"]["value"];
        reqObj["promo_code"] = impStatus["promoCode"]["value"];
        reqObj["termscondition"] = "1";
        reqObj["is_switched"] = userInfo["is_switched"]
          ? userInfo["is_switched"]
          : 0;
        setbuttonDisable(true);
        placeOrder_req(reqObj, dispatch)
          .then((res) => {
            dispatch({
              type: "LOADING",
              payload: true,
            });

            if (typeof res !== "undefined") {
              if (typeof res.data !== "undefined") {
                if (Object.keys(res.data).length) {
                  if (res.data.state != "error" && res.data.status == true) {
                    res.data["poNumber"] = impStatus["poNumber"]["value"];
                    res.data["comments"] = impStatus["comments"]["value"];
                    res.data["promoCode"] = impStatus["promoCode"]["value"];
                    let freqObj = {
                      sessionKey: userInfo.session_id,
                    };
                    fetchCartItem(freqObj)(dispatch).then(() => {
                      props.history.push({
                        pathname: "/billReceipt",
                        state: {
                          headObj: res.data,
                          cartInfo: cartInfo,
                        },
                      });
                      dispatch({
                        type: "LOADING",
                        payload: false,
                      });
                    });
                  } else {
                    if (res.data.status == false && res.data.outofstock === 1) {
                      setbuttonDisable(false);
                      dispatch({
                        type: "LOADING",
                        payload: false,
                      });
                      console.log("outofstock", res.data.detail);
                      outOfStock = res.data.detail;
                      new popupClass(
                        true,
                        outOfStockItems(),
                        "No Longer Available",
                        "Remove For Me",
                        "Return To Cart",
                        "cart-remove-for-me",
                        "cart-return-to-cart",
                        dispatch,
                        onClickRemoveOutofStock,
                        onClickClosePopupOutofStock,
                        "out-of-stock-close",
                        "",
                        "out-of-stock-buttons"
                      ).emitPopup();
                    } else if (
                      res?.data?.status == false &&
                      res?.data?.flag == "furnace_qty"
                    ) {
                      setbuttonDisable(false);
                      new popupClass(
                        true,
                        res.data.message,
                        "Furnace quantity",
                        "",
                        "",
                        "",
                        "",
                        dispatch,
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        onClickClosePopupOutofStock
                      ).emitPopup();
                    } else {
                      throw "False response from API";
                    }
                  }
                } else {
                  throw "res.data is an empty object";
                }
              } else {
                throw "res.data is undefined";
              }
            } else {
              throw "res is undefined";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setTermsFlag(true);
    }
  };
  // -------------- END ------------------------- //
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <MasterLayout {...props}>
      <div id="confirmation-order">
        {false ? (
          <Loader />
        ) : (
          <div style={{ paddingTop: 45 }}>
            <p className="topHeaderMessage">
              Items are reserved during checkout for 5 Minutes and then moved to
              your wishlist
            </p>
            <div className="confirmation-table">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Qty</th>
                    <th>Size</th>
                    <th>Description</th>
                    <th>Unit Price</th>
                    <th>Sub Total</th>
                  </tr>
                </thead>
                <tbody>
                  {cartInfo && cartInfo.length
                    ? cartInfo.map((ele, ind) => {
                        return (
                          <React.Fragment key={ind}>
                            <tr>
                              <td>{ele.owned_quantity}</td>
                              <td>{ele.size}</td>
                              <td className="cart-page-product-name">
                                {ele.name}
                              </td>
                              <td>${ele.retail_price}</td>
                              <td>
                                $
                                {(
                                  ele.retail_price * ele.owned_quantity
                                ).toFixed(2)}
                                <p></p>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })
                    : null}
                </tbody>
              </table>
              <div className="sub-total">
                <p className="text-right py-3 mx-3">
                  Sub Total $
                  {cartInfo && cartInfo.length
                    ? totalPrice
                      ? totalPrice
                      : 0
                    : 0}
                </p>
              </div>
              <div>
                <p className="text-left payment-info">
                  <span className="paymt-info">Payment Info</span>
                  <span className="sdc">
                    (Your SDC Representative will contact you shortly)
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-between flex-wrap w-100">
                <div className="input-box">
                  <InputField
                    label="ENTER PROMO CODE (If any)"
                    name="promoCode"
                    size="medium"
                    type="text"
                    id="promoCode"
                    value={impStatus["promoCode"]["value"]}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <span className="error-theme">
                    {impStatus["error"]["poNumber"]
                      ? impStatus["error"]["poNumber"]
                      : ""}
                  </span>
                </div>
              </div>
              <label>
                Note: Valid promo offers will be processed on your final
                invoice, and will not appear on your order at this time
              </label>
              <div className="d-flex justify-content-between flex-wrap w-100">
                <div className="input-box">
                  <InputField
                    label="PO Number"
                    name="poNumber"
                    size="medium"
                    type="text"
                    id="poNumber"
                    value={impStatus["poNumber"]["value"]}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <span className="error-theme">
                    {impStatus["error"]["poNumber"]
                      ? impStatus["error"]["poNumber"]
                      : ""}
                  </span>
                </div>
                <div className="input-box">
                  <InputField
                    label="Order Comments"
                    name="comments"
                    size="medium"
                    type="text"
                    id="orderCommnets"
                    multiline
                    value={impStatus["comments"]["value"]}
                    onChange={handleChange}
                    rowsMax={4}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <span className="error-theme">
                    {impStatus["error"]["comments"]
                      ? impStatus["error"]["comments"]
                      : ""}
                  </span>
                </div>
              </div>
              <div className="my-4">
                <div className="text-left">
                  <CustomCheckBox
                    label={
                      <>
                        <Link onClick={() => setTermsconditionModal(true)}>
                          {terms}
                        </Link>
                      </>
                    }
                    value="shippingAddress"
                    name="terms"
                    onclick={handleChange}
                    checked={termsChecked}
                    className="float-left"
                  />
                </div>
                {/* <span className="error-theme">
                  {impStatus["error"] ? impStatus["error"]["terms"] : ""}
                </span> */}
                {termsFlag && !termsChecked && (
                  <div className="text-left">
                    <span
                      className="text-danger"
                      style={{ marginLeft: "25px" }}
                    >
                      Please check the terms and conditions before placing order
                    </span>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-end">
                <div className="mx-2">
                  {props.goBack ? (
                    <PrimaryButton
                      onsubmit={props.goBack}
                      type="submit"
                      btnSize="small"
                    >
                      Go Back
                    </PrimaryButton>
                  ) : null}
                </div>
                <div className="mx-2">
                  <PrimaryButton
                    type="submit"
                    btnSize="small"
                    onsubmit={placeOrder}
                    disabled={buttonDisable}
                  >
                    Place Order Now
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          open={termsconditionModal}
          onClose={() => {
            setTermsconditionModal(false);
          }}
        >
          <div className="termsConditionModal">
            <h3>Terms & Conditions</h3>
            <TermsAndCondition from="checkout" />
          </div>
        </Modal>
      </div>
    </MasterLayout>
  );
};

export default withRouter(React.memo(OrderShippingConfirmation));
