import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import Popup from "../Popup";
import PrimaryButton from "../PrimaryButton";
import InputField from "../Input/InputField";
import "./accontOverviewPopup.css";
import {
  accountOverviewUpdate,
  accountOverview,
} from "../../Store/Actions/userActions";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f8f8f8",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 350,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const AccountOverviewPopup = ({
  accountDtlsInfor,
  accountViewPopOpen,
  accountViewPopClose,
}) => {
  const [accountDtsInfo, setAccountDtlsInfo] = useState({
    first_name: {
      value:
        accountDtlsInfor && accountDtlsInfor.detail
          ? accountDtlsInfor.detail.first_name
          : "",
      error: "This field is required",
      isAuth: true,
    },
    last_name: {
      value:
        accountDtlsInfor && accountDtlsInfor.detail
          ? accountDtlsInfor.detail.last_name
          : "",
      error: "This field is required",
      isAuth: true,
    },
    phone: {
      value:
        accountDtlsInfor && accountDtlsInfor.detail
          ? accountDtlsInfor.detail.phone
          : "",
      error: "This field is required",
      isAuth: true,
    },
    phoneext: {
      value:
        accountDtlsInfor && accountDtlsInfor.detail
          ? accountDtlsInfor.detail.phoneext
          : "",
      error: "This field is required",
      isAuth: false,
    },
    cellphone: {
      value:
        accountDtlsInfor && accountDtlsInfor.detail
          ? accountDtlsInfor.detail.cellphone
          : "",
      error: "This field is required",
      isAuth: true,
    },
    fax: {
      value:
        accountDtlsInfor && accountDtlsInfor.detail
          ? accountDtlsInfor.detail.fax
          : "",
      error: "This field is required",
      isAuth: false,
    },
    email: {
      value:
        accountDtlsInfor && accountDtlsInfor.detail
          ? accountDtlsInfor.detail.email
          : "",
      error: "This field is required",
      isAuth: true,
    },
  });

  const [error, setError] = useState({});

  const [validationErr, setValidationErr] = useState({});

  const userInfo = useSelector((state) => state.users.users);

  const dispatch = useDispatch();

  const onChangeAccountViewInfo = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    if (name === "phone" || name === "cellphone") {
      value = value.replace(/[^0-9.]/g, "");
    }
    let Obj = {
      value,
      error: accountDtsInfo[name]["error"],
      isAuth: accountDtsInfo[name]["isAuth"],
    };
    setError({
      ...error,
      [name]: "",
    });
    setAccountDtlsInfo({
      ...accountDtsInfo,
      [name]: Obj,
    });
    setValidationErr({
      ...validationErr,
      [name]: "",
    });
  };

  const validate = () => {
    let err = {};
    Object.keys(accountDtsInfo).map((info) => {
      if (accountDtsInfo[info].value === "" && accountDtsInfo[info].isAuth)
        err[info] = accountDtsInfo[info]["error"];
    });

    setError(err);
    return err;
  };

  const validationFormat = () => {
    let valErr = {};
    Object.keys(accountDtsInfo).map((info) => {
      // if (info === "phone" || info === "cellphone" || info === "fax") {
      //   if (accountDtsInfo[info].value.length)
      //     if (accountDtsInfo[info].value.length < 10) {
      //       valErr[info] = "Phone number should be minimum 10 digits";
      //     } else if (accountDtsInfo[info].value.length > 15) {
      //       valErr[info] = "Phone number should be maximum 15 digits only";
      //     }
      // } else if (info === "phoneext") {
      //   if (accountDtsInfo[info].value.length < 1) {
      //     valErr[info] = "Phone number should be minimum 1 digits ";
      //   } else if (accountDtsInfo[info].value.length > 3) {
      //     valErr[info] = "Phone number should be maximum 3 digits only";
      //   }
      // } else if (info === "first_name" || info === "last_name") {
      //   const textFormat = /^[a-zA-Z\s]*$/;
      //   if (!textFormat.test(accountDtsInfo[info].value))
      //     valErr[info] = "Enter the correct name";
      // }
      if (info === "phone" || info === "cellphone") {
        if (accountDtsInfo[info].value.length)
          if (accountDtsInfo[info].value.length < 10) {
            valErr[info] = "Phone number should be minimum 10 digits";
          } else if (accountDtsInfo[info].value.length > 15) {
            valErr[info] = "Phone number should be maximum 15 digits only";
          }
      } else if (info === "phoneext") {
        if (accountDtsInfo[info].value?.trim()) {
          if (accountDtsInfo[info].value.length < 1) {
            valErr[info] = "Phone number should be minimum 1 digits ";
          } else if (accountDtsInfo[info].value.length > 3) {
            valErr[info] = "Phone number should be maximum 3 digits only";
          }
        }
      }
    });
    setValidationErr(valErr);
    return valErr;
  };

  const onSubmitAccountViewInfo = (e) => {
    e.preventDefault();
    if (
      Object.keys(validate()).length === 0 &&
      Object.keys(validationFormat()).length === 0
    ) {
      accountOverviewUpdate(
        {
          sessionKey: userInfo.session_id,
          type: "update",
          first_name: accountDtsInfo.first_name.value,
          last_name: accountDtsInfo.last_name.value,
          phone: accountDtsInfo.phone.value,
          phoneext: accountDtsInfo.phoneext.value,
          cellphone: accountDtsInfo.cellphone.value,
          fax: accountDtsInfo.fax.value,
        },
        dispatch
      ).then(() => {
        accountOverview(
          { sessionKey: userInfo.session_id, type: "view" },
          dispatch
        );
      });
      accountViewPopClose();
    }
  };

  return (
    <Popup
      open={accountViewPopOpen}
      onClose={accountViewPopClose}
      fullWidth={true}
      maxWidth="sm"
      onsubmit={onSubmitAccountViewInfo}
      heading="EDIT CONTACT INFORMATION:"
      classNameDialog="account-edit"
      classNameDialogTitle="heading"
      classNameDialogContentText="account-edit-content"
      classNameClose={"popup-close"}
      actions={
        <>
          <PrimaryButton
            size="small"
            className="edit-contact-close"
            onsubmit={accountViewPopClose}
          >
            close
          </PrimaryButton>
          <PrimaryButton type="submit" className="edit-contact-update">
            Update Changes
          </PrimaryButton>
        </>
      }
    >
      <p>CONTACT INFORMATION:</p>
      <hr />

      <InputField
        label="First Name"
        placeholder="First Name"
        name="first_name"
        size="large"
        type="text"
        id="first_name"
        toolTipTitle={
          <div className="tableShortInfo">
            <h3>Please contact your sales person to change this information</h3>
          </div>
        }
        disabled
        onchange={onChangeAccountViewInfo}
        value={accountDtsInfo.first_name.value}
      />
      {error.first_name ? (
        <span className="error-theme">{error.first_name}</span>
      ) : null}
      {validationErr.first_name ? (
        <span className="error-theme">{validationErr.first_name}</span>
      ) : null}

      <InputField
        label="Last Name"
        placeholder="Last Name"
        name="last_name"
        size="large"
        type="text"
        id="last_name"
        toolTipTitle={
          <div className="tableShortInfo">
            <h3>Please contact your sales person to change this information</h3>
          </div>
        }
        disabled
        onchange={onChangeAccountViewInfo}
        value={accountDtsInfo.last_name.value}
      />
      {error.last_name ? (
        <span className="error-theme">{error.last_name}</span>
      ) : null}
      {validationErr.last_name ? (
        <span className="error-theme">{validationErr.last_name}</span>
      ) : null}

      <InputField
        label="Phone"
        placeholder="Phone"
        name="phone"
        size="large"
        type="text"
        id="phone"
        onchange={onChangeAccountViewInfo}
        value={accountDtsInfo.phone.value}
      />
      {error.phone ? <span className="error-theme">{error.phone}</span> : null}
      {validationErr.phone ? (
        <span className="error-theme">{validationErr.phone}</span>
      ) : null}
      <InputField
        label="Phone Ext"
        placeholder="Phone Ext"
        name="phoneext"
        size="large"
        type="number"
        id="phoneext"
        onchange={onChangeAccountViewInfo}
        value={accountDtsInfo.phoneext.value}
      />
      {error.phoneext ? (
        <span className="error-theme">{error.phoneext}</span>
      ) : null}
      {validationErr.phoneext ? (
        <span className="error-theme">{validationErr.phoneext}</span>
      ) : null}
      <InputField
        label="Cell Phone"
        placeholder="Cell Phone"
        name="cellphone"
        size="large"
        type="text"
        id="cellphone"
        onchange={onChangeAccountViewInfo}
        value={accountDtsInfo.cellphone.value}
      />
      {error.cellphone ? (
        <span className="error-theme">{error.cellphone}</span>
      ) : null}
      {validationErr.cellphone ? (
        <span className="error-theme">{validationErr.cellphone}</span>
      ) : null}

      <InputField
        label="Fax"
        placeholder="Fax"
        name="fax"
        size="large"
        type="text"
        id="fax"
        toolTipTitle={
          <div className="tableShortInfo">
            <h3>Please contact your sales person to change this information</h3>
          </div>
        }
        disabled
        onchange={onChangeAccountViewInfo}
        value={accountDtsInfo.fax.value}
      />

      {error.fax ? <span className="error-theme">{error.fax}</span> : null}
      {validationErr.fax ? (
        <span className="error-theme">{validationErr.fax}</span>
      ) : null}

      <InputField
        label="Email"
        placeholder="Email"
        name="email"
        size="large"
        type="text"
        id="email"
        toolTipTitle={
          <div className="tableShortInfo">
            <h3>Please contact your sales person to change this information</h3>
          </div>
        }
        disabled
        value={accountDtsInfo.email.value}
      />

      <hr />
    </Popup>
  );
};

export default AccountOverviewPopup;
