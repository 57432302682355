import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerInfoForAdmin,
  switchUser,
} from "../../Store/Actions/userActions";
import Layout from "../../views/MyAccount/Layout";

import CustomDropDown from "../CustomDropDown";
import InputField from "../Input/InputField";
import "./manageCustomer.css";
import Pagination from "@material-ui/lab/Pagination";
import { InputAdornment, IconButton, Button } from "@material-ui/core";
// import Loader from "../Loader";
import SearchIcon from "@material-ui/icons/Search";
import { UnfoldMore } from "@material-ui/icons";
import Loader from "../../components/Loader";
import PopupAlert from "../PopupAlert/index";

const ManageCustomer = () => {
  const dropDownValue = [10, 25, 50, 100];

  const [dropdwnValue, setDropdwnValue] = useState(25);

  const [dropdwnSearchValue, setDropDwnSearchValue] = useState({
    searchVal: "",
  });

  const [ascend, setAscend] = useState("ASC");

  const customerTable = useSelector(
    (state) => state.accountOverViewAdmin.customerInfo
  );
  const tableCell = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [cpopup, setCpopup] = useState(false);
  const [username, setUsername] = useState("");
  const userInfo = useSelector((state) => state.users.users);
  const loader = useSelector((state) => {
    return state.loader;
  });
  const handleChangeDropDownValue = (name) => {
    let chngVal = parseInt(name);
    setDropdwnValue(chngVal);

    getCustomerInfoForAdmin(
      {
        sessionKey: userInfo.session_id,
        search_value: "",
        size: chngVal,
        page: 1,
        sort: "",
        dir: ascend,
      },
      dispatch
    );
  };

  const dispatch = useDispatch();
  const handleChangeSearchValue = (e) => {
    setDropDwnSearchValue({ searchVal: e.target.value });

    getCustomerInfoForAdmin(
      {
        sessionKey: userInfo.session_id,
        search_value: e.target.value,
        size: dropdwnValue,
        page: 1,
        sort: "",
        dir: ascend,
      },
      dispatch
    );
  };

  const sortableData = (e) => {
    let name = e.currentTarget.getAttribute("name");
    setAscend("DESC");

    getCustomerInfoForAdmin(
      {
        sessionKey: userInfo.session_id,
        search_value: e.target.value,
        size: dropdwnValue,
        page: 1,
        sort: "",
        dir: ascend,
        column: name,
      },
      dispatch
    ).then(() => {
      setAscend("ASC");
    });
  };

  const setPopup = (name) => {
    setUsername(name);
    setCpopup(true);
  };

  const closePopup = () => {
    setUsername("");
    setCpopup(false);
  };

  const onConfirmClick = () => {
    switchUser(username, dispatch);
    closePopup();
  };

  useEffect(() => {
    if (
      customerTable &&
      typeof customerTable !== "undefined" &&
      customerTable.detail &&
      customerTable.total_row
    ) {
      setTotalData(customerTable.total_row);
    }
  }, [customerTable]);

  const currentPageNumber = (event, value) => {
    setCurrentPage(value);
    const reqObj = {};
    reqObj["sessionKey"] = userInfo.session_id;
    reqObj["search_value"] = "";
    reqObj["search_key"] = "";
    reqObj["size"] = dropdwnValue;
    reqObj["page"] = value;
    reqObj["sort"] = "";
    reqObj["dir"] = ascend;

    getCustomerInfoForAdmin(reqObj, dispatch).then(() => {
      window.scrollTo(0, 0);
    });
  };

  useEffect(() => {
    getCustomerInfoForAdmin(
      {
        sessionKey: userInfo.session_id,
        search_value: "",
        size: dropdwnValue,
        page: 1,
        sort: "",
        dir: "DESC",
      },
      dispatch
    );
  }, []);
  return (
    <Layout title="manage-customer">
      <hr />
      <div className="d-flex justify-content-between">
        <div className="my-2">
          <label className="mx-2">Show</label>
          <CustomDropDown
            filterData={dropDownValue}
            selectValue={dropdwnValue}
            handleDropDownChange={handleChangeDropDownValue}
          />
          <label className="mx-2">Entries</label>
        </div>
        <div>
          <InputField
            label="Search"
            placeholder="Search"
            name="searchVal"
            size="small"
            type="text"
            id="searchVal"
            value={dropdwnSearchValue.searchVal}
            onChange={handleChangeSearchValue}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>{" "}
      {loader.isLoading ? (
        <Loader />
      ) : (
        <>
          <div class="table-respon">
            <table className="table table-striped mt-4" ref={tableCell}>
              <thead>
                <tr>
                  <th scope="col" name="username" onClick={sortableData}>
                    Username
                    <UnfoldMore />
                  </th>
                  <th
                    scope="col"
                    className=""
                    name="zip_code"
                    onClick={sortableData}
                  >
                    Cust#
                    <UnfoldMore />
                  </th>
                  <th
                    scope="col"
                    className=""
                    name="first_name"
                    onClick={sortableData}
                  >
                    Name
                    <UnfoldMore />
                  </th>
                  <th
                    scope="col"
                    className=""
                    name="companyname"
                    onClick={sortableData}
                  >
                    Company
                    <UnfoldMore />
                  </th>
                  <th
                    scope="col"
                    className=""
                    name="phone"
                    onClick={sortableData}
                  >
                    Phone
                    <UnfoldMore />
                  </th>
                  <th scope="col" className="" name="actions">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {customerTable && customerTable.detail
                  ? customerTable.detail.map((tab, index) => (
                      <tr key={index}>
                        <td className="userName-value">{tab.username}</td>
                        <td>{tab.zip_code}</td>
                        <td>{tab.first_name}</td>
                        <td>{tab.companyname}</td>
                        <td>{tab.phone}</td>
                        <td>
                          <Button
                            onClick={() => setPopup(tab.username)}
                            color="primary"
                          >
                            Switch User
                          </Button>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            {!!cpopup ? (
              <PopupAlert
                open={cpopup}
                heading={"Switch User"}
                content={
                  "Please take a moment to confirm your action. Continue?"
                }
                action={"Cancel"}
                secondaryAction={"Confirm"}
                secClickAction={onConfirmClick}
                onClose={closePopup}
              />
            ) : null}
          </div>
          <React.Fragment>
            {customerTable &&
            customerTable.detail &&
            customerTable.total_row &&
            totalData &&
            typeof totalData !== "undefined" ? (
              <>
                {/* <Pagination
                  rowsPerPage={dropdwnValue}
                  total_row_count={totalData}
                  fallback={currentPageNumber}
                />{" "} */}
                <Pagination
                  page={currentPage}
                  count={Math.ceil(customerTable.total_row / dropdwnValue)}
                  onChange={currentPageNumber}
                />
              </>
            ) : null}
          </React.Fragment>
        </>
      )}
    </Layout>
  );
};

export default React.memo(ManageCustomer);
