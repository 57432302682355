export default class {
  constructor(tostifyView, tostifyMessage, severity, dispatch) {
    this.tostifyView = tostifyView;
    this.tostifyMessage = tostifyMessage;
    this.severity = severity;
    this.dispatch = dispatch;
  }
  emittoast() {
    this.dispatch({
      type: "TOSTIFY_MESSAGE",
      payload: { ...this },
    });
  }
}
