import React, { useState, useEffect } from "react";
import {
  DialogContentText,
  DialogContent,
  Dialog,
  Button,
  DialogTitle,
  Slide,
  Tooltip,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";

import "./detailsPopup.css";
import NoImage from "../../assets/images/NoImageAvailable.jpg";
import InputField from "../Input/InputField";
import CustomCheckBox from "../CustomCheckBox";
import PrimaryButton from "../PrimaryButton";
import TextAreaPopup from "../TextAreaPopup";
import {
  addComment,
  Searchfilters,
  AddToWatchList,
  deleteWatchList,
  wishlistList,
  specialProductDetails,
} from "../../Store/Actions/userActions";
import { withRouter } from "react-router-dom";
import {
  addItemToCart,
  fetchCartItem,
  addToCartBulk,
} from "../../Store/Actions/cart.actions";
import toastifyClass from "../../utils/emitToastify";
import PopupAlert from "../PopupAlert/index";
import popupClass from "../../utils/emitPopup";
import SecondaryButton from "../SecondaryButton";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const DetailsPopup = (props) => {
  const [commentDts, setCommentDtls] = useState({
    comment: "",
  });
  const [open, setOpen] = useState({
    op: true,
  });
  const [thisSatDetails, setThisSatDetails] = useState({});
  const userInfo = useSelector((state) => state.users.users);
  const lists = useSelector((state) => state.Search.details);

  const [qty, setQty] = useState([]);
  const [textAreaOpen, setTextAreaOpen] = useState(false);
  const [textAreaData, setTextAreaData] = useState({});
  const [skuArr, setSkuArr] = useState([]);
  const [cpopup, setCpopup] = useState(false);
  const [cpopupData, setCpopupData] = useState({});
  const [isOrderChanged, setIsOrderChanged] = useState([]);
  const [addtocartoberride, setAddtocartoberride] = useState(false);
  const [img, setImg] = useState("");
  const dispatch = useDispatch();
  const viewComment = useSelector((state) => state.commnet);
  const productInfo = useSelector((state) => state.Search.details);
  const currentTime = useSelector((state) => state.serverTime);
  const cartDetails = useSelector((state) => {
    return state.cart.savedCart;
  });
  const [furnaceTimereached, setFurnaceTimereached] = useState(false);
  const furnaceTime = useSelector((state) => state.furnaceTime);
  const serverTime = useSelector((state) => state.serverTime);

  const [furnaceData, setFurnaceData] = useState({
    startTiming: moment.tz(
      moment(props.fromDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      "America/Los_Angeles"
    ),
    endTiming: moment.tz(
      moment(props.toDate).utc().format("YYYY-MM-DD HH:mm:ss"),
      "America/Los_Angeles"
    ),
  });

  useEffect(() => {
    let currentDateTime = moment(serverTime.currentTimeDtls).tz(
      "America/Los_Angeles"
    );
    if (
      currentDateTime &&
      currentDateTime.isBetween(furnaceData.startTiming, furnaceData.endTiming)
    ) {
      setFurnaceTimereached(true);
    } else {
      setFurnaceTimereached(false);
    }
  }, [serverTime.currentTimeDtls]);

  useEffect(() => {
    if (furnaceTime.furnaceData) {
      setFurnaceData({
        startTiming: moment.tz(
          moment(furnaceTime.furnaceData.start_time)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
          "America/Los_Angeles"
        ),
        endTiming: moment.tz(
          moment(furnaceTime.furnaceData.end_time)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
          "America/Los_Angeles"
        ),
      });
    }
  }, [furnaceTime]);

  // ----------------- EVENT HANDLERS -------------------------- //
  const handleClose = (e) => {
    setQty([]);
    props.onpop1();
  };
  const qtyChange = (e, data, index, quantityIncrement) => {
    let currentQty = qty;
    let orderChangeCheck = isOrderChanged;
    let newQuantiy =
      quantityIncrement !== undefined ? quantityIncrement : e.target.value;
    currentQty[index] = newQuantiy;
    if (data.inv_type) {
      if (data.inv_type == "managed") {
        if (newQuantiy > data.quantity_available) {
          orderChangeCheck[index] = false;
          //setIsOrderChanged(false);
          setQty([...currentQty]);
          new popupClass(
            true,
            "Insufficient QTY",
            "Added to  your cart",
            "",
            "",
            "",
            "",
            dispatch
          ).emitPopup();
        } else if (newQuantiy <= data.quantity_available) {
          //setIsOrderChanged(true);
          orderChangeCheck[index] = true;
          setQty([...currentQty]);
        }
      } else if (data.inv_type == "static") {
        if (data.customer_limit == 0) {
          orderChangeCheck[index] = true;
          setQty([...currentQty]);
        } else if (!isNaN(data.quantity_available)) {
          if (newQuantiy <= data.quantity_available) {
            orderChangeCheck[index] = true;
            setQty([...currentQty]);
          } else {
            orderChangeCheck[index] = false;
          }
        } else {
          if (data.quantity_available === "-") {
            orderChangeCheck[index] = false;
            setQty([...currentQty]);
          }
        }
      } else {
        orderChangeCheck[index] = true;
        setQty([...currentQty]);
      }
    } else {
      currentQty[index] = newQuantiy;
      orderChangeCheck[index] = true;
      setQty([...currentQty]);
    }

    if (newQuantiy == "" || newQuantiy == 0) {
      orderChangeCheck[index] = false;
    }

    setIsOrderChanged(orderChangeCheck);
  };

  const deleteWishlistPopupCallback = () => {
    setAddtocartoberride(true);
  };

  const secondayCallBack = () => {
    let searchFilterReqObj = {};
    let addPro = JSON.parse(localStorage.getItem("addProduct"));
    let obj = { ...addPro };
    obj["override"] = 1;
    if (props && typeof props.location != "undefined") {
      let ID = props.location.search.split("key=")[1];
      if (ID && typeof ID !== "undefined") {
        ID = ID.split("&")[0];
      }
      let routeName = props.location.pathname.split("/")[1];

      searchFilterReqObj = {
        sessionKey: userInfo.session_id,
        search_value: "",
        search_key: "",
        size: routeName == "furnance" ? "100" : "25",
        page: "1",
        cid: ID ? ID : "",
      };
    }
    addItemToCart(obj, dispatch).then((res) => {
      fetchCartItem({
        sessionKey: userInfo.session_id,
      })(dispatch);

      if (props.location.pathname.includes("/specialProduct")) {
        let specialProductDtls = JSON.parse(
          sessionStorage.getItem("specialPage")
        );
        if (
          specialProductDtls === null ||
          specialProductDtls === "undefined" ||
          Object.keys(specialProductDtls).length === 0
        ) {
          specialProductDtls = {
            sessionKey: userInfo.session_id,
            cid: "",
            search_key: "",
            search_value: "",
            page: props.pageNo,
            size: "25",
            is_special: 1,
          };
        }

        specialProductDetails(specialProductDtls, dispatch, true);
      } else {
        Searchfilters(searchFilterReqObj, dispatch);
      }

      dispatch({
        type: "POPUP_MESSAGE_CLOSE",
        payload: false,
      });
    });
    // addItemToCartFnt(obj).then((res) => {
    //   fetchCartItem({
    //     sessionKey: userInfo.session_id,
    //   })(dispatch);

    //   if (
    //     props.inv_type == "static" ||
    //     (props.inv_type == "managed" &&
    //       parseInt(qty) + props.cart_qty <= props.quantity_available) ||
    //     (props.inv_type == "managed" &&
    //       parseInt(qty) + props.cart_qty <= props.customer_limit)
    //   ) {
    //     dispatch({
    //       type: "POPUP_MESSAGE_CLOSE",
    //       payload: false,
    //     });
    //   }
    // });
  };

  const secondayCallBack1 = () => {
    addAlltoCart("", true);
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  const addAlltoCart = async (element, override = false) => {
    let zeroQty = [];
    console.log(qty);
    qty.map((q, i) => {
      if (q != "" && q != undefined && q <= 0) {
        zeroQty.push(thisSatDetails.sku_dtls[i].sku);
      }
    });

    if (zeroQty.length > 0) {
      new popupClass(
        true,
        "Zero Qty choosen on Sku: " + zeroQty.join(","),
        "Added to  your cart",
        "",
        "",
        "",
        "",
        dispatch
      ).emitPopup();
      zeroQty = [];
    } else {
      const checkoutItem = [];
      let sufficient = true;
      thisSatDetails.sku_dtls.map((ele, index) => {
        if (qty[index] != "" && qty[index] != undefined) {
          // AddtoCartOnBlur("", ele, index);
          if (
            qty[index] !== "" &&
            parseInt(qty[index]) > parseInt(ele.quantity_available)
          ) {
            sufficient = false;
            new popupClass(
              true,
              "Insufficient QTY",
              "Added to  your cart",
              "",
              "",
              "",
              "",
              dispatch
            ).emitPopup();
            return;
          } else {
            let itemVal = {
              websku: ele.websku,
              items: [
                {
                  sku: ele.sku,
                  qty: qty[index],
                  override: override ? 1 : 0,
                },
              ],
            };
            checkoutItem.push(itemVal);
          }
        }
      });
      const payload = {
        bulk: checkoutItem,
        sessionKey: userInfo.session_id,
      };
      if (sufficient) {
        const result = await addToCartBulk(payload, dispatch);
        if (result.data.status) {
          if (result.data.flag !== "exist") {
            fetchCartItem({
              sessionKey: userInfo.session_id,
            })(dispatch);
            if (qty.length > 0) {
              qty.map((e, i) => (qty[i] = ""));
            }
            let searchFilterReqObj = {};
            if (props && typeof props.location != "undefined") {
              let ID = props.location.search.split("key=")[1];
              if (ID && typeof ID !== "undefined") {
                ID = ID.split("&")[0];
              }
              let routeName = props.location.pathname.split("/")[1];

              searchFilterReqObj = {
                sessionKey: userInfo.session_id,
                search_value: "",
                search_key: "",
                size: routeName == "furnance" ? "100" : "25",
                page: "1",
                cid: ID ? ID : "",
              };
            }

            if (props.location.pathname.includes("/specialProduct")) {
              let specialProductDtls = JSON.parse(
                sessionStorage.getItem("specialPage")
              );
              if (
                specialProductDtls === null ||
                specialProductDtls === "undefined" ||
                Object.keys(specialProductDtls).length === 0
              ) {
                specialProductDtls = {
                  sessionKey: userInfo.session_id,
                  cid: "",
                  search_key: "",
                  search_value: "",
                  page: props.pageNo,
                  size: "25",
                  is_special: 1,
                };
              }

              specialProductDetails(specialProductDtls, dispatch);
            } else {
              Searchfilters(searchFilterReqObj, dispatch);
            }
          } else {
            return Promise.reject();
          }
        } else {
          if (result.data.flag === "exist") {
            let stringValue = (
              <p>
                The Following Items already is in your Cart, Are you sure you
                want to add more? <br /> {result.data.skuDetail.join(",")}
              </p>
            );

            new popupClass(
              true,
              stringValue,
              "Oops !! Already Available in Cart",
              "No",
              "Yes",
              "ok-popup-app",
              "no-popup-app",
              dispatch,
              undefined,
              secondayCallBack1,
              "",
              "already-avail-cart",
              "already-add-action"
            ).emitPopup();
          } else {
            new toastifyClass(
              true,
              result.data.msg && typeof result.data.msg == "string"
                ? result.data.msg
                : result.data.detail && typeof result.data.detail == "string"
                ? result.data.detail
                : "",
              "error",
              dispatch
            ).emittoast();
          }
        }
      }
    }
  };

  const AddtoCartOnBlur = (e, data, index) => {
    if (data.inv_type === "live" && !furnaceTimereached) {
      let dateTimeDtls = `Sale starts at ${moment(furnaceData.startTiming)
        .utc()
        .format("Do MMMM YYYY, LT")} and ends at ${moment(furnaceData.endTiming)
        .utc()
        .format("Do MMMM YYYY, LT")}.  Please wait. Thanks.`;
      console.log("dateTimeDtls", dateTimeDtls);
      new popupClass(
        true,
        dateTimeDtls,
        "Oops !!! Sale Timings",
        "",
        "",
        "",
        "",
        dispatch
      ).emitPopup();
    } else {
      try {
        if (!data.cart_qty) {
          data.cart_qty = 0;
        }
        let addItemToartReqObj = {};
        let searchFilterReqObj = {};
        if (props && typeof props.location != "undefined") {
          let ID = props.location.search.split("key=")[1];
          if (ID && typeof ID !== "undefined") {
            ID = ID.split("&")[0];
          }
          let routeName = props.location.pathname.split("/")[1];

          searchFilterReqObj = {
            sessionKey: userInfo.session_id,
            search_value: "",
            search_key: "",
            size: routeName == "furnance" ? "100" : "25",
            page: "1",
            cid: ID ? ID : "",
          };
        }
        if (typeof props.location === "undefined")
          throw "props.location is undefined";
        if (typeof data === "undefined") throw "data is undefined";
        if (typeof data.cart_qty === "undefined")
          throw "data.cart_qty is undefined";
        if (data.cart_qty === "") throw "data.cart_qty is empty";
        if (qty[index] === "") throw "e.target.value is empty";
        if (data.cart_qty === 0) {
          if (
            qty[index] <= parseInt(data.quantity_available) &&
            qty[index] !== ""
          ) {
            addItemToartReqObj = {
              sessionKey: userInfo.session_id,
              webSKU: data.websku,
              SKU: data.sku,
              qty: qty[index],
              override: 0,
            };
          } else {
            if (
              !isNaN(parseInt(data.quantity_available)) &&
              data.inv_type != "static"
            ) {
              if (qty[index] !== "") {
                new popupClass(
                  true,
                  "Insufficient QTY",
                  "Added to  your cart",
                  "",
                  "",
                  "",
                  "",
                  dispatch
                ).emitPopup();
                return;
              }
            } else {
              addItemToartReqObj = {
                sessionKey: userInfo.session_id,
                webSKU: data.websku,
                SKU: data.sku,
                qty: qty[index],
                override: 0,
              };
            }
          }
        } else {
          if (
            qty[index] <= parseInt(data.quantity_available) &&
            qty[index] !== ""
          ) {
            addItemToartReqObj = {
              sessionKey: userInfo.session_id,
              webSKU: data.websku,
              SKU: data.sku,
              qty: qty[index],
              override: 0,
            };
          } else {
            if (!isNaN(parseInt(data.quantity_available))) {
              if (qty[index] !== "") {
                new popupClass(
                  true,
                  "Insufficient QTY",
                  "Added to  your cart",
                  "",
                  "",
                  "",
                  "",
                  dispatch
                ).emitPopup();
                return;
              }
            } else {
              addItemToartReqObj = {
                sessionKey: userInfo.session_id,
                webSKU: data.websku,
                SKU: data.sku,
                qty: qty[index],
                override: 0,
              };
            }
          }
        }

        setCpopupData(addItemToartReqObj);
        localStorage.setItem("addProduct", JSON.stringify(addItemToartReqObj));

        addItemToCart(
          addItemToartReqObj,
          dispatch,
          secondayCallBack,
          undefined,
          deleteWishlistPopupCallback
        )
          .then((res) => {
            qty[index] = "";
            fetchCartItem({
              sessionKey: userInfo.session_id,
            })(dispatch);
            if (props.location.pathname.includes("/specialProduct")) {
              let specialProductDtls = JSON.parse(
                sessionStorage.getItem("specialPage")
              );
              if (
                specialProductDtls === null ||
                specialProductDtls === "undefined" ||
                Object.keys(specialProductDtls).length === 0
              ) {
                specialProductDtls = {
                  sessionKey: userInfo.session_id,
                  cid: "",
                  search_key: "",
                  search_value: "",
                  page: props.pageNo,
                  size: "25",
                  is_special: 1,
                };
              }

              specialProductDetails(specialProductDtls, dispatch, true);
            } else {
              Searchfilters(searchFilterReqObj, dispatch);
            }
          })
          .catch((error) => {
            throw "API ERROR";
          });
      } catch (e) {
        console.log(e);
        // new toastifyClass(true, e, "error", dispatch).emittoast();
      }
    }
  };
  const handleTextAreaOpen = (data) => {
    setTextAreaData(data);
    setTextAreaOpen(true);
  };
  const handleTextAreaClose = (e) => {
    setTextAreaData({});
    setTextAreaOpen(false);
  };
  const handleCommentValue = (commentVal) => {
    let valueComts = {
      sessionKey: userInfo.session_id,
      websku: props.details.websku,
      comment: commentVal,
      sku: textAreaData.sku ? textAreaData.sku : "",
    };
    addComment(valueComts, dispatch)
      .then((res) => {
        if (props && typeof props.location != "undefined") {
          // let ID = props.location.search.split("key=")[1];
          // if (ID && typeof ID !== "undefined"){
          //   ID = ID.split("&")[0];
          // }
          // let routeName = props.location.pathname.split("/")[1];

          // let obj = {
          //   sessionKey: userInfo.session_id,
          //   search_value: "",
          //   search_key: "",
          //   size: "25",
          //   page: routeName == "furnance" ? "100" : "25",
          //   cid: ID ? ID : "",
          // };
          // Searchfilters(obj, dispatch);
          fetchCartItem({
            sessionKey: userInfo.session_id,
          })(dispatch);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    handleTextAreaClose();
  };
  const wishlistCLick = (data, toggle) => {
    let dskuArr = [...skuArr];
    let skuIndex = dskuArr.findIndex((ele) => {
      return ele.sku === data.sku;
    });
    if (data.wishlist) {
      if (typeof data.wishlist_id != "undefined") {
        let obj = {
          sessionKey: userInfo.session_id,
          id: data.wishlist_id,
        };
        deleteWatchList(obj, dispatch).then(function () {
          if (props && typeof props.location != "undefined") {
            let ID = props.location.search.split("key=")[1];
            if (ID && typeof ID !== "undefined") {
              ID = ID.split("&")[0];
            }
            let routeName = props.location.pathname.split("/")[1];

            let obj = {
              sessionKey: userInfo.session_id,
              search_value: "",
              search_key: "",
              size: routeName == "furnance" ? "100" : "25",
              page: "1",
              cid: ID ? ID : "",
            };
            Searchfilters(obj, dispatch);
            let wish_req = {
              sessionKey: userInfo.session_id,
              search_value: "",
              search_key: "",
              size: 5,
              page: 1,
              sort: "",
              dir: "",
            };
            wishlistList(wish_req, dispatch);
          }
        });
      } else {
        console.log("data.wishlist_id is undefined");
      }
    }
    if (skuIndex >= 0) {
      dskuArr.splice(skuIndex, 1);
    } else {
      dskuArr.push({ sku: data.sku, qty: 1 });
    }
    setSkuArr(dskuArr);
  };
  const handleWatchlist = () => {
    try {
      if (typeof props["details"]["websku"] != "undefined") {
        let reqObj = {
          sessionKey: userInfo.session_id,
          websku: props["details"]["websku"],
          items: skuArr,
        };
        AddToWatchList(reqObj, dispatch).then(function (res) {
          if (props && typeof props.location != "undefined") {
            let mainNav = sessionStorage.getItem("mainNav");
            let stockAvl = JSON.parse(sessionStorage.getItem("stockAvl"));
            let ID = props.location.search.split("key=")[1];
            if (ID && typeof ID !== "undefined") {
              ID = ID.split("&")[0];
            }
            let routeName = props.location.pathname.split("/")[1];

            let obj = {
              sessionKey: userInfo.session_id,
              search_value: "",
              search_key: "",
              size: routeName == "furnance" ? "100" : "25",
              page: "1",
              // size: mainNav == "The Furnace" ? "25" : stockAvl + 3000,
              // page: mainNav == "The Furnace" ? "1" : "",
              cid: ID ? ID : "",
            };
            Searchfilters(obj, dispatch);
            let wish_req = {
              sessionKey: userInfo.session_id,
              search_value: "",
              search_key: "",
              size: 5,
              page: 1,
              sort: "",
              dir: "",
            };
            wishlistList(wish_req, dispatch);
          }
        });
      } else {
        throw "ebsku is null - Cannot submit";
      }
    } catch (e) {
      let err =
        typeof e === "string" ? e : "unlnown error - wishlist add APi function";
      new toastifyClass(true, err, "error", dispatch).emittoast();
    }
  };

  const deleteWishlistClose = () => {
    setCpopup(false);
  };
  // ----------------- END -------------------------- //

  // ----------------- Helper Functions ------------- //
  const ifChecked = (data) => {
    let index = skuArr.findIndex((ele) => ele.sku === data.sku);
    return index >= 0 ? true : false;
  };
  // ----------------- END ------------- //

  // ---------- USE EFECTS -------------------------- //
  //console.log(skuArr, "this is the asku arr");
  useEffect(() => {
    return () => {
      setSkuArr([]);
    };
  }, []);

  useEffect(() => {
    try {
      if (props.details && typeof props.details != "undefined") {
        console.log("props.details");
        setThisSatDetails(props.details);
        if (typeof props.details.sku_dtls != "undefined") {
          if (Array.isArray(props.details.sku_dtls)) {
            if (props.details.sku_dtls.length) {
              setSkuArr([
                ...skuArr,
                ...props.details.sku_dtls
                  .map((ele) => {
                    return {
                      sku: ele.sku,
                      wishlist: ele.wishlist,
                      override: ele.cart_qty > 0 ? 1 : 0,
                    };
                  })
                  .filter((ele) => ele.wishlist),
              ]);
            } else {
              throw "props.details.sku_dtls an empty array";
            }
          } else {
            throw "props.details.sku_dtls is not an array";
          }
        } else {
          if (typeof props.details.sku != "undefined") {
            if (typeof props.details.wishlist != "undefined") {
              if (props.details.wishlist) {
                let skuA = [{ sku: props.details.sku }];
                setSkuArr([...skuArr, ...skuA]);
              }
            } else {
              throw "props.details.wishlist is undefined";
            }
          } else {
            throw "props.details.sku is undefined";
          }
        }
      } else {
        throw "props.details is undefined";
      }
    } catch (e) {
      console.log(e, "errrrrorrrrr");
    }
  }, [props.details]);

  useEffect(() => {
    try {
      if (typeof lists.data !== "undefined") {
        if (Array.isArray(lists.data)) {
          if (lists.data.length) {
            let currentData;
            if (
              props &&
              typeof props !== "undefined" &&
              props.history.location.pathname == "/furnance"
            ) {
              currentData = lists.data[0].find(
                (ele) => ele["websku"] === props["details"]["websku"]
              );
            } else {
              currentData = lists.data.find(
                (ele) => ele["websku"] === props["details"]["websku"]
              );
            }

            if (typeof currentData !== "undefined") {
              if (Object.keys(currentData).length) {
                setThisSatDetails(currentData);
              } else {
                throw "currentdata is an empty object";
              }
            } else {
              throw "currentdata is undefined";
            }
          } else {
            throw "lists.data is an empty array";
          }
        } else {
          throw "lists.data is not a Array";
        }
      } else {
        throw "lists.data is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  }, [lists]);

  useEffect(() => {
    try {
      if (thisSatDetails.sku_dtls && thisSatDetails.sku_dtls.length > 0) {
        let variableChanged = false;
        let newSatDetails = [];
        thisSatDetails.sku_dtls.map((eles) => {
          let cart_data = cartDetails.items.filter((item) =>
            item.sku_dtls.sku.includes(eles.sku.toString())
          );
          if (cart_data.length > 0) {
            variableChanged = true;
            eles.cart_qty = cart_data[0].sku_dtls.owned_quantity;
            eles.comments = cart_data[0].sku_dtls.comment;
            // eles.quantity_available = cart_data[0].sku_dtls.quantity_available;
            newSatDetails.push(eles);
          } else {
            eles.cart_qty = 0;
            eles.comments = "";
            newSatDetails.push(eles);
          }
        });
        if (variableChanged) {
          console.log("useEffect newSatDetails", newSatDetails);
          thisSatDetails.sku_dtls = newSatDetails;
        }
      } else if (thisSatDetails && thisSatDetails.inv_type == "live") {
        let cart_data = cartDetails.items.filter((item) =>
          item.sku_dtls.sku.includes(thisSatDetails.sku.toString())
        );
        if (cart_data.length > 0) {
          thisSatDetails.cart_qty = cart_data[0].sku_dtls.owned_quantity;
          thisSatDetails.comments = cart_data[0].sku_dtls.comment;
          thisSatDetails.quantity_available =
            cart_data[0].sku_dtls.quantity_available;
        }
      }
    } catch (err) {
      console.log(err, "==> this is cart err");
    }
  }, [cartDetails, thisSatDetails]);

  useEffect(() => {
    // if(thisSatDetails.image) {
    //   if(Array.isArray(thisSatDetails.image)) {
    //   thisSatDetails.image[0] = thisSatDetails.image[0].replace('/thumb',"")
    //   } else {
    //   thisSatDetails.image = thisSatDetails.image.replace('/thumb',"")
    //   }
    if (thisSatDetails.image) {
      // if (Array.isArray(thisSatDetails.image)) {
      //   setImg(thisSatDetails.image[0].replace("/thumb", ""));
      // } else {
      //   if (
      //     thisSatDetails.product_categories_vir &&
      //     thisSatDetails.product_categories_vir.includes("FURNACE")
      //   ) {
      //     setImg(thisSatDetails.image.replace("/thumb", "/thumb/compress"));
      //   } else {
      //     setImg(thisSatDetails.image.replace("/thumb", ""));
      //   }
      // }

      //For live site
      if (Array.isArray(thisSatDetails.image)) {
        setImg(thisSatDetails.image[0].replace("/thumb", "/thumb/compress"));
      } else {
        setImg(thisSatDetails.image.replace("/thumb", "/thumb/compress"));
      }

      //For test site
      // if (Array.isArray(thisSatDetails.image)) {
      //   setImg(thisSatDetails.image[0].replace("/thumb", ""));
      // } else {
      //     setImg(thisSatDetails.image.replace("/thumb", ""));
      // }
    }
  }, [thisSatDetails]);

  useEffect(() => {
    try {
      if (addtocartoberride) {
        if (typeof cpopupData != "undefined") {
          if (Object.keys(cpopupData).length) {
            addItemToCart(
              {
                sessionKey: cpopupData.sessionKey,
                webSKU: cpopupData.webSKU,
                SKU: cpopupData.SKU,
                qty: parseInt(cpopupData.qty),
                override: 1,
              },
              dispatch
            )
              .then((res) => {
                if (props && typeof props.location != "undefined") {
                  let ID = props.location.search.split("key=")[1];
                  if (ID && typeof ID !== "undefined") {
                    ID = ID.split("&")[0];
                  }
                  let routeName = props.location.pathname.split("/")[1];

                  let obj = {
                    sessionKey: userInfo.session_id,
                    search_value: "",
                    search_key: "",
                    size: routeName == "furnance" ? "100" : "25",
                    page: "1",
                    cid: ID ? ID : "",
                  };
                  Searchfilters(obj, dispatch).then(() => {
                    setAddtocartoberride(false);
                    dispatch({
                      type: "POPUP_MESSAGE_CLOSE",
                      payload: false,
                    });
                  });
                }
              })
              .catch((error) => {
                throw "API ERROR";
              })
              .then(function () {
                setCpopup(false);
              })
              .catch((error) => {
                console.log(error);
                throw "API ERROR";
              });
          } else {
            throw "cpopupData is empty";
          }
        } else {
          throw "cpopupData is undefined";
        }
      } else {
        throw "addtocartoberride is false";
      }
    } catch (e) {}
  }, [addtocartoberride]);
  // ---------- END -------------------------- //

  const imgUrl = () => {
    if (thisSatDetails.inv_item == 1 || thisSatDetails.inv_item == 2) {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/live/${thisSatDetails.image}`;
    } else {
      return `http://seadwellnode.auctionsoftware.com/uploads/images/product/static/${thisSatDetails.image}`;
    }
  };

  const imageError = (e) => {
    return (e.target.src =
      "https://auctionsoftwaremarketplace.com/seadwelling/images/default/NoImageAvailable.jpg");
  };

  return (
    <div>
      {typeof thisSatDetails !== "undefined" &&
      Object.keys(thisSatDetails).length ? (
        <Dialog
          open={props.value}
          fullWidth="true"
          maxWidth="md"
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title" className="popup-header">
            <span className="popup-title">
              {thisSatDetails ? thisSatDetails.name : ""}
            </span>
            <Close
              className="float-right popup-close"
              onClick={(e) => handleClose(e) && props.onpop1(e)}
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="row">
                <div className="col-md-5 prdt-img">
                  <img
                    src={img}
                    onError={imageError}
                    alt="noimage"
                    className={`popup-image`}
                  />
                </div>
                <div className="col-md-7 tblWrp">
                  <div id="mx-3i" className="ml-3">
                    <div>
                      <h3 className="admrh">
                        {thisSatDetails ? thisSatDetails.description : ""}
                      </h3>
                      {/* <p>SDC FISH Value Pack 1 - 1 Starry Blenny</p> */}
                      <p className="admr">
                        {" "}
                        Websku: {thisSatDetails ? thisSatDetails.websku : ""}
                      </p>

                      {thisSatDetails.n_price == 1 && (
                        <>
                          <table className="popup-table admr">
                            <thead>
                              <tr>
                                <th>Size</th>
                                <th>Qty Avail</th>
                                <th>Price</th>
                                <th className={"prodt-popup-input"}>Order</th>
                                <th>Comment</th>
                                {/* {thisSatDetails &&
                            thisSatDetails.sku_dtls &&
                            thisSatDetails &&
                            thisSatDetails.sku_dtls.length ? (
                              thisSatDetails.sku_dtls.map((ele, ind) => {
                                return (
                                  <th>{ele.size === "" ? "-" : ele.size}</th>
                                );
                              })
                            ) : (
                              <th>S</th>
                            )} */}
                              </tr>
                            </thead>
                            <tbody>
                              {thisSatDetails &&
                              thisSatDetails.sku_dtls &&
                              thisSatDetails.sku_dtls.length ? (
                                thisSatDetails.sku_dtls.map((ele, index) => {
                                  if (ele.lotprice != "1") {
                                    return (
                                      <tr>
                                        <td>{ele.size}</td>
                                        <td>
                                          {ele && ele.inv_type != "static"
                                            ? ele.quantity_available
                                            : "-"}
                                        </td>
                                        <td>
                                          {ele.special_price &&
                                          parseFloat(ele.retail_price) >
                                            parseFloat(ele.special_price) ? (
                                            <>
                                              <span
                                                style={{
                                                  textDecoration:
                                                    "line-through",
                                                }}
                                              >
                                                {ele.retail_price}
                                              </span>
                                              <br />
                                              <span style={{ color: "red" }}>
                                                {ele.special_price}
                                              </span>
                                            </>
                                          ) : (
                                            ele.retail_price
                                          )}
                                        </td>
                                        <td className="furnace-inputarea">
                                          <div className="onRight ItemSmallView">
                                            {ele.cart_qty &&
                                            ele.cart_qty > 0 ? (
                                              <div className="dots addedCrtImgPopup">
                                                <img src="/assets/images/aedCrt.png" />
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            <div className="itemPopupPlusMinusButton">
                                              <Button
                                                style={{ height: "45px" }}
                                                disabled={
                                                  userInfo.detail.cities ===
                                                    "Y" && ele.cities === "Y"
                                                }
                                                onClick={(e) =>
                                                  parseInt(qty[index]) > 0
                                                    ? parseInt(qty[index]) === 1
                                                      ? qtyChange(
                                                          e,
                                                          ele,
                                                          index,
                                                          ""
                                                        )
                                                      : qtyChange(
                                                          e,
                                                          ele,
                                                          index,
                                                          `${
                                                            parseInt(
                                                              qty[index]
                                                            ) - 1
                                                          }`
                                                        )
                                                    : ""
                                                }
                                              >
                                                <span className="material-icons">
                                                  remove
                                                </span>
                                              </Button>
                                            </div>
                                            <div className="orderInput">
                                              <InputField
                                                type="number"
                                                value={qty[index]}
                                                disabled={
                                                  userInfo.detail.cities ===
                                                    "Y" && ele.cities === "Y"
                                                }
                                                // placeholder={ele.cart_qty}
                                                name={thisSatDetails.sku}
                                                // onBlur={(e) => {
                                                //   AddtoCartOnBlur(e, ele, index);
                                                // }}
                                                onChange={(e) => {
                                                  qtyChange(e, ele, index);
                                                }}
                                              />
                                            </div>
                                            <div className="itemPopupPlusMinusButton">
                                              <Button
                                                style={{ height: "45px" }}
                                                disabled={
                                                  userInfo.detail.cities ===
                                                    "Y" && ele.cities === "Y"
                                                }
                                                onClick={(e) =>
                                                  qty[index] === "" ||
                                                  qty[index] === undefined
                                                    ? qtyChange(
                                                        e,
                                                        ele,
                                                        index,
                                                        "1"
                                                      )
                                                    : qtyChange(
                                                        e,
                                                        ele,
                                                        index,
                                                        `${
                                                          parseInt(qty[index]) +
                                                          1
                                                        }`
                                                      )
                                                }
                                              >
                                                <span className="material-icons">
                                                  add
                                                </span>
                                              </Button>
                                            </div>
                                            <div className="orderAddButton">
                                              <PrimaryButton
                                                disabled={
                                                  !isOrderChanged[index] ||
                                                  (userInfo.detail.cities ===
                                                    "Y" &&
                                                    ele.cities === "Y")
                                                }
                                                // disabled={!isOrderChanged[index]}
                                                onClick={(e) => {
                                                  AddtoCartOnBlur(
                                                    e,
                                                    ele,
                                                    index
                                                  );
                                                }}
                                                style={{ height: "45px" }}
                                              >
                                                <span className="material-icons">
                                                  add_shopping_cart
                                                </span>
                                              </PrimaryButton>
                                            </div>
                                            <span className="tooltip-inner">
                                              {ele.sku}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div>
                                            {ele.cart_qty &&
                                            ele.cart_qty > 0 ? (
                                              <PrimaryButton
                                                type="button"
                                                btnSize="small"
                                                onsubmit={(e) => {
                                                  handleTextAreaOpen(ele);
                                                }}
                                              >
                                                {ele.comments != "" &&
                                                ele.comments
                                                  ? "View"
                                                  : "Add"}
                                              </PrimaryButton>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </td>

                                        {/* 
                           

                            {thisSatDetails &&
                            thisSatDetails.sku_dtls &&
                            thisSatDetails.sku_dtls.length ? (
                              thisSatDetails.sku_dtls.map((ele) => {
                                return (
                                  <td>
                                    <CustomCheckBox
                                      checked={ifChecked(ele)}
                                      onclick={(e) => wishlistCLick(ele, "1")}
                                      className="mx-5"
                                    />
                                  </td>
                                );
                              })
                            ) : (
                              <td>
                                <CustomCheckBox
                                  checked={ifChecked(thisSatDetails)}
                                  onclick={(e) =>
                                    wishlistCLick(thisSatDetails, 0)
                                  }
                                  className="mx-5 "
                                />
                              </td>
                            )}
                          */}
                                      </tr>
                                    );
                                  } else {
                                    return null;
                                  }
                                })
                              ) : thisSatDetails &&
                                thisSatDetails.inv_type == "live" ? (
                                <tr>
                                  <td>{thisSatDetails.size}</td>
                                  <td>{thisSatDetails.quantity_available}</td>
                                  <td>{thisSatDetails.retail_price}</td>
                                  <td>
                                    <div
                                      className={`onRight ItemSmallView ${
                                        props &&
                                        typeof props.fromDate !== "undefined" &&
                                        typeof props.toDate !== "undefined" &&
                                        moment(new Date())
                                          .tz("America/Los_Angeles")
                                          .isBetween(
                                            moment.tz(
                                              moment(props.fromDate)
                                                .utc()
                                                .format("YYYY-MM-DD HH:mm:ss"),
                                              "America/Los_Angeles"
                                            ),
                                            moment.tz(
                                              moment(props.toDate)
                                                .utc()
                                                .format("YYYY-MM-DD HH:mm:ss"),
                                              "America/Los_Angeles"
                                            )
                                          )
                                          ? ""
                                          : "furnance-no-sale"
                                      }`}
                                    >
                                      {thisSatDetails.cart_qty &&
                                      thisSatDetails.cart_qty > 0 ? (
                                        <div className="dots addedCrtImgPopup">
                                          <img src="/assets/images/aedCrt.png" />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {props &&
                                      typeof props.fromDate !== "undefined" &&
                                      typeof props.toDate !== "undefined" &&
                                      moment(new Date())
                                        .tz("America/Los_Angeles")
                                        .isBetween(
                                          moment.tz(
                                            moment(props.fromDate)
                                              .utc()
                                              .format("YYYY-MM-DD HH:mm:ss"),
                                            "America/Los_Angeles"
                                          ),
                                          moment.tz(
                                            moment(props.toDate)
                                              .utc()
                                              .format("YYYY-MM-DD HH:mm:ss"),
                                            "America/Los_Angeles"
                                          )
                                        ) ? (
                                        <>
                                          <div className="itemPopupPlusMinusButton">
                                            <Button
                                              style={{ height: "45px" }}
                                              disabled={
                                                userInfo.detail.cities ===
                                                  "Y" &&
                                                thisSatDetails.cities === "Y"
                                              }
                                              onClick={(e) =>
                                                parseInt(qty[0]) > 0
                                                  ? parseInt(qty[0]) === 1
                                                    ? qtyChange(
                                                        e,
                                                        thisSatDetails,
                                                        0,
                                                        ""
                                                      )
                                                    : qtyChange(
                                                        e,
                                                        thisSatDetails,
                                                        0,
                                                        `${
                                                          parseInt(qty[0]) - 1
                                                        }`
                                                      )
                                                  : ""
                                              }
                                            >
                                              <span className="material-icons">
                                                remove
                                              </span>
                                            </Button>
                                          </div>
                                          <div className="orderInput">
                                            <InputField
                                              value={qty[0]}
                                              disabled={
                                                userInfo.detail.cities ===
                                                  "Y" &&
                                                thisSatDetails.cities === "Y"
                                              }
                                              // placeholder={thisSatDetails.cart_qty}
                                              // onBlur={(e) => {
                                              //   AddtoCartOnBlur(e, thisSatDetails, 0);
                                              // }}
                                              onChange={(e) => {
                                                qtyChange(e, thisSatDetails, 0);
                                              }}
                                            />
                                          </div>
                                          <div className="itemPopupPlusMinusButton">
                                            <Button
                                              style={{ height: "45px" }}
                                              disabled={
                                                userInfo.detail.cities ===
                                                  "Y" &&
                                                thisSatDetails.cities === "Y"
                                              }
                                              onClick={(e) =>
                                                qty[0] === "" ||
                                                qty[0] === undefined
                                                  ? qtyChange(
                                                      e,
                                                      thisSatDetails,
                                                      0,
                                                      "1"
                                                    )
                                                  : qtyChange(
                                                      e,
                                                      thisSatDetails,
                                                      0,
                                                      `${parseInt(qty[0]) + 1}`
                                                    )
                                              }
                                            >
                                              <span className="material-icons">
                                                add
                                              </span>
                                            </Button>
                                          </div>
                                          <div className="orderAddButton">
                                            <PrimaryButton
                                              // disabled={!isOrderChanged[0]}
                                              disabled={
                                                !isOrderChanged[0] ||
                                                (userInfo.detail.cities ===
                                                  "Y" &&
                                                  thisSatDetails.cities === "Y")
                                              }
                                              onClick={(e) => {
                                                AddtoCartOnBlur(
                                                  e,
                                                  thisSatDetails,
                                                  0
                                                );
                                              }}
                                              style={{ height: "45px" }}
                                            >
                                              <span className="material-icons">
                                                add_shopping_cart
                                              </span>
                                            </PrimaryButton>
                                          </div>
                                        </>
                                      ) : (
                                        <p className="qty-notice">
                                          The order quantity box will appear
                                          only when the sale starts.
                                        </p>
                                      )}
                                      <span className="tooltip-inner">
                                        {thisSatDetails.websku}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {thisSatDetails.cart_qty &&
                                      thisSatDetails.cart_qty > 0 ? (
                                        <PrimaryButton
                                          type="button"
                                          btnSize="small"
                                          onsubmit={(e) => {
                                            handleTextAreaOpen(thisSatDetails);
                                          }}
                                        >
                                          {thisSatDetails &&
                                          thisSatDetails.comments != "" &&
                                          thisSatDetails.comments
                                            ? "View"
                                            : "Add"}
                                        </PrimaryButton>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {/* <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <div className="orderAllAddButton">
                                <PrimaryButton
                                  style={{
                                    height: "45px",
                                  }}
                                  onClick={addAlltoCart}
                                  disabled={
                                    !(
                                      qty.length > 0 &&
                                      qty.filter((ele) => ele != "").length > 0
                                    )
                                  }
                                >
                                  Add ALL
                                </PrimaryButton>
                              </div>
                            </td>
                            <td></td>
                          </tr> */}
                            </tbody>
                          </table>
                        </>
                      )}

                      {thisSatDetails.has_lot == 1 && (
                        <>
                          <h3 style={{ color: "red", marginTop: "20px" }}>
                            Lot Pricing available
                          </h3>
                          <table
                            className="popup-table admr"
                            style={{ marginTop: "8px" }}
                          >
                            <thead>
                              <tr>
                                <th>Size</th>
                                <th>Qty Avail</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th className={"prodt-popup-input"}>Order</th>
                                <th>Comment</th>
                              </tr>
                            </thead>
                            <tbody>
                              {thisSatDetails &&
                                thisSatDetails.sku_dtls &&
                                thisSatDetails.sku_dtls.length &&
                                thisSatDetails.sku_dtls.map((ele, index) => {
                                  if (ele.lotprice == "1") {
                                    return Object.keys(
                                      ele.lot_dtls.Quantity
                                    ).map((val, i) => {
                                      if (i == 0) {
                                        return (
                                          <tr>
                                            <td
                                              rowspan={
                                                Object.keys(
                                                  ele.lot_dtls.Quantity
                                                ).length
                                              }
                                            >
                                              {ele.size}
                                            </td>
                                            <td
                                              rowspan={
                                                Object.keys(
                                                  ele.lot_dtls.Quantity
                                                ).length
                                              }
                                            >
                                              {ele && ele.inv_type != "static"
                                                ? ele.quantity_available
                                                : "-"}
                                            </td>
                                            <td>
                                              {ele.lot_dtls.Quantity[val]}
                                            </td>
                                            <td>{ele.lot_dtls.price[val]}</td>
                                            <td
                                              rowspan={
                                                Object.keys(
                                                  ele.lot_dtls.Quantity
                                                ).length
                                              }
                                              className="furnace-inputarea"
                                            >
                                              <div className="onRight ItemSmallView">
                                                {ele.cart_qty &&
                                                ele.cart_qty > 0 ? (
                                                  <div className="dots addedCrtImgPopup">
                                                    <img src="/assets/images/aedCrt.png" />
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                <div className="itemPopupPlusMinusButton">
                                                  <Button
                                                    style={{ height: "45px" }}
                                                    disabled={
                                                      userInfo.detail.cities ===
                                                        "Y" &&
                                                      ele.cities === "Y"
                                                    }
                                                    onClick={(e) =>
                                                      parseInt(qty[index]) > 0
                                                        ? parseInt(
                                                            qty[index]
                                                          ) === 1
                                                          ? qtyChange(
                                                              e,
                                                              ele,
                                                              index,
                                                              ""
                                                            )
                                                          : qtyChange(
                                                              e,
                                                              ele,
                                                              index,
                                                              `${
                                                                parseInt(
                                                                  qty[index]
                                                                ) - 1
                                                              }`
                                                            )
                                                        : ""
                                                    }
                                                  >
                                                    <span className="material-icons">
                                                      remove
                                                    </span>
                                                  </Button>
                                                </div>
                                                <div className="orderInput">
                                                  <InputField
                                                    c
                                                    type="number"
                                                    value={qty[index]}
                                                    disabled={
                                                      userInfo.detail.cities ===
                                                        "Y" &&
                                                      ele.cities === "Y"
                                                    }
                                                    // placeholder={ele.cart_qty}
                                                    name={thisSatDetails.sku}
                                                    // onBlur={(e) => {
                                                    //   AddtoCartOnBlur(e, ele, index);
                                                    // }}
                                                    onChange={(e) => {
                                                      qtyChange(e, ele, index);
                                                    }}
                                                  />
                                                </div>
                                                <div className="itemPopupPlusMinusButton">
                                                  <Button
                                                    style={{ height: "45px" }}
                                                    disabled={
                                                      userInfo.detail.cities ===
                                                        "Y" &&
                                                      ele.cities === "Y"
                                                    }
                                                    onClick={(e) =>
                                                      qty[index] === "" ||
                                                      qty[index] === undefined
                                                        ? qtyChange(
                                                            e,
                                                            ele,
                                                            index,
                                                            "1"
                                                          )
                                                        : qtyChange(
                                                            e,
                                                            ele,
                                                            index,
                                                            `${
                                                              parseInt(
                                                                qty[index]
                                                              ) + 1
                                                            }`
                                                          )
                                                    }
                                                  >
                                                    <span className="material-icons">
                                                      add
                                                    </span>
                                                  </Button>
                                                </div>
                                                <div className="orderAddButton">
                                                  <PrimaryButton
                                                    // disabled={!isOrderChanged[index]}
                                                    disabled={
                                                      !isOrderChanged[index] ||
                                                      (userInfo.detail
                                                        .cities === "Y" &&
                                                        ele.cities === "Y")
                                                    }
                                                    onClick={(e) => {
                                                      AddtoCartOnBlur(
                                                        e,
                                                        ele,
                                                        index
                                                      );
                                                    }}
                                                    style={{ height: "45px" }}
                                                  >
                                                    <span className="material-icons">
                                                      add_shopping_cart
                                                    </span>
                                                  </PrimaryButton>
                                                </div>
                                                <span className="tooltip-inner">
                                                  {ele.sku}
                                                </span>
                                              </div>
                                            </td>
                                            <td
                                              rowspan={
                                                Object.keys(
                                                  ele.lot_dtls.Quantity
                                                ).length
                                              }
                                            >
                                              <div>
                                                {ele.cart_qty &&
                                                ele.cart_qty > 0 ? (
                                                  <PrimaryButton
                                                    type="button"
                                                    btnSize="small"
                                                    onsubmit={(e) => {
                                                      handleTextAreaOpen(ele);
                                                    }}
                                                  >
                                                    {ele.comments != "" &&
                                                    ele.comments
                                                      ? "View"
                                                      : "Add"}
                                                  </PrimaryButton>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      } else {
                                        return (
                                          <tr>
                                            <td>
                                              {ele.lot_dtls.Quantity[val]}
                                            </td>
                                            <td>{ele.lot_dtls.price[val]}</td>
                                          </tr>
                                        );
                                      }
                                    });
                                  }
                                })}
                            </tbody>
                          </table>
                        </>
                      )}

                      {thisSatDetails.has_lot == undefined &&
                        thisSatDetails.n_price == undefined && (
                          <>
                            <table className="popup-table admr">
                              <thead>
                                <tr>
                                  <th>Size</th>
                                  <th>Qty Avail</th>
                                  <th>Price</th>
                                  <th className={"prodt-popup-input"}>Order</th>
                                  <th>Comment</th>

                                  {/* {thisSatDetails &&
                            thisSatDetails.sku_dtls &&
                            thisSatDetails &&
                            thisSatDetails.sku_dtls.length ? (
                              thisSatDetails.sku_dtls.map((ele, ind) => {
                                return (
                                  <th>{ele.size === "" ? "-" : ele.size}</th>
                                );
                              })
                            ) : (
                              <th>S</th>
                            )} */}
                                </tr>
                              </thead>
                              <tbody>
                                {thisSatDetails &&
                                thisSatDetails.inv_type == "live" ? (
                                  <tr>
                                    <td>{thisSatDetails.size}</td>
                                    <td>{thisSatDetails.quantity_available}</td>
                                    {thisSatDetails.special_price &&
                                    parseFloat(thisSatDetails.retail_price) >
                                      parseFloat(
                                        thisSatDetails.special_price
                                      ) ? (
                                      <td>
                                        <span
                                          className="furnace-price"
                                          style={{
                                            textDecoration: "line-through",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          ${thisSatDetails.retail_price}
                                        </span>
                                        <br></br>
                                        <span
                                          className="furnace-price"
                                          style={{
                                            color: "red",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          ${thisSatDetails.special_price}
                                        </span>
                                      </td>
                                    ) : (
                                      <td>{thisSatDetails.retail_price}</td>
                                    )}
                                    <td>
                                      <div
                                        className={`onRight ItemSmallView ${
                                          furnaceTimereached
                                            ? ""
                                            : "furnance-no-sale"
                                        }`}
                                      >
                                        {thisSatDetails.cart_qty &&
                                        thisSatDetails.cart_qty > 0 ? (
                                          <div className="dots addedCrtImgPopup">
                                            <img src="/assets/images/aedCrt.png" />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {furnaceTimereached ? (
                                          <>
                                            <div className="itemPopupPlusMinusButton">
                                              <Button
                                                style={{ height: "45px" }}
                                                disabled={
                                                  userInfo.detail.cities ===
                                                    "Y" &&
                                                  thisSatDetails.cities === "Y"
                                                }
                                                onClick={(e) =>
                                                  parseInt(qty[0]) > 0
                                                    ? parseInt(qty[0]) === 1
                                                      ? qtyChange(
                                                          e,
                                                          thisSatDetails,
                                                          0,
                                                          ""
                                                        )
                                                      : qtyChange(
                                                          e,
                                                          thisSatDetails,
                                                          0,
                                                          `${
                                                            parseInt(qty[0]) - 1
                                                          }`
                                                        )
                                                    : ""
                                                }
                                              >
                                                <span className="material-icons">
                                                  remove
                                                </span>
                                              </Button>
                                            </div>
                                            <div className="orderInput">
                                              <InputField
                                                value={qty[0]}
                                                disabled={
                                                  userInfo.detail.cities ===
                                                    "Y" &&
                                                  thisSatDetails.cities === "Y"
                                                }
                                                onChange={(e) => {
                                                  qtyChange(
                                                    e,
                                                    thisSatDetails,
                                                    0
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="itemPopupPlusMinusButton">
                                              <Button
                                                style={{ height: "45px" }}
                                                disabled={
                                                  userInfo.detail.cities ===
                                                    "Y" &&
                                                  thisSatDetails.cities === "Y"
                                                }
                                                onClick={(e) =>
                                                  qty[0] === "" ||
                                                  qty[0] === undefined
                                                    ? qtyChange(
                                                        e,
                                                        thisSatDetails,
                                                        0,
                                                        "1"
                                                      )
                                                    : qtyChange(
                                                        e,
                                                        thisSatDetails,
                                                        0,
                                                        `${
                                                          parseInt(qty[0]) + 1
                                                        }`
                                                      )
                                                }
                                              >
                                                <span className="material-icons">
                                                  add
                                                </span>
                                              </Button>
                                            </div>
                                            <div className="orderAddButton">
                                              <PrimaryButton
                                                // disabled={!isOrderChanged[0]}
                                                disabled={
                                                  !isOrderChanged[0] ||
                                                  (userInfo.detail.cities ===
                                                    "Y" &&
                                                    thisSatDetails.cities ===
                                                      "Y")
                                                }
                                                onClick={(e) => {
                                                  AddtoCartOnBlur(
                                                    e,
                                                    thisSatDetails,
                                                    0
                                                  );
                                                }}
                                                style={{ height: "45px" }}
                                              >
                                                <span className="material-icons">
                                                  add_shopping_cart
                                                </span>
                                              </PrimaryButton>
                                            </div>
                                          </>
                                        ) : (
                                          <p className="qty-notice">
                                            The order quantity box will appear
                                            only when the sale starts.
                                          </p>
                                        )}
                                        <span className="tooltip-inner">
                                          {thisSatDetails.websku}
                                        </span>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {thisSatDetails.cart_qty &&
                                        thisSatDetails.cart_qty > 0 ? (
                                          <PrimaryButton
                                            type="button"
                                            btnSize="small"
                                            onsubmit={(e) => {
                                              handleTextAreaOpen(
                                                thisSatDetails
                                              );
                                            }}
                                          >
                                            {thisSatDetails &&
                                            thisSatDetails.comments != "" &&
                                            thisSatDetails.comments
                                              ? "View"
                                              : "Add"}
                                          </PrimaryButton>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </tbody>
                            </table>
                          </>
                        )}

                      {(thisSatDetails.has_lot != undefined ||
                        thisSatDetails.n_price != undefined) && (
                        <div
                          className="orderAllAddButton"
                          style={{ textAlign: "center" }}
                        >
                          <PrimaryButton
                            style={{
                              height: "45px",
                              width: "50%",
                              marginTop: "20px",
                            }}
                            onClick={addAlltoCart}
                            disabled={
                              !(
                                qty.length > 0 &&
                                qty.filter(
                                  (ele) => ele != "" && ele != undefined
                                ).length > 0
                              )
                            }
                          >
                            Add ALL
                          </PrimaryButton>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    id="popbut"
                    className="popup-button d-flex align-items-center admr "
                  >
                    {" "}
                    {/* <PrimaryButton
                      type="button"
                      btnSize="small"
                      onsubmit={handleWatchlist}
                      className="popup-button-one"
                    >
                      Add To WishList
                    </PrimaryButton> */}
                    {/* <PrimaryButton
                      type="button"
                      btnSize="small"
                      onsubmit={handleTextAreaOpen}
                      className=" ml-3 popup-button-two"
                    >
                      {thisSatDetails &&
                      thisSatDetails.comments != "" &&
                      thisSatDetails.comments
                        ? "View Comment"
                        : "Add Comment"}
                    </PrimaryButton> */}
                    {textAreaOpen ? (
                      <TextAreaPopup
                        textOpen={textAreaOpen}
                        close={handleTextAreaClose}
                        commentChange={handleCommentValue}
                        {...textAreaData}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      ) : null}
      {cpopup && false ? (
        <PopupAlert
          open={cpopup}
          heading={"Oops!! Already available in cart"}
          content={`The following items already in your cart. Are you sure want to add more? ${
            cpopupData.sku
          } - ${cpopupData.title ? cpopupData.title : cpopupData.name}`}
          action={"Cancel"}
          secondaryAction={"Confirm"}
          secClickAction={deleteWishlistPopupCallback}
          onClose={deleteWishlistClose}
        />
      ) : null}
    </div>
  );
};

export default withRouter(DetailsPopup);
