import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Divider,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import "./TextAreaPopup.css";
import PrimaryButton from "../PrimaryButton";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TextAreaPopup = ({
  close,
  textOpen,
  commentChange,
  comments,
  websku,
}) => {
  const sfdetails = [useSelector((state) => state.Search.details)];
  const cartDetails = useSelector((state) => state.cart);

  const [commentValue, setCommentValue] = useState("");
  const [isUpdate, setIsUpdate] = useState(false);
  const [lcomment, setLcomment] = useState("");
  const [cmtErr, setCmtErr] = useState("");
  const handleCommentChange = (e) => {
    setCommentValue(e.target.value);
    setCmtErr("");
  };

  const commentSubmit = () => {
    if (commentValue.length > 0) {
      commentChange(commentValue);
    } else {
      setCmtErr("Please add the comment.");
    }
  };

  useEffect(() => {
    console.log("comments", comments);
    if (comments && typeof comments != "undefined" && comments != "") {
      setCommentValue(comments);
      setIsUpdate(true);
    }
  }, [comments]);

  // useEffect(() => {
  //   if (Array.isArray(sfdetails)) {
  //     if (typeof sfdetails[0] != "undefined") {
  //       if (Array.isArray(sfdetails[0].data)) {
  //         let Indexofe = sfdetails[0].data.findIndex(
  //           (ele) => ele.websku === websku
  //         );
  //         let ele = sfdetails[0].data[Indexofe];
  //         if (Indexofe >= 0) {
  //           setLcomment(ele.comments);
  //         }
  //       } else {
  //         console.log("sfdetails[0].data  is not an array");
  //       }
  //     } else {
  //       console.log("sfdetails[0] is undefined");
  //     }
  //   } else {
  //     console.log("sfdetails is not an array");
  //   }
  // }, [sfdetails]);
  // useEffect(() => {
  //   if (lcomment && typeof lcomment != "undefined" && lcomment != "") {
  //     setCommentValue(lcomment);
  //   }
  // }, [lcomment]);

  return (
    <div>
      <Dialog
        open={textOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title "
          className="textarea-header "
        >
          <span className="textarea-title">ADD YOUR COMMENT FOR THIS ITEM</span>
          <Close className="float-right popup-close" onClick={close} />
        </DialogTitle>

        <DialogContent className="my-1">
          <textarea
            id="comment"
            name="comment"
            rows="4"
            cols="50"
            className="w-100"
            maxlength="50"
            value={commentValue}
            onChange={handleCommentChange}
          />
          <p className="text-right">*50 chars max</p>
          {cmtErr ? <span className="error-theme">{cmtErr}</span> : null}
        </DialogContent>
        <Divider />
        <DialogActions className="mx-2 my-1">
          <PrimaryButton type="button" btnSize="small" onsubmit={commentSubmit}>
            {isUpdate ? "UPDATE" : "ADD"}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default React.memo(TextAreaPopup);
