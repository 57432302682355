import socketIOClient from "socket.io-client";
const socket = socketIOClient(process.env.REACT_APP_BACKEND_URL, {
  transports: ["websocket"],
});

socket.on("connect", function () {
  console.log("Socket Connected");
});

export const socketChangeInFurnace = (cb) => {
  socket.on("change_in_furnace", (data) => {
    cb(data);
  });
};

export const socketServerTime = (cb) => {
  socket.on("server_time", (data) => {
    cb(data);
  });
};

export const socketLiveFurnaceTime = (cb) => {
  socket.on("live_furnace_time", (data) => {
    cb(data);
  });
};

export const socketRemoveCart = (cb) => {
  socket.on("remove_cart", (data) => {
    cb(data);
  });
};
