import React, { useState, useEffect } from "react";

const Pagination = ({ rowsPerPage, total_row_count, fallback }) => {
  const pageNumbers = [];
  const [masterArr, setMasterArr] = useState([]);
  const [numbersToShow, setNumbersToShow] = useState([]);
  const [firstAndLast, setFirstAndLast] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    setCurrentPage(1);
    for (let i = 1; i <= Math.ceil(total_row_count / rowsPerPage); i++) {
      pageNumbers.push(i);
    }
    if (Math.ceil(total_row_count / rowsPerPage) < 5) {
      setNumbersToShow(pageNumbers);
    } else {
      setNumbersToShow([1, 2, 3, 4, 5]);
    }
    setMasterArr(pageNumbers);
  }, [total_row_count]);

  useEffect(() => {
    if (currentPage <= masterArr.length) {
      fallback(currentPage);
    }
    if (currentPage > masterArr.length) {
      setCurrentPage(masterArr.length);
    }
    if (currentPage <= 0) {
      setCurrentPage(1);
      return;
    }
  }, [currentPage]);

  const prevNext = (type) => {
    let ind = 0;
    let cind = 0;
    if (type == "prev") {
      ind = masterArr.indexOf(numbersToShow[0]);
      setCurrentPage(currentPage - 1);
      if (ind >= 4) {
        setFirstAndLast(true);
      }
      if (ind <= 5) {
        setFirstAndLast(false);
        if (Math.ceil(total_row_count / rowsPerPage) < 5) {
          let a = [];
          for (let i = 1; i <= Math.ceil(total_row_count / rowsPerPage); i++) {
            a.push(i);
            setNumbersToShow(a);
          }
        } else {
          setNumbersToShow([1, 2, 3, 4, 5]);
        }
        return;
      }
      cind = ind - 1;
      const clonedMasterArr = [...masterArr];
      const pageNumberArr = clonedMasterArr.splice(cind, 3);
      setCurrentPage(pageNumberArr[0]);
      setNumbersToShow(pageNumberArr);
    } else if (type == "next") {
      ind = masterArr.indexOf(numbersToShow[numbersToShow.length - 1]);
      setCurrentPage(currentPage + 1);
      if (ind >= 4) {
        setFirstAndLast(true);
      }
      if (ind < 4) {
        setFirstAndLast(false);

        if (Math.ceil(total_row_count / rowsPerPage) < 5) {
          let a = [];
          for (let i = 1; i <= Math.ceil(total_row_count / rowsPerPage); i++) {
            a.push(i);
            setNumbersToShow(a);
          }
        } else {
          setNumbersToShow([1, 2, 3, 4, 5]);
        }
        return;
      }
      let mincindex = Number(
        masterArr.indexOf(masterArr[masterArr.length - 1]) - 2
      );
      if (ind >= mincindex) {
        const clonedMasterArr = [...masterArr];
        let ele5last = clonedMasterArr.slice(
          Math.max(clonedMasterArr.length - 5, 0)
        );
        setNumbersToShow(ele5last);
        setFirstAndLast(false);
        return false;
      }
      cind = ind - 1;

      const clonedMasterArr = [...masterArr];
      const pageNumberArr = clonedMasterArr.splice(cind, 3);
      setCurrentPage(pageNumberArr[0]);
      setNumbersToShow(pageNumberArr);
    }
  };
  const numberClick = (number, key) => {
    setCurrentPage(number);
    let mcurrentIndex = masterArr.indexOf(number);
    if (key == 0) {
      mcurrentIndex = mcurrentIndex - 3;
    }
    const nindex = numbersToShow.indexOf(number);
    const nLastindex = numbersToShow.indexOf(
      numbersToShow[numbersToShow.length - 1]
    );
    if (mcurrentIndex >= 4) {
      setFirstAndLast(true);
      let mincindex = Number(
        masterArr.indexOf(masterArr[masterArr.length - 1]) - 2
      );
      if (mcurrentIndex >= mincindex) {
        const clonedMasterArr = [...masterArr];
        let ele5last = clonedMasterArr.slice(
          Math.max(clonedMasterArr.length - 5, 0)
        );
        setNumbersToShow(ele5last);
        setFirstAndLast(false);
        return;
      }
      if (nindex === nLastindex || true) {
        const clonedMasterArr = [...masterArr];
        const pageNumberArr = clonedMasterArr.splice(mcurrentIndex, 3);
        setNumbersToShow(pageNumberArr);
      } else {
      }
    } else {
      if (Math.ceil(total_row_count / rowsPerPage) < 5) {
        let a = [];
        for (let i = 1; i <= Math.ceil(total_row_count / rowsPerPage); i++) {
          a.push(i);
          setNumbersToShow(a);
        }
      } else {
        setNumbersToShow([1, 2, 3, 4, 5]);
      }
      setFirstAndLast(false);
    }
  };
  const lastEleClick = () => {
    // fallback(masterArr[masterArr.length - 1])
    const clonedMasterArr = [...masterArr];
    let ele5last = clonedMasterArr.slice(
      Math.max(clonedMasterArr.length - 5, 0)
    );
    setNumbersToShow(ele5last);
    setFirstAndLast(false);
  };
  return (
    <nav>
      <ul className="pagination">
        <li className="page-item" onClick={(e) => prevNext("prev")}>
          <a className="page-link">Previous</a>
        </li>
        {firstAndLast ? (
          <>
            <li className="page-item">
              <a onClick={() => numberClick(1, 0)} className="page-link">
                {1}
              </a>
            </li>
            <span>....</span>
          </>
        ) : null}
        {numbersToShow.map((number, key) => {
          return (
            <>
              <li
                key={number}
                className={`page-item ${currentPage == number ? "active" : ""}`}
              >
                <a
                  onClick={() => numberClick(number, key)}
                  className="page-link"
                >
                  {number}
                </a>
              </li>
            </>
          );
        })}
        {firstAndLast ? (
          <>
            <span>....</span>
            <li className="page-item">
              <a onClick={lastEleClick} className="page-link">
                {masterArr[masterArr.length - 1]}
              </a>
            </li>
          </>
        ) : null}
        <li className="page-item" onClick={(e) => prevNext("next")}>
          <a className="page-link">Next</a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
