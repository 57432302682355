import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import "./addAddress.css";
import PrimaryButton from "../PrimaryButton";
import InputField from "../Input/InputField";
import RadioBox from "../RadioBox";
import {
  radioButton,
  shippingAddressInfo,
  shippingContactInfo,
} from "./addressInfo";

import "./addAddress.css";
import SelectField from "../Input/SelectField";
import {
  getCountries,
  saveShippingAddress,
  updateShippingDetails,
  getShippingDetails,
} from "../../Store/Actions/userActions";
import formState from "./utils/formState";
import popupClass from "../../utils/emitPopup";

const orgState = JSON.stringify(formState);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddAddress = ({ ship_req, data, addressOpen, addressClose }) => {
  // REDUX METHODS
  const dispatch = useDispatch();

  // Redux states
  const countries = useSelector((state) => state.newRegistration.newCountries);
  const userInfo = useSelector((state) => state.users.users);
  // END
  // LOCAL STATES
  const [countryList, setCountryList] = useState([]);
  const [stateForm, setStateForm] = useState(formState);
  const [validationErr, setValidationErr] = useState({});
  const [formError, setFormError] = useState({});
  //END

  // USE EFFECTS
  // runs only when the component loads for the first time
  useEffect(() => {
    getCountries(dispatch);
    let org = JSON.parse(orgState);
    setStateForm(org);
  }, []);
  useEffect(() => {
    if (Object.keys(data).length) {
      stateForm["address_1"]["value"] = data["shipping_address"];
      stateForm["address_2"]["value"] = data["shipping_address2"];
      stateForm["attention"]["value"] = data["ship_attention"];
      stateForm["cell_phone"]["value"] = data["ship_cellphone"];
      stateForm["city"]["value"] = data["shipping_city"];
      stateForm["company_name"]["value"] = data["shipping_company"];
      stateForm["country"]["value"] = data["shipping_country"];
      stateForm["email"]["value"] = data["ship_email"];
      stateForm["enable"]["value"] = data["ship_status"].toString();
      stateForm["fax"]["value"] = data["ship_fax"];
      stateForm["nickName"]["value"] = data["ship_nickname"];
      stateForm["phone"]["value"] = data["ship_phone"];
      stateForm["state"]["value"] = data["shipping_state"];
      stateForm["zip_code"]["value"] = data["shipping_zipcode"];
      setStateForm({ ...stateForm });
    }
  }, [data]);
  // Runs once the Countries Data is fetched
  useEffect(() => {
    setCountryList(countries);
  }, [countries]);
  //END

  // Input onchange function
  const onchangeFunc = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    var changeObj = stateForm[name];
    if (typeof changeObj != "undefined") {
      changeObj["value"] = value;
      if (formError[name]) delete formError[name];
      setStateForm({
        ...stateForm,
        [name]: changeObj,
      });
      // }else{
      //   alert(`${name} is undefined`)
      // }
    }
    setValidationErr({
      ...validationErr,
      [name]: "",
    });
  };
  // END
  // Input Validation Function
  const validate = () => {
    let error = {};
    Object.keys(stateForm).map((ele) => {
      if (
        stateForm[ele].value === "" &&
        typeof stateForm[ele] === "object" &&
        stateForm[ele].isRequired &&
        typeof stateForm[ele].value != "undefined"
      ) {
        error[ele] = stateForm[ele]["err_message"]
          ? formState[ele]["err_message"]
          : "Required";
      }
    });
    setFormError({
      ...formError,
      ...error,
    });
    return error;
  };

  const VaildationFormat = () => {
    let valErr = {};
    Object.keys(stateForm).map((property) => {
      if (property === "phone" || property === "cell_phone") {
        if (!(stateForm[property].value === ""))
          if (stateForm[property].value.length < 10) {
            valErr[property] = "Phone number should be minimum 10 digits";
          } else if (stateForm[property].value.length > 15) {
            valErr[property] = "Phone number should be maximum 15 digits only";
          }
      } else if (property === "email") {
        const emailvalid =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!emailvalid.test(stateForm[property].value))
          valErr[property] = "Please enter the valid email address";
      } else if (property === "zip_code") {
        if (stateForm[property].value.length < 5) {
          valErr[property] = "Zip code should be minimum 5 digits";
        } else if (stateForm[property].value.length > 13) {
          valErr[property] = "Zip code should be maximum 13 digits only";
        }
      } else if (property === "nickName") {
        const textFormat = /^[a-zA-Z\s]*$/;
        if (!textFormat.test(stateForm[property].value))
          valErr[property] = "Enter the correct name";
      } else if (property === "fax") {
        if (!(stateForm[property].value === ""))
          if (stateForm[property].value.length < 10) {
            valErr[property] = "Fax number should be minimum 10 digits";
          } else if (stateForm[property].value.length > 15) {
            valErr[property] = "Fax number should be maximum 15 digits only";
          }
      }
    });
    setValidationErr(valErr);

    return valErr;
  };
  // END
  // Form Submit function calls once the add shipping button is clisked
  const formSubmit = (e) => {
    e.preventDefault();

    if (Object.keys(validate()).length === 0) {
      if (Object.keys(VaildationFormat()).length === 0) {
        let responseObj = {};
        responseObj["sessionKey"] = userInfo["session_id"];
        responseObj["ship_status"] = stateForm["enable"]["value"];
        responseObj["shipping_name"] = stateForm["enable"]["value"];
        responseObj["ship_nickname"] = stateForm["nickName"]["value"];
        responseObj["ship_attention"] = stateForm["attention"]["value"];
        responseObj["ship_phone"] = stateForm["phone"]["value"];
        responseObj["ship_email"] = stateForm["email"]["value"];
        responseObj["ship_cellphone"] = stateForm["cell_phone"]["value"];
        responseObj["ship_fax"] = stateForm["fax"]["value"];
        responseObj["shipping_company"] = stateForm["company_name"]["value"];
        responseObj["shipping_address"] = stateForm["address_1"]["value"];
        responseObj["shipping_address2"] = stateForm["address_2"]["value"];
        responseObj["shipping_country"] = stateForm["country"]["value"];
        responseObj["shipping_city"] = stateForm["city"]["value"];
        responseObj["shipping_state"] = stateForm["state"]["value"];
        responseObj["shipping_zipcode"] = stateForm["zip_code"]["value"];
        function saveorupdate(responseObj) {
          if (data && typeof data != "undefined" && Object.keys(data).length) {
            responseObj["id"] = data["id"];
            return updateShippingDetails(responseObj, dispatch);
          } else {
            return saveShippingAddress(responseObj);
          }
        }
        saveorupdate(responseObj)
          .then(function (response) {
            // let toastifyMsg = {};
            // toastifyMsg["tostifyView"] = true;
            // toastifyMsg["tostifyMessage"] = "";
            // toastifyMsg["severity"] = "success";
            if (response.data && response.data.status) {
              // toastifyMsg["tostifyView"] = true;
              // toastifyMsg["tostifyMessage"] = response.data.detail;
              // toastifyMsg["severity"] = "success";
              addressClose();
              new popupClass(
                true,
                "Shipping Address sent to admin. You will receive the notification shortly.",
                "Shipping Address Submitted",
                "Ok",
                "",
                "",
                "",
                dispatch,
                "",
                "",
                "",
                "add-address-popup"
              ).emitPopup();
            }
            // else {
            // toastifyMsg["tostifyView"] = true;
            // toastifyMsg["tostifyMessage"] = response.data.detail;
            // toastifyMsg["severity"] = "error";
            // }
            // dispatch({
            //   type: "TOSTIFY_MESSAGE",
            //   payload: toastifyMsg,
            // });
            // return true;
          })
          // .then(function () {
          //   getShippingDetails(ship_req, dispatch);
          //   addressClose();
          // })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    let errorsElements = document.getElementsByClassName("error-theme");

    if (
      errorsElements &&
      typeof errorsElements != "undefined" &&
      errorsElements.length
    ) {
      let firstErrElement = errorsElements[0];
      firstErrElement.parentElement.parentElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [formError]);

  //END
  return (
    <div>
      <Dialog
        open={addressOpen}
        TransitionComponent={Transition}
        keepMounted
        fullWidth="true"
        maxWidth="sm"
        // onClose={addressClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className="add-address"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          className="add-shipping-title"
        >
          <span className="title">Add Shipping Address</span>
          <Close className="float-right popup-close" onClick={addressClose} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div>
              <RadioBox title="Status">
                <FormControlLabel
                  value={"1"}
                  control={<Radio color="primary" />}
                  label={"Enable"}
                  name="enable"
                  onClick={onchangeFunc}
                  checked={stateForm["enable"]["value"] === "1" ? true : false}
                />
                <FormControlLabel
                  value={"0"}
                  control={<Radio color="primary" />}
                  label={"Disable"}
                  name="enable"
                  onClick={onchangeFunc}
                  checked={stateForm["enable"]["value"] === "0" ? true : false}
                />
              </RadioBox>
              {formError["enable"] ? (
                <span className="error-theme">{formError["enable"]}</span>
              ) : null}
            </div>
            <div>
              <InputField
                label={
                  <span>
                    Nick Name<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder="Nick Name"
                name="nickName"
                size="medium"
                type="text"
                id="nick name"
                onChange={onchangeFunc}
                value={stateForm["nickName"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["nickName"] ? (
                <span className="error-theme">{formError["nickName"]}</span>
              ) : null}

              {validationErr.nickName ? (
                <span className="error-theme">{validationErr.nickName}</span>
              ) : null}
            </div>
            <div className="shipping-contact">
              <p className="my-1">Shipping Contact Information</p>
              <InputField
                label={
                  <span>
                    Attention<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder={"Attention"}
                name={"attention"}
                size="medium"
                type={"text"}
                id={"attention"}
                onChange={onchangeFunc}
                value={stateForm["attention"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["attention"] ? (
                <span className="error-theme">{formError["attention"]}</span>
              ) : null}
              {validationErr.attention ? (
                <span className="error-theme">{validationErr.attention}</span>
              ) : null}
              <InputField
                label={
                  <span>
                    Phone<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder={"Phone"}
                name={"phone"}
                size="medium"
                type={"number"}
                id={"phone"}
                onChange={onchangeFunc}
                value={stateForm["phone"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["phone"] ? (
                <span className="error-theme">{formError["phone"]}</span>
              ) : null}
              {validationErr.phone ? (
                <span className="error-theme">{validationErr.phone}</span>
              ) : null}
              <InputField
                label={
                  <span>
                    Cell Phone<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder={"Cell Phone"}
                name={"cell_phone"}
                size="medium"
                type={"number"}
                id={"cell_phone"}
                onChange={onchangeFunc}
                value={stateForm["cell_phone"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["cell_phone"] ? (
                <span className="error-theme">{formError["cell_phone"]}</span>
              ) : null}
              {validationErr.cell_phone ? (
                <span className="error-theme">{validationErr.cell_phone}</span>
              ) : null}
              <InputField
                label={"Fax"}
                placeholder={"Fax"}
                name={"fax"}
                size="medium"
                type={"number"}
                id={"fax"}
                onChange={onchangeFunc}
                value={stateForm["fax"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["fax"] ? (
                <span className="error-theme">{formError["fax"]}</span>
              ) : null}
              {validationErr.fax ? (
                <span className="error-theme">{validationErr.fax}</span>
              ) : null}
              <InputField
                label={
                  <span>
                    Email ID<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder={"Email ID"}
                name={"email"}
                size="medium"
                type={"email"}
                id={"email"}
                onChange={onchangeFunc}
                value={stateForm["email"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["email"] ? (
                <span className="error-theme">{formError["email"]}</span>
              ) : null}
              {validationErr.email ? (
                <span className="error-theme">{validationErr.email}</span>
              ) : null}
            </div>
            <div className="shipping-address">
              <p className="my-1">Shipping Address Information</p>
              <InputField
                label={
                  <span>
                    Company Name<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder={"Company Name"}
                name={"company_name"}
                size="medium"
                type={"text"}
                id={"company_name"}
                onChange={onchangeFunc}
                value={stateForm["company_name"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["company_name"] ? (
                <span className="error-theme">{formError["company_name"]}</span>
              ) : null}
              {validationErr.company_name ? (
                <span className="error-theme">
                  {validationErr.company_name}
                </span>
              ) : null}
              <InputField
                label={
                  <span>
                    Address Line 1<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder={"Address Line 1"}
                name={"address_1"}
                size="medium"
                type={"text"}
                id={"address_1"}
                onChange={onchangeFunc}
                value={stateForm["address_1"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["address_1"] ? (
                <span className="error-theme">{formError["address_1"]}</span>
              ) : null}
              {validationErr.address_1 ? (
                <span className="error-theme">{validationErr.address_1}</span>
              ) : null}
              <InputField
                label={"Address Line 2"}
                placeholder={"Address Line 2"}
                name={"address_2"}
                size="medium"
                type={"text"}
                id={"address_2"}
                onChange={onchangeFunc}
                value={stateForm["address_2"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["address_2"] ? (
                <span className="error-theme">{formError["address_2"]}</span>
              ) : null}
              {validationErr.address_2 ? (
                <span className="error-theme">{validationErr.address_2}</span>
              ) : null}
              {countryList &&
              countryList.country &&
              countryList.country.length ? (
                <SelectField
                  label={
                    <span>
                      Country<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder={"Country"}
                  name={"country"}
                  size="medium"
                  type={"text"}
                  id={"country"}
                  pc_onchange={onchangeFunc}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value="">Select Country</option>

                  {countryList &&
                  countryList.country &&
                  countryList.country.length
                    ? countryList.country.map((ele, ind) => {
                        return (
                          <option value={ele.ccode} key={ind}>
                            {ele.cname}
                          </option>
                        );
                      })
                    : null}
                </SelectField>
              ) : null}
              {formError["country"] ? (
                <span className="error-theme">{formError["country"]}</span>
              ) : null}
              {validationErr.country ? (
                <span className="error-theme">{validationErr.country}</span>
              ) : null}
              <InputField
                label={
                  <span>
                    City<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder={"City"}
                name={"city"}
                size="medium"
                type={"text"}
                id={"city"}
                onChange={onchangeFunc}
                value={stateForm["city"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["city"] ? (
                <span className="error-theme">{formError["city"]}</span>
              ) : null}
              {validationErr.city ? (
                <span className="error-theme">{validationErr.city}</span>
              ) : null}
              <InputField
                label={
                  <span>
                    State<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder={"State"}
                name={"state"}
                size="medium"
                type={"text"}
                id={"state"}
                onChange={onchangeFunc}
                value={stateForm["state"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["state"] ? (
                <span className="error-theme">{formError["state"]}</span>
              ) : null}
              {validationErr.state ? (
                <span className="error-theme">{validationErr.state}</span>
              ) : null}
              <InputField
                label={
                  <span>
                    Zip Code<span className="asterk">&nbsp;*</span>
                  </span>
                }
                placeholder={"Zip Code"}
                name={"zip_code"}
                size="medium"
                type={"number"}
                id={"zip_code"}
                onChange={onchangeFunc}
                value={stateForm["zip_code"]["value"]}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {formError["zip_code"] ? (
                <span className="error-theme">{formError["zip_code"]}</span>
              ) : null}
              {validationErr.zip_code ? (
                <span className="error-theme">{validationErr.zip_code}</span>
              ) : null}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="add-address-button">
          <PrimaryButton
            type="button"
            btnSize="small"
            className=" ml-3 popup-button-two"
            // onClick = {formSubmit}
            onsubmit={formSubmit}
          >
            {/* {data && Object.keys(data).length ? "update" : "Add"} Shipping Address */}
            Add Shipping Address
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default React.memo(AddAddress);
