import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Button, TextField, Grid } from "@material-ui/core";
import "./MyAccount.css";
import Layout from "./Layout";
import SearchIcon from "@material-ui/icons/Search";
import CustomDropDown from "../../components/CustomDropDown";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {
  getSavedCart,
  getEditSavedDtls,
  deleteSavedItemIndividual,
} from "../../Store/Actions/cart.actions";
import PopupAlert from "../../components/PopupAlert/index";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SecondaryButton from "../../components/SecondaryButton";
import Loader from "../../components/Loader";
import { CodeSharp } from "@material-ui/icons";

function createData(number, nickname, date_added, cartAction) {
  return { number, nickname, date_added, cartAction };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "number",
    numeric: true,
    disablePadding: true,
    label: "Number",
  },
  { id: "nickname", disablePadding: false, label: "Nickname" },
  {
    id: "created",
    numeric: true,
    disablePadding: false,
    label: "Created",
  },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: "Edit",
  },
  {
    id: "delete",
    numeric: true,
    disablePadding: false,
    label: "Delete",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="default" align="center">
          Delete
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <span style={{ fontSize: "15px", fontWeight: "600" }}>
                {headCell.label}
              </span>
              {orderBy === headCell.id ? (
                <span
                  className={classes.visuallyHidden}
                  style={{ float: "right" }}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : (
                ""
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    tableLayout: "fixed",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const SavedCart = (props) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [savedItemPopup, setSavedItemPopup] = React.useState(false);
  const [rowData, setRowData] = React.useState({});
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    `${moment(new Date()).format("YYYY-MM-DD")} 00:00:00`
  );
  const [selectedToDate, setSelectedToDate] = React.useState(
    `${moment(new Date()).format("YYYY-MM-DD")} 23:59:00`
  );

  // fromDate: new Date(),
  // toDate: new Date()

  const userInfo = useSelector((state) => state.users.users);
  const cartDtls = useSelector((state) => state.cart);
  const [searchValue, setSearchValue] = React.useState("");
  const srows = useSelector((state) =>
    state.savedInfo.savedInfo ? state.savedInfo.savedInfo : {}
  );

  const loader = useSelector((state) => {
    return state.loader;
  });

  const dispatch = useDispatch();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.sku);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  useEffect(() => {
    if (srows && typeof srows.detail !== "undefined" && srows.detail.length) {
      let sortedValue = srows.detail.map((ele) => ({
        ...ele,
        date_added: moment(ele.date_added).format("L"),
      }));

      let sortValue = sortedValue.sort(function (a, b) {
        return b.date_added - a.date_added;
      });
    }
  }, [srows]);

  const handleClick = (event, sku) => {
    const selectedIndex = selected.indexOf(sku);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, sku);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (sku) => selected.indexOf(sku) !== -1;
  let emptyRows = 0;
  if (rows && typeof rows != "undefined") {
    emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  }

  const handleFromDateChange = (date) => {
    let changedDate = moment(date).format("YYYY-MM-DD");
    let addedTimeFormat = `${changedDate} 00:00:00`;
    setSelectedFromDate(addedTimeFormat);
  };

  const handleToDateChange = (date) => {
    let changedDate = moment(date).format("YYYY-MM-DD");
    let addedTimeFormat = `${changedDate} 23:59:00`;
    setSelectedToDate(addedTimeFormat);
  };

  const handleCalendarSearch = () => {
    let getSavedInfo = {
      sessionKey: userInfo.session_id,
      search_value: "",
      search_key: "",
      size: "",
      page: "",
      sort: "",
      dir: "",
      fromDate: selectedFromDate,
      toDate: selectedToDate,
    };
    getSavedCart(getSavedInfo, dispatch);
  };

  // UseEffect Starts ----------
  const history = useHistory();
  useEffect(() => {
    let getSavedInfo = {
      sessionKey: userInfo.session_id,
      search_value: "",
      search_key: "",
      size: rowsPerPage,
      page: 1,
      sort: "",
      dir: "",
      fromDate: "",
      toDate: "",
    };
    getSavedCart(getSavedInfo, dispatch);
  }, []);

  useEffect(() => {
    if (srows && typeof srows.status != "undefined") {
      setRows(srows.detail);
    }
  }, [srows]);

  const onClickEditSavedDtls = (dtls) => {
    sessionStorage.setItem("editSavedDtls", JSON.stringify(dtls));
    history.push("/editSavedCart");
    const savdDtls = {
      sessionKey: userInfo.session_id,
      id: dtls,
    };
    getEditSavedDtls(savdDtls, dispatch);
  };

  const delteSavedItem = (row) => {
    setSavedItemPopup(true);
    setRowData(row);
    //   deleteSavedItemIndividual(
    //     { sessionKey: userInfo.session_id, id: row.id },
    //     dispatch
    //   ).then((res) => {
    //     if (res.data.status) {
    //       let getSavedInfo = {
    //         sessionKey: userInfo.session_id,
    //         search_value: "",
    //         search_key: "",
    //         size: "",
    //         page: "",
    //         sort: "",
    //         dir: "",
    //       };
    //       getSavedCart(getSavedInfo, dispatch);
    //     }
    //   });
    // };
  };

  const deleteSavedItemPopupCallback = () => {
    try {
      dispatch({
        type: "DELETE_CART_UPDATE",
        payload: false,
      });
      if (rowData && typeof rowData != "undefined") {
        if (Object.keys(rowData).length !== 0) {
          let reqObj = {
            sessionKey: userInfo.session_id,
            id: rowData.id,
          };
          deleteSavedItemIndividual(reqObj, dispatch)
            .then((res) => {
              if (res.data.status) {
                let tostifyValue = {
                  tostifyView: true,
                  tostifyMessage: "Product removed from saved cart.",
                  severity: "error",
                };
                dispatch({
                  type: "TOSTIFY_MESSAGE",
                  payload: tostifyValue,
                });
                setSavedItemPopup(false);
                let getSavedInfo = {
                  sessionKey: userInfo.session_id,
                  search_value: "",
                  search_key: "",
                  size: "",
                  page: "",
                  sort: "",
                  dir: "",
                  fromDate: "",
                  toDate: "",
                };
                return getSavedCart(getSavedInfo, dispatch);
              }
            })
            .then((res) => [
              dispatch({
                type: "DELETE_CART_UPDATE",
                payload: true,
              }),
            ]);
        } else {
          throw "rows is an empty array";
        }
      } else {
        throw "rows is undefined";
      }
    } catch (e) {
      console.log(e);
    }
  };
  const deleteSavedPopupClose = () => {
    setSavedItemPopup(false);
  };

  useEffect(() => {
    if (cartDtls.deleteCart) setRows(srows.detail);
  }, [cartDtls.deleteCart]);

  const onSearchChange = (e) => {
    let searchVal = e.target.value;
    setSearchValue(searchVal);
    let getSavedInfo = {
      sessionKey: userInfo.session_id,
      search_value: searchVal ? searchVal : "",
      search_key: "",
      size: rowsPerPage,
      page: 1,
      sort: "",
      dir: "",
      fromDate: "",
      toDate: "",
    };

    getSavedCart(getSavedInfo, dispatch);
  };

  const handleChangeDropDownValue = (value) => {
    setRowsPerPage(parseInt(value));
    setPage(0);
  };

  return (
    <>
      <Layout title="Saved Cart">
        <hr />
        <div className="savedCart">
          <div className="d-flex justify-content-between align-items-center">
            <div className="cartSearchLt">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="row align-items-end">
                  <div className="col">
                    <label className="mx-2">Show</label>
                    <CustomDropDown
                      filterData={[5, 10, 25]}
                      selectValue={rowsPerPage}
                      handleDropDownChange={handleChangeDropDownValue}
                    />
                    <label className="mx-2">Entries</label>
                  </div>
                  <div className="col">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Start date"
                      format="yyyy-MM-dd"
                      value={selectedFromDate}
                      name="fromDate"
                      onChange={handleFromDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div>
                  <div className="col">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="End date"
                      // format="yyyy/MM/dd"
                      format="yyyy-MM-dd"
                      name="toDate"
                      value={selectedToDate}
                      onChange={handleToDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </div>
                  <div className="col">
                    <SecondaryButton
                      className="second-button saved-cart-search"
                      variant="outlined"
                      onsubmit={handleCalendarSearch}
                    >
                      Search
                    </SecondaryButton>
                  </div>
                  <div className="search-savedCart">
                    <Grid
                      container
                      spacing={1}
                      justify="flex-end"
                      alignItems="flex-end"
                    >
                      <Grid item>
                        <SearchIcon />
                      </Grid>
                      <Grid item>
                        <TextField
                          id="search"
                          label="Search here"
                          name="search_value"
                          value={searchValue}
                          onChange={onSearchChange}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={`mt-5 saved-cart-table ${classes.root}`}>
            <Paper className={classes.paper}>
              {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size="medium"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody className={classes.tbody}>
                    {loader.isLoading ? (
                      <TableRow style={{ height: "150px" }}>
                        <TableCell colSpan={5}>
                          <Loader />
                        </TableCell>
                      </TableRow>
                    ) : rows && rows.length > 0 ? (
                      <>
                        {stableSort(rows, getComparator(order, orderBy))
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <TableRow
                                hover
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={row.number}
                                selected={isItemSelected}
                              >
                                <TableCell align="center">{row.id}</TableCell>

                                <TableCell
                                  className="nickname"
                                  component="th"
                                  id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                  onClick={() => onClickEditSavedDtls(row.id)}
                                >
                                  {row.nickname}
                                </TableCell>
                                <TableCell align="center">
                                  {moment(row.date_added).format(
                                    "YYYY-MM-DD hh:mm:ss"
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  <SecondaryButton
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    className="mx-4 second-button edit-saved-cart"
                                    onsubmit={() =>
                                      onClickEditSavedDtls(row.id)
                                    }
                                  >
                                    Edit
                                  </SecondaryButton>
                                </TableCell>
                                <TableCell align="center">
                                  <SecondaryButton
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    className="mx-4 second-button delete-saved-cart"
                                    onClick={(event) => delteSavedItem(row)}
                                  >
                                    Delete
                                  </SecondaryButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                          )} */}
                      </>
                    ) : (
                      rows &&
                      typeof rows !== "undefined" &&
                      rows.length === 0 && (
                        <TableRow style={{ height: "150px" }}>
                          <TableCell colSpan={5}>
                            <p className="text-center no-data">
                              No data available in table
                            </p>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </div>
        {savedItemPopup ? (
          <PopupAlert
            open={savedItemPopup}
            heading={"Delete Saved Item"}
            content={"Do you Want to delete this saved cart?"}
            action={"Cancel"}
            secondaryAction={"Ok"}
            secClickAction={deleteSavedItemPopupCallback}
            onClose={deleteSavedPopupClose}
            maxWidth={"xs"}
            classNameDialogActions={"saved-cart-delete-buttons"}
            okButtonClass={"saved-cart-ok"}
            submitButtonClass={"saved-cart-cancel"}
          />
        ) : null}
      </Layout>
    </>
  );
};
export default SavedCart;
