const initalState = {
  exportFreshData: [],
  exportSaltData: [],
};

export default function (state = initalState, action) {
  switch (action.type) {
    case "EXPORT_FRESH_STOCK_LEVEL":
      return {
        ...state,
        exportFreshData: action.payload,
      };
    case "EXPORT_SALT_STOCK_LEVEL":
      return {
        ...state,
        exportSaltData: action.payload,
      };

    default:
      return state;
  }
}
