import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PrimaryButton from "../PrimaryButton";
import InputField from "../Input/InputField";
import { editUserMail } from "../../Store/Actions/userActions";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditExistingInfoMailPopup = ({ popupOpen, popupClose }) => {
  const [editExistingInfo, setEditExistingInfo] = useState({
    email: {
      value: "",
      error: "Field Required",
    },
  });

  const [error, setError] = useState({});

  const dispatch = useDispatch();

  const history = useHistory();

  const editUserStatus = useSelector((state) => state.editUserInfo);

  const userInfo = useSelector((state) => state.users);

  const onExistingUserInfoChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let Obj = {
      value: value.trim(),
      error: editExistingInfo[name]["error"],
    };
    setError({
      ...error,
      [name]: "",
    });
    setEditExistingInfo({
      ...editExistingInfo,
      [name]: Obj,
    });
  };

  const onExistingUserInfoSubmit = (e) => {
    e.preventDefault();

    if (
      editExistingInfo &&
      editExistingInfo.email &&
      editExistingInfo.email.value &&
      editExistingInfo.email.value != ""
    ) {
      history.push({
        pathname: "/editUserInformation",
        state: { email: editExistingInfo.email.value },
      });
      popupClose();
    }
  };
  return (
    <div>
      <Dialog
        open={popupOpen}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={popupClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className="change-password"
      >
        <DialogTitle id="alert-dialog-slide-title" className="heading">
          <span className="title">Edit Application</span>
          <Close className="float-right popup-close" onClick={popupClose} />
        </DialogTitle>
        <form onSubmit={onExistingUserInfoSubmit}>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="change-pasword-content"
            >
              <InputField
                label="Enter Your Email Address"
                placeholder="Enter Your Email Address"
                name="email"
                size="large"
                type="text"
                id="email"
                onchange={onExistingUserInfoChange}
                value={editExistingInfo.email.value}
              />{" "}
              {error.email ? (
                <span style={{ color: "red" }}>{error.email}</span>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              type="submit"
              size="small"
              className=" ml-3 popup-button-two"
            >
              Submit
            </PrimaryButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default withRouter(EditExistingInfoMailPopup);
