import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import "./changePassword.css";
import PrimaryButton from "../PrimaryButton";
import InputField from "../Input/InputField";
import { change_password } from "../../Store/Actions/userActions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePasswordPopup = ({ changePasswordClose, changePasswordOpen }) => {
  const [error, setError] = useState({});
  const [changePassword, setChangePassword] = useState({
    current_password: {
      value: "",
      error: "Current Password is Required",
    },
    new_password: {
      value: "",
      error: "New Password is Required",
    },
    confirm_password: {
      value: "",
      error: "Confirm Password is Required",
    },
  });

  const [passwrdExisting, setPasswrdExisting] = useState({});

  const [newCurrentPass, setNewCurrentPass] = useState({});

  const [minDigit, setMinDigit] = useState({});

  const [currentPasswrdError, setCurrentPasswrdError] = useState({});

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.users.users);

  const changeResponce = useSelector((state) => state.changePasswrd);

  const validate = () => {
    let error_ = {};
    Object.keys(changePassword).map((el) => {
      if (changePassword[el].value === "") {
        error_[el] = changePassword[el]["error"];
      }
    });
    setError(error_);
    return error_;
  };

  const onChangePasswordChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let Obj = {
      value: value,
      error: changePassword[name]["error"],
    };

    setError({
      ...error,
      [name]: "",
    });

    setChangePassword({
      ...changePassword,
      [name]: Obj,
    });

    setPasswrdExisting({});

    setMinDigit({});

    setNewCurrentPass({});

    setCurrentPasswrdError({});
  };

  const passwordMatch = () => {
    let passExist = {};

    if (
      changePassword.current_password.value.localeCompare(
        changePassword.new_password.value
      ) === 0
    ) {
      passExist["error"] =
        "Old Password and New Password should not be the same";
    }

    setPasswrdExisting(passExist);

    return passExist;
  };

  const newCurrentPasswrdMatch = () => {
    let passMatch = {};

    if (
      !(
        changePassword.new_password.value.localeCompare(
          changePassword.confirm_password.value
        ) === 0
      )
    ) {
      passMatch["error"] = "Confirm password should be same as new password";
    }

    setNewCurrentPass(passMatch);

    return passMatch;
  };

  const passwordValidation = () => {
    let minD = {};

    if (changePassword.new_password.value.length < 6) {
      minD["error"] = "Minimum 6 digit is required";
    }

    setMinDigit(minD);

    return minD;
  };

  const resetChangePassword = () => {
    setChangePassword({
      current_password: {
        value: "",
        error: "Current Password is Required",
      },
      new_password: {
        value: "",
        error: "New Password is Required",
      },
      confirm_password: {
        value: "",
        error: "Confirm Password is Required",
      },
    });
  };

  const onChangePasswordSubmit = (e) => {
    e.preventDefault();
    if (Object.keys(validate()).length === 0) {
      if (
        Object.keys(passwordMatch()).length === 0 &&
        Object.keys(passwordValidation()).length === 0 &&
        Object.keys(newCurrentPasswrdMatch()).length === 0
      )
        if (
          changePassword.new_password.value.length >= 6 &&
          changePassword.new_password.value.localeCompare(
            changePassword.confirm_password.value
          ) === 0
        ) {
          change_password(
            {
              sessionKey: userInfo.session_id,
              current_password: changePassword.current_password.value,
              new_password: changePassword.new_password.value,
              confirm_password: changePassword.confirm_password.value,
            },
            dispatch
          );
        }
    }
  };

  useEffect(() => {
    if (!changeResponce.changePasswrdstaus.status) {
      setCurrentPasswrdError({ error: changeResponce.changePasswrdstaus.msg });
    }
  }, [changeResponce.changePasswrdstaus]);

  useEffect(() => {
    if (changeResponce.closePopup) {
      resetChangePassword();
      changePasswordClose();
      dispatch({
        type: "CHANGED_PASSWORD_POPUP",
        payload: false,
      });
    }
  }, [changeResponce]);

  return (
    <div>
      <Dialog
        open={changePasswordOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={changePasswordClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className="change-password"
        disableBackdropClick={true}
      >
        <DialogTitle id="alert-dialog-slide-title" className="heading">
          <span className="title">Change Password</span>
          <Close
            className="float-right popup-close"
            onClick={() => {
              resetChangePassword();
              changePasswordClose();
            }}
          />
        </DialogTitle>
        <form onSubmit={onChangePasswordSubmit}>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              className="change-pasword-content"
            >
              <p className="my-1">
                Enter the following details to change your password
              </p>
              <InputField
                label="User Name"
                placeholder="User Name"
                name="username"
                size="medium"
                type="text"
                id="username"
                InputProps={{
                  readOnly: true,
                }}
                value={userInfo.detail ? userInfo.detail.username : null}
              />
              <InputField
                label="Current Password"
                placeholder="Current Password "
                name="current_password"
                size="medium"
                type="password"
                id="current_password"
                onchange={onChangePasswordChange}
                value={changePassword.current_password.value}
              />
              {error.current_password ? (
                <span className="error-theme">{error.current_password}</span>
              ) : null}
              {currentPasswrdError.error ? (
                <span className="error-theme">{currentPasswrdError.error}</span>
              ) : null}

              <InputField
                label="New Password"
                placeholder="New Password"
                name="new_password"
                size="medium"
                type="password"
                id="new_password"
                onchange={onChangePasswordChange}
                value={changePassword.new_password.value}
              />
              {error.new_password ? (
                <span className="error-theme">{error.new_password}</span>
              ) : null}

              {minDigit.error ? (
                <span className="error-theme">{minDigit.error}</span>
              ) : null}
              {passwrdExisting.error ? (
                <span className="error-theme">{passwrdExisting.error}</span>
              ) : null}
              <InputField
                label="Confirm Password"
                placeholder="Confirm Password"
                name="confirm_password"
                size="medium"
                type="password"
                id="confirm_password"
                onchange={onChangePasswordChange}
                value={changePassword.confirm_password.value}
              />
              {error.confirm_password ? (
                <span className="error-theme">{error.confirm_password}</span>
              ) : null}

              {newCurrentPass.error ? (
                <span className="error-theme">{newCurrentPass.error}</span>
              ) : null}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryButton
              type="submit"
              btnSize="small"
              className=" ml-3 popup-button-two"
            >
              Submit
            </PrimaryButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
export default ChangePasswordPopup;
