const initalState = {
  isLoading: false,
  cartLoading: false,
};
export default function (state = initalState, action) {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "CART_LOADING":
      return {
        ...state,
        cartLoading: action.payload,
      };
    default:
      return state;
  }
}
