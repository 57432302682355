import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import { getCountries } from "../../Store/Actions/userActions";
import PrimaryButton from "../PrimaryButton";

const TermsAndCondition = (props) => {
  const dispatch = useDispatch();

  const countryInfo = useSelector((state) => state.newRegistration.newRegInfo);

  const checkout = useSelector((state) => state.checkout.checkout);

  const [terms, setTerms] = useState([]);

  const termsCondition = useSelector((state) => state.newRegistration);

  const history = useHistory();

  const detailUsa = () => {
    if (props.from && props.from === "checkout") {
      if (checkout.user_dtls.countryname) {
        if (checkout.user_dtls.countryname === "USA") {
          return { __html: terms[0] ? terms[0].terms : null };
        } else {
          return { __html: terms[1] ? terms[1].terms : null };
        }
      } else {
        return { __html: terms[0] ? terms[0].terms : null };
      }
    } else if (countryInfo.country) {
      if (countryInfo.country.value === "USA") {
        return { __html: terms[0] ? terms[0].terms : null };
      } else {
        return { __html: terms[1] ? terms[1].terms : null };
      }
    } else {
      return { __html: terms[0] ? terms[0].terms : null };
    }
  };

  const onAccept = () => {
    history.push("/thankyou");
  };

  useEffect(() => {
    getCountries(dispatch);
  }, []);

  useEffect(() => {
    if (termsCondition.newCountries.terms) {
      setTerms(termsCondition.newCountries.terms);
      if (!props.from) {
        const top = document.querySelector("#thank-you");
        top.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [termsCondition.newCountries]);

  useEffect(() => {
    if (!props.from) {
      const top = document.querySelector("#thank-you");
      top.scrollIntoView({ behavior: "smooth" });
      // window.scrollTo(10,10)
    }
  }, []);

  return (
    <div className="sdHome">
      {props.from === "checkout" ? (
        <Container id="thank-you">
          <div
            className="pt-5 text-left"
            dangerouslySetInnerHTML={detailUsa()}
          />
        </Container>
      ) : (
        <>
          <div className="bnrBack">
            <div className="sdhBanner">
              <div className="sdhLogo">
                <img
                  src="./assets/images/logo_home.png"
                  alt="Seadwelling logo"
                ></img>
              </div>
            </div>
          </div>
          <Container id="thank-you">
            <div
              className="pt-5 text-left"
              dangerouslySetInnerHTML={detailUsa()}
            />
            <PrimaryButton
              size="medium"
              style={{ width: "300px" }}
              className="mt-4"
              onsubmit={onAccept}
            >
              I Accept Terms and Conditions
            </PrimaryButton>
          </Container>
        </>
      )}
    </div>
  );
};

export default TermsAndCondition;
