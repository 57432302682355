import axios from "axios";
import apiEndPoint from "../apiEndPoints";
import toastifyClass from "../../utils/emitToastify";
import { randHex } from "../../utils/generateHex";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/sdc`;
//const baseUrl =  "http://localhost:3002/sdc"

export const user_login = (users) => (dispatch) => {
  getUserCall(users)
    .then((res) => {
      sessionStorage.setItem("user", JSON.stringify(res.data));
      localStorage.setItem("initalpopup", JSON.stringify(1));
      localStorage.setItem("orderSessionId", randHex(15));
      return Promise.resolve(res);
    })
    .then(function (res) {
      dispatch({
        type: "GET_USERS",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .then(function (res) {
      if (res.data.status) {
        dispatch({
          type: "LOGIN",
        });
      }
      return Promise.resolve(res);
    })
    .then((res) => {
      let tostifyValue = {};
      if (res.data.status === true) {
        tostifyValue["tostifyView"] = true;
        tostifyValue["tostifyMessage"] = "Login Successfull!";
        tostifyValue["severity"] = "success";
      } else {
        if (res.data.msg === "USER_ALREADY_LOGGINED_OTH_BROWSER") {
          dispatch({
            type: "USER_ALREADY_LOGGED_IN",
            payload: true,
          });
          // sessionStorage.setItem('msg','USER_ALREADY_LOGGINED_OTH_BROWSER')
          //tostifyValue["tostifyMessage"] = 'User loggined in some other broswer bye!!';
        } else {
          tostifyValue["tostifyView"] = true;
          tostifyValue["tostifyMessage"] = res.data.msg;
          tostifyValue["severity"] = "error";
        }
      }
      dispatch({
        type: "TOSTIFY_MESSAGE",
        payload: tostifyValue,
      });
    })
    .catch(function (err) {
      console.log(err);
    });
};

export const switchUser = async (username, dispatch) => {
  await axios
    .post(baseUrl + apiEndPoint.switchUser, { username })
    .then((res) => {
      if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        sessionStorage.clear();
        sessionStorage.setItem("user", JSON.stringify(res.data));
        return Promise.resolve(res);
      }
    })
    .then(function (res) {
      dispatch({
        type: "GET_USERS",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .then(function (res) {
      if (res.data.status) {
        dispatch({
          type: "LOGIN",
        });
      }
      return Promise.resolve(res);
    })
    .then((res) => {
      let tostifyValue = {};
      if (res.data.status === true) {
        tostifyValue["tostifyView"] = true;
        tostifyValue["tostifyMessage"] = "Login Successfull!";
        tostifyValue["severity"] = "success";
      } else {
        tostifyValue["tostifyView"] = true;
        tostifyValue["tostifyMessage"] = res.data.msg;
        tostifyValue["severity"] = "error";
      }
      dispatch({
        type: "TOSTIFY_MESSAGE",
        payload: tostifyValue,
      });
      window.location.reload();
    })
    .catch(function (err) {
      console.log(err, "===> err");
    });
};

export const validateUser = async (data, dispatch) => {
  await axios
    .post(baseUrl + apiEndPoint.validateUser, data)
    .then((res) => {
      if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else if (res.data.status != false) {
        sessionStorage.clear();
        sessionStorage.setItem("user", JSON.stringify(res.data));
        return Promise.resolve(res);
      }
    })
    .then(function (res) {
      dispatch({
        type: "GET_USERS",
        payload: res.data,
      });
      return Promise.resolve(res);
    })
    .then(function (res) {
      if (res.data.status) {
        dispatch({
          type: "LOGIN",
        });
      }
      return Promise.resolve(res);
    })
    .catch(function (err) {
      console.log(err, "===> err");
    });
};

// export const userLogOut = (dispatch) => {
//   const logoutUser = sessionStorage.clear();
//   const tostifyValue = {
//     tostifyView: true,
//     tostifyMessage: "Logout Successfull!",
//     severity: "success",
//   };
//   dispatch({
//     type: "LOGOUT",
//     payload: logoutUser,
//   });
//   dispatch({
//     type: "TOSTIFY_MESSAGE",
//     payload: tostifyValue,
//   });
// };

export const userLogOut = async (dispatch) => {
  const user = JSON.parse(sessionStorage.getItem("user")) || {};
  const session_id = user.session_id;
  const { detail = {} } = user;
  const { user_id = "" } = detail;
  if (user_id) {
    await axios
      .post(baseUrl + apiEndPoint.userLogOut, { user_id, session_id })
      .then((res) => {
        console.log(res);
        window.location.href = "/";
        const luser = sessionStorage.clear();
        localStorage.removeItem("initalpopup");
        const tostifyValue = {
          tostifyView: true,
          tostifyMessage: "Logout Successfull!",
          severity: "success",
        };
        dispatch({
          type: "LOGOUT",
          payload: luser,
        });
        dispatch({
          type: "TOSTIFY_MESSAGE",
          payload: tostifyValue,
        });
      });
  }
};

const getUserCall = (users) => {
  return axios.post(baseUrl + apiEndPoint.login, users);
};

export const getUsers = (users) => (dispatch) => {
  getUserCall(users).then((res) => {
    if (
      res.data.status == "error" &&
      res.data.message == "Invalid SessionKey"
    ) {
      dispatch({
        type: "POPUP_MESSAGE_CLOSE",
        payload: false,
      });
      userLogOut(dispatch);
    } else {
      dispatch({
        type: "GET_USERS",
        payload: res.data,
      });
    }
  });
};

export const getCountries = async (dispatch) => {
  const res = await axios.get(baseUrl + apiEndPoint.countries);
  if (res.data.status == "error" && res.data.message == "Invalid SessionKey") {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    userLogOut(dispatch);
  } else {
    dispatch({
      type: "NEW_COUNTRIES",
      payload: res.data,
    });
  }
};

export const submitNewUser = async (newUserInfo, dispatch) => {
  return axios.post(baseUrl + apiEndPoint.countries, newUserInfo);
};

export const submitMoreInfo = async (addMoreInfo, dispatch) => {
  return axios.post(baseUrl + apiEndPoint.moreInfo, addMoreInfo);
};

export const getUploadedDocuments = async (info, dispatch) => {
  return axios.post(baseUrl + apiEndPoint.getDocs, info);
};

export const homePage = async (homePageInfo, dispatch) => {
  const res = await axios.post(
    baseUrl + apiEndPoint.homePageContent,
    homePageInfo
  );
  if (res.data.status == "error" && res.data.message == "Invalid SessionKey") {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    userLogOut(dispatch);
  }
  return res;
};

export const forgetPassword = (fPass, dispatch) => {
  return axios.post(baseUrl + apiEndPoint.forgetpassword, fPass);
};

export const helpfulLink = async (helpfulLink, dispatch) => {
  const res = await axios.post(
    baseUrl + apiEndPoint.homePageContent,
    helpfulLink
  );
  dispatch({
    type: "HELPFUL_LINK",
    payload: res.data.detail,
  });
};

export const shipingTracking = async (shipingTrack, dispatch) => {
  const res = await axios.post(
    baseUrl + apiEndPoint.homePageContent,
    shipingTrack
  );
  if (res.data.status == "error" && res.data.message == "Invalid SessionKey") {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    userLogOut(dispatch);
  } else {
    dispatch({
      type: "SHIPING_TRACKING",
      payload: res.data.detail,
    });
  }
};

export const change_password = async (passwordInfo, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.changePassword, passwordInfo)
    .then((res) => {
      let tostifyValue = {};
      if (res.data.status === true) {
        tostifyValue["tostifyView"] = true;
        tostifyValue["severity"] = "success";
      } else {
        tostifyValue["tostifyView"] = true;
        tostifyValue["severity"] = "error";
      }

      if (Array.isArray(res.data.detail) && res.data.status === false) {
        tostifyValue["tostifyMessage"] = res.data.msg;
      } else {
        tostifyValue["tostifyMessage"] = res.data.msg;
      }
      dispatch({
        type: "TOSTIFY_MESSAGE",
        payload: tostifyValue,
      });
      return Promise.resolve(res);
    })
    .then((res) => {
      dispatch({
        type: "CHANGED_PASSWORD",
        payload: res.data,
      });
    });
};

export const editUserMail = async (editMailId, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.editRegistration, editMailId)
    .then((res) => {
      if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        dispatch({
          type: "EDIT_CUSTOMER_INFORMATION",
          payload: res.data,
        });
      }
    });
};

export const updateUserInformation = async (updatedInfo, dispatch) => {
  return await axios.post(
    baseUrl + apiEndPoint.updateRegistration,
    updatedInfo
  );
};

export const Searchfilters = async (
  searchvalues,
  dispatch,
  isLoading,
  fetchLoad,
  viewCartDispatch,
  disableLoader = false,
  dataLoader = false
) => {
  dispatch({
    type: "LOADING",
    payload: !disableLoader
      ? !isLoading && typeof isLoading !== "undefined"
        ? isLoading
        : true
      : false,
  });

  console.log(isLoading, disableLoader, "==>load issues");
  dispatch({
    type: "FETCH_LOAD",
    payload: !disableLoader
      ? fetchLoad && typeof fetchLoad !== "undefined"
        ? fetchLoad
        : false
      : false,
  });

  return await axios
    .post(baseUrl + apiEndPoint.Searchfilters, searchvalues)
    .then((result) => {
      if (
        result.data.status == "error" &&
        result.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        result.data.data = result.data.data.filter((ele) => {
          if (ele.hasOwnProperty("sku_dtls")) {
            return ele.sku_dtls.length > 0 ? true : false;
          } else {
            return true;
          }
        });
        console.log("viewCartDispatch", viewCartDispatch);
        // if (viewCartDispatch) {
        dispatch({
          type: "GET_SEARCH_RESULT",
          payload: result.data,
        });
        // }

        dispatch({
          type: "LOADING",
          payload: false,
        });

        dispatch({
          type: "TOTAL_DATA",
          payload: result.data.total_row,
        });
        return result;
      }
    });
};

export const Sitemenu = async (key, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.smenu, { sessionKey: key })
    .then((result) => {
      dispatch({
        type: "SITE_MENU",
        payload: result.data,
      });
      return result;
    });
};

export const ftp = async (sessionKey, dispatch) => {
  const res = await axios.post(baseUrl + apiEndPoint.ftpPage, sessionKey);

  dispatch({
    type: "GET_FTP",
    payload: res.data,
  });
};

export const featuredProducts = async (productDetails, dispatch) => {
  const res = await axios.post(
    baseUrl + apiEndPoint.featuredProduct,
    productDetails
  );
  if (res.data.status == "error" && res.data.message == "Invalid SessionKey") {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    userLogOut(dispatch);
  } else {
    dispatch({
      type: "FEATURED_PRODUCTS",
      payload: res.data,
    });
  }
};

export const addComment = async (comments, dispatch) => {
  comments = {
    ...comments,
    orderSessionId: localStorage.getItem("orderSessionId"),
  };
  const res = await axios.post(baseUrl + apiEndPoint.addComment, comments);

  const tostifyValue = {
    tostifyView: true,
    tostifyMessage: res.data.detail,
    severity: "success",
  };
  dispatch({
    type: "TOSTIFY_MESSAGE",
    payload: tostifyValue,
  });

  dispatch({
    type: "VIEW_COMMENT",
    payload: res.data,
  });
  return Promise.resolve(res);
};

export const accountOverview = async (accountUpdateDts, dispatch) => {
  const res = await axios.post(
    baseUrl + apiEndPoint.accountView,
    accountUpdateDts
  );
  if (res.data.status == "error" && res.data.message == "Invalid SessionKey") {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
    userLogOut(dispatch);
  } else {
    dispatch({
      type: "ACCOUNT_OVERVIEW",
      payload: res.data,
    });
  }
};

export const accountOverviewUpdate = async (accountUpdate, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.accountView, accountUpdate)
    .then((res) => {
      if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        let tostifyValue = {};

        if (res.data.status === true) {
          tostifyValue["tostifyView"] = true;
          tostifyValue["tostifyMessage"] = res.data.detail;
          tostifyValue["severity"] = "success";
        } else {
          tostifyValue["tostifyView"] = true;
          tostifyValue["tostifyMessage"] = res.data.message;
          tostifyValue["severity"] = "error";
        }
        dispatch({
          type: "TOSTIFY_MESSAGE",
          payload: tostifyValue,
        });
        return Promise.resolve(res);
      }
    })
    .then((res) => {
      dispatch({
        type: "ACCOUNT_OVERVIEW_INFO_UPDATED",
        payload: res.data,
      });
    });
};

export const phoneNumberUpdate = async (phoneUpdatePayload, dispatch) => {
  return await axios.post(
    baseUrl + apiEndPoint.phoneNumberUpdate,
    phoneUpdatePayload
  );
};

export const saveShippingAddress = async (responseObj, dispatch) => {
  return axios.post(baseUrl + apiEndPoint.insertShipping, responseObj);
};

export const getShippingDetails = (requestObj, dispatch) => {
  return axios
    .post(baseUrl + apiEndPoint.getShipping, requestObj)
    .then((res) => {
      if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        res.data.detail["total_row"] = res.data.total_row;
        res.data.detail["current_page"] = res.data.current_page;
        dispatch({
          type: "SHIPPINGADDRESS",
          payload: res.data.detail,
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateShippingDetails = async (requestObj, dispatch) => {
  return axios
    .post(baseUrl + apiEndPoint.updateShippingAddress, requestObj)
    .then((res) => {
      if (res.data.status) {
        new toastifyClass(
          true,
          "Updated Successfully",
          "success",
          dispatch
        ).emittoast();
        return res;
      } else {
        //new toastifyClass(true, "Unknown Error", "error", dispatch).emittoast();
        return res;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteShippingDetails = async (requestObj, dispatch) => {
  return axios
    .post(`${baseUrl}${apiEndPoint.deleteShippingAddress}`, requestObj)
    .then((res) => {
      if (res.data.status != "error") {
        new toastifyClass(
          true,
          "deleted Successfully",
          "error",
          dispatch
        ).emittoast();
        return true;
      } else {
        //new toastifyClass(true, "Unknown Error", "error", dispatch).emittoast();
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getCustomerInfoForAdmin = async (customerInfo, dispatch) => {
  dispatch({
    type: "LOADING",
    payload: true,
  });
  return await axios
    .post(baseUrl + apiEndPoint.accountOverviewAdmin, customerInfo)
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: "ACCOUNT_OVERVIEW_ADMIN",
          payload: res.data,
        });
        dispatch({
          type: "LOADING",
          payload: false,
        });
      } else {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      }
    });
};

export const getProductPopupDetails = async (productInfo, dispatch) => {
  const res = await axios.post(
    baseUrl + apiEndPoint.getProductDetails,
    productInfo
  );
};

export const addedToCart = async (addItem, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.addToCart, addItem)
    .then((res) => {
      if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        if (res.data.status) {
          new toastifyClass(
            true,
            res.data.msg,
            "success",
            dispatch
          ).emittoast();
        }
      }
    });
};

export const AddToWatchList = (requestObj, dispatch) => {
  return axios.post(baseUrl + apiEndPoint.wishList, requestObj).then((res) => {
    if (
      res.data.status == "error" &&
      res.data.message == "Invalid SessionKey"
    ) {
      dispatch({
        type: "POPUP_MESSAGE_CLOSE",
        payload: false,
      });
      userLogOut(dispatch);
    } else {
      if (res.data && res.data.status) {
        new toastifyClass(
          true,
          res.data.detail,
          "success",
          dispatch
        ).emittoast();
        return res;
      } else {
        new toastifyClass(
          true,
          "Please Select any field",
          "error",
          dispatch
        ).emittoast();
        throw "Please Select any field";
      }
    }
  });
};

export const deleteWatchList = (requestObj, dispatch) => {
  return axios
    .post(baseUrl + apiEndPoint.deletewishList, requestObj)
    .then((res) => {
      if (res.data && res.data.status === true) {
        new toastifyClass(
          true,
          "Product removed from Wishlist",
          "error",
          dispatch
        ).emittoast();
        return res;
      } else if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        new toastifyClass(true, "API Error", "error", dispatch).emittoast();
        throw "API Error";
      }
    });
};

export const deleteSavedWatchList = (requestObj, dispatch) => {
  return axios
    .post(baseUrl + apiEndPoint.deleteSavedwishList, requestObj)
    .then((res) => {
      if (res.data && res.data.status === true) {
        new toastifyClass(
          true,
          "Product removed from saved Wishlist",
          "error",
          dispatch
        ).emittoast();
        return res;
      } else if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        new toastifyClass(true, "API Error", "error", dispatch).emittoast();
        throw "API Error";
      }
    });
};

export const wishlistList = async (requestObj, dispatch, intialLoad) => {
  return axios
    .post(baseUrl + apiEndPoint.getAllWishlist, requestObj)
    .then((res) => {
      if (res.data.status === true && intialLoad ? !intialLoad : true) {
        dispatch({
          type: "WISHLIST",
          payload: res.data,
        });
        dispatch({
          type: "WISHLIST_DETAILS",
          payload: res.data,
        });
        return res;
      } else if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        // new toastifyClass(true, "API Error", "error", dispatch).emittoast();
        if (res.data.status) {
          return res;
        } else {
          console.log("wishlist error");
        }
      }
    });
};

export const savedwishlistList = async (requestObj, dispatch, intialLoad) => {
  return axios
    .post(baseUrl + apiEndPoint.getAllSavedWishlist, requestObj)
    .then((res) => {
      if (res.data.status === true && intialLoad ? !intialLoad : true) {
        dispatch({
          type: "SAVED_WISHLIST",
          payload: res.data,
        });
        dispatch({
          type: "SAVED_WISHLIST_DETAILS",
          payload: res.data,
        });
        return res;
      } else if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        // new toastifyClass(true, "API Error", "error", dispatch).emittoast();
        if (res.data.status) {
          return res;
        } else {
          console.log("saved wishlist error");
        }
      }
    });
};

export const bannerLogoUpload = async (uploadFile, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.uploadBanner, uploadFile)
    .then((res) => {
      if (res.data.status) {
        new toastifyClass(true, res.data.msg, "success", dispatch).emittoast();
      } else {
        new toastifyClass(true, res.data.msg, "error", dispatch).emittoast();
      }
      return res;
    });
};

export const exportStockLevel = async (csvData, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.exportStockLevelCsv, csvData)
    .then((res) => {
      if (res.data.status) {
        if (
          res.data.status == "error" &&
          res.data.message == "Invalid SessionKey"
        ) {
          dispatch({
            type: "POPUP_MESSAGE_CLOSE",
            payload: false,
          });
          userLogOut(dispatch);
        } else {
          if (res.data.exportName === "Freshwater") {
            dispatch({
              type: "EXPORT_FRESH_STOCK_LEVEL",
              payload: res.data,
            });
          } else {
            dispatch({
              type: "EXPORT_SALT_STOCK_LEVEL",
              payload: res.data,
            });
          }
        }
      }
    });
};

export const featuredProductDetails = async (featProDtls, dispatch) => {
  dispatch({
    type: "LOADING",
    payload: true,
  });
  return await axios
    .post(baseUrl + apiEndPoint.featuredProduct, featProDtls)
    .then((res) => {
      if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        dispatch({
          type: "FEATURED_PRODUCTS",
          payload: res.data,
        });

        // dispatch({
        //   type: "LOADING",
        //   payload: false,
        // });
      }
    });
};

export const specialProductDetails = async (
  featProDtls,
  dispatch,
  addCartLoad
) => {
  dispatch({
    type: "LOADING",
    payload:
      !addCartLoad && typeof addCartLoad !== "undefined" ? addCartLoad : true,
  });
  return await axios
    .post(baseUrl + apiEndPoint.featuredProduct, featProDtls)
    .then((res) => {
      if (
        res.data.status == "error" &&
        res.data.message == "Invalid SessionKey"
      ) {
        dispatch({
          type: "POPUP_MESSAGE_CLOSE",
          payload: false,
        });
        userLogOut(dispatch);
      } else {
        dispatch({
          type: "SPECIAL_PRODUCTS",
          payload: res.data,
        });

        // dispatch({
        //   type: "LOADING",
        //   payload: false,
        // });
      }
    });
};

export const getCurrentTime = async (dispatch) => {
  return await axios.post(baseUrl + apiEndPoint.getTime).then((res) => {
    if (res.data.status)
      dispatch({
        type: "INITIAL_SERVER_TIME",
        payload: res.data.detail,
      });
    return res;
  });
};

export const getInventoryDtlsUpdatedTime = async (sessionVal, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.invUpdatedtime, sessionVal)
    .then((res) => {
      if (res.status)
        dispatch({
          type: "INV_TIMER",
          payload: res.data.updatedTime,
        });
    });
};

export const exportOrderItems = async (dispatch) => {
  return await axios.get(baseUrl + apiEndPoint.exportOrder).then((res) => {
    dispatch({
      type: "EXPORT_BULK_ORDER",
      payload: res.data,
    });
  });
};

export const importOrderItems = async (importData, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.importExcelBulkOrder, importData)
    .then((res) => {
      if (res.data.status) {
        new toastifyClass(
          true,
          res.data.details,
          "success",
          dispatch
        ).emittoast();
      } else {
        new toastifyClass(
          true,
          res.data.details,
          "error",
          dispatch
        ).emittoast();
      }
      return res;
    });
};

export const viewLogoBanner = async (viewbanner, dispatch) => {
  return await axios.post(baseUrl + apiEndPoint.viewLogoBanner, viewbanner);
};
