import axios from "axios";
import apiEndPoint from "../apiEndPoints";
import toastifyClass from "../../utils/emitToastify";
import popupClass from "../../utils/emitPopup";
import { randHex } from "../../utils/generateHex";
import moment from "moment";

const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/sdc`;

export const userLogOut = async (dispatch) => {
  const user = JSON.parse(sessionStorage.getItem("user")) || {};
  const session_id = user.session_id;
  const { detail = {} } = user;
  const { user_id = "" } = detail;
  if (user_id) {
    await axios
      .post(baseUrl + apiEndPoint.userLogOut, { user_id, session_id })
      .then((res) => {
        console.log(res);
        window.location.href = "/";
        const luser = sessionStorage.clear();
        localStorage.removeItem("initalpopup");
        const tostifyValue = {
          tostifyView: true,
          tostifyMessage: "Logout Successfull!",
          severity: "success",
        };
        dispatch({
          type: "LOGOUT",
          payload: luser,
        });
        dispatch({
          type: "TOSTIFY_MESSAGE",
          payload: tostifyValue,
        });
      });
  }
};

export const getSavedCart = (getSavedInfo, dispatch) => {
  dispatch({
    type: "LOADING",
    payload: true,
  });
  return axios
    .post(`${baseUrl}${apiEndPoint.getSavedCart}`, getSavedInfo)
    .then(function (response) {
      if (response.data) {
        dispatch({
          type: "SAVED_CART",
          payload: response.data,
        });
        dispatch({
          type: "LOADING",
          payload: false,
        });
      } else {
        throw "error";
      }
    })
    .catch(function (error) {
      new toastifyClass(true, "Unknown Error", "error", dispatch);
    });
};

export const addItemToCart = async (
  item,
  dispatch,
  onButtonCallback,
  secondatButtonCallback,
  alreadyExistItem,
  ispopupHangle,
  okClose
) => {
  let cartJson = {
    sessionKey: item.sessionKey,
    websku: item.webSKU,
    items: [
      {
        sku: item.SKU,
        qty: item.qty,
        override: item.override,
      },
    ],
    is_saved_wishlist: item.is_saved_wishlist || 0,
  };
  return axios.post(baseUrl + apiEndPoint.addToCart, cartJson).then((res) => {
    if (ispopupHangle === false) {
      return res;
    }
    if (res.data.status) {
      new toastifyClass(
        true,
        res.data.msg && typeof res.data.msg == "string"
          ? res.data.msg
          : res.data.detail && typeof res.data.detail == "string"
          ? res.data.detail
          : "",
        "success",
        dispatch
      ).emittoast();
      dispatch({
        type: "INC_CART",
        payload: res.data,
      });
    } else {
      dispatch({
        type: "CART_PRODUCTS_EXIST",
        payload: res.data,
      });
      if (res.data.flag == "exist") {
        let stringValue = `The Following Items already is in your Cart, Are you sure you want to add more? `;
        new popupClass(
          true,
          stringValue,
          "Oops !! Already Available in Cart",
          "No",
          "Yes",
          "ok-popup-app",
          "no-popup-app",
          dispatch,
          "",
          onButtonCallback,
          "",
          "already-avail-cart",
          "already-add-action"
        ).emitPopup();
      } else {
        if (!res.data.status && res.data.flag == "error") {
          new popupClass(
            true,
            res.data.msg,
            "Added to  your cart",
            "",
            "",
            "",
            "",
            dispatch,
            okClose,
            "",
            "",
            "insufficent-poup"
          ).emitPopup();
        } else if (!res.data.status) {
          let starttime = moment(res.data.starttime)
            .utc()
            .format("Do MMMM YYYY, LT");
          let endtime = moment(res.data.endtime)
            .utc()
            .format("Do MMMM YYYY, LT");
          let dateTimeDtls = `Sale starts at ${starttime} and ends at ${endtime}.  Please wait. Thanks.`;
          new popupClass(
            true,
            dateTimeDtls,
            "Oops !!! Sale Timings",
            "",
            "",
            "",
            "",
            dispatch,
            "",
            "",
            "",
            "sale-end-popup",
            ""
          ).emitPopup();
          return res;
        }
      }
    }
    return res;
  });
};

export const addToCartBulk = async (payload, dispatch) => {
  const res = await axios.post(baseUrl + apiEndPoint.addToCartBulk, payload);
  if (res.data.status) {
    new toastifyClass(
      true,
      res.data.msg && typeof res.data.msg == "string"
        ? res.data.msg
        : res.data.detail && typeof res.data.detail == "string"
        ? res.data.detail
        : "",
      "success",
      dispatch
    ).emittoast();
    dispatch({
      type: "INC_CART",
      payload: res.data,
    });
    return res;
  } else {
    // new toastifyClass(
    //   true,
    //   res.data.msg && typeof res.data.msg == "string"
    //     ? res.data.msg
    //     : res.data.detail && typeof res.data.detail == "string"
    //     ? res.data.detail
    //     : "",
    //   "error",
    //   dispatch
    // ).emittoast();
    return res;
  }
};

export const addToSavedWishList = async (payload, dispatch) => {
  const res = await axios.post(
    baseUrl + apiEndPoint.addToSavedWishList,
    payload
  );
  if (res.data.status) {
    new toastifyClass(
      true,
      res.data.msg && typeof res.data.msg == "string"
        ? res.data.msg
        : res.data.detail && typeof res.data.detail == "string"
        ? res.data.detail
        : "",
      "success",
      dispatch
    ).emittoast();
    return res;
  }
};

export const fetchCartItem = (cart) => async (dispatch) => {
  dispatch({
    type: "CART_LOADING",
    payload: true,
  });
  cart = {
    ...cart,
    orderSessionId: localStorage.getItem("orderSessionId"),
  };
  return await axios.post(baseUrl + apiEndPoint.viewCart, cart).then((res) => {
    if (
      res.data.status == "error" &&
      res.data.message == "Invalid SessionKey"
    ) {
      dispatch({
        type: "POPUP_MESSAGE_CLOSE",
        payload: false,
      });
      userLogOut(dispatch);
    } else {
      if (res.data.status !== "error") {
        if (typeof res.data !== "undefined") {
          if (typeof res.data.detail !== "undefined") {
            if (Array.isArray(res.data.detail)) {
              res.data.detail = res.data.detail.map((ele) => {
                ele["db_time"] = res.data.db_time;
                ele["db_time_live"] = res.data.db_time_live;
                ele["db_time_managed"] = res.data.db_time_managed;
                return ele;
              });
              if (
                res.data.detail.length > 0 &&
                res.data.detail[0].sku_dtls.purchaseitem != "Y" &&
                cart.viewTime != 1
              ) {
                localStorage.setItem("db_time_live", res.data.db_time_live);
                localStorage.setItem(
                  "db_time_managed",
                  res.data.db_time_managed
                );
              }
            }
          }
        }

        if (res.data.status) {
          dispatch({
            type: "CART_DATA",
            payload: res.data,
          });
        }
        dispatch({
          type: "VIEW_CART_ITEM_INCREMENT",
          payload: res.data,
        });
        dispatch({
          type: "OUT_OF_STOCK",
          payload: res.data.outOfStock,
        });
        dispatch({
          type: "CART_LOADING",
          payload: false,
        });
      }
    }
    return res;
  });
};

export const getEditSavedDtls = async (savedId, dispatch) => {
  dispatch({
    type: "LOADING",
    payload: true,
  });
  return await axios
    .post(baseUrl + apiEndPoint.editSavedCart, savedId)
    .then((res) => {
      dispatch({
        type: "EDIT_SAVED_CART_ITEM",
        payload: res.data,
      });
      dispatch({
        type: "LOADING",
        payload: false,
      });
      return res;
    });
};

export const updateCartItem = async (updateInfo, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.updateCart, updateInfo)
    .then((res) => {
      if (res.data.status) {
        new toastifyClass(true, res.data.msg, "success", dispatch).emittoast();
        dispatch({
          type: "INC_CART",
          payload: res.data,
        });
      } else {
        // new toastifyClass(true, res.data.msg, "error", dispatch).emittoast();
        new popupClass(
          true,
          res.data.msg,
          "Added to  your cart",
          "",
          "",
          "",
          "",
          dispatch
        ).emitPopup();
      }

      return res;
    });
};

export const deleteSingleCartItem = async (delItem, dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.deleteCart, delItem)
    .then((res) => {
      if (res.data.status) alert("Delete Successfully");
      // new popupClass(
      //   true,
      //   "Delete Successfully",
      //   "Confirm Delete",
      //   "Ok",
      //   "",
      //   "",
      //   "",
      //   dispatch
      // ).emitPopup();
    });
};

export const fetchSavedCartNickName = (cart) => async (dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.getSavedCart, cart)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: "SAVED_CART_NICKNAME",
          payload: res.data.detail,
        });
      }
    });
};

export const fetchOrderDetails = (details) => async (dispatch) => {
  return await axios
    .post(baseUrl + apiEndPoint.orderConfirm, details)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: "FETCH_ORDER",
          payload: res.data,
        });
      }
    });
};

export const saveCartWithNickName = async (
  cart,
  dispatch,
  secndaryFunction
) => {
  return await axios.post(baseUrl + apiEndPoint.savedCart, cart).then((res) => {
    if (res.data.status) {
      new popupClass(
        true,
        res.data.msg,
        "Saved Item",
        "Ok",
        "",
        "",
        "",
        dispatch,
        secndaryFunction,
        undefined,
        "",
        "",
        "",
        "xs"
      ).emitPopup();
      //  dispatch({
      //    type: "SAVED_CART_NICKNAME",
      //    payload: res.data.detail,
      //  });
    } else {
      const liveItemsContent =
        "Your Cart Contains Live/Manage Inventory Items. Live/Manage Inventory items have a time limit, and cannot be saved.";
      new popupClass(
        true,
        liveItemsContent,
        "Contains Live Items!!!",
        "Ok",
        "",
        "live-item-button",
        "",
        dispatch,
        "",
        "",
        "",
        "live-item-popup"
      ).emitPopup();
    }
  });
};

export const fetchShippingDetails = (payload, dispatch) => {
  return axios.post(baseUrl + apiEndPoint.editShippingAddress, payload);
};
export const placeOrder_req = (payload, dispatch) => {
  payload = {
    ...payload,
    orderSessionId: localStorage.getItem("orderSessionId"),
  };
  return axios.post(baseUrl + apiEndPoint.placeOrder, payload).then((res) => {
    localStorage.setItem("orderSessionId", randHex(15));
    if (res.data.status == false && res.data.outofstock === 1) {
      console.log("outofstock");
    } else if (res.data.status == false && res.data.flag === "furnace_qty") {
      console.log("furnace qty");
    } else if (res.data && res.data.statue != "error") {
      new toastifyClass(true, "Order Placed", "success", dispatch).emittoast();
    } else {
      new toastifyClass(true, "Error", "success", dispatch).emittoast();
    }
    return res;
  });
};

export const deleteAllIncart = async (deleteData, dispatch) => {
  dispatch({
    type: "LOADING",
    payload: true,
  });
  return axios
    .post(baseUrl + apiEndPoint.deleteAllCart, deleteData)
    .then((res) => {
      return res;
    });
};
export const deleteSavedItemIndividual = async (deleteIndvItem, dispatch) => {
  return axios.post(baseUrl + apiEndPoint.deleteSavedCart, deleteIndvItem);
};

export const deleteOutOfStock = async (deleteItem, dispatch) => {
  return axios.post(
    baseUrl + apiEndPoint.removeOutOfStockItemFromCart,
    deleteItem
  );
};

export const updateSavedCartName = (savedItemDtls, dispatch) => {
  dispatch({
    type: "LOADING",
    payload: true,
  });
  return axios.post(baseUrl + apiEndPoint.updateSavedCart, savedItemDtls);
};

export const editSavedCartSingleItemDelete = async (
  deleteSingleEditItem,
  dispatch
) => {
  dispatch({
    type: "LOADING",
    payload: true,
  });
  return await axios.post(
    baseUrl + apiEndPoint.deleteSavedItem,
    deleteSingleEditItem
  );
};

export const editSavedCartWholeItemDelete = async (
  deleteWholeWebsku,
  dispatch
) => {
  dispatch({
    type: "LOADING",
    payload: true,
  });
  return await axios.post(
    baseUrl + apiEndPoint.deleteSavedWholeItem,
    deleteWholeWebsku
  );
};

export const editSavedCartItemCartUpdate = async (itemCartDtls, dispatch) => {
  dispatch({
    type: "LOADING",
    payload: true,
  });
  return await axios.post(
    baseUrl + apiEndPoint.updateSavedCartItem,
    itemCartDtls
  );
};

export const purchaseFromEditSavedCart = async (purchaseItem, dispatch) => {
  return await axios.post(
    baseUrl + apiEndPoint.purchaseFromSavedCart,
    purchaseItem
  );
};

export const intemateBackendToDelete = async (deleteItem, dispatch) => {
  return axios.post(baseUrl + "/removelive_managed_item_api", deleteItem);
};

export const getAllUsersAdmin = async (userInfoAdmin, dispatch) => {
  return axios
    .post(baseUrl + apiEndPoint.getEmployeeList, userInfoAdmin)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: "FETCH_USER_DETAILS",
          payload: res.data.data,
        });
      }
    });
};

export const getAdminSelectedUserDtls = async (getUserOrder, dispatch) => {
  return axios
    .post(baseUrl + apiEndPoint.getUserOrders, getUserOrder)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: "SELECTED_ADMIN_USER_DTLS",
          payload: res.data.data,
        });
      }
    });
};

export const combineOrderAdmin = async (combineOrderDtls, dispatch) => {
  return axios
    .post(baseUrl + apiEndPoint.combineOrders, combineOrderDtls)
    .then((res) => {
      return res;
    });
};

export const getUserViewDetailProd = async (userOrdDtlLts, dispatch) => {
  return axios
    .post(baseUrl + apiEndPoint.getUserOrderDetailsList, userOrdDtlLts)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: "VIEW_ORDER_PRODCUT_DTLS",
          payload: res.data.data,
        });
      }
    });
};
export const confirmOrderId = async (confirmId) => {
  return axios
    .post(baseUrl + apiEndPoint.confirmRoi, confirmId)
    .then((res) =>
      console.log(res.data, "this is the responce from the order id")
    );
};
