import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Home from "./views/Home";
import "./App.css";
import Routes from "./Routes";
import TostifyWrapper from "./components/TostifyWrapper";
import TostifyAlert from "./components/TostifyAlert";
import PopupAlert from "./components/PopupAlert";
const App = () => {
  const [view, setView] = useState(false);
  const tostifyInfo = useSelector((state) => state.tostify);
  const popupView = useSelector((state) => state.popupAlert);

  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: "TOSTIFY_MESSAGE",
        payload: { tostifyView: false, tostifyMessage: "", severity: "" },
      });
    }, 6000);
  }, [tostifyInfo.tostifyView]);

  const onClickClose = () => {
    dispatch({
      type: "POPUP_MESSAGE_CLOSE",
      payload: false,
    });
  };

  return (
    <>
      <div className="App">
        <Router>
          <Routes />
        </Router>
      </div>
      <TostifyWrapper open={tostifyInfo.tostifyView}>
        <TostifyAlert severity={tostifyInfo.severity}>
          {tostifyInfo.tostifyMessage}
        </TostifyAlert>
      </TostifyWrapper>

      <PopupAlert
        open={popupView.popupView}
        heading={popupView.heading}
        content={popupView.popupMessage}
        action={popupView.actions}
        onClose={
          popupView.okbuttonCallback ? popupView.okbuttonCallback : onClickClose
        }
        {...popupView}
      />
    </>
  );
};

export default App;
