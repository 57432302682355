const intialState = {
  details: {},
  key: "",
  menues: null,
  navId: "",
  nav_name: sessionStorage.getItem("nav_name")
    ? sessionStorage.getItem("nav_name")
    : "",
  navSubId: "",
  searchedValueDetails: {},
  furnanceTotalData: "",
  fetchLoader: false,
  masterLayoutSku: "",
  masterLayoutPrice: "",
  masterLayoutCoralType: "",
  furnanceLoad: false,
  loginInitalWishlist: false,
  menuSelect: false,
  masterLayoutValue: false,
};

export default function (state = intialState, action) {
  switch (action.type) {
    case "GET_SEARCH_RESULT":
      return {
        ...state,
        details: action.payload,
      };
    case "SEARCH_KEY":
      return {
        ...state,
        key: action.payload,
      };
    case "SITE_MENU":
      return {
        ...state,
        menues: action.payload,
      };
    case "NAV_ID":
      return {
        ...state,
        navId: action.payload,
      };
    case "nav_name":
      return {
        ...state,
        nav_name: action.payload,
      };
    case "NAV_SUB_ID":
      return {
        ...state,
        navSubId: action.payload,
      };
    case "REDIRECT":
      return { redirectTo: action.payload };

    case "SEARCHED_VALUE":
      return {
        ...state,
        searchedValueDetails: action.payload,
      };
    case "TOTAL_DATA":
      return {
        ...state,
        furnanceTotalData: action.payload,
      };

    case "FETCH_LOAD":
      return {
        ...state,
        fetchLoader: action.payload,
      };
    case "MASTERLAYOUT_SKU":
      return {
        ...state,
        masterLayoutSku: action.payload,
      };
    case "MASTERLAYOUT_PRICE":
      return {
        ...state,
        masterLayoutPrice: action.payload,
      };
    case "MASTERLAYOUT_CORAL_TYPE":
      return {
        ...state,
        masterLayoutCoralType: action.payload,
      };

    case "FURNANCE_LOAD_DATA":
      return {
        ...state,
        furnanceLoad: action.payload,
      };
    case "LOGIN_WISHLIST_POPUP":
      return {
        ...state,
        loginInitalWishlist: action.payload,
      };

    case "MENU_SELECTED":
      return {
        ...state,
        menuSelect: action.payload,
      };

    case "MASTER_LAYOUT_VALUE_SELECTED":
      return {
        ...state,
        masterLayoutValue: action.payload,
      };
    default:
      return state;
  }
}
