import React, { useState, useEffect, useRef } from "react";
import { ArrowBack } from "@material-ui/icons";
import { Container, IconButton } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import PrimaryButton from "../PrimaryButton";
import CustomCheckBox from "../CustomCheckBox";
import InputField from "../Input/InputField";
import SelectField from "../Input/SelectField";
import {
  getCountries,
  updateUserInformation,
  editUserMail,
} from "../../Store/Actions/userActions";
import { formstate } from "./utils";

const EditExistingInfoPage = (props) => {
  const editUserStatus = useSelector((state) => {
    return state.editUserInfo;
  });
  const sref = useRef(null);

  const [editExistInfo, setEditExistInfo] = useState(formstate({}));

  const [editExistError, setEditExistError] = useState({});

  const [validationErr, setValidationErr] = useState({});
  const [test, setTest] = useState("test");

  // const editUserStatus = useSelector((state) => state.editUserInfo);

  const countries = useSelector((state) => state.newRegistration.newCountries);

  const dispatch = useDispatch();

  const history = useHistory();

  const urlpath = window.location.href;

  useEffect(() => {
    getCountries(dispatch);
    if (props && props.location && props.location.state) {
      editUserMail({ email: props.location.state.email }, dispatch);
    } else if (urlpath && urlpath.split("email=")[1]) {
      editUserMail({ email: urlpath.split("email=")[1] }, dispatch).then(() => {
        sref.current.scrollIntoView();
      });
    }
  }, []);
  const onEditExistingUserInfoChange = (e) => {
    let name = e.target.name;
    let type = e.target.value;
    switch (type) {
      case "file":
        let file = e.target.files[0];
        setEditExistInfo({
          ...editExistInfo,
          [name]: file,
        });
        break;
      default:
        let value = e.target.value;
        let Obj = {
          value,
          error: editExistInfo[name]["error"],
          isAuth: editExistInfo[name]["isAuth"],
        };

        if (editExistInfo[name].hasOwnProperty("checked")) {
          Obj["checked"] = !editExistInfo[name]["checked"];
          if (editExistError["checkedValue"]) {
            delete editExistError["checkedValue"];
          }
          if (Obj["checked"] === true) {
            let copyCheckedValue = editExistInfo.checkedValue;
            let checkedValueIndex = copyCheckedValue.indexOf(Obj.value);
            if (checkedValueIndex < 0) {
              editExistInfo.checkedValue.push(Obj.value);
            }
          } else if (Obj["checked"] === false) {
            let copyCheckedValue = editExistInfo.checkedValue;
            let checkedValueIndex = copyCheckedValue.indexOf(Obj.value);
            copyCheckedValue.splice(checkedValueIndex, 1);
            setEditExistInfo({ checkedValue: copyCheckedValue });
          }
        }
        setEditExistError({
          ...editExistError,
          [name]: "",
        });

        setEditExistInfo({
          ...editExistInfo,
          [name]: Obj,
        });

        setValidationErr({
          ...validationErr,
          [name]: "",
        });
        break;
    }
  };

  const convertIntoFormDqata = (registerData) => {
    let formData = new FormData();
    Object.keys(registerData).map((reg, regIndex) => {
      if (reg === "filestax" || reg === "fileftax")
        formData.append(reg, registerData[reg]);
      else if (reg === "checkedValue")
        formData.append("retailStore", registerData[reg].toString());
      else formData.append(reg, registerData[reg].value);
    });
    return formData;
  };

  const validate = () => {
    let err = {};
    Object.keys(editExistInfo).map((user) => {
      if (user == "checkedValue") {
        if (Array.isArray(editExistInfo[user])) {
          if (editExistInfo[user].length === 0) {
            err["checkedValue"] = "please check any field";
          }
        }
      }
      if (editExistInfo[user].value === "" && editExistInfo[user].isAuth) {
        err[user] = editExistInfo[user]["error"];
      }
    });
    setEditExistError(err);
    return err;
  };

  const validationFormat = () => {
    let valErr = {};
    Object.keys(editExistInfo).map((property) => {
      if (property === "phone" || property === "oPhone") {
        if (
          !(editExistInfo[property].value === undefined) ||
          !(editExistInfo[property].value === "")
        )
          if (editExistInfo[property].value.length < 10) {
            valErr[property] = "Phone number should be minimum 10 digits";
          } else if (editExistInfo[property].value.length > 15) {
            valErr[property] = "Phone number should be maximum 15 digits only";
          }
      } else if (property === "email") {
        const emailvalid =
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!emailvalid.test(editExistInfo[property].value))
          valErr[property] = "Please enter the valid email address";
      } else if (property === "oZip" || property === "zipCode") {
        if (editExistInfo[property].value.length < 5) {
          valErr[property] = "Zip code should be minimum 5 digits";
        } else if (editExistInfo[property].value.length > 13) {
          valErr[property] = "Zip code should be maximum 13 digits only";
        }
      }
      //  else if (
      //   property === "busiName" ||
      //   property === "contactPerson" ||
      //   property === "altPerson" ||
      //   property === "ownerName"
      // ) {
      //   const textFormat = /^[a-zA-Z\s]*$/;
      //   if (!(editExistInfo[property].value === ""))
      //     if (!textFormat.test(editExistInfo[property].value))
      //       valErr[property] = "Enter the correct name";
      // }
    });
    setValidationErr(valErr);
    return valErr;
  };

  const onEditExistingUserInfoSubmit = (e) => {
    e.preventDefault();
    const formDataObj = convertIntoFormDqata(editExistInfo);
    if (Object.keys(validate()).length === 0) {
      if (Object.keys(validationFormat()).length === 0) {
        updateUserInformation(formDataObj, dispatch).then((res) => {
          if (res.data && typeof res.data != "undefined") {
            let tostifyValue = {};
            if (res.data.status === true) {
              tostifyValue["tostifyView"] = true;
              tostifyValue["tostifyMessage"] = res.data.msg;
              tostifyValue["severity"] = "success";
            } else {
              tostifyValue["tostifyView"] = true;
              tostifyValue["tostifyMessage"] = "edit registration error";
              tostifyValue["severity"] = "error";
            }

            dispatch({
              type: "TOSTIFY_MESSAGE",
              payload: tostifyValue,
            });
            if (res.data.status) history.push("/thankyou");
          }
        });
      }
    }
  };

  useEffect(() => {
    let errorsElements = document.getElementsByClassName("error-theme");
    if (
      errorsElements &&
      typeof errorsElements != "undefined" &&
      errorsElements.length
    ) {
      let firstErrElement = errorsElements[0];
      firstErrElement.parentElement.parentElement.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [editExistError]);

  useEffect(() => {
    if (editUserStatus.status)
      if (editUserStatus.editUserInfo.hasOwnProperty("retailStore")) {
        let checkedValueArr =
          editUserStatus.editUserInfo.retailStore.split(",");
        let editVal = formstate(editUserStatus);
        checkedValueArr.map((edit) => {
          switch (edit) {
            case "retailStores":
              editVal["retailStores"] = {
                value: "",
                checked: true,
                isAuth: false,
              };
              break;
            case "onlineRetailer":
              editVal["onlineRetailer"] = {
                value: "",
                checked: true,
                isAuth: false,
              };

              break;
            case "serviceMaintenanceBusiness":
              editVal["serviceMaintenanceBusiness"] = {
                value: "",
                checked: true,
                isAuth: false,
              };
              break;
            case "educationalInstitution":
              editVal["educationalInstitution"] = {
                value: "",
                checked: true,
                isAuth: false,
              };
              break;
            case "publicPrivateAquarium":
              editVal["publicPrivateAquarium"] = {
                value: "",
                checked: true,
                isAuth: false,
              };
              break;
          }
        });
        setEditExistInfo({
          ...editExistInfo,
          ...editVal,
          checkedValue: checkedValueArr,
        });
      }
  }, [editUserStatus.status]);

  return (
    <div className="sdHome">
      <div className="bnrBack">
        <div className="sdhBanner">
          <div className="sdhLogo">
            <img
              src="./assets/images/logo_home.png"
              alt="Seadwelling logo"
            ></img>
          </div>
        </div>
      </div>
      <Container>
        <div ref={sref} className="new-customer-information pt-5">
          <div className="heading  py-3 ">
            <p className=" heading-text text-white text-left mx-3 my-0">
              Edit Customer Information
              <IconButton className="float-right  p-0">
                <Link to="/">
                  {" "}
                  <ArrowBack className="text-white heading-arrow my-1" />
                </Link>
              </IconButton>
            </p>
          </div>
        </div>
        <div className="business-information d-flex justify-content-between my-3">
          <p className="business-text my-2">Business Information</p>
        </div>{" "}
        <form onSubmit={onEditExistingUserInfoSubmit}>
          <div className="business-types">
            <p className="text-left">
              Types of Business:
              <span className="asterk">&nbsp;*</span>
            </p>
            <div className="float-left">
              <CustomCheckBox
                label="Retail Store"
                value="retailStores"
                name="retailStores"
                onclick={onEditExistingUserInfoChange}
                checked={editExistInfo.retailStores.checked}
              />
              <CustomCheckBox
                label="Online Retailer"
                value="onlineRetailer"
                name="onlineRetailer"
                onclick={onEditExistingUserInfoChange}
                checked={editExistInfo.onlineRetailer.checked}
              />
              <CustomCheckBox
                label="Service Maintenance Business"
                value="serviceMaintenanceBusiness"
                name="serviceMaintenanceBusiness"
                onclick={onEditExistingUserInfoChange}
                checked={editExistInfo.serviceMaintenanceBusiness.checked}
              />
              <CustomCheckBox
                label="Educational Institution"
                value="educationalInstitution"
                name="educationalInstitution"
                onclick={onEditExistingUserInfoChange}
                checked={editExistInfo.educationalInstitution.checked}
              />
              <CustomCheckBox
                label="Public/Private Aquarium"
                value="publicPrivateAquarium"
                name="publicPrivateAquarium"
                onclick={onEditExistingUserInfoChange}
                checked={editExistInfo.publicPrivateAquarium.checked}
              />
              {editExistError.checkedValue ? (
                <div>
                  <p className="error-theme"> {editExistError.checkedValue}</p>
                </div>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-between flex-wrap w-100"></div>
          <div className="owner-info mt-3">
            {/* <p className="text-left">Owner Information</p> */}
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Referred by:(Name of Magazine, Company, Trade show or
                      Other)
                      {/* <span className="asterk">&nbsp;*</span> */}
                    </span>
                  }
                  // placeholder="Referred by:(Name of Magazine, Company, Trade show or Other) "
                  name="refBy"
                  size="medium"
                  type="text"
                  id="refBy"
                  value={editExistInfo.refBy.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.refBy ? (
                  <span className="error-theme">{editExistError.refBy}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Business Name
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Business Name"
                  name="busiName"
                  size="medium"
                  type="text"
                  id="busiName"
                  value={editExistInfo.busiName.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.busiName ? (
                  <span className="error-theme">{editExistError.busiName}</span>
                ) : null}
                {validationErr.busiName ? (
                  <span className="error-theme">{validationErr.busiName}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Address 1<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Address 1"
                  name="address1"
                  size="medium"
                  type="text"
                  id="address1"
                  value={editExistInfo.address1.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.address1 ? (
                  <span className="error-theme">{editExistError.address1}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Address 2"
                  // placeholder="Address 2"
                  name="address2"
                  size="medium"
                  type="text"
                  id="address2"
                  value={editExistInfo.address2.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.address2 ? (
                  <span className="error-theme">{editExistError.address2}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Address 3"
                  // placeholder="Address 3"
                  name="address3"
                  size="medium"
                  type="text"
                  id="address3"
                  value={editExistInfo.address3.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.address3 ? (
                  <span className="error-theme">{editExistError.address3}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Country<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Country"
                  name="country"
                  id="country"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={editExistInfo.country.value}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                {editExistError.country ? (
                  <span className="error-theme">{editExistError.country}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      City<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="City"
                  name="city"
                  size="medium"
                  type="text"
                  id="city"
                  value={editExistInfo.city.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.city ? (
                  <span className="error-theme">{editExistError.city}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      State<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="State"
                  name="state"
                  size="medium"
                  type="text"
                  id="state"
                  value={editExistInfo.state.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.state ? (
                  <span className="error-theme">{editExistError.state}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Zip Code<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Zip Code"
                  name="zipCode"
                  size="medium"
                  type="text"
                  id="zipCode"
                  value={editExistInfo.zipCode.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.zipCode ? (
                  <span className="error-theme">{editExistError.zipCode}</span>
                ) : null}

                {validationErr.zipCode ? (
                  <span className="error-theme">{validationErr.zipCode}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Main Telephone<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Main Telephone"
                  name="phone"
                  size="medium"
                  type="number"
                  id="phone"
                  value={editExistInfo.phone.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.phone ? (
                  <span className="error-theme">{editExistError.phone}</span>
                ) : null}
                {validationErr.phone ? (
                  <span className="error-theme">{validationErr.phone}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Fax"
                  // placeholder="Fax"
                  name="fax"
                  size="medium"
                  type="number"
                  id="fax"
                  value={editExistInfo.fax.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.fax ? (
                  <span className="error-theme">{editExistError.fax}</span>
                ) : null}
                {validationErr.fax ? (
                  <span className="error-theme">{validationErr.fax}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Alt.Phone"
                  // placeholder="Alt.Phone"
                  name="altPhone"
                  size="medium"
                  type="number"
                  id="altPhone"
                  value={editExistInfo.altPhone.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.altPhone ? (
                  <span className="error-theme">{editExistError.altPhone}</span>
                ) : null}
                {validationErr.altPhone ? (
                  <span className="error-theme">{validationErr.altPhone}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Email<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Email"
                  name="email"
                  size="medium"
                  type="text"
                  id="email"
                  value={editExistInfo.email.value}
                  InputProps={{
                    readOnly: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.email ? (
                  <span className="error-theme">{editExistError.email}</span>
                ) : null}
                {validationErr.email ? (
                  <span className="error-theme">{validationErr.email}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Please list all Websites and Social Media Sites
                      {/* <span className="asterk">&nbsp;*</span> */}
                    </span>
                  }
                  // placeholder="Please list all Websites and Social Media Sites"
                  name="allWASS"
                  size="medium"
                  type="text"
                  id="allWASS"
                  value={editExistInfo.allWASS.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.allWASS ? (
                  <span className="error-theme">{editExistError.allWASS}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Contact Person(s)
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Contact Person(s)"
                  name="contactPerson"
                  size="medium"
                  type="text"
                  id="contactPerson"
                  value={editExistInfo.contactPerson.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.contactPerson ? (
                  <span className="error-theme">
                    {editExistError.contactPerson}
                  </span>
                ) : null}
                {validationErr.contactPerson ? (
                  <span className="error-theme">
                    {validationErr.contactPerson}
                  </span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  label="Alt.Contact Name(s)"
                  // placeholder="Alt.Contact Name(s)"
                  name="altPerson"
                  size="medium"
                  type="text"
                  id="altPerson"
                  value={editExistInfo.altPerson.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.altPerson ? (
                  <span className="error-theme">
                    {editExistError.altPerson}
                  </span>
                ) : null}
                {validationErr.altPerson ? (
                  <span className="error-theme">{validationErr.altPerson}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Alternate Phone No"
                  // placeholder="Alternate Phone No"
                  name="alternatePhone1"
                  size="medium"
                  type="text"
                  id="alternatePhone1"
                  value={editExistInfo.alternatePhone1.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.alternatePhone1 ? (
                  <span className="error-theme">
                    {editExistError.alternatePhone1}
                  </span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  label="Years in Business"
                  // placeholder="Alternate Phone No"
                  name="years_in_business"
                  size="medium"
                  type="text"
                  id="years_in_business"
                  value={editExistInfo.years_in_business.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.years_in_business ? (
                  <span className="error-theme">
                    {editExistError.years_in_business}
                  </span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  label="Years at Current Address"
                  // placeholder="Alternate Phone No"
                  name="years_at_current_address"
                  size="medium"
                  type="text"
                  id="years_at_current_address"
                  value={editExistInfo.years_at_current_address.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.years_at_current_address ? (
                  <span className="error-theme">
                    {editExistError.years_at_current_address}
                  </span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  label="*(California Customers) State Sales Tax Resale #: (Must include copy)"
                  // splaceholder="*(California Customers) State Sales Tax Resale #: (Must include copy)"
                  name="ssTax"
                  size="medium"
                  type="text"
                  id="ssTax"
                  value={editExistInfo.ssTax.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.ssTax ? (
                  <span className="error-theme">{editExistError.ssTax}</span>
                ) : null}
              </div>{" "}
              {editExistInfo.filestaxpath.files === "" ? (
                <div>No Document available</div>
              ) : (
                <div className="input-box my-2">
                  <a
                    target="_blank"
                    name="filestaxpath"
                    size="medium"
                    type="file"
                    id="filestax"
                    href={`${editExistInfo.filestaxpath.files}`}
                    // onChange={onEditExistingUserInfoChange}
                    style={{ float: "left" }}
                  >
                    View Document
                  </a>

                  {editExistError.filestaxpath ? (
                    <span className="error-theme">
                      {editExistError.filestaxpath}
                    </span>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <div className="owner-info mt-3">
            <p className="text-left">Owner Information</p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Owner(s) Name
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Owner(s) Name"
                  name="ownerName"
                  size="medium"
                  type="text"
                  id="ownerName"
                  value={editExistInfo.ownerName.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.ownerName ? (
                  <span className="error-theme">
                    {editExistError.ownerName}
                  </span>
                ) : null}
                {validationErr.ownerName ? (
                  <span className="error-theme">{validationErr.ownerName}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Home Address 1<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Home Address 1"
                  name="homeAddress1"
                  size="medium"
                  type="text"
                  id="homeAddress1"
                  value={editExistInfo.homeAddress1.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.homeAddress1 ? (
                  <span className="error-theme">
                    {editExistError.homeAddress1}
                  </span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      City<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="City"
                  name="homeCity"
                  size="medium"
                  type="text"
                  id="homeCity"
                  value={editExistInfo.homeCity.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.homeCity ? (
                  <span className="error-theme">{editExistError.homeCity}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      State<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="State"
                  name="oState"
                  size="medium"
                  type="text"
                  id="oState"
                  value={editExistInfo.oState.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.oState ? (
                  <span className="error-theme">{editExistError.oState}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Zip<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Zip"
                  name="oZip"
                  size="medium"
                  type="text"
                  id="oZip"
                  value={editExistInfo.oZip.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.oZip ? (
                  <span className="error-theme">{editExistError.oZip}</span>
                ) : null}
                {validationErr.oZip ? (
                  <span className="error-theme">{validationErr.oZip}</span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Home Phone<span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Home Phone"
                  name="oPhone"
                  size="medium"
                  type="number"
                  id="oPhone"
                  value={editExistInfo.oPhone.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.oPhone ? (
                  <span className="error-theme">{editExistError.oPhone}</span>
                ) : null}
                {validationErr.oPhone ? (
                  <span className="error-theme">{validationErr.oPhone}</span>
                ) : null}
              </div>
              <div className="input-box">
                <InputField
                  label="Cell Phone"
                  // placeholder="Cell Phone"
                  name="oMobile"
                  size="medium"
                  type="number"
                  id="oMobile"
                  value={editExistInfo.oMobile.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.oMobile ? (
                  <span className="error-theme">{editExistError.oMobile}</span>
                ) : null}
                {validationErr.oMobile ? (
                  <span className="error-theme">{validationErr.oMobile}</span>
                ) : null}
              </div>{" "}
              <div className="input-box">
                <InputField
                  label={
                    <span>
                      Business License or Federal Tax ID#: (Must include copy)
                      <span className="asterk">&nbsp;*</span>
                    </span>
                  }
                  // placeholder="Business License or Federal Tax ID#: (Must include copy)"
                  name="federalTax"
                  size="medium"
                  type="text"
                  id="federalTax"
                  value={editExistInfo.federalTax.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.federalTax ? (
                  <span className="error-theme">
                    {editExistError.federalTax.error}
                  </span>
                ) : null}
              </div>{" "}
              <div className="input-box my-2">
                <a
                  target="_blank"
                  name="fileftaxpath"
                  size="medium"
                  type="file"
                  id="fileftaxpath"
                  href={`${editExistInfo.fileftaxpath.files}`}
                  style={{ float: "left" }}
                >
                  {" "}
                  View Document
                </a>
                <span
                  style={{ color: "red", fontSize: "15px", float: "left" }}
                  className="mx-2 "
                >
                  *
                </span>
                {editExistError.fileftaxpath ? (
                  <span className="error-theme">
                    {editExistError.fileftaxpath.error}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="shipping-info mt-3">
            <p className="text-left">Shipping Information</p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div className="input-box">
                <InputField
                  label="Preferred Carrier(Airline, Door-to-Door or Trucking)"
                  // placeholder="Preferred Carrier(Airline, Door-to-Door or Trucking)"
                  name="preferCarr"
                  size="medium"
                  type="text"
                  id="preferCarr"
                  value={editExistInfo.preferCarr.value}
                  onChange={onEditExistingUserInfoChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {editExistError.preferCarr ? (
                  <span className="error-theme">
                    {editExistError.preferCarr}
                  </span>
                ) : null}
              </div>

              <div className="input-box">
                <SelectField
                  name="airportCode"
                  value={editExistInfo.airportCode.defaultValue}
                  id="airportCode"
                  pc_onchange={onEditExistingUserInfoChange}
                  defaultValue={editExistInfo.airportCode.defaultValue}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <option value={editExistInfo.airportCode.defaultValue}>
                    {editExistInfo.airportCode.defaultValue}
                  </option>
                  {countries.aitport_code
                    ? countries.aitport_code.map((a, index) => (
                        <option key={index} value={a.airport_code}>
                          {a.airport_code}
                        </option>
                      ))
                    : null}
                </SelectField>
                {editExistError.airportCode ? (
                  <span className="error-theme">
                    {editExistError.airportCode}
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="shipping-info mt-3 mb-3">
            <p className="text-left">Additional Information</p>
            <div className="d-flex justify-content-between flex-wrap w-100">
              <div style={{ paddingRight: "10px" }}>
                {editExistInfo.business_attachments1.files === undefined ? (
                  <div>No Documents available</div>
                ) : (
                  <div className="my-2">
                    <a
                      target="_blank"
                      name="business_attachments1"
                      size="medium"
                      type="file"
                      id="business_attachments1"
                      href={`${editExistInfo.business_attachments1.files}`}
                      // onChange={onEditExistingUserInfoChange}
                      style={{ float: "left" }}
                    >
                      View Document1
                    </a>
                  </div>
                )}
              </div>
              <div style={{ paddingRight: "10px" }}>
                {editExistInfo.business_attachments2.files !== undefined && (
                  <div className="my-2">
                    <a
                      target="_blank"
                      name="business_attachments2"
                      size="medium"
                      type="file"
                      id="business_attachments2"
                      href={`${editExistInfo.business_attachments2.files}`}
                      // onChange={onEditExistingUserInfoChange}
                      style={{ float: "left" }}
                    >
                      View Document2
                    </a>
                  </div>
                )}
              </div>

              <div style={{ paddingRight: "10px" }}>
                {editExistInfo.business_attachments3.files !== undefined && (
                  <div className="my-2">
                    <a
                      target="_blank"
                      name="business_attachments3"
                      size="medium"
                      type="file"
                      id="business_attachments3"
                      href={`${editExistInfo.business_attachments3.files}`}
                      // onChange={onEditExistingUserInfoChange}
                      style={{ float: "left" }}
                    >
                      View Document3
                    </a>
                  </div>
                )}
              </div>

              <div style={{ paddingRight: "10px" }}>
                {editExistInfo.business_attachments4.files !== undefined && (
                  <div className="my-2">
                    <a
                      target="_blank"
                      name="business_attachments4"
                      size="medium"
                      type="file"
                      id="business_attachments4"
                      href={`${editExistInfo.business_attachments4.files}`}
                      // onChange={onEditExistingUserInfoChange}
                      style={{ float: "left" }}
                    >
                      View Document4
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="submit-button my-2">
            <PrimaryButton
              type="submit"
              btnSize="small"
              className="p-0 edit-customer-submit"
            >
              Submit
            </PrimaryButton>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default React.memo(EditExistingInfoPage);
