import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  withRouter,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Search from "../views/Search";
import Home from "../views/Home";
import Header from "../components/Header";
import Cart from "../views/Cart";
import Footer from "../components/Footer";
import MyAccount from "../views/MyAccount";
import NewCustomerInformation from "../components/NewCustomerInformation";
import AddMoreInformation from "../components/AddMoreInformation";
import DetailsPopup from "../components/DetailsPopup";
import changePasswordPopup from "../components/ChangePasswordPopup";
import HelpfulLinks from "../components/HelpfulLinks";
import ShipingTacking from "../components/ShipingTracking";
import EditExistingInfoPage from "../components/EditEixistingInfoPage";
import Landing from "../views/Landing page/Landing";
import AllFurnance from "../components/AllFurnance";
import GetFtp from "../components/GetFtp";
import FeaturedProducts from "../components/FeaturedProduct";
import Furnance from "../components/Furnance";
import DryGoods from "../components/DryGoods";
import TermsAndCondition from "../components/TermsAndCondition";
import Thankyou from "../components/ThankyouNotes";
import EditSavedDetails from "../components/EditSavedDetails";
import SavedCart from "../views/MyAccount/SavedCart";
import OrderShippingCustomerInformation from "../components/OrderShippingCustomerInformation";
import OrderShippingConfirmation from "../components/OrderShippingConfirmation";
import Checkout from "../views/Checkout";
import ImportLogoBannerAdmin from "../components/ImportLogoBannerAdmin";
import BillReceipt from "../components/BillReceipt";
import SpecialProduct from "../components/SpecialPage";
import ImportExportCsv from "../components/ImportExportCsv";
import PrivateRoute from "../Routes/PrivateRoute/index";
import { validateUser } from "../Store/Actions/userActions";
import Flyer from "../components/Flyer";

const Routes = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      let user = JSON.parse(sessionStorage.getItem("user"));
      if (user?.detail?.username && user?.detail?.logged_in) {
        validateUser(
          {
            username: user.detail.username,
            session_id: user.detail.logged_in,
          },
          dispatch
        );
      }
    }
  }, []);

  return (
    <>
      {props.location.pathname === "/" ||
      props.location.pathname === "/application" ||
      props.location.pathname === "/addmoreinfo" ||
      props.location.pathname === "/editUserInformation" ||
      props.location.pathname === "/termsandcondition" ||
      props.location.pathname === "/thankyou" ? null : (
        <Header />
      )}
      <Switch>
        <Route exact path="/" component={Home} />
        <PrivateRoute exact path="/search" component={Search} />
        <PrivateRoute exact path="/landing" component={Landing} />
        <PrivateRoute exact path="/allfurnace" component={AllFurnance} />
        <PrivateRoute exact path="/cart" component={Cart} />
        <PrivateRoute exact path="/account" component={MyAccount} />
        <Route
          restricted={false}
          path="/application"
          component={NewCustomerInformation}
        />
        <Route
          restricted={false}
          path="/addmoreinfo"
          component={AddMoreInformation}
        />
        <PrivateRoute
          exact
          path="/editSavedCart"
          component={EditSavedDetails}
        />
        <PrivateRoute
          exact
          path="/changePassword"
          component={changePasswordPopup}
        />
        <Route exact path="/helpfulLinks" component={HelpfulLinks} />
        <PrivateRoute
          exact
          path="/shippingTracking"
          component={ShipingTacking}
        />
        <Route
          exact
          path="/editUserInformation"
          component={EditExistingInfoPage}
        />
        {/* <Route exact to="/getFtp" component={GetFtp}/> */}
        <PrivateRoute exact path="/furnance" component={Furnance} />
        <PrivateRoute
          exact
          path="/featureProduct"
          component={FeaturedProducts}
        />
        <PrivateRoute exact path="/drygoods" component={DryGoods} />

        <Route exact path="/termsandcondition" component={TermsAndCondition} />
        <Route exact path="/thankyou" component={Thankyou} />
        <PrivateRoute exact path="/savedCart" component={SavedCart} />
        <PrivateRoute exact path="/checkout" component={Checkout} />

        <PrivateRoute
          exact
          path="/orderConfirmationCustomerDetails"
          component={OrderShippingCustomerInformation}
        />
        <PrivateRoute
          exact
          path="/orderConfirmation"
          component={OrderShippingConfirmation}
        />
        <PrivateRoute
          exact
          path="/uploadBanner"
          component={ImportLogoBannerAdmin}
        />
        <PrivateRoute exact path="/billReceipt" component={BillReceipt} />
        <PrivateRoute path="/cart/:timer" component={Cart} />
        <PrivateRoute exact path="/specialProduct" component={SpecialProduct} />
        <PrivateRoute exact path="/uploadCsv" component={ImportExportCsv} />
        <PrivateRoute exact path="/flyer" component={Flyer} />
      </Switch>
      <Footer />
    </>
  );
};

export default withRouter(Routes);
