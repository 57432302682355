import React from "react";

const CustomDropDown = (props) => {
  const handleDropDown = (e) => {
    props.handleDropDownChange(e.target.value);
  };
  return (
    <select value={props.selectValue} onChange={handleDropDown}>
      {props.defaultValue ? <option>{props.defaultValue}</option> : null}
      {props.filterData.map((data) => {
        return (
          <option key={data} value={data}>
            {data}
          </option>
        );
      })}
    </select>
  );
};

export default CustomDropDown;
