import React, { useState, useEffect } from "react";
import {
  DialogContentText,
  DialogContent,
  Dialog,
  Button,
  DialogTitle,
  Slide,
  Tooltip,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import NoImage from "../../assets/images/NoImageAvailable.jpg";
import "./detailPopupCart.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const DetailsPopupCart = (props) => {
  const handleClose = () => {
    props.onpop1();
  };

  const imageError = (e) => {
    e.target.src = NoImage;
  };
  return (
    <Dialog
      open={props.value}
      fullWidth="true"
      className="details-view-popup"
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" className="popup-header">
        <span className="popup-title">{props.details.name}</span>
        <Close className="float-right popup-close" onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <div className="row">
            <div className="col-md-4">
              <img
                onError={imageError}
                src={props.details.image}
                alt="noimage"
                className="cart-popup-image my-5"
              />
            </div>
            <div className="col-md-8 tblWrp">
              <div id="mx-3i" className="mx-3">
                <div>
                  <h3>{props.details.name}</h3>
                  <p>websku:{props.details.websku}</p>
                  <table className="popup-table admr">
                    <tbody>
                      <tr>
                        <td className="static-table">Size</td>

                        {props.details.similar_items.map((el) => (
                          <td>
                            {el.sku_dtls.size === "" ? "-" : el.sku_dtls.size}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td className="static-table">Quantity Available</td>

                        {props.details.similar_items.map((el) => (
                          <td>{el.sku_dtls.quantity_available}</td>
                        ))}
                      </tr>
                      <tr>
                        <td className="static-table">Price</td>

                        {props.details.similar_items.map((el) => (
                          <td>{el.sku_dtls.retail_price}</td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
export default DetailsPopupCart;
