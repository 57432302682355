import React, { useState } from "react";
import { useDispatch } from "react-redux";

import InputField from "../Input/InputField";
import PrimaryButton from "../PrimaryButton";
import { forgetPassword } from "../../Store/Actions/userActions";
import emittoast from "../../utils/emitToastify";

const ForgotCredentials = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      setError({
        ...error,
        email: "This Value is required",
      });
    } else {
      forgetPassword(
        {
          email: email,
        },
        dispatch
      )
        .then((result) => {
          if (result.data.status) {
            new emittoast(
              true,
              "Email has been sent with Username and Password Information",
              "success",
              dispatch
            ).emittoast();
          } else {
            new emittoast(true, result.data.msg, "error", dispatch).emittoast();
          }
        })
        .catch((error) => {
          new emittoast(true, "unknown Error", "error", dispatch).emittoast();
        });
    }
  };
  const change = (e) => {
    let value = e.target.value;
    setEmail(value);
    setError({});
  };
  return (
    <div className="forgotCredentials">
      <form onSubmit={onSubmit}>
        <h2>Forgot Password ?</h2>
        <InputField
          label="Email address"
          placeholder="Enter your email address"
          type="email"
          name="email"
          onChange={change}
          value={email}
        />
        <span style={{ color: "red" }}>{error["email"]}</span>
        <PrimaryButton type="submit" label="Continue" size="large" />
      </form>
    </div>
  );
};
export default ForgotCredentials;
